import React, { useState, useEffect } from 'react';
import { makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { InputLabel, FormHelperText, FormControl, Select, IconButton, TextField,  DialogContent, MenuItem, Menu, CircularProgress, DialogActions, MenuList, Tooltip, Snackbar, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { MAX_DATA_VIZ, MAX_MAP_DATA_VIZ } from '../App';
import { useSelector, useDispatch } from 'react-redux'
import { fetchChangeFreqSeries } from '../thunks'
import { frequencySettings } from '../definitions/FrequencySettings';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme: any) => ({
  loadingRoot: {
    width: "75px",
    height: "75px",
    color: "#004785"
  },
  dialogRoot: {
    // height: '100%',
    maxWidth: '800px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "30%",
  },
}));

export interface ChangeFrequencyDialogProps {
  open: boolean,
  onClose: () => void,
  editNode?: any,
}

export default function ChangeFrequencyDialog(props: ChangeFrequencyDialogProps) {
  const classes = useStyles();
  const { onClose, open, editNode } = props;
  const[seriesSelected, setSeriesSelected] = useState<string>("");
  const[seriesId, setSeriesId] = useState<string>("");
  const[initialFrequency, setInitialFrequency] = useState<string>("");
  const[mapScale, setMapScale] = useState<string>("");
  const[availableFrequencies, setAvailableFrequencies] = useState<string[]>([]);
  const[targetFrequency, setTargetFrequency] = useState<string>("");
  const[aggregationMethod, setAggregationMethod] = useState<string>("");
  const[skipNa, setSkipNa] = useState<boolean>(true);
  const[messageOpen, setMessageOpen] = useState<boolean>(false);
  const[message, setMessage] = useState<string>("");
  const[messageStatus, setMessageStatus] = useState<boolean>(false);
  const [seriesError, setseriesError] = useState<boolean>(false);
  const [frequencyError, setFrequencyError] = useState<boolean>(false);
  const [aggError, setAggError] = useState<boolean>(false);

  const dispatch = useDispatch()
  const isLoadingVizNode = useSelector((state: any) => state.isLoadingVizNode)
  const nodeCounters = useSelector((state: any) => state.nodeCounters)
  const sessionRecipe = useSelector((state: any) => state.sessionRecipe)

  useEffect(() => {
    // on open
    if (editNode !== undefined && editNode.type === 'transformation' && editNode.transformation_id.indexOf('frequency_changes') !== -1) {
      var id = editNode.input[0]
      var initialFrequencyEditNode = editNode.params.initial_frequency
      var targetFrequencyEditNode = editNode.params.target_frequency
      var aggregationMethodEditNode = editNode.params.aggregation_method
      var skipNaEditNode = editNode.params.skip_na
      var availableFrequenciesEditNode = [] as string[]
      var map_scale = ''

      if (editNode.meta && editNode.meta.chart_attributes && editNode.meta.chart_attributes.map_scale) {
        map_scale = editNode.meta.chart_attributes.map_scale
      }

      var initMaxDays = 184;
      if (frequencySettings[initialFrequencyEditNode] !== undefined) {
        initMaxDays = frequencySettings[initialFrequencyEditNode].maxDays
      }
      if (initialFrequencyEditNode == 'Quarterly') {
        availableFrequenciesEditNode = ['Annual', 'Yearly Ending Mar', 'Yearly Ending Jun', 'Yearly Ending Sep', 'SemiAnnual']
      } else if (initialFrequencyEditNode == 'SemiAnnual') {
        availableFrequenciesEditNode = ['Annual', 'Yearly Ending Jun']
      } else {
        Object.keys(frequencySettings).forEach((label: any) => {
          if (initialFrequencyEditNode !== '') {
            var f = frequencySettings[label]
            if (f.maxDays > initMaxDays) {
              availableFrequenciesEditNode.push(label)
            }
          }
        })
      }

      setSeriesId(id);
      setMapScale(map_scale)
      setInitialFrequency(initialFrequencyEditNode)
      setAvailableFrequencies(availableFrequenciesEditNode)
      setSeriesSelected(id + ';' + initialFrequencyEditNode + ';' + map_scale);
      setTargetFrequency(targetFrequencyEditNode);
      setAggregationMethod(aggregationMethodEditNode);
      setSkipNa(skipNaEditNode)
    }
  }, [open]);
  
  const handleClose = () => {
    setseriesError(false);
    setSeriesId("");
    setMapScale("");
    setInitialFrequency("")
    setAvailableFrequencies([] as string[])
    setSeriesSelected("");
    setTargetFrequency("");
    setAggregationMethod("");
    setSkipNa(true)
    setFrequencyError(false);
    setAggError(false);
    onClose();
  };

  const closeMessage = () =>{
    setMessageOpen(false);
    setMessage("");
  }

  const handleChangeSeries = (event: any) => {
    var selectString = event.target.value
    var selectArray = selectString.split(';')
    var initFreq = selectArray[1]
    setSeriesSelected(selectString);
    setSeriesId(selectArray[0])
    setInitialFrequency(initFreq)
    setMapScale(selectArray[2])

    if (targetFrequency !== '' && frequencySettings[targetFrequency].displayRank >= frequencySettings[initFreq].displayRank) {
      setTargetFrequency('')
    }

    var newAvailFreq = [] as string[]
    var initMaxDays = 184;
    if (frequencySettings[initFreq] !== undefined) {
      initMaxDays = frequencySettings[initFreq].maxDays
    }
    if (initFreq == 'Quarterly') {
      newAvailFreq = ['Annual', 'Yearly Ending Mar', 'Yearly Ending Jun', 'Yearly Ending Sep', 'SemiAnnual']
    } else if (initFreq == 'SemiAnnual') {
      newAvailFreq = ['Annual', 'Yearly Ending Jun']
    } else {
      Object.keys(frequencySettings).forEach((label: any) => {
        if (initFreq !== '') {
          var f = frequencySettings[label]
          if (f.maxDays > initMaxDays) {
            newAvailFreq.push(label)
          }
        }
      })
    }
    setAvailableFrequencies(newAvailFreq)
  };

  const handleChangeFreq = (event: any) => {
    setTargetFrequency(event.target.value)
  };

  const handleChangeAgg = (event: any) => {
    setAggregationMethod(event.target.value)
  };

  const handleChangeSkipNa = (event: any) => {
    setSkipNa(event.target.checked);
  };

  return (
    <div>
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="md" fullWidth={true} scroll='paper' classes={{ paper: classes.dialogRoot }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle >Change Frequency</DialogTitle>
        {/* <Tooltip title="More info" arrow>
          <IconButton  
            style={{ flexGrow: 0, flexShrink: 0, color: '#326b9c', backgroundColor: '#ffffff', textDecoration: 'none', textDecorationColor: 'rgba(255,255,255,0)', margin: '16px 24px', padding: '0' }}  
            target="_blank" href="https://visualization-page.s3.amazonaws.com/How+to+Use+the+Visualization+Page_20210729.pdf#page=18"
          >
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
      </div>
      {editNode !== undefined && <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        <i>Editing {editNode.name}</i><br/>
        Cannot change the target frequency, map scale, or type (i.e. series or map).
      </div>}
      <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        Calculates a series with items of target frequency from the items in the series using the specified aggregation method. Calculation returns null if no non-null values in target period.
      </div>
      <DialogContent style={{ boxSizing: 'border-box', alignSelf: "center", width: "90%", position: 'relative' }}>
        <div style={{ position: 'relative', width: '100%' }}>
          {(isLoadingVizNode) && <div style={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', zIndex: 1, backgroundColor: 'rgba(255,255,255,0.5)' }}><CircularProgress thickness={3} className={classes.loadingRoot} /></div>}
          <div style={{ display: 'flex', flexDirection: 'column', width: '90%', margin: '0px auto' }}>
            <FormControl error={seriesError} className={classes.formControl}>
              <InputLabel id="series-select-label">Series</InputLabel>
              <Select
                labelId="series-select-label"
                id="series-select"
                label="Series"
                value={seriesSelected}
                onChange={handleChangeSeries}
                style={{ fontSize: '16px' }}
              >
                {(sessionRecipe.length > 0 && sessionRecipe.some((node: any) => {
                  if (!node.displayed || (editNode !== undefined && (node.id === editNode.id || !node.meta || !node.meta.ancestors || node.meta.ancestors.includes(editNode.id) || !node.meta.dataType || node.meta.dataType !== editNode.meta.dataType))) {
                    return false
                  }
                  var targetDisplayRank = 0
                  if (targetFrequency !== '' && frequencySettings[targetFrequency] !== undefined) {
                    targetDisplayRank = frequencySettings[targetFrequency].displayRank
                  }
                  if (node.result.series !== null && node.result.series.length > 0 && (editNode !== undefined || nodeCounters.series < MAX_DATA_VIZ)) {
                    var frequency = node.result.series[0].frequency
                    var displayRank = 1000;
                    if (frequencySettings[frequency] !== undefined) {
                      displayRank = frequencySettings[frequency].displayRank
                    } 
                    return displayRank > 11 && displayRank < 1000 && (editNode === undefined || displayRank > targetDisplayRank)
                  } else if (node.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0 && (editNode !== undefined || nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
                    var frequency = node.result.maps[0].children[0].frequency
                    var displayRank = 1000;
                    if (frequencySettings[frequency] !== undefined) {
                      displayRank = frequencySettings[frequency].displayRank
                    } 
                    return displayRank > 11 && displayRank < 1000 && (editNode === undefined || displayRank > targetDisplayRank)
                  } else {
                    return false
                  }
                })) 
                  ? sessionRecipe.map((node: any, i: number) => {
                    var output = [] as any[]
                    // if editing params, restrict to not self, not descendent, and same frequency
                    if (editNode === undefined || (node.id !== editNode.id && node.meta && node.meta.ancestors && !node.meta.ancestors.includes(editNode.id) && node.meta.dataType && node.meta.dataType === editNode.meta.dataType)) {
                      if (node.displayed && (node.meta.dataType === undefined || node.meta.dataType === 'series') && (editNode !== undefined || nodeCounters.series < MAX_DATA_VIZ)) {
                        if (node.result.series !== null && node.result.series.length > 0) {
                          var frequency = node.result.series[0].frequency
                          var displayRank = 1000;
                          if (frequency !== null && frequencySettings[frequency] !== undefined) {
                            displayRank = frequencySettings[frequency].displayRank
                          }
                          var targetDisplayRank = 0
                          if (targetFrequency !== '' && frequencySettings[targetFrequency] !== undefined) {
                            targetDisplayRank = frequencySettings[targetFrequency].displayRank
                          }

                          if (displayRank > 11 && displayRank < 1000 && (editNode === undefined || displayRank > targetDisplayRank)) {
                            output.push(<MenuItem value={node.id + ';' + frequency + ';'} key={node.id + 'freqChange'}>{node.name + ', ' + frequency}</MenuItem>)
                          }
                        }
                      } else if (node.displayed && (node.meta.dataType !== undefined && node.meta.dataType === 'map') && (editNode !== undefined || nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
                        if (node.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0) {
                          var frequency = node.result.maps[0].children[0].frequency
                          var map_scale = node.result.maps[0].map_scale
                          var displayRank = 1000;
                          if (frequency !== null && frequencySettings[frequency] !== undefined) {
                            displayRank = frequencySettings[frequency].displayRank
                          }
                          var targetDisplayRank = 0
                          if (targetFrequency !== '' && frequencySettings[targetFrequency] !== undefined) {
                            targetDisplayRank = frequencySettings[targetFrequency].displayRank
                          }

                          if (displayRank > 11 && displayRank < 1000 && (editNode === undefined || (displayRank > targetDisplayRank && map_scale === mapScale))) {
                            output.push(<MenuItem value={node.id + ';' + frequency + ';' + map_scale} key={node.id + 'freqChange'}>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="mapIcon mapIcon_inSelect">
                                  <div className="mapIcon_map">Map</div>
                                  {node.meta.chart_attributes.map_scale && <div>{(node.meta.chart_attributes.map_location_abbreviation !== '') ? node.meta.chart_attributes.map_location_abbreviation : node.meta.chart_attributes.map_scale}</div>}
                                </div>
                                {node.name + ', ' + frequency}
                              </div>
                            </MenuItem>)
                          }
                        }
                      }
                    }
                    return output
                  })
                  : <MenuItem value=""><em>No series available.</em></MenuItem>
                }
                {(editNode === undefined && nodeCounters.series >= MAX_DATA_VIZ) && <MenuItem value="" disabled><em>Max time series in workspace reached</em></MenuItem>}
                {(editNode === undefined && nodeCounters.maps >= MAX_MAP_DATA_VIZ) && <MenuItem value="" disabled><em>Max maps in workspace reached</em></MenuItem>}
              </Select>
              <FormHelperText>{seriesError ? "*Required" : ""}</FormHelperText>
            </FormControl>
            <FormControl error={frequencyError} className={classes.formControl} disabled={initialFrequency === ''}>
              <InputLabel id="freq-select-label">Target Frequency</InputLabel>
              <Select
                labelId="freq-select-label"
                id="freq-select"
                label="Target Frequency"
                disabled={editNode !== undefined}
                value={targetFrequency}
                onChange={handleChangeFreq}
                style={{ fontSize: '16px' }}
              >
                {availableFrequencies.map((label: any) => <MenuItem value={label} key={label}>{label}</MenuItem>)}
              </Select>
              <FormHelperText>{frequencyError? "*Required. Series can only be made less frequent." : "Series can only be made less frequent."}</FormHelperText>
            </FormControl>
            <FormControl error={aggError} className={classes.formControl}>
              <InputLabel id="agg-select-label">Aggregation Method</InputLabel>
              <Select
                labelId="agg-select-label"
                id="agg-select"
                label="Aggregation Method"
                value={aggregationMethod}
                onChange={handleChangeAgg}
                style={{ fontSize: '16px' }}
              >
                {targetFrequency == 'SemiAnnual' 
                  ? [['sum', 'Sum'], ['mean', 'Average'], ['median', 'Median'], ['min', 'Minimum'], ['max', 'Maximum']].map((aggArray: any) => <MenuItem value={aggArray[0]} key={aggArray[0]}>{aggArray[1]}</MenuItem>) 
                  : [['sum', 'Sum'], ['first', 'First Value'], ['last', 'Last Value'], ['mean', 'Average'], ['median', 'Median'], ['min', 'Minimum'], ['max', 'Maximum']].map((aggArray: any) => <MenuItem value={aggArray[0]} key={aggArray[0]}>{aggArray[1]}</MenuItem>)}
              </Select>
              <FormHelperText>{aggError ? "*Required" : ""}</FormHelperText>
            </FormControl>
            <div className={classes.formControl}>
              <FormControlLabel
                control={<Checkbox checked={skipNa} onChange={handleChangeSkipNa} name="skip na" />}
                label="Exclude missing and null values when computing result so all available values used."
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '16px' }}>
        <Button 
          variant="contained" 
          // style={{ backgroundColor:"#026cb5", color: "white"}}
          disabled={isLoadingVizNode}
          onClick={() => {
            var hasError = false;
            var node: any = sessionRecipe.find((element: any) => element.displayed && element.id === seriesId)
            if (seriesId === "" || node === undefined)
            {
              setseriesError(true);
              hasError = true;
            }
            else
            {
              setseriesError(false);
            }
            if(targetFrequency === "")
            {
              setFrequencyError(true);
              hasError = true;
            }
            else
            {
              setFrequencyError(false);
            }
            if(aggregationMethod === "")
            {
              setAggError(true);
              hasError = true;
            }
            else
            {
              setAggError(false);
            }
            if (!hasError && sessionRecipe.length > 0) {
              setseriesError(false);
              dispatch(fetchChangeFreqSeries(node, initialFrequency, targetFrequency, aggregationMethod, skipNa, editNode))
              if (editNode !== undefined) {
                handleClose()
              }
            }
          }}
        >
          {editNode !== undefined ? 'Update workspace' : 'Add to workspace'}
        </Button>
        <Button onClick={handleClose} variant="contained" style={{ backgroundColor:"#c5093b", color: "white"}} >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
    <Snackbar anchorOrigin={{vertical: "top", horizontal:"center"}} open={messageOpen} autoHideDuration={6000} onClose={closeMessage}>
      <Alert onClose={closeMessage} severity={messageStatus === true? "success" : "error"}>
          {message}
      </Alert>
    </Snackbar>
    </div>
  );
}