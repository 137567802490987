import * as React from 'react';
import { NativeSelect, Tooltip, IconButton, Checkbox, Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import { DataResult } from '../Models/DataResult';
import { frequencySettings } from '../definitions/FrequencySettings';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { removeVizSeries, showChartVizSeries, changeAxisVizSeries, setChartTableLocOptions } from '../actions'
import { setMapPrimary } from '../thunks'
import { useSelector, useDispatch } from 'react-redux'
import ErrorIcon from '@mui/icons-material/Error';
import { InfoOutlined } from '@mui/icons-material';
import InfoDialog from './InfoDialog';



interface VizTableProps {
  // data: DataResult,
  // selectable: boolean,
}

export default function VizTable(props: VizTableProps) {
  const [selected, setSelected] = React.useState<string[]>([]);
  const [infoOpen, setInfoOpen] = React.useState<boolean>(false);
  const [moreInfoRowId, setMoreInfoRowId] = React.useState<string | null>(null);

  const sessionRecipe = useSelector((state: any) => state.sessionRecipe)
  const sessionRecipeInfo = useSelector((state: any) => state.sessionRecipeInfo)
  const addAlert = useSelector((state: any) => state.addAlert)
  const dispatch = useDispatch()

  const vizView = (sessionRecipeInfo && sessionRecipeInfo.meta && sessionRecipeInfo.meta.vizView) ? sessionRecipeInfo.meta.vizView : 'chart'

  const selectable = vizView === 'chart' || vizView === 'table'
  const showY = vizView === 'chart'

  React.useEffect(() => {
    if (addAlert.open && addAlert.isRegression && addAlert.id !== '') {
      handleMoreInfoOpen(addAlert.id)
    }
  }, [addAlert]);

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    var tempRecipe = [...sessionRecipe]
    var currNodeIndex = tempRecipe.findIndex((n: any) => n.id === id)
    if (currNodeIndex !== -1) {
      tempRecipe[currNodeIndex] = {
        ...tempRecipe[currNodeIndex],
        meta: {
          ...tempRecipe[currNodeIndex].meta,
          chart_attributes: {
            ...tempRecipe[currNodeIndex].meta.chart_attributes,
            show: !tempRecipe[currNodeIndex].meta.chart_attributes.show
          }
        }
      }
      dispatch(setChartTableLocOptions(tempRecipe))
    }
    dispatch(showChartVizSeries(id))
  };

  const handleMoreInfoOpen = (value: any) =>{
    setMoreInfoRowId(value);
    setInfoOpen(true);
  }

  const handleCloseMoreInfo = () =>{
    setInfoOpen(false);
    setMoreInfoRowId(null);
  }

  return (
    <React.Fragment>
    <TableContainer component={Paper}>
      {sessionRecipe.length == 0 ? <Table sx={{ minWidth: '400px' }} stickyHeader aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell 
              align='left'
              style={{whiteSpace: "nowrap", fontStyle: "normal", fontWeight: 'bold', fontSize: '16px', color: '#666666'}} 
            >No data in workspace.</TableCell>
          </TableRow>
        </TableBody>
      </Table> : <Table sx={{ minWidth: '400px' }} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            {selectable && <TableCell
              padding="checkbox"
            ></TableCell>}
            <TableCell
              // key={i} 
              align='left'
              style={{ whiteSpace: "nowrap" }}
            >Name</TableCell>
            {showY && <TableCell
              // key={i} 
              align='center'
              style={{ whiteSpace: "nowrap" }}
            >Y-Axis Postion</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sessionRecipe.filter((row: any) => row.displayed).map((row: any, i: number) => {
            // need to add row for each series/map in obj
            const isItemSelected = row.meta !== null && row.meta.chart_attributes && row.meta.chart_attributes.show;
            const labelId = `enhanced-table-checkbox-${i}`;

            return (
              <TableRow
                hover
                onClick={selectable ? (event) => handleClick(event, row.id) : undefined}
                role="checkbox"
                aria-checked={selectable && isItemSelected}
                tabIndex={-1}
                key={row.name + '-' + i}
                selected={selectable && isItemSelected}
              >
                {selectable && <TableCell
                  padding="checkbox"
                >
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </TableCell>}
                <TableCell
                  // key={column.id} 
                  align='left'
                  id={labelId}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                      {(row.meta.error !== undefined && row.meta.error !== null) && <ErrorIcon color="error" style={{ marginRight: '10px' }} />}
                      {((row.meta.dataType !== undefined && row.meta.dataType === 'map') || row.result.maps !== null) && <div className="mapIcon">
                        <div className="mapIcon_map">Map</div>
                        {(row.meta.chart_attributes.map_location_abbreviation !== '') ? row.meta.chart_attributes.map_location_abbreviation : row.meta.chart_attributes.map_scale}
                      </div>}
                      {(row.meta.error !== undefined && row.meta.error !== null) && 'ERROR: '}
                      {row.name}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                      <Tooltip title="Info">
                        <IconButton onClick={(event) =>{
                              handleMoreInfoOpen(row.id);
                              event.stopPropagation();
                        }}   style={{ flexGrow: 0, flexShrink: 0, textDecoration: 'none', color: '#004785', textDecorationColor: 'rgba(255,255,255,0)', marginLeft: '8px', padding: '1px', fontSize: '20px', borderRadius: '4px' }}>
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={"Remove from workspace"} arrow>
                        <IconButton
                          style={{ flexGrow: 0, flexShrink: 0, textDecoration: 'none', color: '#c5093b', textDecorationColor: 'rgba(255,255,255,0)', marginLeft: '8px', padding: '1px', fontSize: '20px', borderRadius: '4px' }}
                          onClick={(() => {
                            dispatch(removeVizSeries(row.id, row.meta.dataType !== undefined ? row.meta.dataType : 'series'))
                            dispatch(setChartTableLocOptions(sessionRecipe.filter((n: any) => n.id !== row.id)))
                            if (sessionRecipeInfo.meta.mapPrimaryId === row.id) {
                              dispatch(setMapPrimary(null));
                            }
                          })}
                        >
                          <ClearIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </TableCell>
                {showY && <TableCell
                  // key={column.id} 
                  align='center'
                  id={labelId}
                >
                  {(row.meta.error === undefined || row.meta.error === null) && <FormControl style={{ minWidth: '70px' }}>
                    <NativeSelect
                      defaultValue={(row.meta !== null && row.meta.chart_attributes && row.meta.chart_attributes.axisRight) ? 'right' : 'left'}
                      inputProps={{
                        name: 'Y-Axis Postion',
                        id: 'axisRight-' + row.id,
                      }}
                      style={{ fontSize: '14px' }}
                      onClick={(event: any) => { event.stopPropagation() }}
                      onChange={(event: any) => {
                        var axisRight = event.target.value === 'right'
                        dispatch(changeAxisVizSeries(row.id, axisRight))
                      }}
                    >
                      <option value="left">
                        Left
                      </option>
                      <option value="right">
                        Right
                      </option>
                    </NativeSelect>
                  </FormControl>}
                </TableCell>}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>}
    </TableContainer>
    {moreInfoRowId != null && <InfoDialog id={moreInfoRowId} open={infoOpen} onClose={handleCloseMoreInfo} />}
    </React.Fragment>
  );
} 