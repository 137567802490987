import * as React from 'react';
import { Radio, Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Collapse, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { DataResult } from '../Models/DataResult';
import { frequencySettings } from '../definitions/FrequencySettings';
import DataTable from './DataTable';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';



interface MapDataTableProps {
  data: DataResult,
  handleRowClick: (selected: number[]) => void,
  selectable: boolean,
  selected: number[] | null
}

function Row(props: { row: any, dataInfo: DataResult, selectable: boolean, isItemSelected: boolean, handleClick: (event: React.MouseEvent<unknown>, rowNum: number) => void, labelId: string, index: number }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow 
        // sx={{ '& > *': { borderBottom: 'unset !important' } }}
        hover
        onClick={props.selectable ? (event) => props.handleClick(event, props.index) : undefined}
        role="checkbox"
        aria-checked={props.selectable && props.isItemSelected}
        tabIndex={-1}
        key={row.name + '-' + props.index}
        selected={props.selectable && props.isItemSelected}
      >
        {props.selectable && <TableCell component="th" padding="checkbox" 
          // sx={{
          //   position: "sticky",
          //   left: 0,
          //   background: "white",
          //   // boxShadow: "2px 0px 5px grey",
          //   // borderRight: '2px solid rgb(224, 224, 224)',
          // }}
        >
          <Radio
            color="primary"
            checked={props.isItemSelected}
            inputProps={{
              'aria-labelledby': props.labelId,
            }}
          />
        </TableCell>}
        <TableCell component="th" padding="checkbox">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(event) => { event.stopPropagation(); setOpen(!open)}}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align={'left'}>
          {row.name}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div style={{ width: '100%', overflow: 'auto', maxHeight: '500px' }}>
              <DataTable data={{...props.dataInfo, maps: null, series: row.children}} selectable={false} showLocation={true} selected={null} mapTable={true} />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function MapDataViewDialog(props: MapDataTableProps) {
  const [rows, setRows] = useState<any[]>([] as any[]);
  const [columns, setColumns] = useState<any[]>([] as any[]);
  const [selected, setSelected] = React.useState<number[]>([]);

  React.useEffect(() =>{
    // console.log(props);
    if (props.data.maps !== null && props.data.maps.length > 0) {
      var parsedData = [] as any[]
      props.data.maps.forEach((m) => {
        var rowData = {
          name: m.name,
          children: [...m.children]
        } as any

        rowData.children.sort((a: any, b: any) => {
          if (a.locationCode === b.locationCode) {
            return a.location.localeCompare(b.location)
          } else {
            if (a.locationCode === '-1') {
              return 1
            }
            if (b.locationCode === '-1') {
              return -1
            }
            return a.locationCode.localeCompare(b.locationCode)
          }
        })

        parsedData.push(rowData)
      })

      setRows(parsedData)

      // console.log(parsedData);

      var c = [{id: "name", label: "Name", align: 'left', }] as any[];

      setColumns(c);
    }
    if(props.selected != null)
    {
      setSelected(props.selected);
    }
  }, [props])

  const handleClick = (event: React.MouseEvent<unknown>, rowNum: number) => {
    const selectedIndex = selected.indexOf(rowNum);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = [rowNum];
    } else {
      newSelected = [] as number[];
    }

    setSelected(newSelected);
    props.handleRowClick(newSelected)
  };

  const isSelected = (rowNum: number) => selected.indexOf(rowNum) !== -1;

  return ( 
    <TableContainer component={Paper}>
      {rows.length > 0 && <Table sx={{ minWidth: 650, tableLayout: 'fixed' }} stickyHeader aria-label="simple table">
        {/* <TableHead>
          <TableRow>
            {props.selectable && <TableCell 
              padding="checkbox"
              sx={{
                position: "sticky",
                left: 0,
                background: "white",
                // boxShadow: "2px 0px 5px grey",
                // borderRight: '2px solid rgb(224, 224, 224)',
                borderBottomWidth: '2px',
                zIndex: 3,
              }}
            ></TableCell>}
            <TableCell 
              padding="checkbox"
              sx={{
                position: "sticky",
                left: 0,
                background: "white",
                // boxShadow: "2px 0px 5px grey",
                // borderRight: '2px solid rgb(224, 224, 224)',
                borderBottomWidth: '2px',
                zIndex: 3,
              }}
            ></TableCell>
            {columns.map((c: any, i: number) =>{
              return(
                <TableCell 
                  key={i} 
                  align={c.align}
                  style={{whiteSpace: "nowrap"}} 
                  sx={i === 1 ? {
                    position: "sticky",
                    left: 45,
                    background: "white",
                    // boxShadow: "2px 0px 5px grey",
                    borderRight: '2px solid rgb(224, 224, 224)',
                    borderBottomWidth: '2px',
                    zIndex: 3,
                  } : {
                    borderBottomWidth: '2px',
                  }}
                >{c.label}</TableCell>
              )
            })}
          </TableRow>
        </TableHead> */}
        <TableBody>
          {rows.map((row, i) => {
            const isItemSelected = isSelected(i);
            const labelId = `enhanced-table-checkbox-${i}`;

            return (
              <Row row={row} dataInfo={props.data} selectable={props.selectable} isItemSelected={isItemSelected} labelId={labelId} handleClick={handleClick} index={i} />
              // <TableRow 
              //   hover
              //   onClick={props.selectable ? (event) => handleClick(event, i) : undefined}
              //   role="checkbox"
              //   aria-checked={props.selectable && isItemSelected}
              //   tabIndex={-1}
              //   key={row.name}
              //   selected={props.selectable && isItemSelected}
              // >
              //   {props.selectable && <TableCell padding="checkbox" sx={{
              //     position: "sticky",
              //     left: 0,
              //     background: "white",
              //     // boxShadow: "2px 0px 5px grey",
              //     // borderRight: '2px solid rgb(224, 224, 224)',
              //   }}>
              //     <Checkbox
              //       color="primary"
              //       checked={isItemSelected}
              //       inputProps={{
              //         'aria-labelledby': labelId,
              //       }}
              //     />
              //   </TableCell>}
              //   {columns.map((column) => {
              //     const value = row[column.id];
              //     return (
              //       <TableCell 
              //         key={column.id} 
              //         align={column.align}
              //         id={column.id === 'name' ? labelId : undefined}
              //         sx={column.id === 'name' ? {
              //           position: "sticky",
              //           left: 45,
              //           background: "white",
              //           // boxShadow: "2px 0px 5px grey",
              //           borderRight: '2px solid rgb(224, 224, 224)',
              //         } : undefined}
              //       >
              //         {value}
              //       </TableCell>
              //     );
              //   })}
              // </TableRow>
            );
          })}
        </TableBody>
      </Table>}
    </TableContainer>
  );
}