import React, {  useState, useEffect } from 'react';
import { makeStyles, withStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { InputLabel, FormHelperText, FormControl, Select, IconButton, TextField,  DialogContent, MenuItem, Menu, CircularProgress, DialogActions, MenuList, Tooltip, Snackbar, Typography, Input, Chip, Checkbox, ListItemText, SelectChangeEvent } from '@mui/material';
import { Alert, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import ReactHtmlParser from 'react-html-parser';
import InfoIcon from '@mui/icons-material';

const Styledbutton = withStyles((theme: any) => ({
  root: {
    color: "black",
    backgroundColor: "white",
  },
  selected: {
    color: "white !important",
    backgroundColor: "#004785 !important"
  }
}))(ToggleButton);

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    height: '100%',
    maxWidth: '1200px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: "30%",
    margin: '0px 5% 10px 5%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    fontSize: '15px',
    margin: 2,
  },
  regressionSelectRoot: {
    paddingBottom: '5px',
    '&:focus': {
      backgroundColor: 'initial',
    },
  },
}));

export interface RegressionResultsDialogProps {
  open: boolean,
  onClose: () => void,
}

function formatNumberDecimal(x: string) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

function buildComparisonTable(regressionSeriess: any[]) {
  var top = [
    '<th></th>',
    '<td>Dep. Variable</td>'
  ] as string[];
  var middle = [] as string[];
  var bottom = [
    '<td>R-squared</td>',
    '<td>No. Observations</td>',
    '<td>Included Effects</td>'
  ] as string[];
  var independent_ids = [] as string[];
  var independent_short_names = [] as string[];
  regressionSeriess.forEach((series: any, i: number) => {
    top[0] += '<th colspan="2" >' + series.id + '</th>'
    top[1] += '<td colspan="2" >' + series.result.transformation_info.dependent_shortName + '</td>'
    bottom[0] += '<td colspan="2" >' + formatNumberDecimal(parseFloat(series.result.transformation_info.r_squared).toFixed(3)) + '</td>'
    bottom[1] += '<td colspan="2" >' + formatNumberDecimal(series.result.transformation_info.n_obs) + '</td>'
    if (series.result.transformation_info.included_effects === undefined) {
      bottom[2] += '<td colspan="2" >n/a</td>'
    } else if (series.result.transformation_info.included_effects.length > 0) {
      bottom[2] += '<td colspan="2" >' + series.result.transformation_info.included_effects.join(', ') + '</td>'
    } else {
      bottom[2] += '<td colspan="2" >None</td>'
    }

    series.result.transformation_info.params_info.forEach((param: any) => {
      if (!independent_ids.includes(param.id)) {
        if (param.id === 'const') {
          independent_ids.unshift('const')
          middle.unshift('<td>Constant</td>')
        } else {
          var shortName = param.shortName
          // if (independent_short_names.includes(shortName)) {
          //   var i = 1
          //   while (independent_short_names.includes(shortName + '[' + i + ']')) { i++ }
          //   independent_short_names.push(shortName + '[' + i + ']')
          // } else {
          //   independent_short_names.push(shortName)
          // }
          independent_ids.push(param.id)
          middle.push('<td>' + shortName + '</td>')
        }
      }
    })
  })

  independent_ids.forEach((id: string, i: number) => {
    regressionSeriess.forEach((series: any) => {
      //if has id add cells with info if not blank cells
      var param = series.result.transformation_info.params_info.find((s: any) => s.id === id)
      if (param === undefined) {
        middle[i] += '<td style="text-align: right;"></td>'
        middle[i] += '<td style="text-align: left;"></td>'
      } else {
        middle[i] += '<td style="text-align: right; padding: 7px 0px 7px 6px;">' + formatNumberDecimal(parseFloat(param.coefficient).toFixed(3))
                     + '<br>(' + formatNumberDecimal(parseFloat(param.standard_error).toFixed(3)) + ')</td>'
        middle[i] += '<td style="text-align: left; vertical-align: top; padding: 7px 20px 7px 0px;">' + param.significance_asterisks + '</td>'
      }
    })

  })

  // console.log(independent_ids)
  // console.log(middle)

  var output = '<table class="ols-regression-comparison">';
  top.forEach((row:string) => output += '<tr>' + row +  '</tr>')
  middle.forEach((row:string) => output += '<tr>' + row +  '</tr>')
  bottom.forEach((row:string) => output += '<tr>' + row +  '</tr>')
  output += '</table>'

  return output
}

// function buildSeriesReference(regressionSeriess: any[]) {}

export default function RegressionResultsDialog(props: RegressionResultsDialogProps) {
  const classes = useStyles();
  const { onClose, open } = props;
  const[optionsArray, setOptionsArray] = useState<string[]>([]);
  const[selectedArray, setSelectedArray] = useState<string[]>([]);
  const[tableType, setTableType] = useState<string>('detail');

  const sessionRecipe = useSelector((state: any) => state.sessionRecipe)

  useEffect(() => {
    var newOptions = sessionRecipe.filter((node: any, i: number) => node.displayed && node.type === 'transformation' && node.transformation_id.indexOf('regressions') !== -1 && (node.result.series !== null || node.result.maps !== null)).map((s: any, i: number) => s.id)
    var newSelected = [] as string[]
    newOptions.forEach((id: string) => {
      if (selectedArray.includes(id) || !optionsArray.includes(id)) {
        newSelected.push(id)
      }
    })
    setOptionsArray(newOptions)
    setSelectedArray(newSelected)
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleChangeSelected = (event: SelectChangeEvent<string[]>) => {
    setSelectedArray(event.target.value as string[]);
  };

  return (
    <div>
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="md" fullWidth={true} scroll='paper' classes={{ paper: classes.dialogRoot }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle >Regression Results</DialogTitle>
        {/* <Tooltip title="More info" arrow>
          <IconButton  
            style={{ flexGrow: 0, flexShrink: 0, color: '#326b9c', backgroundColor: '#ffffff', textDecoration: 'none', textDecorationColor: 'rgba(255,255,255,0)', margin: '16px 24px', padding: '0' }}  
            target="_blank" href="https://visualization-page.s3.amazonaws.com/How+to+Use+the+Visualization+Page_20210729.pdf#page=21"
          >
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
      </div>
      <FormControl className={classes.formControl}>
        <InputLabel id="selected_regressions_label">Series</InputLabel>
        <Select
          labelId="selected_regressions_label"
          id="selected_regressions"
          multiple
          value={selectedArray}
          onChange={handleChangeSelected}
          input={<Input />}
          renderValue={(selected: any) => (
            <div className={classes.chips}>
              {selected.map((value: any) => ( // need to update to using name not val
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          classes={{ root: classes.regressionSelectRoot }}
        >
          {optionsArray.length > 0
            ? sessionRecipe.map((s: any, i: number) => {
              if (optionsArray.includes(s.id)) {
                var shortName = s.name
                if (shortName.length > 90) {
                  shortName = shortName.substring(0,87) + '...'
                }
                return <MenuItem key={s.id + 'regress' +i} value={s.id}>
                  <Checkbox checked={selectedArray.includes(s.id)} />
                  <ListItemText primary={shortName} />
                </MenuItem>
              }
            })
            : <MenuItem value=""><em>No regression results available.</em></MenuItem>
          }
        </Select>
      </FormControl>
      <ToggleButtonGroup
        exclusive
        size="small"
        className={classes.formControl}
      >
        <Styledbutton 
          value="detail" 
          selected={tableType === 'detail'}
          onClick={() => {
            setTableType('detail');
          }}
        >
          Detail
        </Styledbutton>
        <Styledbutton 
          value ="compare" 
          selected={tableType === 'compare'}
          onClick={() => { 
            setTableType('compare');
          }}
        >
          Compare
        </Styledbutton>
      </ToggleButtonGroup>
      <DialogContent style={{ boxSizing: 'border-box', alignSelf: "center", width: "90%", position: 'relative', height: '100%' }}>
        {selectedArray.length < 1 
          ? <div style={{ textAlign: 'center', fontStyle: 'italic', color: '#666666', marginTop: '17%' }}>No series selected.</div>
          : tableType === 'detail' ? <div style={{ display: 'flex', flexDirection: 'column', margin: '0px' }}>
            {sessionRecipe.map((s: any, i: number) => { 
              if (selectedArray.includes(s.id) && s.result.transformation_info) {
                return <div style={{ marginBottom: '40px'}}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: '#004785' }}>{s.name}</Typography>
                  <div style={{ overflowX: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>{ReactHtmlParser(s.result.transformation_info.description_html)}</div>
                </div>
              }
            })}
          </div>
          : tableType === 'compare' ? <div>
            <div style={{ overflowX: 'auto', margin: '0px' }}>
              {ReactHtmlParser(
                buildComparisonTable(
                  sessionRecipe.filter((s: any, i: number) => selectedArray.includes(s.id))
                )
              )}
            </div>
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <div style={{ marginRight: '10px', fontWeight: 'bold' }}>
                Key:
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div>Coefficient</div>
                  <div>(Standard Error)</div>
                </div>
                <div style={{ marginTop: '10px' }}>
                  * P&gt;|t|  <span style={{ fontSize: '1.3em' }}>&lt;</span>  0.05,  ** P&gt;|t|  <span style={{ fontSize: '1.3em' }}>&lt;</span>  0.01,  *** P&gt;|t|  <span style={{ fontSize: '1.3em' }}>&lt;</span>  0.001
                </div>
              </div>
            </div>
          </div>
          : <div></div>
        }
      </DialogContent>
      <DialogActions style={{ padding: '16px' }}>
        <Button onClick={handleClose} variant="contained" style={{ backgroundColor:"#c5093b", color: "white"}} >
          close
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
}