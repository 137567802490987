import * as React from 'react';
import { endpoint } from '../App';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { List, ListItem, Typography, CircularProgress, Tooltip, IconButton, TextField, Link, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { frequencySettings } from '../definitions/FrequencySettings';
import { stateInfo } from '../definitions/StateInfo';
import ReactHtmlParser from 'react-html-parser';
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import ChangeUnitDialog from './ChangeUnitDialog'
import ChangeFrequencyDialog from './ChangeFrequencyDialog'
import CalculateDialog from './CalculateDialog'
import RunRegressionDialog from './RunRegressionDialog'
import DataTable from '../components/DataTable';
import { useSelector, useDispatch } from 'react-redux'
import { updateRecipeData } from '../thunks'


interface InfoDialogProps {
    open: boolean,
    onClose: () => void,
    id: string | null
}

function formatNumberDecimal(x: string) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export default function InfoDialog(props: InfoDialogProps) {
    const sessionRecipeInfo = useSelector((state: any) => state.sessionRecipeInfo)
    const sessionRecipe = useSelector((state: any) => state.sessionRecipe)
    const node = props.id === null ? null : sessionRecipe.find((n:any) => n.id === props.id)
    const dispatch = useDispatch()

    const [scraperInfo, setScraperInfo] = React.useState<any>(null);
    const [openChangeUnit, setOpenChangeUnit] = React.useState(false);
    const [openChangeFreq, setOpenChangeFreq] = React.useState(false);
    const [openCalculate, setOpenCalculate] = React.useState(false);
    const [openRunRegression, setOpenRunRegression] = React.useState(false);
    const [openNameEdit, setOpenNameEdit] = React.useState(false);
    const [nameEdit, setNameEdit] = React.useState<string>("");
    const [nameEditError, setNameEditError] = React.useState(false);
    const [minimum, setMinimum] = React.useState<string>("");
    const [maximum, setMaximum] = React.useState<string>("");
    const [average, setAverage] = React.useState<string>("");
    const [median, setMedian] = React.useState<string>("");
    const [regressionEffectsShowAll, setRegressionEffectsShowAll] = React.useState(false);
    React.useEffect(() => {
        if (props.open === true && node !== null && node !== undefined) {
            if (node.result.series != null && node.result.series.length > 0) {
                var pointsFilterNull = node.result.series[0].data_points.filter((p: any) => p.value !== null)
                if (pointsFilterNull.length > 0) {
                    var min = pointsFilterNull[0].value;
                    var max = pointsFilterNull[0].value;
                    var total = 0;
                    var values = [] as number[];
                    pointsFilterNull.forEach((p: any) => {
                        if (min > p.value) {
                            min = p.value;
                        }
                        if (max < p.value) {
                            max = p.value;
                        }
                        total += p.value;
                        values.push(p.value);
                    });
                    setAverage((total / values.length).toFixed(2).toString());
                    setMinimum(min);
                    setMaximum(max);
                    values.sort((n1, n2) => n1 - n2);
                    if (values.length % 2 === 0) {
                        var val1 = values[values.length / 2];
                        var val2 = values[(values.length / 2) - 1];
                        setMedian(((val1 + val2) / 2).toString());
                    }
                    else {
                        setMedian(values[(values.length / 2 - 0.5)].toString())
                    }
                } else {
                    setMinimum('n/a')
                    setMaximum('n/a')
                    setAverage('n/a')
                    setMedian('n/a')
                }

                const fetchScraperInfo = async () => {
                    try {
                        const requestOptions = {
                            method: 'GET',
                            headers: { 'Content-Type': 'application/json' },
                        }
                        const scraperInfoResponse = await fetch(endpoint + "scrapers/" + node.result.search.scraper_id, requestOptions);
                        if (!scraperInfoResponse.ok) {
                            throw Error(scraperInfoResponse.statusText);
                        } else {
                            var sInfo = await scraperInfoResponse.json();
                            setScraperInfo(sInfo)
                            // console.log(sInfo)
                        }
                    } catch (e) {
                        console.log(e)
                        setScraperInfo(null)
                    }
                }
                fetchScraperInfo()
            }
        }
    }, [props.open, node])
    // console.log(props);
    const handleClose = () => {
        props.onClose()
        setScraperInfo(null)
        setOpenChangeUnit(false)
        setOpenChangeFreq(false)
        setOpenCalculate(false)
        setOpenRunRegression(false)
        setOpenNameEdit(false)
        setNameEdit("")
        setNameEditError(false)
        setMinimum("")
        setMaximum("")
        setAverage("")
        setMedian("")
    };

    const submitNewName = () =>{
        var nameEditTrim = nameEdit.trim()
        if (nameEditTrim === node.name) {
            setOpenNameEdit(false)
        } else {
            if (sessionRecipe.some((n: any) => n.id !== node.id && (n.name.trim() === nameEditTrim || n.id.trim() === nameEditTrim))) {
                setNameEditError(true)
            } else {
                var editNode: any = {
                    ...node,
                    name: nameEditTrim,
                    meta: {
                        ...node.meta,
                        aliasCreated: true,
                        original_name: node.meta.aliasCreated ? node.meta.original_name : node.name,
                    }
                }
                if (nameEditTrim === editNode.meta.original_name.trim()) {
                    editNode.meta.aliasCreated = false
                }
                dispatch(updateRecipeData(editNode))
                setOpenNameEdit(false)
                setNameEditError(false)
            }
        }
    }
    
    const handleChangeRegressionEffectsShowAll = (
        event: React.MouseEvent<HTMLElement>,
        newShowAll: string,
    ) => {
        setRegressionEffectsShowAll(newShowAll === 'showAll');
    };

    // console.log(node.result.search.dg_parameters);
    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            maxWidth="md" fullWidth={true}
        >
            <div style={{ position: 'relative', width: '100%' }}>
                {(sessionRecipeInfo.loading) && <div style={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', zIndex: 4, backgroundColor: 'rgba(255,255,255,0.5)' }}><CircularProgress thickness={3} style={{ width: "75px", height: "75px", color: "#004785" }} /></div>}
                <DialogTitle id="alert-dialog-title">
                    {openNameEdit ? <div style={{ display: 'flex', alignItems: 'center', }}>
                        <TextField 
                          fullWidth
                          autoFocus
                          label={"Edit name" + ((node.meta && node.meta.aliasCreated) ? ' *' : '')}
                          helperText="New name must be unique."
                          value={nameEdit} error={nameEditError}
                          onChange={(event: any) => { setNameEdit(event.target.value) }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter" && nameEdit.trim() !== '') {
                                submitNewName()
                            }
                          }}
                        />
                        <Tooltip title={'Apply new name'} arrow>
                            <IconButton disabled={nameEdit.trim() === ''} color='success' style={{ fontSize: '22px', borderRadius: '4px', marginLeft: '10px' }} onClick={submitNewName}>
                                <CheckIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Cancel'} arrow>
                            <IconButton style={{ color: 'rgb(197, 9, 59)', fontSize: '22px', borderRadius: '4px', marginLeft: '10px' }} onClick={() => { setOpenNameEdit(false) }}>
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </div> : <div style={{ display: 'flex', alignItems: 'center', }}>
                        {(node.meta.error !== undefined && node.meta.error !== null) && <ErrorIcon color="error" style={{ marginRight: '10px' }} />}
                        {((node.meta.dataType !== undefined && node.meta.dataType === 'map') || node.result.maps !== null) && <div className="mapIcon" style={{ fontSize: '0.45em', padding: '5px 7px' }}>
                            <div className="mapIcon_map">Map</div>
                            {node.meta.chart_attributes.map_scale && <div>{(node.meta.chart_attributes.map_location_abbreviation !== '') ? node.meta.chart_attributes.map_location_abbreviation : node.meta.chart_attributes.map_scale}</div>}
                        </div>}
                        {(node.meta.error !== undefined && node.meta.error !== null) && 'ERROR: '}
                        {node.name} {(node.meta && node.meta.aliasCreated) && ' *'}
                        <Tooltip title={"Edit " + (((node.meta.dataType !== undefined && node.meta.dataType === 'map') || node.result.maps !== null) ? 'map' : 'series') + ' name'} arrow>
                            <IconButton style={{ color: '#FFCC00', fontSize: '22px', borderRadius: '4px', marginLeft: '10px' }} onClick={() => {
                                setOpenNameEdit(true)
                                setNameEdit(node.name)
                                setNameEditError(false)
                            }}>
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </div>}
                    {(node.meta && node.meta.aliasCreated) && <div>
                        <Typography style={{ fontStyle: 'italic' }} variant="body1">* Originally named: {node.meta.original_name}</Typography>
                    </div>}
                </DialogTitle>
                <DialogContent>
                    {node.type === 'transformation' && <Button variant="outlined" style={{ marginBottom: '10px' }} onClick={() => { 
                        if (node.transformation_id.indexOf('frequency_changes') !== -1) {
                            setOpenChangeFreq(true)
                        } else if (node.transformation_id.indexOf('unit_changes') !== -1) {
                            setOpenChangeUnit(true)
                        } else if (node.transformation_id.indexOf('calculations') !== -1) {
                            setOpenCalculate(true)
                        } else if (node.transformation_id.indexOf('regressions') !== -1) {
                            setOpenRunRegression(true)
                        }
                    }}>Edit Parameters</Button>}
                    {(node.meta.error !== undefined && node.meta.error !== null) ? <DialogContentText id="alert-dialog-description">
                        {/* TODO: add in selected params if transform? */}
                        {node.meta.error.details}<br/>{node.meta.error.raw_error}
                    </DialogContentText> : <DialogContentText id="alert-dialog-description">
                        {node.type === 'transformation' ? <div>
                            <Typography style={{ paddingBottom: "15px" }} variant="body1">{ReactHtmlParser(node.result.transformation_info.description_html)}</Typography>
                            {(node.transformation_id.indexOf('regressions.PanelOLS') !== -1 && (node.params.entity_effects || node.params.time_effects) && node.result.transformation_info.estimated_effects) && <div style={{ marginBottom: '20px' }}>
                                {/* TODO: location selector? */}
                                {!endpoint.includes('prod-wmdb') && <div style={{ paddingBottom: "15px", display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontWeight: 'bold' }} variant="body1">Estimated Effects</Typography>
                                    <ToggleButtonGroup
                                        color="primary"
                                        size="small"
                                        value={regressionEffectsShowAll ? 'showAll' : ''}
                                        exclusive
                                        onChange={handleChangeRegressionEffectsShowAll}
                                        style={{ marginLeft: '15px' }}
                                        disabled={node.params.entity_effects && node.params.time_effects}
                                    >
                                        <ToggleButton value="showAll">Dev Only - Show All</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>}
                                <DataTable 
                                    selectable={false} 
                                    showLocation={node.params.entity_effects || regressionEffectsShowAll}
                                    // locSelectedObj={chartTableLocSelectedObj}
                                    selected={null}
                                    mapTable={true}
                                    data={{ 
                                        maps: null,
                                        series: (regressionEffectsShowAll || (node.params.entity_effects && node.params.time_effects)) ? node.result.transformation_info.estimated_effects.children : node.params.entity_effects ? node.result.transformation_info.estimated_effects.children.map((s: any) => { return {...s, data_points: [{ ...s.data_points[0], key: 'Estimated Effects' }] } }) : [node.result.transformation_info.estimated_effects.children[0]],
                                        name: node.result.transformation_info.estimated_effects.name,
                                        description: '',
                                        search: null,
                                        date_range: null,
                                        other_metadata: null,
                                    }}
                                />
                            </div>}
                        </div> : <div>
                            <Typography variant="body1"><strong>Description</strong></Typography>
                            <Typography style={{ paddingBottom: "15px" }} variant="body1">{node.result.description}</Typography>
                        </div>}
                        <Typography variant="body1"><strong>Frequency</strong></Typography>
                        <Typography style={{ paddingBottom: "15px" }} variant="body1">
                            {(node.result.series != null && node.result.series.length > 0) ? node.result.series[0].frequency 
                            : (node.result.maps != null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0) ? node.result.maps[0].children[0].frequency
                            : <i>No data</i>}
                        </Typography>
                        <Typography variant="body1"><strong>Source</strong></Typography>
                        {node.result.search !== null ? 
                            scraperInfo !== null ? <Typography style={{ paddingBottom: "15px" }} variant="body1">
                                {scraperInfo.source_url ? <Link href={scraperInfo.source_url}>{scraperInfo.name}</Link> : scraperInfo.name}{scraperInfo.description && <span> - <i>{scraperInfo.description}</i></span>}
                            </Typography> : <Typography style={{ paddingBottom: "15px" }} variant="body1">
                                {node.result.search.scraper_name}
                            </Typography>
                        : <Typography style={{ paddingBottom: "15px" }} variant="body1">
                            Created in workspace
                        </Typography>}
                        {(node.result.search != null && node.result.search.dg_parameters != null) && Object.keys(node.result.search.dg_parameters).length > 0 &&
                            <React.Fragment>
                                <Typography variant="body1"><strong>Search parameters Used</strong></Typography>
                                <List>
                                    {Object.keys(node.result.search.dg_parameters).map((k, i: number) => {
                                        return (
                                            <ListItem><strong>{k}</strong>: {node.result.search.dg_parameters[k].toString()}</ListItem>
                                        )
                                    })}
                                </List>
                            </React.Fragment>
                        }
                        {(node.result.maps != null && node.result.maps.length > 0) && <div>
                            <Typography variant="body1"><strong>Locations</strong></Typography>
                            <Typography style={{ paddingBottom: "15px" }} variant="body1">{(node.meta && node.meta.chart_attributes && node.meta.chart_attributes.locationsObj) ? node.meta.chart_attributes.locationsObj.map((s: any) => s.location).join('; ')
                            : node.result.maps[0].children.map((s: any) => s.location).join('; ')}</Typography>
                        </div>}
                        {(node.result.series != null && node.result.series.length > 0) && <div style={{ width: "100%", display: "flex" }}>
                            <div style={{ width: "50%", display: "block" }}>
                                <Typography variant="body1"><strong>Start</strong></Typography>
                                <Typography style={{ paddingBottom: "15px" }} variant="body1">{frequencySettings[node.result.series[0].frequency].dateFormatter(node.result.series[0].data_points[0].key)}</Typography>
                            </div>
                            <div style={{ width: "50%", display: "block" }}>
                                <Typography variant="body1"><strong>End</strong></Typography>
                                <Typography style={{ paddingBottom: "15px" }} variant="body1">{frequencySettings[node.result.series[0].frequency].dateFormatter(node.result.series[0].data_points[node.result.series[0].data_points.length - 1].key)}</Typography>
                            </div>
                        </div>}
                        {(node.result.series != null && node.result.series.length > 0) && <React.Fragment>
                            <Typography variant="body1"><strong>Summary Stats</strong></Typography>
                            <Typography variant="body1">Min: {formatNumberDecimal(minimum)}</Typography>
                            <Typography variant="body1">Max: {formatNumberDecimal(maximum)}</Typography>
                            <Typography variant="body1">Average: {formatNumberDecimal(average)}</Typography>
                            <Typography variant="body1">Median: {formatNumberDecimal(median)}</Typography>
                        </React.Fragment>}

                    </DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: "rgb(197, 9, 59)", color: "white" }} onClick={handleClose}>Close</Button>
                </DialogActions>
            </div>
            <ChangeUnitDialog open={openChangeUnit} onClose={() => { setOpenChangeUnit(false) }} editNode={node} />
            <ChangeFrequencyDialog open={openChangeFreq} onClose={() => { setOpenChangeFreq(false) }} editNode={node} />
            <CalculateDialog open={openCalculate} onClose={() => { setOpenCalculate(false) }} editNode={node} />
            <RunRegressionDialog open={openRunRegression} onClose={() => { setOpenRunRegression(false) }} editNode={node} />
        </Dialog>
    );
}