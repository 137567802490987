import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore, persistConfig } from './store';
import { persistStore, getStoredState, REHYDRATE } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react'

const store = configureStore()
const persistor = persistStore(store)

function crossBrowserListener(store: any, persistConfig: any) {
  return async function() {
    let state = await getStoredState(persistConfig)

    store.dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state,
    })
  }
}

window.addEventListener('storage', crossBrowserListener(store, persistConfig));

ReactDOM.render(
  <Provider store={store}>
		<PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      <React.StrictMode>
        <BrowserRouter>
        <App />
        </BrowserRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
