import React, {  useState, useEffect } from 'react';
import { endpoint } from '../App';
import { makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { InputLabel, FormHelperText, FormControl, Select, IconButton, TextField,  DialogContent, MenuItem, Menu, CircularProgress, DialogActions, MenuList, Tooltip, Snackbar, List, ListItem, FormControlLabel, Checkbox, Input, Accordion, AccordionSummary, AccordionDetails, Typography, Link, ListItemText } from '@mui/material';
import { Alert } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { stateInfo } from '../definitions/StateInfo';
import { MAX_DATA_VIZ, MAX_MAP_DATA_VIZ } from '../App';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux'
import { fetchOLSRegressionSeries, fetchPanelOLSRegressionSeries } from '../thunks'
import { frequencySettings } from '../definitions/FrequencySettings';
import { mapScaleInfo } from '../definitions/MapScaleInfo';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme: any) => ({
  loadingRoot: {
    width: "75px",
    height: "75px",
    color: "#004785"
  },
  dialogRoot: {
    // height: '100%',
    maxWidth: '800px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "30%",
  },
  buttondisabled:{
    backgroundColor: "grey!important",
    color: "white!important"
  },
  checkbox_label: {
    fontSize: '0.9em'
  }
}));

export interface RunRegressionDialogProps {
  open: boolean,
  onClose: () => void,
  editNode?: any,
}

export default function RunRegressionDialog(props: RunRegressionDialogProps) {
  const classes = useStyles();
  const { onClose, open, editNode } = props;
  const[dependentSelected, setDependentSelected] = useState<string>("");
  const[frequency, setFrequency] = useState<string>("");
  const[mapScale, setMapScaleState] = useState<string>("");
  const[mapName, setMapName] = useState<string>("");
  const[isDependentMap, setIsDependentMap] = useState<boolean>(false);
  const[messageOpen, setMessageOpen] = useState<boolean>(false);
  const[message, setMessage] = useState<string>("");
  const[messageStatus, setMessageStatus] = useState<boolean>(false);
  const[dependentError, setDependentError] = useState<boolean>(false);
  const [independentVars, setIndependentVars] = useState<string[]>([""] as string[]);
  const [independentError, setIndependentError] = useState<boolean>(false);
  const[addConst, setAddConst] = useState<boolean>(true);
  const[entityEffects, setEntityEffects] = useState<boolean>(false);
  const[entityEffectsDisabled, setEntityEffectsDisabled] = useState<boolean>(true);
  const[entityEffectsMapScale, setEntityEffectsMapScale] = useState<string>("");
  const[entityEffectsShowAll, setEntityEffectsShowAll] = useState<boolean>(false);
  const[timeEffects, setTimeEffects] = useState<boolean>(false);
  const[timeEffectsDisabled, setTimeEffectsDisabled] = useState<boolean>(true);
  const[availablePeriods, setAvailablePeriods] = useState<string[]>([] as string[]);
  const[start, setStart] = useState<string>('');
  const[end, setEnd] = useState<string>('');
  const[dropPeriods, setDropPeriods] = useState<string[]>([] as string[]);
  const[availableLocations, setAvailableLocations] = useState<any[]>([] as any[]);
  const[dropLocations, setDropLocations] = useState<any[]>([] as any[]);
  const[showMore, setShowMore] = useState<boolean>(false);
  const [editMapError, setEditMapError] = useState<boolean>(false);

  const dispatch = useDispatch()
  const isLoadingVizNode = useSelector((state: any) => state.isLoadingVizNode)
  const nodeCounters = useSelector((state: any) => state.nodeCounters)
  const sessionRecipe = useSelector((state: any) => state.sessionRecipe)

  useEffect(() => {
    // on open
    if (editNode !== undefined && editNode.type === 'transformation' && editNode.transformation_id.indexOf('regressions') !== -1) {
      // common to OLS and Panel OLS
      var dependentId = editNode.params.dependent_id
      var frequencyEditNode = editNode.params.frequency
      var addConstEditNode = editNode.params.add_const
      var startPeriodEditNode = editNode.params.start_period ? editNode.params.start_period : ''
      var endPeriodEditNode = editNode.params.end_period ? editNode.params.end_period : ''
      var dropPeriodsEditNode = editNode.params.drop_periods ? editNode.params.drop_periods : [] as string[]
      // vary by regression type
      var independentVarsEditNode = [''] as string[]
      var isDependentMapEditNode = false
      var entityEffectsEditNode = false
      var entityEffectsMapScaleEditNode = ''
      var entityEffectsShowAllEditNode = false
      var timeEffectsEditNode = false
      var dropLocationsEditNode = [] as any[]
      // determine from selected
      var entityEffectsDisabledEditNode = true
      var timeEffectsDisabledEditNode = true
      var availablePeriodsEditNode = [] as string[]
      var availableLocationsEditNode = [] as any[]
      var map_scale = ''
      var map_name = ''

      if (editNode.meta && editNode.meta.chart_attributes && editNode.meta.chart_attributes.map_scale) {
        map_scale = editNode.meta.chart_attributes.map_scale
        if (editNode.meta.chart_attributes.map_location_name) {
          map_name = editNode.meta.chart_attributes.map_location_name
        }
      }

      if (editNode.transformation_id.split('.')[1] === 'OLS') {
        independentVarsEditNode = editNode.params.independent_ids.map((id: string) => id + ';ts')
        var currSeries = sessionRecipe.find((element: any) => element.id === dependentId)
        if (currSeries !== undefined && currSeries.result.series !== null && currSeries.result.series.length > 0) {
          currSeries.result.series[0].data_points.forEach((item: any) => {
            if (item.value !== null) {
              availablePeriodsEditNode.push(item.key)
            }
          })
        }
      } else if (editNode.transformation_id.split('.')[1] === 'PanelOLS') {
        independentVarsEditNode = editNode.params.time_independent_ids.map((id: string) => id + ';ts').concat(editNode.params.map_independent_ids.map((id: string) => id + ';map'))
        isDependentMapEditNode = editNode.params.dependent_isMap
        entityEffectsEditNode = editNode.params.entity_effects
        entityEffectsMapScaleEditNode = editNode.params.entity_effects_map_scale ? editNode.params.entity_effects_map_scale : map_scale
        entityEffectsShowAllEditNode = editNode.params.entity_effects_show_all ? true : false
        timeEffectsEditNode = editNode.params.time_effects
        dropLocationsEditNode = editNode.params.drop_locations ? editNode.params.drop_locations : [] as any[]
        entityEffectsDisabledEditNode = false
        if (editNode.params.time_independent_ids.length === 0) {
          timeEffectsDisabledEditNode = false
        }

        if (isDependentMapEditNode) {
          var availPeriodSet = new Set();
          var currMapSeries = sessionRecipe.find((element: any) => element.id === dependentId)
          if (currMapSeries !== undefined && currMapSeries.result.maps !== null && currMapSeries.result.maps.length > 0) {
            currMapSeries.result.maps[0].children.forEach((s: any) => {
              availableLocationsEditNode.push({ location: s.location, locationCode: s.locationCode })
              s.data_points.forEach((item: any) => {
                if (item.value !== null) {
                  availPeriodSet.add(item.key)
                }
              })
            })
            availPeriodSet.forEach((p: any) => { availablePeriodsEditNode.push(p) })
          }
        } else {
          var currSeries = sessionRecipe.find((element: any) => element.id === dependentId)
          if (currSeries !== undefined && currSeries.result.series !== null && currSeries.result.series.length > 0) {
            currSeries.result.series[0].data_points.forEach((item: any) => {
              if (item.value !== null) {
                availablePeriodsEditNode.push(item.key)
              }
            })
            var indMapStr = independentVarsEditNode.find((idStr: string) => idStr !== '' && idStr.split(';')[1] === 'map')
            if (indMapStr !== undefined) {
              var indMap = sessionRecipe.find((element: any) => element.id === indMapStr?.split(';')[0])
              if (indMap !== undefined && indMap.result.maps !== null && indMap.result.maps.length > 0) {
                indMap.result.maps[0].children.forEach((s: any) => {
                  availableLocationsEditNode.push({ location: s.location, locationCode: s.locationCode })
                })
              }
            }
          }
        }
      }

      availablePeriodsEditNode.sort()
      availableLocationsEditNode.sort((a: any, b: any) => {
        if (a.locationCode === '-1' && b.locationCode === '-1') {
          return a.location.localeCompare(b.location)
        } else if (a.locationCode === '-1') {
          return 1
        } else if (b.locationCode === '-1') {
          return -1
        } else {
          return a.locationCode.localeCompare(b.locationCode)
        }
      })

      setDependentSelected(dependentId);
      setIndependentVars(independentVarsEditNode);
      setFrequency(frequencyEditNode);
      setMapScaleState(map_scale);
      setMapName(map_name);
      setIsDependentMap(isDependentMapEditNode);
      setAddConst(addConstEditNode);
      setEntityEffects(entityEffectsEditNode)
      setEntityEffectsDisabled(entityEffectsDisabledEditNode)
      setEntityEffectsMapScale(entityEffectsMapScaleEditNode)
      setEntityEffectsShowAll(entityEffectsShowAllEditNode)
      setTimeEffects(timeEffectsEditNode)
      setTimeEffectsDisabled(timeEffectsDisabledEditNode)
      setAvailablePeriods(availablePeriodsEditNode)
      setStart(startPeriodEditNode);
      setEnd(endPeriodEditNode)
      setDropPeriods(dropPeriodsEditNode)
      setAvailableLocations(availableLocationsEditNode)
      setDropLocations(dropLocationsEditNode)
    }
  }, [open]);

  const handleClose = () => {
    // console.log(independentVars);
    setDependentSelected("");
    setIndependentVars([""]);
    setFrequency("");
    setMapScale("");
    setMapName("");
    setIsDependentMap(false);
    setDependentError(false);
    setIndependentError(false);
    setAddConst(true);
    setEntityEffects(false)
    setEntityEffectsDisabled(true)
    setEntityEffectsMapScale("")
    setEntityEffectsShowAll(true)
    setTimeEffects(false)
    setTimeEffectsDisabled(true)
    setAvailablePeriods([] as string[])
    setStart('');
    setEnd('')
    setDropPeriods([] as string[])
    setAvailableLocations([] as any[])
    setDropLocations([] as any[])
    setShowMore(false)
    setEditMapError(false)
    onClose();
  };

  const closeMessage = () =>{
    setMessageOpen(false);
    setMessage("");
  }

  const setMapScale = (map_scale: string) => {
    if (map_scale !== mapScale) {
      setMapScaleState(map_scale)
      setEntityEffectsMapScale(map_scale)
    }
  }

  const handleChangeDependent = (event: any) => {
    var selectString = event.target.value
    var selectArray = selectString.split(';')
    var newIndependentVars = independentVars
    setDependentSelected(selectArray[0]);
    if(frequency !== "" && frequency !== selectArray[1])
    {
      newIndependentVars = newIndependentVars.map(() => '')
    }
    setFrequency(selectArray[1]);
    setIsDependentMap(selectArray[2] === 'map')
    setStart('');
    setEnd('')
    setDropPeriods([] as string[])

    var newAvailPeriods = [] as string[]
    var newAvailLocations = [] as any[]
    if (selectArray[2] === 'map') {
      handleChangeEntityEffectsDisable(false)
      var availPeriodSet = new Set();
      var currMapSeries = sessionRecipe.find((element: any) => element.id === selectArray[0])

      if (currMapSeries !== undefined && currMapSeries.result.maps !== null && currMapSeries.result.maps.length > 0) {
        var currMapScale = currMapSeries.result.maps[0].map_scale
        var currMapName = currMapSeries.meta.chart_attributes.map_location_name
        setMapScale(currMapScale)
        setMapName(currMapName)
        newIndependentVars = newIndependentVars.map((idStr: string) => {
          var validIndVar = idStr === '' || idStr.split(';')[1] === 'ts' || (idStr.split(';')[1] === 'map' && idStr.split(';')[0] !== selectArray[0])
          var indMap = sessionRecipe.find((element: any) => element.id === idStr?.split(';')[0])
          if (validIndVar && indMap !== undefined && indMap.result.maps !== null && indMap.result.maps.length > 0) {
            var map_scale = indMap.result.maps[0].map_scale
            var map_name = indMap.meta.chart_attributes.map_location_name
            var mapScale_ancestor = []
            if (mapScaleInfo[currMapScale]) {
              mapScale_ancestor = mapScaleInfo[currMapScale].ancestors
            }
            validIndVar = mapScale_ancestor.includes(map_scale) || (
              map_scale === currMapScale 
              && (currMapName === '' 
                || map_name === currMapName 
                || (
                  (map_scale === 'county' || map_scale === 'city') && (map_name.includes('US ') || currMapName.includes('US '))
                )
              )
            )
          }
          return validIndVar ? idStr : ''
        })
        if (newIndependentVars.length === 0) {
          newIndependentVars = ['']
        }
        currMapSeries.result.maps[0].children.forEach((s: any) => {
          newAvailLocations.push({ location: s.location, locationCode: s.locationCode })
          s.data_points.forEach((item: any) => {
            if (item.value !== null) {
              availPeriodSet.add(item.key)
            }
          })
        })
        availPeriodSet.forEach((p: any) => { newAvailPeriods.push(p) })
      }
    } else {
      handleChangeEntityEffectsDisable(true)
      setMapScale('')
      setMapName('')
      newIndependentVars = newIndependentVars.map((idStr: string) => idStr === '' || (idStr.split(';')[1] === 'ts' && idStr.split(';')[0] !== selectArray[0]) ? idStr : '')
      var currSeries = sessionRecipe.find((element: any) => element.id === selectArray[0])
      if (currSeries !== undefined && currSeries.result.series !== null && currSeries.result.series.length > 0) {
        currSeries.result.series[0].data_points.forEach((item: any) => {
          if (item.value !== null) {
            newAvailPeriods.push(item.key)
          }
        })
      }
    }
    newAvailPeriods.sort()
    newAvailLocations.sort((a: any, b: any) => {
      if (a.locationCode === '-1' && b.locationCode === '-1') {
        return a.location.localeCompare(b.location)
      } else if (a.locationCode === '-1') {
        return 1
      } else if (b.locationCode === '-1') {
        return -1
      } else {
        return a.locationCode.localeCompare(b.locationCode)
      }
    })
    setAvailablePeriods(newAvailPeriods)
    handleChangeAvailableLocations(newAvailLocations)
    setIndependentVars(newIndependentVars)
  };

  const addIndVar = () =>{
    var temp = Object.assign([], independentVars) as string[];
    temp.push("");
    setIndependentVars(temp);
  }

  const selectIndVar = (position: number, event: React.ChangeEvent<{ value: unknown }>) =>{
    // NOTE: currently restricting independent to maps equivalent or ancestor of dependent. back-end module could handle independent that are more granular than dependent
    var temp = Object.assign([], independentVars) as string[];
    temp[position] = event.target.value as string;
    setIndependentVars(temp);
    handleChangeTimeEffectsDisable(temp.some((idStr: string) => idStr !== '' && idStr.split(';')[1] === 'ts'))

    if (!isDependentMap) {
      handleChangeAvailableLocations([] as any[])
      setMapScale('')
      setMapName('')
    }
  }

  const removeVar = (position: number) =>{
    var temp = Object.assign([], independentVars) as string[];
    temp.splice(position, 1);
    // console.log(temp);
    setIndependentVars(temp);
    handleChangeTimeEffectsDisable(temp.some((idStr: string) => idStr !== '' && idStr.split(';')[1] === 'ts'))
  }

  const handleChangeAddConst = (event: any) => {
    setAddConst(event.target.checked);
  };

  const handleChangeEntityEffects = (event: any) => {
    setEntityEffects(event.target.checked);
  };

  const handleChangeEntityEffectsDisable = (isDisabled: boolean) => {
    setEntityEffectsDisabled(isDisabled);
    if (isDisabled) {
      setEntityEffects(false);
    }
  };

  const handleChangeEntityScale = (event: any) => {

    setEntityEffectsMapScale(event.target.value);

  };

  const handleChangeEntityEffectsShowAll = (event: any) => {
    setEntityEffectsShowAll(event.target.checked);
  };

  const handleChangeTimeEffects = (event: any) => {
    setTimeEffects(event.target.checked);
  };

  const handleChangeTimeEffectsDisable = (isDisabled: boolean) => {
    setTimeEffectsDisabled(isDisabled);
    if (isDisabled) {
      setTimeEffects(false);
    }
  };

  const handleChangeAvailableLocations = (locations: any[]) => {
    setAvailableLocations(locations);
    var newDropLocations = [] as any[]
    dropLocations.forEach((l: any) => {
      if (locations.includes((loc: any) => loc.locationCode === l.locationCode && (loc.locationCode !== '-1' || loc.location === l.location))) {
        newDropLocations.push(l)
      }
    })
    setDropLocations(newDropLocations)
  };

  const handleChangeStart = (event: any) => {

    setStart(event.target.value);

    if (end !== '' && Date.parse(event.target.value) >= Date.parse(end)) {
      setEnd('')
    }
    
  };

  const handleChangeEnd = (event: any) => {

    setEnd(event.target.value);

    if (start !== '' && Date.parse(event.target.value) <= Date.parse(start)) {
      setStart('')
    }
    
  };


  const handleDropPeriods = (event: any) => {

    setDropPeriods(event.target.value);
    
  };


  return (
    <div>
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="md" fullWidth={true} scroll='paper' classes={{ paper: classes.dialogRoot }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle >Run Regression</DialogTitle>
        {/* <Tooltip title="More info" arrow>
          <IconButton  
            style={{ flexGrow: 0, flexShrink: 0, color: '#326b9c', backgroundColor: '#ffffff', textDecoration: 'none', textDecorationColor: 'rgba(255,255,255,0)', margin: '16px 24px', padding: '0' }}  
            target="_blank" href="https://visualization-page.s3.amazonaws.com/How+to+Use+the+Visualization+Page_20210729.pdf#page=20"
          >
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
      </div>
      {editNode !== undefined && <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        <i>Editing {editNode.name}</i><br/>
        <span style={{ color: editMapError ? '#d32f2f' : 'inherit' }}>Cannot change the frequency, map scale, or type (i.e. series or map).</span>
      </div>}
      <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        Uses an Ordinary Least Squares (OLS) regression model to compute a fitted series and generate summary information available in Regression Results.&nbsp;
        {showMore ? 
          <span>
            All series must have the same frequency to be used in a regression and only the intersection of the series considered for analysis. Frequency of the dependent variable will determine the independent variable options. If any variables are maps (indexed by time and state), the data will be treated as panel data and the resulting fitted values will be a map. Entity (fixed) effects available only if at least one variable is a map. Time effects are only available if all independent variables are maps. Please see <Link href ="https://www.statsmodels.org/stable/generated/statsmodels.regression.linear_model.OLS.html">statsmodels</Link> and <Link href ="https://bashtage.github.io/linearmodels/panel/panel/linearmodels.panel.model.PanelOLS.html">linearmodels</Link> for more information on the packages used for calculation. 
            <Button color="primary" onClick={e => {e.stopPropagation(); setShowMore(false)}} style={{fontSize: "smaller"}}>Show Less</Button>
          </span> 
          : <Button color="primary" onClick={e => {e.stopPropagation(); setShowMore(true)}} style={{fontSize: "smaller"}}>Show More</Button>}
      </div>
      <DialogContent style={{ boxSizing: 'border-box', alignSelf: "center", width: "90%", position: 'relative' }}>
        <div style={{ position: 'relative', width: '100%' }}>
          {(isLoadingVizNode) && <div style={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', zIndex: 1, backgroundColor: 'rgba(255,255,255,0.5)' }}><CircularProgress thickness={3} className={classes.loadingRoot} /></div>}
          <div style={{ display: 'flex', flexDirection: 'column', width: '90%', margin: '0px auto' }}>
            <FormControl error={dependentError} className={classes.formControl}>
              <InputLabel id="series-select-label">Dependent Variable</InputLabel>
              <Select
                labelId="series-select-label"
                id="series-select"
                label="Dependent Variable"
                value={dependentSelected === '' ? '' : isDependentMap ? dependentSelected + ";" + frequency + ';map' : dependentSelected + ";" + frequency + ';ts'}
                onChange={handleChangeDependent}
                // style={{ fontSize: '16px' }}
              >
                {(sessionRecipe.length > 0) 
                  ? sessionRecipe.map((node: any, i: number) => {
                    var output = [] as any[]
                    // if editing params, restrict to not self, not descendent, and same frequency. allow all time series.
                    if (editNode === undefined || (node.id !== editNode.id && node.meta && node.meta.ancestors && !node.meta.ancestors.includes(editNode.id) && node.meta.dataType && (node.meta.dataType === editNode.meta.dataType))) {
                      if (node.displayed && (node.meta.dataType === undefined || node.meta.dataType === 'series') && (editNode !== undefined || nodeCounters.series < MAX_DATA_VIZ || nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
                        if (node.result.series !== null && node.result.series.length > 0) {
                          var f = node.result.series[0].frequency
                          var displayRank = 1000;
                          if (f !== null && frequencySettings[f] !== undefined) {
                            displayRank = frequencySettings[f].displayRank
                          }
                          if (displayRank < 1000 && (editNode === undefined || f === frequency)) {
                            output.push(<MenuItem value={node.id + ';' + f + ';ts'} key={node.id + 'regression'}>{node.name + ', ' + f}</MenuItem>)
                          }
                        }
                      } else if (node.displayed && (node.meta.dataType !== undefined && node.meta.dataType === 'map') && (editNode !== undefined || nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
                        if (node.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0) {
                          var f = node.result.maps[0].children[0].frequency
                          var map_scale = node.result.maps[0].map_scale
                          // var map_name = node.meta.chart_attributes.map_location_name
                          var displayRank = 1000;
                          if (f !== null && frequencySettings[f] !== undefined) {
                            displayRank = frequencySettings[f].displayRank
                          }
                          if (displayRank < 1000 && (editNode === undefined || (f === frequency && map_scale === mapScale))) {
                            output.push(<MenuItem value={node.id + ';' + f + ';map'} key={node.id + 'calculate'}>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="mapIcon mapIcon_inSelect">
                                  <div className="mapIcon_map">Map</div>
                                  {node.meta.chart_attributes.map_scale && <div>{(node.meta.chart_attributes.map_location_abbreviation !== '') ? node.meta.chart_attributes.map_location_abbreviation : node.meta.chart_attributes.map_scale}</div>}
                                </div>
                                {node.name + ', ' + f}
                              </div>
                            </MenuItem>)
                          }
                        }
                      }
                    }
                    return output
                  })
                  : <MenuItem value=""><em>No series available.</em></MenuItem>
                }
                {(nodeCounters.series >= MAX_DATA_VIZ && nodeCounters.maps >= MAX_MAP_DATA_VIZ) && <MenuItem value="" disabled><em>Max time series in workspace reached</em></MenuItem>}
                {nodeCounters.maps >= MAX_MAP_DATA_VIZ && <MenuItem value="" disabled><em>Max maps in workspace reached</em></MenuItem>}
              </Select>
              <FormHelperText>{dependentError ? "*Required" : ""}</FormHelperText>
            </FormControl>
            {independentVars.length > 0 && 
            <List>
              {independentVars.map((v, i) =>{
                return(
                  <ListItem key={v + i}>
                        <Tooltip title="Remove Independent Variable">
                              <IconButton disabled={independentVars.length === 1} aria-label="add" onClick={(event: any) => removeVar(i)}
                              classes={{
                                disabled: classes.buttondisabled
                              }}
                                style={{ backgroundColor:"#c5093b", color: "white", padding: "5px", marginLeft: "5px"}} >
                                  <RemoveIcon />
                              </IconButton>
                          </Tooltip>
                      <FormControl style={{width: "70%"}} className={classes.formControl}>
                            <InputLabel id={i + "ind-label"}>Independent Variable</InputLabel>
                            <Select
                              error={independentError === true && v === ""}
                              labelId={i + "ind-label"}
                              id={i + "ind"}
                              label="Independent Variable"
                              value={v}
                              disabled={dependentSelected === "" || (nodeCounters.series >= MAX_DATA_VIZ && nodeCounters.maps >= MAX_MAP_DATA_VIZ)}
                              onChange={(event: any) => selectIndVar(i, event)}
                            >
                              {(sessionRecipe.length > 0) 
                                ? sessionRecipe.map((node: any, i: number) => {
                                  var output = [] as any[]
                                  if (editNode === undefined || (node.id !== editNode.id && node.meta && node.meta.ancestors && !node.meta.ancestors.includes(editNode.id) && node.meta.dataType && (node.meta.dataType === 'series' || node.meta.dataType === editNode.meta.dataType))) {
                                    if (node.displayed && (node.meta.dataType === undefined || node.meta.dataType === 'series') && (editNode !== undefined || nodeCounters.series < MAX_DATA_VIZ || nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
                                      if (node.result.series !== null && node.result.series.length > 0) {
                                        var f = node.result.series[0].frequency
                                        var displayRank = 1000;
                                        if (f !== null && frequencySettings[f] !== undefined) {
                                          displayRank = frequencySettings[f].displayRank
                                        }
                                        if (displayRank < 1000 && frequency === f) {
                                          if (dependentSelected !== node.id && !independentVars.includes(node.id + ';ts')) {
                                            output.push(<MenuItem value={node.id + ';ts'} key={node.id + 'calculate'}>{node.name + ', ' + f}</MenuItem>)
                                          } else {
                                            output.push(<MenuItem disabled value={node.id + ';ts'} key={node.id + 'calculate'}>{node.name + ', ' + f}</MenuItem>)
                                          }
                                        }
                                      }
                                    } else if (node.displayed && (node.meta.dataType !== undefined && node.meta.dataType === 'map') && (editNode !== undefined || nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
                                      if (node.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0) {
                                        var f = node.result.maps[0].children[0].frequency
                                        var map_scale = node.result.maps[0].map_scale
                                        var map_name = node.meta.chart_attributes.map_location_name
                                        var displayRank = 1000;
                                        if (f !== null && frequencySettings[f] !== undefined) {
                                          displayRank = frequencySettings[f].displayRank
                                        }
                                        var mapScale_ancestor = []
                                        if (mapScaleInfo[mapScale]) {
                                          mapScale_ancestor = mapScaleInfo[mapScale].ancestors
                                        }
                                        if (displayRank < 1000 && frequency === f && mapScale !== ''
                                          && (
                                            (map_scale === mapScale && (mapName === '' || map_name === mapName || ((map_scale === 'county' || map_scale === 'city') && (map_name.includes('US ') || mapName.includes('US ')))))
                                            || mapScale_ancestor.includes(map_scale)
                                          )
                                        ) {
                                          output.push(<MenuItem value={node.id + ';map'} key={node.id + 'calculate'} disabled={dependentSelected === node.id || independentVars.includes(node.id + ';map')}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                              <div className="mapIcon mapIcon_inSelect">
                                                <div className="mapIcon_map">Map</div>
                                                {node.meta.chart_attributes.map_scale && <div>{(node.meta.chart_attributes.map_location_abbreviation !== '') ? node.meta.chart_attributes.map_location_abbreviation : node.meta.chart_attributes.map_scale}</div>}
                                              </div>
                                              {node.name + ', ' + f}
                                            </div>
                                          </MenuItem>)
                                        }
                                      }
                                    }
                                  }
                                  return output
                                })
                                : <MenuItem value=""><em>No series available.</em></MenuItem>
                              }
                              {(editNode === undefined && nodeCounters.series >= MAX_DATA_VIZ && nodeCounters.maps >= MAX_MAP_DATA_VIZ) && <MenuItem value="" disabled><em>Max time series in workspace reached</em></MenuItem>}
                              {(editNode === undefined && nodeCounters.maps >= MAX_MAP_DATA_VIZ) && <MenuItem value="" disabled><em>Max maps in workspace reached</em></MenuItem>}
                            </Select>
                            <FormHelperText>{independentError ? "*Required" : ""}</FormHelperText>
                      </FormControl>
                  </ListItem>
                )
              })}
              </List>}
            <Tooltip title="Add Independent Variable">
                <IconButton aria-label="add" disabled={dependentSelected === ""} 
                classes={{
                  disabled: classes.buttondisabled
                }}
                onClick={addIndVar}
                    style={{ backgroundColor:"#026cb5", color: "white", padding: "5px", width: "35px"}} >
                      <AddIcon />
                </IconButton>
            </Tooltip>
            <div className={classes.formControl}>
              <div>
                <FormControlLabel
                  control={<Checkbox size="small" checked={addConst} onChange={handleChangeAddConst}  name="add constant" />}
                  label="Add a constant (a.k.a. intercept) to the RHS of model."
                  classes={{ label: classes.checkbox_label }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  control={<Checkbox size="small" disabled={entityEffectsDisabled} checked={entityEffects} onChange={handleChangeEntityEffects} name="add entity effects" />}
                  label="Include entity (fixed) effects in the model."
                  classes={{ label: classes.checkbox_label }}
                />
                {(!entityEffectsDisabled && entityEffects) && <div style={{ display: 'flex' }}>
                  <FormControl style={{ width: '100%', maxWidth: '300px' }}>
                    <InputLabel id="entity-scale-select-label">Group by Map Level</InputLabel>
                    <Select
                      labelId="entity-scale-select-label"
                      id="entity-scale-select"
                      label="Group by Map Level"
                      disabled={!mapScaleInfo[mapScale] || mapScale === 'country' || mapScale === 'state'}
                      value={entityEffectsMapScale}
                      onChange={handleChangeEntityScale}
                      style={{ fontSize: '16px' }}
                    >
                      {[mapScale].concat(mapScaleInfo[mapScale] ? mapScaleInfo[mapScale].ancestors.filter((m: string) => m !== 'country') : []).map((m: string, i: number) => { 
                        return <MenuItem value={m} key={m + 'entity-scale-select'}>{m === '' ? m : m[0].toUpperCase() + m.slice(1)}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  {(!endpoint.includes('prod-wmdb') && entityEffectsMapScale !== '' && entityEffectsMapScale !== mapScale) && <FormControlLabel
                    control={<Checkbox size="small" checked={entityEffectsShowAll} onChange={handleChangeEntityEffectsShowAll} name="show all effects" />}
                    label="DEV - Show effects for all observations."
                    classes={{ label: classes.checkbox_label }}
                    style={{ marginLeft: '15px' }}
                  />}
                </div>}
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox size="small" disabled={timeEffectsDisabled} checked={timeEffects} onChange={handleChangeTimeEffects} name="add time effects" />}
                  label="Include time effects in the model."
                  classes={{ label: classes.checkbox_label }}
                />
              </div>
            </div>
            <Accordion className={classes.formControl}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography  variant="body2">Limit observations</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <div className={classes.formControl} style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <FormControl style={{ width: '100%', maxWidth: '220px' }}>
                      <InputLabel id="start-select-label">Start period*</InputLabel>
                      <Select
                        labelId="start-select-label"
                        id="start-select"
                        label="Start period*"
                        disabled={dependentSelected === ''}
                        value={start}
                        onChange={handleChangeStart}
                        // displayEmpty
                        style={{ fontSize: '16px' }}
                      >
                        {dependentSelected !== '' 
                          ? [[<MenuItem value="" key={'null-unitChange-start'}>All periods</MenuItem>]].concat(availablePeriods.map((p: string, i: number) => { 
                            var periodDisplay = frequencySettings['NotSet'].dateFormatter(p);
                            if (frequencySettings[frequency] !== undefined) {
                              periodDisplay = frequencySettings[frequency].dateFormatter(p);
                            }
                            return <MenuItem value={p} key={p + 'unitChange-start'}>{periodDisplay}</MenuItem>
                          }))
                          : <MenuItem value="">All periods</MenuItem>
                        }
                      </Select>
                    </FormControl>
                    <FormControl style={{ width: '100%', maxWidth: '220px', marginLeft: '16px' }}>
                      <InputLabel id="end-select-label">End period*</InputLabel>
                      <Select
                        labelId="end-select-label"
                        id="end-select"
                        label="End period*"
                        disabled={dependentSelected === ''}
                        value={end}
                        onChange={handleChangeEnd}
                        // displayEmpty
                        style={{ fontSize: '16px' }}
                      >
                        {dependentSelected !== '' 
                          ? [[<MenuItem value="" key={'null-unitChange-start'}>All periods</MenuItem>]].concat(availablePeriods.map((p: string, i: number) => { 
                            var periodDisplay = frequencySettings['NotSet'].dateFormatter(p);
                            if (frequencySettings[frequency] !== undefined) {
                              periodDisplay = frequencySettings[frequency].dateFormatter(p);
                            }
                            return <MenuItem value={p} key={p + 'unitChange-start'}>{periodDisplay}</MenuItem>
                          }))
                          : <MenuItem value="">All periods</MenuItem>
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="drop-select-label">Drop periods*</InputLabel>
                    <Select
                      labelId="drop-select-label"
                      id="drop-select"
                      label="Drop periods*"
                      disabled={dependentSelected === ''}
                      value={dropPeriods}
                      onChange={handleDropPeriods}
                      multiple
                      input={<Input />}
                      renderValue={(selected: any) => {
                        var output = [] as string[]
                        selected.sort()
                        selected.forEach((item: any, i: number) => {
                          var periodDisplay = frequencySettings['NotSet'].dateFormatter(item);
                          if (frequencySettings[frequency] !== undefined) {
                            periodDisplay = frequencySettings[frequency].dateFormatter(item);
                          }
                          output.push(periodDisplay)
                        })

                        return output.join('; ')
                      }}
                      style={{ fontSize: '16px' }}
                    >
                      {availablePeriods.map((p: string, i: number) => { 
                        var periodDisplay = frequencySettings['NotSet'].dateFormatter(p);
                        if (frequencySettings[frequency] !== undefined) {
                          periodDisplay = frequencySettings[frequency].dateFormatter(p);
                        }
                        return <MenuItem value={p} key={p + 'unitChange-start'}>
                          <Checkbox checked={dropPeriods.indexOf(p) > -1} />
                          <ListItemText primary={periodDisplay} />
                        </MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      fullWidth={true}
                      multiple
                      options={availableLocations}
                      value={dropLocations}
                      isOptionEqualToValue={(l: any, o: any) => l.locationCode === o.locationCode && (l.locationCode !== '-1' || l.location === o.location)}
                      disabled={availableLocations.length === 0}
                      size='small'
                      disableCloseOnSelect
                      limitTags={10}
                      filterOptions={createFilterOptions({
                        stringify: (option: any) => {
                          return (option.locationCode === '-1' || mapScale === 'city' || mapScale === 'county') ? option.location : stateInfo[option.locationCode] === undefined ? option.locationCode + ' - ' + option.location : stateInfo[option.locationCode].abbreviation + ' - ' + stateInfo[option.locationCode].name
                        },
                      })}
                      getOptionLabel={(option: any) => (option.locationCode === '-1' || mapScale === 'city' || mapScale === 'county') ? option.location : stateInfo[option.locationCode] === undefined ? option.locationCode : stateInfo[option.locationCode].abbreviation}
                      onChange={(event: any, value: any) => {
                        setDropLocations(value)
                      }}
                      renderOption={(props: any, option: any, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            size='small'
                            checked={selected}
                          />
                          <span style={{ fontSize: '0.95em' }}>{(option.locationCode === '-1' || mapScale === 'city' || mapScale === 'county') ? option.location : stateInfo[option.locationCode] === undefined ? option.locationCode + ' - ' + option.location : stateInfo[option.locationCode].abbreviation + ' - ' + stateInfo[option.locationCode].name}</span>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField 
                          {...params}
                          inputProps={{ 
                            ...params.inputProps, 
                            // autoComplete: 'off',
                            autoComplete: 'new-password',
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                          variant="standard" 
                          label="Drop locations*" 
                          size='small'
                          placeholder="Drop locations*" 
                        />
                      )}
                    />
                    <FormHelperText><br></br>* Available periods based on dependent variable. Available locations based on dependent variable if map, else based on first map listed in independent variables. Null values excluded.</FormHelperText>
                  </FormControl>
                </div>
              </AccordionDetails>
            </Accordion>
            <p></p>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '16px', alignItems: 'start' }}>
        <div style={{ marginRight: '8px' }}>
          <Button 
            variant="contained" 
            // style={{ backgroundColor:"#026cb5", color: "white"}}
            disabled={isLoadingVizNode}
            onClick={() => { 
              var hasError = false;
              var depTime = sessionRecipe.find((s: any) => s.displayed && s.id === dependentSelected);
              var depMap = sessionRecipe.find((s: any) => s.displayed && s.id === dependentSelected);
              var indTime = sessionRecipe.filter((s: any) => s.displayed && independentVars.includes(s.id + ';ts'));
              var indMap = sessionRecipe.filter((s: any) => s.displayed && independentVars.includes(s.id + ';map'));  
              if(dependentSelected === "" || depTime === undefined || (nodeCounters.maps >= MAX_MAP_DATA_VIZ && isDependentMap))
              {
                setDependentError(true);
                hasError = true;
              }
              if(independentVars.includes("") || independentVars.length !== indTime.length + indMap.length || (nodeCounters.maps >= MAX_MAP_DATA_VIZ && independentVars.some((idStr: string) => idStr !== '' && idStr.split(';')[1] === 'map')))
              {
                setIndependentError(true);
                hasError = true;

              }
              else {
                setDependentError(false);
                setIndependentError(false);
              }
              if (!hasError && (sessionRecipe.length > 0)) {
                setDependentError(false);
                setIndependentError(false);

                if (isDependentMap) {
                  setEditMapError(false)
                  var entityEffectsMapScaleAPI: any = (entityEffectsMapScale === '' || entityEffectsMapScale === mapScale) ? null : entityEffectsMapScale
                  var entityEffectsShowAllAPI = entityEffectsShowAll && (entityEffectsMapScale !== '' && entityEffectsMapScale !== mapScale)
                  dispatch(fetchPanelOLSRegressionSeries(depTime, depMap, isDependentMap, indTime, indMap, frequency, addConst, timeEffects, entityEffects, entityEffectsMapScaleAPI, entityEffectsShowAll, start, end, dropPeriods, dropLocations, editNode))
                  if (editNode !== undefined) {
                    handleClose()
                  }
                } else {
                  if (editNode !== undefined && editNode.meta.dataType === 'map') {
                    setEditMapError(true)
                  } else {
                    dispatch(fetchOLSRegressionSeries(depTime, indTime, frequency, addConst, start, end, dropPeriods, editNode))
                    if (editNode !== undefined) {
                      handleClose()
                    }
                  }
                }
              }
            }}
          >
            {editNode !== undefined ? 'Update workspace*' : 'Add to workspace*'}
          </Button>
          <FormHelperText>* Generates fitted series</FormHelperText>
        </div>
        <Button onClick={handleClose} variant="contained" style={{ backgroundColor:"#c5093b", color: "white"}} >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
    <Snackbar anchorOrigin={{vertical: "top", horizontal:"center"}} open={messageOpen} autoHideDuration={6000} onClose={closeMessage}>
      <Alert onClose={closeMessage} severity={messageStatus === true? "success" : "error"}>
          {message}
      </Alert>
    </Snackbar>
    </div>
  );
}
