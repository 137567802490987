import { DataResult } from './Models/DataResult';
import { endpoint, MAX_DATA_VIZ, MAX_MAP_DATA_VIZ } from './App';
import { userRecipeListLoading, updateUser, newRecipe, updateRecipeListSuccess, updateRecipeListFailure, staleSessionAlert, addVizSeriesSuccess, reloadVizSeriesSuccess, saveLoadRecipeInProgress, saveRecipeSuccess, saveRecipeFailure, loadRecipeSuccess, loadRecipeFailure, updateRecipeDataSuccess, updateRecipeDataFailure, setChartTableLocOptions, incrementDerivedSeriesCount, addVizSeriesFailure, addVizSeriesLoading, setMapPrimaryId, setIsModified } from './actions'
import { frequencySettings } from './definitions/FrequencySettings';
import { Expression } from './VisualizationTool/CalculateDialog';
import { stateInfo } from './definitions/StateInfo';

export const addResultToViz = (result_id: string, index: number, result: DataResult) => async (dispatch: any, getState: any) => {
  try {
    dispatch(addVizSeriesLoading());

    const { nodeCounters, sessionRecipe, sessionRecipeInfo } = getState()

    if ((result.maps === null && (result.series === null || result.series.length === 0)) || (result.series === null && (result.maps === null || result.maps.length === 0 || result.maps[0].children.length === 0))) {
      console.log('result has no inner series')
      dispatch(addVizSeriesFailure('Data object is empty.'));
      return false;
    }

    var nodeType = 'series'
    if (result.maps !== null && result.maps.length > 0) {
      nodeType = 'map'
    }
  
    if ((nodeType === 'series' && nodeCounters.series < MAX_DATA_VIZ) || (nodeType === 'map' && nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
      var datanodeInfo: any
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }
      const seriesResponse = await fetch(endpoint + "datanode/" + result_id + "/" + index, requestOptions);
      if (!seriesResponse.ok) {
        throw Error(seriesResponse.statusText);
      } else {
        datanodeInfo = await seriesResponse.json();
        var recipeInfo: any = {
          id: datanodeInfo.series_id,
          name: result.name,
          displayed: true,
          type: 'db_datanode',
          params: {
            id: datanodeInfo.series_id
          },
          meta: {
            result_id,
            result_index: index,
            dataType: nodeType,
            datanodeInfo: datanodeInfo,
          }
        }

        if (nodeType === 'series' && (result.series !== null && result.series.length > 0)) {
          recipeInfo.name += ', ' + result.series[0].name
        }
        if (nodeType === 'map' && (result.maps !== null && result.maps.length > 0)) {
          recipeInfo.name += ', ' + result.maps[0].name
        }

        // set/update mapPrimary
        var prevNode = sessionRecipe.find((s: any) => (s.id === recipeInfo.id) || (s.type === 'db_datanode' && s.meta.result_id === recipeInfo.meta.result_id && s.meta.result_index === recipeInfo.meta.result_index))
        if (prevNode !== undefined) {
          if (prevNode.meta.aliasCreated) {
            recipeInfo.meta.aliasCreated = true
            recipeInfo.meta.original_name = recipeInfo.name
            recipeInfo.name = prevNode.name
          }
          // TODO maybe re-run the recipe? or at least relevant nodes?
          dispatch(reloadVizSeriesSuccess(result, recipeInfo));
          dispatch(setChartTableLocOptions(sessionRecipe.filter((node: any) => node.id === prevNode.id).concat({...recipeInfo, result})))
          // if (sessionRecipeInfo.meta.mapPrimaryId === prevNode.id) {
          //   dispatch(setMapPrimary(recipeInfo.id));
          // } else if (sessionRecipeInfo.meta.mapPrimaryId === null && nodeType === 'map') {
          //   dispatch(setMapPrimary(null));
          // }
          if (nodeType === 'map') {
            dispatch(setMapPrimary(recipeInfo.id));
          }
        } else {
          dispatch(addVizSeriesSuccess(result, recipeInfo));
          dispatch(setChartTableLocOptions(sessionRecipe.concat({...recipeInfo, result})))
          // if (sessionRecipeInfo.meta.mapPrimaryId === null && nodeType === 'map') {
          //   dispatch(setMapPrimary(null));
          // }
          if (nodeType === 'map') {
            dispatch(setMapPrimary(recipeInfo.id));
          }
        }
        return true;
      }
    } else {
      if (nodeType === 'series') {
        throw Error('Max time series reached.');
      } else {
        throw Error('Max maps reached.');
      }
    }
  } catch (e) {
    console.log(e)
    var msg = ''
    if (e instanceof Error) {
      msg = e.message
    }
    dispatch(addVizSeriesFailure(msg));
    return false;
  }
}

export const fetchChangeUnitSeries = (origNode: any, unitChangeType: string, params: any, editNode: any = null) => async (dispatch: any, getState: any) => {
  try {
    dispatch(addVizSeriesLoading());
    const { derivedSeriesCounters, nodeCounters, sessionRecipe, sessionRecipeInfo } = getState()
    const transformType = 'ChangeUnit'
    var currCount = 0
    var fullApiCall = endpoint + "transformations/recipes"

    if (derivedSeriesCounters !== undefined && derivedSeriesCounters[transformType] !== undefined) {
      currCount = derivedSeriesCounters[transformType]
    }
    currCount++
    var countString = ('' + currCount).padStart(3, '0')

    var transform_id = transformType + countString

    if (editNode !== null) {
      transform_id = editNode.id
    }

    var nodeType = 'series'
    if (origNode.result.maps !== null && origNode.result.maps.length > 0) {
      nodeType = 'map'
    }

    var recipeInfo: any = {
      id: transform_id,
      name: transform_id,
      displayed: true,
      type: 'transformation',
      transformation_id: 'unit_changes.' + unitChangeType,
      input: [origNode.id],
      params: {
        ...params,
        output_id: transform_id,
        // documentation_url: apiDocs,
      },
      meta: {
        dataType: nodeType,
      }
    }

    if (editNode !== null) {
      recipeInfo.name = editNode.name
      dispatch(updateRecipeData(recipeInfo))
    } else {
      if ((nodeType === 'series' && nodeCounters.series < MAX_DATA_VIZ) || (nodeType === 'map' && nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
        var output: any = null
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: transform_id,
            nodes: [{
              id: origNode.id,
              name: origNode.name,
              displayed: false,
              type: 'manual_datanode',
              params: {
                ...origNode.result
              }
            }, recipeInfo]
          })
        }
        const seriesResponse = await fetch(fullApiCall, requestOptions);
        if (!seriesResponse.ok) {
          throw Error(seriesResponse.statusText);
        } else {
          const response_json = await seriesResponse.json();
          output = response_json.nodes.find((node: any) => node.id === transform_id)
          if (output !== undefined) {
            var outputSeries: any = output.result

            if ((outputSeries.raw_error === undefined || outputSeries.raw_error === null) && ((nodeType === 'series' && outputSeries.series !== null && outputSeries.series.length > 0) || (nodeType === 'map' && outputSeries.maps !== null && outputSeries.maps.length > 0 && outputSeries.maps[0].children.length > 0))) {

              recipeInfo.name = outputSeries.name

              if (nodeType === 'series') {
                recipeInfo.name += ', ' + outputSeries.series[0].name
              }
              if (nodeType === 'map') {
                recipeInfo.name += ', ' + outputSeries.maps[0].name
              }
              
              // commented out code updates just the changed node
              // if (editNode !== null) {
              //   var prevNode = sessionRecipe.find((s: any) => s.id === recipeInfo.id)
              //   if (prevNode !== undefined) {
              //     dispatch(reloadVizSeriesSuccess(outputSeries, recipeInfo));
              //     dispatch(setChartTableLocOptions(sessionRecipe.filter((node: any) => node.id === prevNode.id).concat({...recipeInfo, result: outputSeries})))
              //     if (sessionRecipeInfo.meta.mapPrimaryId === prevNode.id) {
              //       dispatch(setMapPrimary(recipeInfo.id));
              //     } else if (sessionRecipeInfo.meta.mapPrimaryId === null && nodeType === 'map') {
              //       dispatch(setMapPrimary(null));
              //     }
              //   } else {
              //     throw Error('Edited node is not part of recipe.');
              //   }
              // } else {
                dispatch(incrementDerivedSeriesCount(transformType))
                dispatch(addVizSeriesSuccess(outputSeries, recipeInfo))
                dispatch(setChartTableLocOptions(sessionRecipe.concat({...recipeInfo, result: outputSeries})))
                // if (sessionRecipeInfo.meta.mapPrimaryId === null && nodeType === 'map') {
                //   dispatch(setMapPrimary(null));
                // }
                if (nodeType === 'map') {
                  dispatch(setMapPrimary(recipeInfo.id));
                }
              // }
            } else {
              if (outputSeries.raw_error !== undefined && outputSeries.raw_error !== null && (outputSeries.raw_error === 'Result does not contain any values.' || outputSeries.raw_error === 'No periods available for analysis.')) {
                throw Error(outputSeries.raw_error);
              } else if (outputSeries.details !== undefined && outputSeries.details !== null) {
                throw Error(outputSeries.details);
              } else {
                throw Error('Exception occurred during calculation.');
              }
            }
          } else {
            throw Error('Node missing from output.');
          }

          return true;
        }
      } else {
        if (nodeType === 'series') {
          throw Error('Max time series reached.');
        } else {
          throw Error('Max maps reached.');
        }
      }
    }
  } catch (e) {
    console.log(e)
    var msg = ''
    if (e instanceof Error) {
      msg = e.message
    }
    dispatch(addVizSeriesFailure(msg));
    return false;
  }
  
}

const aggregation_info: any = {
  sum: {
    string: 'Sum'
  },
  first: {
    string: 'First Value'
  },
  last: {
    string: 'Last Value'
  },
  mean: {
    string: 'Average'
  },
  median: {
    string: 'Median'
  },
  min: {
    string: 'Minimum'
  },
  max: {
    string: 'Maximum'
  },
}

export const fetchChangeFreqSeries = (origNode: any, initial_frequency: string, target_frequency: string, aggregation_method: string, skip_na: boolean, editNode: any = null) => async (dispatch: any, getState: any) => {
  try {
    dispatch(addVizSeriesLoading());
    const { derivedSeriesCounters, nodeCounters, sessionRecipe, sessionRecipeInfo } = getState()
    const transformType = 'ChangeFrequency'
    var currCount = 0

    if (derivedSeriesCounters !== undefined && derivedSeriesCounters[transformType] !== undefined) {
      currCount = derivedSeriesCounters[transformType]
    }
    currCount++
    var countString = ('' + currCount).padStart(3, '0')

    var transform_id = transformType + countString

    if (editNode !== null) {
      transform_id = editNode.id
    }

    var nodeType = 'series'
    if (origNode.result.maps !== null && origNode.result.maps.length > 0) {
      nodeType = 'map'
    }

    var recipeInfo: any = {
      id: transform_id,
      name: transform_id,
      displayed: true,
      type: 'transformation',
      transformation_id: 'frequency_changes.ChangeFrequency',
      input: [origNode.id],
      params: {
        initial_frequency,
        target_frequency,
        aggregation_method,
        skip_na,
        output_id: transform_id,
        // documentation_url: apiDocs,
      },
      meta: {
        dataType: nodeType,
      }
    }

    if (editNode !== null) {
      recipeInfo.name = editNode.name
      dispatch(updateRecipeData(recipeInfo))
    } else {
      if ((nodeType === 'series' && nodeCounters.series < MAX_DATA_VIZ) || (nodeType === 'map' && nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
        var output: any = null
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: transform_id,
            nodes: [{
              id: origNode.id,
              name: origNode.name,
              displayed: false,
              type: 'manual_datanode',
              params: {
                ...origNode.result
              }
            }, recipeInfo]
          })
        }
        const seriesResponse = await fetch(endpoint + "transformations/recipes", requestOptions);
        if (!seriesResponse.ok) {
          throw Error(seriesResponse.statusText);
        } else {
          const response_json = await seriesResponse.json();
          output = response_json.nodes.find((node: any) => node.id === transform_id)
          if (output !== undefined) {
            var outputSeries: any = output.result

            if ((outputSeries.raw_error === undefined || outputSeries.raw_error === null) && ((nodeType === 'series' && outputSeries.series !== null && outputSeries.series.length > 0) || (nodeType === 'map' && outputSeries.maps !== null && outputSeries.maps.length > 0 && outputSeries.maps[0].children.length > 0))) {

              recipeInfo.name = outputSeries.name

              if (nodeType === 'series') {
                recipeInfo.name += ', ' + outputSeries.series[0].name
              }
              if (nodeType === 'map') {
                recipeInfo.name += ', ' + outputSeries.maps[0].name
              }

              dispatch(incrementDerivedSeriesCount(transformType))
              dispatch(addVizSeriesSuccess(outputSeries, recipeInfo))
              dispatch(setChartTableLocOptions(sessionRecipe.concat({...recipeInfo, result: outputSeries})))
              // if (sessionRecipeInfo.meta.mapPrimaryId === null && nodeType === 'map') {
              //   dispatch(setMapPrimary(null));
              // }
              if (nodeType === 'map') {
                dispatch(setMapPrimary(recipeInfo.id));
              }
            } else {
              if (outputSeries.raw_error !== undefined && outputSeries.raw_error !== null && (outputSeries.raw_error === 'Result does not contain any values.' || outputSeries.raw_error === 'No periods available for analysis.')) {
                throw Error(outputSeries.raw_error);
              } else if (outputSeries.details !== undefined && outputSeries.details !== null) {
                throw Error(outputSeries.details);
              } else {
                throw Error('Exception occurred during calculation.');
              }
            }
          } else {
            throw Error('Node missing from output.');
          }

          return true;
        }
      } else {
        if (nodeType === 'series') {
          throw Error('Max time series reached.');
        } else {
          throw Error('Max maps reached.');
        }
      }
    }
  } catch (e) {
    console.log(e)
    var msg = ''
    if (e instanceof Error) {
      msg = e.message
    }
    dispatch(addVizSeriesFailure(msg));
    return false;
  }
}

function parseExpression(expression: Expression[]) {
  var output = expression.map((item) => {
    if (item.type === 'series') {
      var currFunction = item.payload.function
      if (currFunction === 'none') {
        currFunction = null
      }
      if (item.payload.isMap) {
        return {
          ...item,
          payload: {
            ...item.payload,
            function: currFunction
          },
          type: 'map'
        } as Expression
      } else {
        return {
          ...item,
          payload: {
            ...item.payload,
            function: currFunction
          },
        } as Expression
      }
    } else if (item.type === 'parentheses') {
      var currFunction = item.payload.function
      if (currFunction === 'none') {
        currFunction = null
      }
      return {
        ...item,
        payload: {
          ...item.payload,
          function: currFunction,
          expression: parseExpression(item.payload.expression)
        } as any
      } as Expression
    } else {
      return item
    }
  }) as Expression[]
  return output
}

export const fetchCalculateSeries = ( nodesIncluded: any[], expression: Expression[], frequency: string, editNode: any = null) => async (dispatch: any, getState: any) =>{
  try {
    dispatch(addVizSeriesLoading());
    const { derivedSeriesCounters, nodeCounters, sessionRecipe, sessionRecipeInfo } = getState()
    const transformType = 'Calculate'
    var currCount = 0
    var fullApiCall = endpoint + "transformations/recipes";
    var data = [] as any[];
    var parsedExpression = parseExpression(expression)
    var recipeIds = [] as string[]
    var output: any

    if (derivedSeriesCounters !== undefined && derivedSeriesCounters[transformType] !== undefined) {
      currCount = derivedSeriesCounters[transformType]
    }
    currCount++
    var countString = ('' + currCount).padStart(3, '0')

    var transform_id = transformType + countString

    if (editNode !== null) {
      transform_id = editNode.id
    }

    var nodeType = 'series'
    if (parsedExpression.some(item => item.type === 'map')) {
      nodeType = 'map'
    }

    if (editNode !== null || (nodeType === 'series' && nodeCounters.series < MAX_DATA_VIZ) || (nodeType === 'map' && nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
  
      nodesIncluded.forEach((node: any) =>{
        recipeIds.push(node.id)
        data.push({
          id: node.id,
          name: node.name,
          displayed: false,
          type: 'manual_datanode',
          params: {
            ...node.result
          }
        });
      })
  
      var recipeInfo: any = {
        id: transform_id,
        name: transform_id,
        displayed: true,
        type: 'transformation',
        transformation_id: 'calculations.Calculate',
        input: recipeIds,
        params: {
          frequency: frequency, 
          expression: parsedExpression,
          output_id: transform_id,
          // documentation_url: apiDocs,
        },
        meta: {
          dataType: nodeType,
        }
      }

      if (editNode !== null) {
        recipeInfo.name = editNode.name
        dispatch(updateRecipeData(recipeInfo))
      } else {
  
        data.push(recipeInfo)
        
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            name: transform_id,
            nodes: data,
          })
        }
        const seriesResponse = await fetch(fullApiCall, requestOptions);
        if (!seriesResponse.ok) {
          throw Error(seriesResponse.statusText);
        } else {
          const response_json = await seriesResponse.json();
          var output = response_json.nodes.find((node: any) => node.id === transform_id)
          if (output !== undefined) {
            var outputSeries: any = output.result

            if ((outputSeries.raw_error === undefined || outputSeries.raw_error === null) && ((nodeType === 'series' && outputSeries.series !== null && outputSeries.series.length > 0) || (nodeType === 'map' && outputSeries.maps !== null && outputSeries.maps.length > 0 && outputSeries.maps[0].children.length > 0))) {

              recipeInfo.name = outputSeries.name

              if (nodeType === 'series') {
                recipeInfo.name += ', ' + outputSeries.series[0].name
              }
              if (nodeType === 'map') {
                recipeInfo.name += ', ' + outputSeries.maps[0].name
              }

              dispatch(incrementDerivedSeriesCount(transformType))
              dispatch(addVizSeriesSuccess(outputSeries, recipeInfo))
              dispatch(setChartTableLocOptions(sessionRecipe.concat({...recipeInfo, result: outputSeries})))
              // if (sessionRecipeInfo.meta.mapPrimaryId === null && nodeType === 'map') {
              //   dispatch(setMapPrimary(null));
              // }
              if (nodeType === 'map') {
                dispatch(setMapPrimary(recipeInfo.id));
              }
            } else {
              if (outputSeries.raw_error !== undefined && outputSeries.raw_error !== null && (outputSeries.raw_error === 'Result does not contain any values.' || outputSeries.raw_error === 'No periods available for analysis.')) {
                throw Error(outputSeries.raw_error);
              } else if (outputSeries.details !== undefined && outputSeries.details !== null) {
                throw Error(outputSeries.details);
              } else {
                throw Error('Exception occurred during calculation.');
              }
            }
          } else {
            throw Error('Node missing from output.');
          }

          return true;
        }
      }
    } else {
      if (nodeType === 'series') {
        throw Error('Max time series reached.');
      } else {
        throw Error('Max maps reached.');
      }
    }
  } catch (e) {
    console.log(e)
    var msg = ''
    if (e instanceof Error) {
      msg = e.message
    }
    dispatch(addVizSeriesFailure(msg));
    return false;
  }
}

export const fetchOLSRegressionSeries = (dependentSeries: any, independentSeriess: any[], frequency: string, add_const: boolean, start: string, end: string, drop: string[], editNode: any = null) => async (dispatch: any, getState: any) => {
  try {
    dispatch(addVizSeriesLoading());
    const { derivedSeriesCounters, nodeCounters, sessionRecipe } = getState()
    const transformType = 'Regression'
    var currCount = 0

    if (derivedSeriesCounters !== undefined && derivedSeriesCounters[transformType] !== undefined) {
      currCount = derivedSeriesCounters[transformType]
    }
    currCount++
    var countString = ('' + currCount).padStart(3, '0')

    var transform_id = transformType + countString

    if (editNode !== null) {
      transform_id = editNode.id
    }

    var nodeType = 'series'

    if (editNode !== null || nodeCounters.series < MAX_DATA_VIZ) {
      var start_period: null | string = null
      var end_period:  null | string = null
      var start_period_string: null | string = null
      var end_period_string:  null | string = null
      var drop_periods_string:  null | string = null

      if (start !== '') {
        start_period = start
        start_period_string = frequencySettings[frequency].dateFormatter(start)
      }

      if (end !== '') {
        end_period = end
        end_period_string = frequencySettings[frequency].dateFormatter(end)
      }

      if (drop.length > 0) {
        var dropArray = [] as string[]
        drop.sort()
        drop.forEach((date) => {
          var str = frequencySettings['NotSet'].dateFormatter(date)
          if (frequencySettings[frequency] !== undefined) {
            str = frequencySettings[frequency].dateFormatter(date)
          }
          dropArray.push(str)
        })
        
        drop_periods_string = dropArray.join('; ');
      }
      const dependent_id = dependentSeries.id
      const independent_ids = independentSeriess.map((x: any) => x.id)
      var data = [{
        id: dependentSeries.id,
        name: dependentSeries.name,
        displayed: false,
        type: 'manual_datanode',
        params: {
          ...dependentSeries.result
        }
      }]

      independentSeriess.forEach((series: any) => {
        data.push({
          id: series.id,
          name: series.name,
          displayed: false,
          type: 'manual_datanode',
          params: {
            ...series.result
          }
        })
      })
      var output: any = null
      var recipeInfo: any = {
        id: transform_id,
        name: transform_id,
        displayed: true,
        type: 'transformation',
        transformation_id: 'regressions.OLS',
        input: [dependent_id].concat(independent_ids),
        params: {
          frequency,
          add_const,
          dependent_id,
          independent_ids,
          start_period,
          start_period_string,
          end_period,
          end_period_string,
          drop_periods: drop.length > 0 ? drop : null,
          drop_periods_string,
          output_id: transform_id,
          // documentation_url: apiDocs,
        },
        meta: {
          dataType: nodeType,
        }
      }
      if (editNode !== null) {
        recipeInfo.name = editNode.name
        dispatch(updateRecipeData(recipeInfo))
      } else {
        data.push(recipeInfo)
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            name: transform_id,
            nodes: data
          })
        }
        const seriesResponse = await fetch(endpoint + "transformations/recipes", requestOptions);
        if (!seriesResponse.ok) {
          throw Error(seriesResponse.statusText);
        } else {
          const response_json = await seriesResponse.json();
          output = response_json.nodes.find((node: any) => node.id === transform_id)
          if (output !== undefined) {
            var outputSeries: any = output.result

            if ((outputSeries.raw_error === undefined || outputSeries.raw_error === null) && (nodeType === 'series' && outputSeries.series !== null && outputSeries.series.length > 0) && (outputSeries.maps === null || outputSeries.maps.length === 0)) {

              recipeInfo.name = outputSeries.name

              recipeInfo.name += ', ' + outputSeries.series[0].name

              dispatch(incrementDerivedSeriesCount(transformType))
              dispatch(addVizSeriesSuccess(outputSeries, recipeInfo))
              dispatch(setChartTableLocOptions(sessionRecipe.concat({...recipeInfo, result: outputSeries})))
            } else {
              if (outputSeries.raw_error !== undefined && outputSeries.raw_error !== null && (outputSeries.raw_error === 'Result does not contain any values.' || outputSeries.raw_error === 'No periods available for analysis.')) {
                throw Error(outputSeries.raw_error);
              } else if (outputSeries.details !== undefined && outputSeries.details !== null) {
                throw Error(outputSeries.details);
              } else {
                throw Error('Exception occurred during calculation.');
              }
            }
          } else {
            throw Error('Node missing from output.');
          }

          return true;
        }
      }
    } else {
      throw Error('Max time series reached.');
    }
  } catch (e) {
    console.log(e)
    var msg = ''
    if (e instanceof Error) {
      msg = e.message
    }
    dispatch(addVizSeriesFailure(msg));
    return false;
  }
}

export const fetchPanelOLSRegressionSeries = (dependentTimeSeries: any, dependentMapSeries: any, dependentIsMap: boolean, independentTimeSeriess: any[], independentMapSeriess: any[], frequency: string, add_const: boolean, time_effects: boolean, entity_effects: boolean, entity_effects_map_scale: string | null, entity_effects_show_all: boolean, start: string, end: string, drop_periods: string[], drop_locations: any[], editNode: any = null) => async (dispatch: any, getState: any) => {
  try {
    dispatch(addVizSeriesLoading());
    const { derivedSeriesCounters, nodeCounters, sessionRecipe, sessionRecipeInfo } = getState()
    const transformType = 'Regression'
    var currCount = 0

    if (derivedSeriesCounters !== undefined && derivedSeriesCounters[transformType] !== undefined) {
      currCount = derivedSeriesCounters[transformType]
    }
    currCount++
    var countString = ('' + currCount).padStart(3, '0')

    var transform_id = transformType + countString

    if (editNode !== null) {
      transform_id = editNode.id
    }

    var nodeType = 'map'

    if (editNode !== null || (nodeType === 'map' && nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
      var start_period: null | string = null
      var end_period:  null | string = null
      var start_period_string: null | string = null
      var end_period_string:  null | string = null
      var drop_periods_string:  null | string = null

      if (start !== '') {
        start_period = start
        start_period_string = frequencySettings[frequency].dateFormatter(start)
      }

      if (end !== '') {
        end_period = end
        end_period_string = frequencySettings[frequency].dateFormatter(end)
      }

      if (drop_periods.length > 0) {
        var dropArray = [] as string[]
        drop_periods.sort()
        drop_periods.forEach((date) => {
          var str = frequencySettings['NotSet'].dateFormatter(date)
          if (frequencySettings[frequency] !== undefined) {
            str = frequencySettings[frequency].dateFormatter(date)
          }
          dropArray.push(str)
        })
        
        drop_periods_string = dropArray.join('; ');
      }
      
      var dependent_id = ''
      if (dependentIsMap) {
        dependent_id = dependentMapSeries.id
      } else {
        dependent_id = dependentTimeSeries.id
      }
      const time_independent_ids = independentTimeSeriess.map((x: any) => x.id)
      const map_independent_ids = independentMapSeriess.map((x: any) => x.id)
      var data = [] as any[]

      if (dependentIsMap) {
        data.push({
          id: dependentMapSeries.id,
          name: dependentMapSeries.name,
          displayed: false,
          type: 'manual_datanode',
          params: {
            ...dependentMapSeries.result
          }
        });
      } else {
        data.push({
          id: dependentTimeSeries.id,
          name: dependentTimeSeries.name,
          displayed: false,
          type: 'manual_datanode',
          params: {
            ...dependentTimeSeries.result
          }
        });
      }

      independentTimeSeriess.forEach((series: any) => {
        data.push({
          id: series.id,
          name: series.name,
          displayed: false,
          type: 'manual_datanode',
          params: {
            ...series.result
          }
        });
      })

      independentMapSeriess.forEach((m: any) => {
        data.push({
          id: m.id,
          name: m.name,
          displayed: false,
          type: 'manual_datanode',
          params: {
            ...m.result
          }
        })
      })
      var output: any = null
      var recipeInfo: any = {
        id: transform_id,
        name: transform_id,
        displayed: true,
        type: 'transformation',
        transformation_id: 'regressions.PanelOLS',
        input: [dependent_id].concat(time_independent_ids, map_independent_ids),
        params: {
          frequency,
          add_const,
          time_effects,
          entity_effects,
          entity_effects_map_scale,
          entity_effects_show_all,
          dependent_id,
          dependent_isMap: dependentIsMap,
          time_independent_ids,
          map_independent_ids,
          start_period,
          start_period_string,
          end_period,
          end_period_string,
          drop_periods: drop_periods.length > 0 ? drop_periods : null,
          drop_periods_string,
          drop_locations: drop_locations.length > 0 ? drop_locations : null,
          output_id: transform_id,
          // documentation_url: apiDocs,
        },
        meta: {
          dataType: nodeType,
        }
      }
      if (editNode !== null) {
        recipeInfo.name = editNode.name
        dispatch(updateRecipeData(recipeInfo))
      } else {
        data.push(recipeInfo)
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            name: transform_id,
            nodes: data
          })
        }
        const seriesResponse = await fetch(endpoint + "transformations/recipes", requestOptions);
        if (!seriesResponse.ok) {
          throw Error(seriesResponse.statusText);
        } else {
          const response_json = await seriesResponse.json();
          output = response_json.nodes.find((node: any) => node.id === transform_id)
          if (output !== undefined) {
            var outputSeries: any = output.result

            if ((outputSeries.raw_error === undefined || outputSeries.raw_error === null) && nodeType === 'map' && outputSeries.maps !== null && outputSeries.maps.length > 0 && outputSeries.maps[0].children.length > 0 && (outputSeries.series === null || outputSeries.series.length === 0)) {

              recipeInfo.name = outputSeries.name

              recipeInfo.name += ', ' + outputSeries.maps[0].name

              dispatch(incrementDerivedSeriesCount(transformType))
              dispatch(addVizSeriesSuccess(outputSeries, recipeInfo))
              dispatch(setChartTableLocOptions(sessionRecipe.concat({...recipeInfo, result: outputSeries})))
              // if (sessionRecipeInfo.meta.mapPrimaryId === null) {
              //   dispatch(setMapPrimary(null));
              // }
              dispatch(setMapPrimary(recipeInfo.id));
            } else {
              if (outputSeries.raw_error !== undefined && outputSeries.raw_error !== null && (outputSeries.raw_error === 'Result does not contain any values.' || outputSeries.raw_error === 'No periods available for analysis.')) {
                throw Error(outputSeries.raw_error);
              } else if (outputSeries.details !== undefined && outputSeries.details !== null) {
                throw Error(outputSeries.details);
              } else {
                throw Error('Exception occurred during calculation.');
              }
            }
          } else {
            throw Error('Node missing from output.');
          }

          return true;
        }
      }
    } else {
      throw Error('Max map series reached.');
    }
  } catch (e) {
    console.log(e)
    var msg = ''
    if (e instanceof Error) {
      msg = e.message
    }
    dispatch(addVizSeriesFailure(msg));
    return false;
  }
}

export const saveRecipe = (name: string, token: string, loadOther = false, loadName = '', loadId = '') => async (dispatch: any, getState: any) => {
  const { sessionRecipe, sessionRecipeInfo } = getState()
  dispatch(saveLoadRecipeInProgress());
  var requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json',
                'Authorization': token },
    body: JSON.stringify({
      name,
      nodes: sessionRecipe.map((n: any) => {
        return {
          ...n,
          name: n.meta.aliasCreated ? n.name : ''
        }
      }),
      meta: sessionRecipeInfo.meta
    })
  }
  await fetch(endpoint + "transformations/recipes?save=true", requestOptions)
  .then( async response => {
    var r = await response;
    if (r.ok) {
      var response_json = await r.json(); 
      // console.log(response_json)
      if (loadOther) {
        dispatch(loadRecipe(loadName, loadId))
      } else {
        // temp so works
        dispatch(saveRecipeSuccess(response_json));
        dispatch(setChartTableLocOptions(response_json.nodes))
        if (sessionRecipeInfo.meta.mapPrimaryId === null || !response_json.nodes.some((node: any) => node.displayed && node.id === sessionRecipeInfo.meta.mapPrimaryId)) {
          dispatch(setMapPrimary(null));
        }
      }
    } else {
      var e = await r.json();
      // console.log(r)
      // console.log(e)
      if (r.status === 403 && (e.detail === 'Only authenticated users can save recipes' || e.detail === 'Invalid token: Signature has expired.')) {
        dispatch(staleSessionAlert())
        console.log('stale session')
      }
      throw Error(r.statusText);
    }
    
  }
  ).catch(error =>{
    console.log(error);
    dispatch(saveRecipeFailure(name));
  });
}

export const loadRecipe = (name: string, id: string) => async (dispatch: any, getState: any) => {
  dispatch(saveLoadRecipeInProgress());
  const { sessionRecipeInfo, sessionRecipe } = getState()
  if (id === '') {
    dispatch(newRecipe());
  } else {
    var requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
    await fetch(endpoint + "transformations/recipes/" + id, requestOptions)
    .then( async response => {
      var r = await response;
      if (r.ok) {
        var response_json = await r.json(); 
        // console.log(response_json)
        // if re-loading recipe get chart_attributes from current workspace
        if (id === sessionRecipeInfo.id) {
          // update recipe level meta
          response_json.meta = {
            ...response_json.meta,
            ...sessionRecipeInfo.meta
          }
          // update each node if it was present in current workspace
          response_json.nodes.forEach((node: any) => {
            var oldNode = sessionRecipe.find((n: any) => n.id === node.id)
            if (oldNode !== undefined) {
              node.meta.chart_attributes = {
                ...node.meta.chart_attributes,
                ...oldNode.meta.chart_attributes
              }
            }
          })
        }
        dispatch(loadRecipeSuccess(response_json));
        dispatch(setChartTableLocOptions(response_json.nodes))
        if (sessionRecipeInfo.meta.mapPrimaryId === null || !response_json.nodes.some((node: any) => node.displayed && node.id === sessionRecipeInfo.meta.mapPrimaryId)) {
          dispatch(setMapPrimary(null));
        }
      } else {
        throw Error(r.statusText);
      }
      
    }
    ).catch(error =>{
      console.log(error);
      dispatch(loadRecipeFailure(name));
    });
  }
}

export const updateRecipeData = (editNode: any = null) => async (dispatch: any, getState: any) => {
  const { sessionRecipeInfo, sessionRecipe } = getState()

  if (sessionRecipe.length > 0) {

    dispatch(saveLoadRecipeInProgress());
    var recipe = [...sessionRecipe]

    if (editNode !== null) {
      var nodeIndex = recipe.findIndex((n: any) => n.id === editNode.id)
      if (nodeIndex !== -1) {
        recipe[nodeIndex] = {
          ...editNode,
          meta: {
            ...recipe[nodeIndex].meta,
            ...editNode.meta,
          }
        }
      }
    }

    recipe = recipe.map((n: any) => {
      return {
        ...n,
        name: n.meta.aliasCreated ? n.name : ''
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: 'current session',
        nodes: recipe
      })
    }
    await fetch(endpoint + "transformations/recipes", requestOptions)
    .then( async response => {
      var r = await response;
      if (r.ok) {
        var response_json = await r.json(); 
        // console.log(response_json)
        dispatch(updateRecipeDataSuccess(response_json.nodes));
        dispatch(setChartTableLocOptions(response_json.nodes));
        if (sessionRecipeInfo.meta.mapPrimaryId === null || !response_json.nodes.some((node: any) => node.displayed && node.id === sessionRecipeInfo.meta.mapPrimaryId)) {
          dispatch(setMapPrimary(null));
        }
        if (editNode !== null) {
          dispatch(setIsModified())
        }
      } else {
        throw Error(r.statusText);
      }
      
    }
    ).catch(error =>{
      console.log(error);
      dispatch(updateRecipeDataFailure());
    });
  }
}

export const setUserInfo = (session: any) => async (dispatch: any, getState: any) => {
  dispatch(userRecipeListLoading());
  if (session !== null) {
    var token = "Bearer " + session.signInUserSession.accessToken.jwtToken
    // console.log('set info')
    // var token = "Bearer " + session.signInUserSession.getAccessToken().jwtToken
    var requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
                  'Authorization': token },
    }
    await fetch(endpoint + "transformations/recipes?page_size=1000&sort_by=name&sort=asc", requestOptions)
    .then( async response => {
      var r = await response;
      if (r.ok) {
        var response_json = await r.json(); 
        // console.log(response_json)
        dispatch(updateUser(token, response_json.data_items, true));
      } else {
        throw Error(r.statusText);
      }
      
    }
    ).catch(error =>{
      console.log(error);
      dispatch(updateUser(token, [] as any[], false));
    });
  } else {
    dispatch(newRecipe());
    dispatch(updateUser('', [] as any[], true));
  }
}

export const setRecipeList = () => async (dispatch: any, getState: any) => {
  const { userInfo } = getState()
  dispatch(userRecipeListLoading());
  if (userInfo.token !== '') {
    var requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
                  'Authorization': userInfo.token },
    }
    await fetch(endpoint + "transformations/recipes?page_size=1000&sort_by=name&sort=asc", requestOptions)
    .then( async response => {
      var r = await response;
      if (r.ok) {
        var response_json = await r.json(); 
        // console.log(response_json)
        dispatch(updateRecipeListSuccess(response_json.data_items));
      } else {
        var e = await r.json();
        // console.log(r)
        // console.log(e)
        if (r.status === 403 && e.detail === 'Invalid token: Signature has expired.') {
          dispatch(staleSessionAlert())
          console.log('stale session')
        }
        throw Error(r.statusText);
      }
      
    }
    ).catch(error =>{
      console.log(error);
      dispatch(updateRecipeListFailure());
    });
  }
}

export const setMapPrimary = (id: string | null) => async (dispatch: any, getState: any) => {
  const { sessionRecipe, sessionRecipeInfo } = getState()
  
  var mapPrimary: any = null
  var frequency: any = null
  var map_scale: any = null
  if (id !== null) {
    mapPrimary = sessionRecipe.find((node: any, i: number) => node.displayed && id === node.id && node.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0)
    if (mapPrimary !== undefined) {
      frequency = mapPrimary.result.maps[0].children[0].frequency
      map_scale = mapPrimary.result.maps[0].map_scale ? mapPrimary.result.maps[0].map_scale : 'state'
    } else {
      mapPrimary = null
    }
  }

  if (mapPrimary === null) {
    // find first 
    mapPrimary = sessionRecipe.find((node: any, i: number) => node.displayed && node.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0)
    if (mapPrimary !== undefined) {
      frequency = mapPrimary.result.maps[0].children[0].frequency
      map_scale = mapPrimary.result.maps[0].map_scale ? mapPrimary.result.maps[0].map_scale : 'state'
    } else {
      // if none then make mapPrimary null
      mapPrimary = null
    }
  }
  
  // find first if id is null
  dispatch(setMapPrimaryId(mapPrimary === null ? null : mapPrimary.id, frequency, map_scale));
}