import React, {  useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, DialogTitle, Dialog, InputLabel, FormHelperText, FormControl, FormControlLabel, Checkbox, Select, IconButton, TextField,  DialogContent, MenuItem, Menu, CircularProgress, DialogActions, MenuList, Tooltip, Snackbar, Typography, Alert, SelectChangeEvent } from '@mui/material';
import { session } from '../App';
import emailjs from 'emailjs-com';

const useStyles = makeStyles((theme: any) => ({
  resultContainer: {
    cursor: 'pointer',
    padding: '10px',
    '&:hover:not(.disabled)': {
      backgroundColor: '#ddd'
    },
    '&:not(:last-of-type)': {
      borderBottom: 'solid 1px #ddd'
    },
    '&.disabled': {
      backgroundColor: '#eee',
      color: '#555555',
      cursor: 'auto'
    },
  },
  loadingRoot: {
    width: "75px",
    height: "75px",
    color: "#004785"
  },
  dialogRoot: {
    // height: '100%',
    maxWidth: '800px',
    paddingTop: '20px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  search: {
    display: 'flex',
    // alignItems: 'center',
    textAlign: "center",
    justifyContent: "space-between",
    position: 'relative',
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    borderColor: "gray",
    '&:hover': {
      borderColor: "#004785",
    },
    '&:focus-within': {
      borderColor: "#004785",
    },
    borderStyle: "solid",
    borderWidth: "1px"

  },
  iconButton: {
    padding: 10,
    color: "black"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 2),

  },
  checkBox:{
    '&:hover':{
      backgroundColor: "rgba(0, 0, 0, 0.04)!important"
    }
  },
  snackbar:{
    backgroundColor: "rgb(255, 244, 229)!important"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "30%",
  },
  deleteButton: {
    backgroundColor:"#a90533", 
    color: "white",
    // marginTop: "10px",
    // marginLeft: "12px",
    // height: "50%",
    '&:hover':{
      backgroundColor: "#a90533!important"
    }
    
  },
  publishButton: {
    backgroundColor:"#004785", 
    color: "white",
    marginTop: "10px",
    marginLeft: "12px",
    height: "50%",
    '&:hover':{
      backgroundColor: "#004785!important"
    }
    
  },
  publishSubmitButton: {
    backgroundColor:"#4BB543", 
    color: "white",
    marginTop: "10px",
    marginLeft: "12px",
    height: "50%",
    '&:hover':{
      backgroundColor: "#4BB543!important"
    }
    
  },
  buttondisabled:{
    backgroundColor: "grey",
    color: "white"
  }
}));

export interface ContactUsDialogProps {
  open: boolean,
  onClose: () => void,
}

function ContactUsDialog(props: ContactUsDialogProps) {
  const classes = useStyles();
  const { onClose, open } = props;

  const[loading, setLoading] = useState<boolean>(false);

  const[username, setUsername] = useState<string | null>(null);
  const[firstName, setFirstName] = useState<string>("");
  const[lastName, setLastName] = useState<string>("");
  const[email, setEmail] = useState<string>("");
  const[industry, setIndustry] = useState<string>("");
  const[message, setMessage] = useState<string>("");
  const[includeUrl, setIncludeUrl] = useState<boolean>(false);

  const[alertOpen, setAlertOpen] = useState<boolean>(false);
  const[alert, setAlert] = useState<string>("");
  const[alertStatus, setAlertStatus] = useState<boolean>(false);
  
  const[emailError, setEmailError] = useState<boolean>(false);
  const[messageError, setMessageError] = useState<boolean>(false);

  useEffect(() => {
    if (session !== null) {
      if (username === null) {
        setUsername(session.username)
        setFirstName('')
        setLastName('')
        setEmail(session.attributes.email)
      }
    } else {
      if (username !== null) {
        setUsername(null)
        setFirstName('')
        setLastName('')
        setEmail('')
      }
    }
  },[open]);

  const handleClose = () => {
    setMessage("");
    setMessageError(false);
    onClose();
  };

  const closeAlert = () =>{
    setAlertOpen(false);
    setAlert("");
  }

  const handleFirstChange = (event: any) => {
    setFirstName(event.target.value)
  }
  const handleLastChange = (event: any) => {
    setLastName(event.target.value)
  }
  const handleEmailChange = (event: any) => {
    setEmail(event.target.value)
    if (emailError && event.target.value !== '') {
      setEmailError(false)
    }
  }
  const handleMessageChange = (event: any) => {
    setMessage(event.target.value)
    if (messageError && event.target.value !== '') {
      setMessageError(false)
    }
  }
  const handleIncludeUrlChange = (event: any) => {
    setIncludeUrl(event.target.checked);
  };

  const handleIndustryChange = (event: SelectChangeEvent) => {
    setIndustry(event.target.value as string)
  };

  function validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <div>
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="md" fullWidth={true} scroll='paper' classes={{ paper: classes.dialogRoot }}>
      <DialogTitle><span style={{ fontSize: '30px', fontWeight: 700, marginLeft: '7%' }}>Contact Us</span></DialogTitle>
      <DialogContent style={{ boxSizing: 'border-box', alignSelf: "center", width: "90%", position: 'relative', padding: '8px 0px' }}>
        <div style={{ position: 'relative', width: '100%' }}>
          {(loading) && <div style={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', zIndex: 1, backgroundColor: 'rgba(255,255,255,0.5)' }}><CircularProgress thickness={3} className={classes.loadingRoot} /></div>}
          <div style={{ display: 'flex', flexDirection: 'column', width: '90%', margin: '0px auto' }}>
            {username !== null && <FormControl className={classes.formControl}>
              <TextField disabled value={username} label="Username" variant="outlined" />
            </FormControl>}
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
              <FormControl style={{ flexGrow: 1, minWidth: '100px' }} className={classes.formControl}>
                <TextField value={firstName} onChange={handleFirstChange} label="First Name" variant="outlined" />
              </FormControl>
              <FormControl style={{ flexGrow: 1, minWidth: '100px' }} className={classes.formControl}>
                <TextField value={lastName} onChange={handleLastChange} label="Last Name" variant="outlined" />
              </FormControl>
            </div>
            <FormControl className={classes.formControl}>
              <TextField error={emailError} required={true} value={email} onChange={handleEmailChange} label="Email" variant="outlined" />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="industry-select-label">Professional Category</InputLabel>
              <Select
                labelId="industry-select-label"
                id="industry-select"
                value={industry}
                label="Professional Category"
                onChange={handleIndustryChange}
              >
                <MenuItem value={'Business'}>Business Professional</MenuItem>
                <MenuItem value={'Government'}>Government Professional</MenuItem>
                <MenuItem value={'Jounalist'}>Jounalist</MenuItem>
                <MenuItem value={'Researcher/Academic'}>Researcher/Academic</MenuItem>
                <MenuItem value={'Student'}>Student</MenuItem>
                <MenuItem value={'Teacher'}>Teacher</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField error={messageError} required={true} value={message} onChange={handleMessageChange} label="Message" variant="outlined" multiline={true} rows={5} />
            </FormControl>
            <FormControlLabel
              control={<Checkbox size="small" checked={includeUrl} onChange={handleIncludeUrlChange}  name="include url" />}
              label="Include current URL with message."
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '16px' }}>
        <Button 
          variant="contained" 
          // style={{ backgroundColor:"#026cb5", color: "white"}}
          disabled={loading}
          onClick={() => {
            var hasError = false
            if (email === '' || !validateEmail(email)) {
              setEmailError(true)
              hasError = true
            }

            if (message === '') {
              setMessageError(true)
              hasError = true
            }

            if (!hasError) {
              setLoading(true)
              var formInfo = {
                username: username !== null ? username : 'Not signed in',
                first_name: firstName !== '' ? firstName : 'Not provided',
                last_name: lastName !== '' ? lastName : 'Not provided',
                email: email,
                industry: industry !== '' ? industry : 'Not provided',
                message: message,
                url: includeUrl ? window.location.href : 'Not included'
              }
              emailjs.send('service_556upkw', 'template_p3flhdj', formInfo, 'user_otgMGqypO4pIEkuVtwCz0')
              .then((result) => {
                setAlertStatus(true)
                setAlert('Message sent!')
                setAlertOpen(true)
                setLoading(false)
                // console.log(result.text);
              }, (error) => {
                setAlertStatus(false)
                setAlert('Oops! Something went wrong.')
                setAlertOpen(true)
                setLoading(false)
                console.log(error.text);
              });
            }
          }}
        >
          Submit
        </Button>
        <Button onClick={handleClose} variant="contained" style={{ backgroundColor:"#c5093b", color: "white"}} >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
    <Snackbar anchorOrigin={{vertical: "top", horizontal:"center"}} open={alertOpen} autoHideDuration={6000} onClose={closeAlert}>
      <Alert onClose={closeAlert} severity={alertStatus ? "success" : "error"}>
          {alert}
      </Alert>
    </Snackbar>
    </div>
  );
}

export default ContactUsDialog;