import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'
import createCompressor from 'redux-persist-transform-compress'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
// import { vizSeries, vizMapSeries, isLoadingVizSeries, vizLength, vizMapLength, derivedSeriesCounters, alert, sessionRecipe, sessionRecipeInfo, saveRecipeAlert, userInfo, staleSessionAlert } from './reducers'
import { sessionRecipe, sessionRecipeInfo, userInfo, staleSessionAlert, nodeCounters, isLoadingVizNode, addAlert, saveRecipeAlert, derivedSeriesCounters } from './reducers'

const reducers: any = { 
  isLoadingVizNode,
  // vizObjects,
  // vizMapSeries,
  // vizLength,
  // vizMapLength,
  addAlert,
  derivedSeriesCounters,
  nodeCounters,
  sessionRecipe,
  sessionRecipeInfo,
  saveRecipeAlert,
  userInfo,
  staleSessionAlert,
};

const compressor = createCompressor({ blacklist: ['isLoadingVizNode', 'addAlert', 'saveRecipeAlert', 'staleSessionAlert'], })

export const persistConfig = {
  key: 'root',
  storage: storageSession,
  stateReconciler: autoMergeLevel1,
  transforms: [compressor]
}


const rootReducer: any = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureStore = () => 
  createStore(
    persistedReducer,
    // rootReducer,
    composeWithDevTools(
      applyMiddleware(thunk)
    )
  )