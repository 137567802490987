import React, { useEffect, useState } from 'react';
import * as Highcharts from 'highcharts';
import NoData from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
// import { Series } from '../SeriesPage/SeriesPage';
import { frequencySettings, ONE_DAY } from '../definitions/FrequencySettings';
import { stateInfo } from '../definitions/StateInfo';
import { connect } from 'react-redux';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ReactDOMServer from 'react-dom/server';

const lodash = require('lodash');

NoData(Highcharts)
Exporting(Highcharts)
OfflineExporting(Highcharts)

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
    thousandsSep: ',',
    numericSymbols: ['k', 'M', 'B', 'T'],
    contextButtonTitle: 'Download chart image',
  }
});

Highcharts.SVGRenderer.prototype.symbols.download = () => {
  const iconString = ReactDOMServer.renderToString(<PhotoCameraIcon />);
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(iconString, 'image/svg+xml');
  const iconPath = svgDoc.querySelector('path')?.getAttribute('d') as string;

  return [iconPath];
}

function parseSeriesToDataSeq(mapPrimary:any, mapSeries: any[], frequencyType = 'NotSet', includeSummary: boolean) {
  var parsed = {} as any;
  var xMin: number = Number.MAX_SAFE_INTEGER, 
    xMax: number = Number.MIN_SAFE_INTEGER, 
    yMin: number = Number.MAX_SAFE_INTEGER, 
    yMax: number = Number.MIN_SAFE_INTEGER;

  mapSeries.forEach((m: any) => {
    if (m.maps !== null) {
      if (mapPrimary.chart_attributes.showAll) {
        mapPrimary.maps[0].children.forEach((sPrimary: any, j: number) => {
          if (includeSummary || mapPrimary.maps[0].summary_row === null || sPrimary.locationCode !== mapPrimary.maps[0].summary_row) {
            var fips = sPrimary.locationCode
            var locationName = sPrimary.location
            if (stateInfo[fips] !== undefined) {
              locationName = stateInfo[fips].name
            }
            if (fips !== '-1') {
              
            }
            var s: any = m.maps[0].children.find((s: any) => (fips === '-1' && s.location === sPrimary.location) || s.locationCode === fips)
            if (sPrimary !== undefined && s !== undefined) {
              sPrimary.data_points.forEach((itemPrimary: any) => {
                var dateUTC = Date.parse(itemPrimary.key)
                if (parsed[dateUTC] === undefined) {
                  parsed[dateUTC] = {
                    name: m.name,
                    frequency: frequencyType,
                    // data_type: m.map_attributes.data_type !== undefined ? m.map_attributes.data_type : '',
                    yAxis: 0,// m.chart_attributes.axisRight ? 1 : 0,
                    data: [] as any[],
                    numDecimals: 0,
                    numDecimalsPrimary: 0,
                  }
                }
      
                var valuePrimary = parseFloat(itemPrimary.value)
                if (!isNaN(valuePrimary)) {
                  var item = s.data_points.find((data_item: any) => Date.parse(data_item.key) === dateUTC)
                  if (item !== undefined) {
                    var value = parseFloat(item.value)
                    if (!isNaN(value)) {
                      var numDecPrimary = countDecimals(valuePrimary)
                      if (numDecPrimary > parsed[dateUTC].numDecimalsPrimary) {
                        parsed[dateUTC].numDecimalsPrimary = numDecPrimary
                      }
                      if (valuePrimary > xMax) {
                        xMax = valuePrimary
                      }
                      if (valuePrimary < xMin) {
                        xMin = valuePrimary
                      }
                      var numDec = countDecimals(value)
                      if (numDec > parsed[dateUTC].numDecimals) {
                        parsed[dateUTC].numDecimals = numDec
                      }
                      if (value > yMax) {
                        yMax = value
                      }
                      if (value < yMin) {
                        yMin = value
                      }
                      parsed[dateUTC].data.push({
                        name: locationName,
                        fips: fips === '-1' ? locationName : fips,
                        x: valuePrimary,
                        y: value
                      })
                    }
                  }
                }
              })
            }
          }
        })
      } else {
        mapPrimary.chart_attributes.showStates.forEach((location: any, j: number) => {
          var sPrimary: any = mapPrimary.maps[0].children.find((s: any) => s.locationCode === location.locationCode && (s.locationCode !== '-1' || s.location === location.location))
          var s: any = m.maps[0].children.find((s: any) => s.locationCode === location.locationCode && (s.locationCode !== '-1' || s.location === location.location))
          if (sPrimary !== undefined && s !== undefined) {
            sPrimary.data_points.forEach((itemPrimary: any) => {
              var dateUTC = Date.parse(itemPrimary.key)
              if (parsed[dateUTC] === undefined) {
                parsed[dateUTC] = {
                  name: m.name,
                  frequency: frequencyType,
                  // data_type: m.map_attributes.data_type !== undefined ? m.map_attributes.data_type : '',
                  yAxis: 0,// m.chart_attributes.axisRight ? 1 : 0,
                  data: [] as any[],
                  numDecimals: 0,
                  numDecimalsPrimary: 0,
                }
              }
    
              var valuePrimary = parseFloat(itemPrimary.value)
              if (!isNaN(valuePrimary)) {
                var item = s.data_points.find((data_item: any) => Date.parse(data_item.key) === dateUTC)
                if (item !== undefined) {
                  var value = parseFloat(item.value)
                  if (!isNaN(value)) {
                    var numDecPrimary = countDecimals(valuePrimary)
                    if (numDecPrimary > parsed[dateUTC].numDecimalsPrimary) {
                      parsed[dateUTC].numDecimalsPrimary = numDecPrimary
                    }
                    if (valuePrimary > xMax) {
                      xMax = valuePrimary
                    }
                    if (valuePrimary < xMin) {
                      xMin = valuePrimary
                    }
                    var numDec = countDecimals(value)
                    if (numDec > parsed[dateUTC].numDecimals) {
                      parsed[dateUTC].numDecimals = numDec
                    }
                    if (value > yMax) {
                      yMax = value
                    }
                    if (value < yMin) {
                      yMin = value
                    }
                    var locationName = location.location
                    if (stateInfo[location.locationCode] !== undefined) {
                      locationName = stateInfo[location.locationCode].name
                    }
                    parsed[dateUTC].data.push({
                      name: locationName,
                      fips: location.locationCode,
                      x: valuePrimary,
                      y: value
                    })
                  }
                }
              }
            })
          }
        })
      }
    }
  })

  return { data_seq: parsed, xMin, xMax, yMin, yMax }
}

var countDecimals = function (value: number) {
  if (Math.floor(value) === value) {
    return 0
  } else {
    return value.toString().split(".")[1].length || 0;
  }
}

function StatesScatterChart(props: HighchartsReact.Props) {
  const { forwardedRef, mapSeries, mapPrimary, frequency, dateUTC, fixedAxis, includeSummary, sessionRecipeInfo } = props;
  const [chartoptions, setChartOptionsState] = useState({
    credits: {
      enabled: false
    },
    exporting: {
      // sourceWidth: 800,
      enabled: false,
      buttons: {
        contextButton: {
          verticalAlign: 'bottom',
          symbol: 'download',
          menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] // removed "viewFullscreen", "printChart", "separator", 
        }
      },
      chartOptions: {
        title: {
          y: -10
        },
        caption: {
          text: '<a href="' + window.location.href + '">' + window.location.href + '</a>',
        },
      },
    },
    title: {
      text: ''
    },
    series: []
  } as Highcharts.Options)

  const [dataSeq, setDataSeq] = useState({} as any)
  const [xMin, setXMin] = useState(0)
  const [xMax, setXMax] = useState(0)
  const [yMin, setYMin] = useState(0)
  const [yMax, setYMax] = useState(0)

  useEffect(() => {
    if (dataSeq[dateUTC] !== undefined) {
      setChartOptions(dataSeq[dateUTC], xMin, xMax, yMin, yMax, frequency, dateUTC, fixedAxis);
    } else {
      var exportFilename: string = 'No Data';
      var dateString = (mapPrimary === null || mapPrimary.name === '') ? '' : (frequencySettings[frequency] !== undefined) ? frequencySettings[frequency].dateFormatter(dateUTC) : frequencySettings['NotSet'].dateFormatter(dateUTC)
      var resetAxes = true;
      var exportEnabled = false
      if (mapPrimary !== null && mapPrimary.name !== '' && mapSeries.length > 0) {
        resetAxes = false
        exportFilename = ''
        exportEnabled = true
        var seriesName = mapPrimary.name
        if (seriesName.length > 30) {
          exportFilename += seriesName.substring(0,18) + ' . . . '
          exportFilename += seriesName.substring(seriesName.length - 9, seriesName.length)
        } else {
          exportFilename += seriesName
        }
  
        var ySeriesName = mapSeries[0].name
        exportFilename += ' - '
        if (ySeriesName.length > 30) {
          exportFilename += ySeriesName.substring(0,18) + ' . . . '
          exportFilename += ySeriesName.substring(ySeriesName.length - 9, ySeriesName.length)
        } else {
          exportFilename += ySeriesName
        }
  
        // exportFilename += ' (' + frequency + ')'
        exportFilename += ' - ' + dateString
      }

      exportFilename += ' - Scatter Chart'

      var newOptions: any = {
        credits: {
          enabled: false
        },
        exporting: {
          // sourceWidth: 800,
          enabled: exportEnabled,
          filename: exportFilename,
          buttons: {
            contextButton: {
              verticalAlign: 'bottom',
              symbol: 'download',
              menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] // removed "viewFullscreen", "printChart", "separator", 
            }
          },
        },
        title: {
          verticalAlign: 'bottom',
          text: dateString
        },
        series: []
      }

      if (resetAxes) {
        newOptions.xAxis = []
        newOptions.yAxis = []
      }

      setChartOptionsState(newOptions)
    }

    // on delay so chart height not prematurely set.
    setTimeout(()=>{
      if (forwardedRef.current !== null) {
        forwardedRef.current.chart.reflow()
      }
    }, 100)
  },[dateUTC]);

  useEffect(() => {
    if (mapPrimary !== null) {
      var { data_seq, xMin, xMax, yMin, yMax } = parseSeriesToDataSeq(mapPrimary, mapSeries, frequency, includeSummary)
      setDataSeq(data_seq)
      setXMin(xMin)
      setXMax(xMax)
      setYMin(yMin)
      setYMax(yMax)
      if (data_seq[dateUTC] !== undefined) {
        setChartOptions(data_seq[dateUTC], xMin, xMax, yMin, yMax, frequency, dateUTC, fixedAxis);
      } else {
        var exportFilename: string = 'No Data';
        var dateString = (mapPrimary === null || mapPrimary.name === '') ? '' : (frequencySettings[frequency] !== undefined) ? frequencySettings[frequency].dateFormatter(dateUTC) : frequencySettings['NotSet'].dateFormatter(dateUTC)
        var resetAxes = true;
        var exportEnabled = false
        if (mapPrimary !== null && mapPrimary.name !== '' && mapSeries.length > 0) {
          resetAxes = false
          exportFilename = ''
          exportEnabled = true
          var seriesName = mapPrimary.name
          if (seriesName.length > 30) {
            exportFilename += seriesName.substring(0,18) + ' . . . '
            exportFilename += seriesName.substring(seriesName.length - 9, seriesName.length)
          } else {
            exportFilename += seriesName
          }
    
          var ySeriesName = mapSeries[0].name
          exportFilename += ' - '
          if (ySeriesName.length > 30) {
            exportFilename += ySeriesName.substring(0,18) + ' . . . '
            exportFilename += ySeriesName.substring(ySeriesName.length - 9, ySeriesName.length)
          } else {
            exportFilename += ySeriesName
          }
    
          // exportFilename += ' (' + frequency + ')'
          exportFilename += ' - ' + dateString
        }

        exportFilename += ' - Scatter Chart'

        var newOptions: any = {
          credits: {
            enabled: false
          },
          exporting: {
            // sourceWidth: 800,
            enabled: exportEnabled,
            filename: exportFilename,
            buttons: {
              contextButton: {
                verticalAlign: 'bottom',
                symbol: 'download',
                menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] // removed "viewFullscreen", "printChart", "separator", 
              }
            },
          },
          title: {
            verticalAlign: 'bottom',
            text: dateString
          },
          series: []
        }

        if (resetAxes) {
          newOptions.xAxis = []
          newOptions.yAxis = []
        }

        setChartOptionsState(newOptions)
      }

      // on delay so chart height not prematurely set.
      setTimeout(()=>{
        if (forwardedRef.current !== null) {
          forwardedRef.current.chart.reflow()
        }
      }, 100)
    } else {
      setDataSeq({} as any)
      setXMin(0)
      setXMax(0)
      setYMin(0)
      setYMax(0)
    }
  },[mapPrimary, mapSeries, frequency, fixedAxis, sessionRecipeInfo.loading]);

  
  function setChartOptions(parsedSeries: any, xMin: number, xMax: number, yMin: number, yMax: number, frequency: string, dateUTC: number, fixedAxis: boolean) {
    var exportFilename: string = 'No Data';
    var dateString = (frequencySettings[frequency] !== undefined) ? frequencySettings[frequency].dateFormatter(dateUTC) : frequencySettings['NotSet'].dateFormatter(dateUTC)
    if (mapPrimary.name !== '' && mapSeries.length > 0) {
      exportFilename = ''
      var seriesName = mapPrimary.name
      if (seriesName.length > 30) {
        exportFilename += seriesName.substring(0,18) + ' . . . '
        exportFilename += seriesName.substring(seriesName.length - 9, seriesName.length)
      } else {
        exportFilename += seriesName
      }

      var ySeriesName = mapSeries[0].name
      exportFilename += ' - '
      if (ySeriesName.length > 30) {
        exportFilename += ySeriesName.substring(0,18) + ' . . . '
        exportFilename += ySeriesName.substring(ySeriesName.length - 9, ySeriesName.length)
      } else {
        exportFilename += ySeriesName
      }

      // exportFilename += ' (' + frequency + ')'
      exportFilename += ' - ' + dateString
    }

    exportFilename += ' - Scatter Chart'
    
    var yAxis = [{
      opposite: false,
      min: fixedAxis ? yMin: undefined,
      max: fixedAxis ? yMax: undefined,
      title: {
        text: ''
      },
      labels: {
        x: -8,
        y: 3
      },
      plotLines: [{
        color: '#bbbbbb',
        width: 2,
        value:  0
      }]
    }, {
      opposite: true,
      title: {
        text: ''
      },
      labels: {
        y: mapSeries.length > 1 ? 3 : -2,
      },
    }] as any[]
    if (mapSeries.length < 6) {
      yAxis = yAxis.concat(mapSeries.map((s: any, i: number) => {
        var text = ''
        // var unit = s.map_attributes.data_type !== undefined ? s.map_attributes.data_type : ''
        var unit = ''
        if (unit !== undefined && unit !== null) {
          text += unit;
        }
        text = '<span style="font-weight: 600">'
        var name = s.name
        if (s.chart_attributes.axisName !== undefined) {
          name = s.chart_attributes.axisName
        }
        if (name.length > 30) {
          text += name.substring(0,18) + ' . . . ' + name.substring(name.length - 9, name.length)
        } else {
          text += name
        }
        if (unit !== undefined && unit !== null) {
          text += '</span> - ' + unit
        }
        return ({
          opposite: false,//s.chart_attributes.axisRight,
          visible: s.chart_attributes.showYAxis === undefined || s.chart_attributes.showYAxis,
          title: {
            text: text,
            style: {
              letterSpacing: '0.3px'
            }
          },
        })
      }))
    }
    var textPrimary = ''
    // var unitPrimary = mapPrimary.map_attributes.data_type !== undefined ? mapPrimary.map_attributes.data_type : ''
    var unitPrimary = ''
    
    if (unitPrimary !== undefined && unitPrimary !== null) {
      textPrimary += unitPrimary;
    }
    textPrimary = '<span style="font-weight: 600">'
    var namePrimary = mapPrimary.name
    if (namePrimary.length > 30) {
      textPrimary += namePrimary.substring(0,18) + ' . . . ' + namePrimary.substring(namePrimary.length - 9, namePrimary.length)
    } else {
      textPrimary += namePrimary
    }
    if (unitPrimary !== undefined && unitPrimary !== null) {
      textPrimary += '</span> - ' + unitPrimary
    }

    var xAxis = [{
      min: fixedAxis ? xMin: undefined,
      max: fixedAxis ? xMax: undefined,
      title: {
        text: textPrimary,
        style: {
          letterSpacing: '0.3px'
        }
      },
      startOnTick: true,
      endOnTick: true,
      gridLineWidth: 1,
      labels: {
        x: -1,
        y: 25
      },
      plotLines: [{
        color: '#bbbbbb',
        width: 2,
        value:  0
      }]
    }]

    setChartOptionsState({
      chart: {
        // height: parsedSeries.length > 1 ? Math.min(430 + 20 * parsedSeries.length, 710) : 400,
        animation: false,
        zoomType: 'xy',
        type: 'scatter'
      },
      credits: {
        enabled: false
      },
      lang: {
        noData: 'No data to display'
      },
      noData: {
        style: {
          fontSize: '15px',
        },
      },
      exporting: {
        // sourceWidth: 800,
        enabled: true,
        filename: exportFilename,
        buttons: {
          contextButton: {
            // verticalAlign: 'bottom',
            symbol: 'download',
            menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] // removed "viewFullscreen", "printChart", "separator", 
          }
        },
      },
      title: {
        verticalAlign: 'bottom',
        text: dateString
      },
      rangeSelector: {
        buttons: [{
          type: 'year',
          count: 5,
          text: '5Y'
        }, {
          type: 'year',
          count: 10,
          text: '10Y'
        }, {
          type: 'all',
          text: 'All'
        }],
        buttonTheme: {
          width: 37
        },
        selected: 2
      },
      legend: {
        enabled: false,
      },
      xAxis: xAxis,
      yAxis: yAxis,
      tooltip: {
        split: false,
        useHTML: true,
        formatter() {
          var self: any = this;
  
          var periodDisplay = '<span style="font-size: 10px">' + self.series.chart.title.textStr;
          
          if (frequency === 'All') {
            periodDisplay += ' (' + self.series.userOptions.frequency + ')'
          }

          periodDisplay += '</span>'

          periodDisplay += '<br>' + self.point.name

          
          var unitPrimary = ''
          // if (mapPrimary.map_attributes.data_type !== undefined && mapPrimary.map_attributes.data_type !== null && mapPrimary.map_attributes.data_type !== '') {
          //   unitPrimary = mapPrimary.map_attributes.data_type
          // }

          if (unitPrimary !== '') {
            unitPrimary = ' (' + unitPrimary + ')'
          }

          var unit = ''
          if (self.series.userOptions.data_type !== undefined && self.series.userOptions.data_type !== null && self.series.userOptions.data_type !== '') {
            unit = self.series.userOptions.data_type
          }

          if (unit !== '') {
            unit = ' (' + unit + ')'
          }

          var valuePrimary = self.x
          var displayValuePrimary = ''
          var numDecimalsPrimary = self.series.userOptions.numDecimalsPrimary

          displayValuePrimary = '<b>' + Highcharts.numberFormat(valuePrimary, numDecimalsPrimary, '.', ',') + '</b>'

          var value = self.y
          var displayValue = ''
          var numDecimals = self.series.userOptions.numDecimals

          displayValue = '<b>' + Highcharts.numberFormat(value, numDecimals, '.', ',') + '</b>'

          var str = '<div style="overflow-wrap: break-word">'
          str += '<span style="color:' + self.series.color + '">\u25CF</span> <span style="font-size:11px">' + periodDisplay
          str += '</span>'
          str += '<br>' + mapPrimary.name
          str += unitPrimary
          str += ': ' + displayValuePrimary
          str += '<br>' + self.series.name
          str += unit
          str += ': ' + displayValue
          str += '</div>'
  
          return str;
        }
      },
      plotOptions: {
        series: {
          showInNavigator: true,
          // animation: {
          //   duration: 1000
          // },
          animation: false,
          // compare: compare,
          dataGrouping: {
            enabled: false
          },
          label: {
            enabled: false
          },
          marker: {
            radius: 4,
            // enabledThreshold: 5,
          },
          dataLabels: {
            enabled: true,
            formatter() {
              var self: any = this;
              var fips = self.point.fips
              return stateInfo[fips] !== undefined ? stateInfo[fips].abbreviation : self.point.name
            },
          },
          opacity: 1,
          color: 'rgba(40, 47, 133, .5)'
        }
      },
      series: lodash.cloneDeep(parsedSeries) //JSON.parse(JSON.stringify(parsedSeries))
    })
  }

  return (
    <HighchartsReact
      {...props}
      ref={forwardedRef}
      highcharts={Highcharts}
      options={chartoptions}
      constructorType={'chart'}
      containerProps={{ style: { userSelect: "none", msUserSelect: "none", height: '100%' } }}
    />
  )
}

const mapStateToProps = (state: any) => ({
  sessionRecipeInfo: state.sessionRecipeInfo,
  // saveRecipeAlert: state.saveRecipeAlert,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect (mapStateToProps, mapDispatchToProps) (StatesScatterChart);