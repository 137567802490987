const annualEndingSettings = {
  maxDays: 366,
  tableColWidth: 170,
  dateFormatterFunc: (indexEndMonth: number) => {
    return ((currPeriod: number) => {
      var tempDate = new Date(currPeriod);
      var tempYear = tempDate.getUTCFullYear();
      var tempMonth = tempDate.getUTCMonth();
      
      tempDate.setUTCMonth(indexEndMonth);

      if (tempMonth > indexEndMonth) {
        tempDate.setUTCFullYear(tempYear + 1)
      }
      
      return 'FY ' + tempDate.getUTCFullYear() + ', end ' + tempDate.toLocaleDateString(undefined, { month: 'short', timeZone: 'UTC' })
    })
  }
} as any;

export const ONE_DAY = 1000 * 60 * 60 * 24

const weeklyEndingSettings = {
  maxDays: 7,
  tableColWidth: 155,
  dateFormatter: (currPeriod: number) => 'W/E ' + new Date(currPeriod + (ONE_DAY * 6)).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })
} as any;

export const frequencySettings = { 
  Annual: {
    displayRank: 0,
    maxDays: 366,
    tableColWidth: 85,
    dateFormatter: (currPeriod: number) => new Date(currPeriod).toLocaleDateString(undefined, { year: 'numeric', timeZone: 'UTC' })
  },
  // AnnualJan: {
  //   displayRank: 1,
  //   maxDays: annualEndingSettings.maxDays,
  //   tableColWidth: annualEndingSettings.tableColWidth,
  //   dateFormatter: annualEndingSettings.dateFormatterFunc(0)
  // },
  // AnnualFeb: {
  //   displayRank: 2,
  //   maxDays: annualEndingSettings.maxDays,
  //   tableColWidth: annualEndingSettings.tableColWidth,
  //   dateFormatter: annualEndingSettings.dateFormatterFunc(1)
  // },
  // AnnualMar: {
  //   displayRank: 3,
  //   maxDays: annualEndingSettings.maxDays,
  //   tableColWidth: annualEndingSettings.tableColWidth,
  //   dateFormatter: annualEndingSettings.dateFormatterFunc(2)
  // },
  // AnnualApr: {
  //   displayRank: 4,
  //   maxDays: annualEndingSettings.maxDays,
  //   tableColWidth: annualEndingSettings.tableColWidth,
  //   dateFormatter: annualEndingSettings.dateFormatterFunc(3)
  // },
  // AnnualMay: {
  //   displayRank: 5,
  //   tableColWidth: annualEndingSettings.tableColWidth,
  //   dateFormatter: annualEndingSettings.dateFormatterFunc(4)
  // },
  // AnnualJun: {
  //   displayRank: 6,
  //   maxDays: annualEndingSettings.maxDays,
  //   tableColWidth: annualEndingSettings.tableColWidth,
  //   dateFormatter: annualEndingSettings.dateFormatterFunc(5)
  // },
  // AnnualJul: {
  //   displayRank: 7,
  //   maxDays: annualEndingSettings.maxDays,
  //   tableColWidth: annualEndingSettings.tableColWidth,
  //   dateFormatter: annualEndingSettings.dateFormatterFunc(6)
  // },
  // AnnualAug: {
  //   displayRank: 8,
  //   maxDays: annualEndingSettings.maxDays,
  //   tableColWidth: annualEndingSettings.tableColWidth,
  //   dateFormatter: annualEndingSettings.dateFormatterFunc(7)
  // },
  // AnnualSep: {
  //   displayRank: 9,
  //   maxDays: annualEndingSettings.maxDays,
  //   tableColWidth: annualEndingSettings.tableColWidth,
  //   dateFormatter: annualEndingSettings.dateFormatterFunc(8)
  // },
  // AnnualOct: {
  //   displayRank: 10,
  //   maxDays: annualEndingSettings.maxDays,
  //   tableColWidth: annualEndingSettings.tableColWidth,
  //   dateFormatter: annualEndingSettings.dateFormatterFunc(9)
  // },
  // AnnualNov: {
  //   displayRank: 11,
  //   maxDays: annualEndingSettings.maxDays,
  //   tableColWidth: annualEndingSettings.tableColWidth,
  //   dateFormatter: annualEndingSettings.dateFormatterFunc(10)
  // },
  'Yearly Ending Jan': {
    displayRank: 1,
    maxDays: annualEndingSettings.maxDays,
    tableColWidth: annualEndingSettings.tableColWidth,
    dateFormatter: annualEndingSettings.dateFormatterFunc(0)
  },
  'Yearly Ending Feb': {
    displayRank: 2,
    maxDays: annualEndingSettings.maxDays,
    tableColWidth: annualEndingSettings.tableColWidth,
    dateFormatter: annualEndingSettings.dateFormatterFunc(1)
  },
  'Yearly Ending Mar': {
    displayRank: 3,
    maxDays: annualEndingSettings.maxDays,
    tableColWidth: annualEndingSettings.tableColWidth,
    dateFormatter: annualEndingSettings.dateFormatterFunc(2)
  },
  'Yearly Ending Apr': {
    displayRank: 4,
    maxDays: annualEndingSettings.maxDays,
    tableColWidth: annualEndingSettings.tableColWidth,
    dateFormatter: annualEndingSettings.dateFormatterFunc(3)
  },
  'Yearly Ending May': {
    displayRank: 5,
    maxDays: annualEndingSettings.maxDays,
    tableColWidth: annualEndingSettings.tableColWidth,
    dateFormatter: annualEndingSettings.dateFormatterFunc(4)
  },
  'Yearly Ending Jun': {
    displayRank: 6,
    maxDays: annualEndingSettings.maxDays,
    tableColWidth: annualEndingSettings.tableColWidth,
    dateFormatter: annualEndingSettings.dateFormatterFunc(5)
  },
  'Yearly Ending Jul': {
    displayRank: 7,
    maxDays: annualEndingSettings.maxDays,
    tableColWidth: annualEndingSettings.tableColWidth,
    dateFormatter: annualEndingSettings.dateFormatterFunc(6)
  },
  'Yearly Ending Aug': {
    displayRank: 8,
    maxDays: annualEndingSettings.maxDays,
    tableColWidth: annualEndingSettings.tableColWidth,
    dateFormatter: annualEndingSettings.dateFormatterFunc(7)
  },
  'Yearly Ending Sep': {
    displayRank: 9,
    maxDays: annualEndingSettings.maxDays,
    tableColWidth: annualEndingSettings.tableColWidth,
    dateFormatter: annualEndingSettings.dateFormatterFunc(8)
  },
  'Yearly Ending Oct': {
    displayRank: 10,
    maxDays: annualEndingSettings.maxDays,
    tableColWidth: annualEndingSettings.tableColWidth,
    dateFormatter: annualEndingSettings.dateFormatterFunc(9)
  },
  'Yearly Ending Nov': {
    displayRank: 11,
    maxDays: annualEndingSettings.maxDays,
    tableColWidth: annualEndingSettings.tableColWidth,
    dateFormatter: annualEndingSettings.dateFormatterFunc(10)
  },
  SemiAnnual: {
    displayRank: 12,
    maxDays: 184,
    tableColWidth: 135,
    dateFormatter: (currPeriod: number) => {
      var tempDate = new Date(currPeriod);
      if (tempDate.getUTCMonth() < 6) {
        return tempDate.toLocaleDateString(undefined, { year: 'numeric', timeZone: 'UTC' }) + ' (1/2)';
      } else {
        return tempDate.toLocaleDateString(undefined, { year: 'numeric', timeZone: 'UTC' }) + ' (2/2)';
      }
    }
  },
  Quarterly: {
    displayRank: 13,
    maxDays: 92,
    tableColWidth: 105,
    dateFormatter: (currPeriod: number) => {
      var tempDate = new Date(currPeriod);
      var tempMonth = tempDate.getUTCMonth();
      if (tempMonth < 3) {
        return tempDate.toLocaleDateString(undefined, { year: 'numeric', timeZone: 'UTC' }) + ' Q1';
      } else if (tempMonth < 6) {
        return tempDate.toLocaleDateString(undefined, { year: 'numeric', timeZone: 'UTC' }) + ' Q2';
      } else if (tempMonth < 9) {
        return tempDate.toLocaleDateString(undefined, { year: 'numeric', timeZone: 'UTC' }) + ' Q3';
      } else if (tempMonth >= 9) {
        return tempDate.toLocaleDateString(undefined, { year: 'numeric', timeZone: 'UTC' }) + ' Q4';
      }
    }
  },
  Monthly: {
    displayRank: 14,
    maxDays: 31,
    tableColWidth: 125,
    dateFormatter: (currPeriod: number) => new Date(currPeriod).toLocaleDateString(undefined, { year: 'numeric', month: 'short', timeZone: 'UTC' })
  },
  // WeeklySun: {
  //   displayRank: 15,
  //   maxDays: weeklyEndingSettings.maxDays,
  //   tableColWidth: weeklyEndingSettings.tableColWidth,
  //   dateFormatter: weeklyEndingSettings.dateFormatter
  // },
  // WeeklyMon: {
  //   displayRank: 16,
  //   maxDays: weeklyEndingSettings.maxDays,
  //   tableColWidth: weeklyEndingSettings.tableColWidth,
  //   dateFormatter: weeklyEndingSettings.dateFormatter
  // },
  // WeeklyTues: {
  //   displayRank: 17,
  //   maxDays: weeklyEndingSettings.maxDays,
  //   tableColWidth: weeklyEndingSettings.tableColWidth,
  //   dateFormatter: weeklyEndingSettings.dateFormatter
  // },
  // WeeklyWeds: {
  //   displayRank: 18,
  //   maxDays: weeklyEndingSettings.maxDays,
  //   tableColWidth: weeklyEndingSettings.tableColWidth,
  //   dateFormatter: weeklyEndingSettings.dateFormatter
  // },
  // WeeklyThurs: {
  //   displayRank: 19,
  //   maxDays: weeklyEndingSettings.maxDays,
  //   tableColWidth: weeklyEndingSettings.tableColWidth,
  //   dateFormatter: weeklyEndingSettings.dateFormatter
  // },
  // WeeklyFri: {
  //   displayRank: 20,
  //   maxDays: weeklyEndingSettings.maxDays,
  //   tableColWidth: weeklyEndingSettings.tableColWidth,
  //   dateFormatter: weeklyEndingSettings.dateFormatter
  // },
  // WeeklySat: {
  //   displayRank: 21,
  //   maxDays: weeklyEndingSettings.maxDays,
  //   tableColWidth: weeklyEndingSettings.tableColWidth,
  //   dateFormatter: weeklyEndingSettings.dateFormatter
  // },
  'Weekly Ending Sunday': {
    displayRank: 15,
    maxDays: weeklyEndingSettings.maxDays,
    tableColWidth: weeklyEndingSettings.tableColWidth,
    dateFormatter: weeklyEndingSettings.dateFormatter
  },
  'Weekly Ending Monday': {
    displayRank: 16,
    maxDays: weeklyEndingSettings.maxDays,
    tableColWidth: weeklyEndingSettings.tableColWidth,
    dateFormatter: weeklyEndingSettings.dateFormatter
  },
  'Weekly Ending Tuesday': {
    displayRank: 17,
    maxDays: weeklyEndingSettings.maxDays,
    tableColWidth: weeklyEndingSettings.tableColWidth,
    dateFormatter: weeklyEndingSettings.dateFormatter
  },
  'Weekly Ending Wednesday': {
    displayRank: 18,
    maxDays: weeklyEndingSettings.maxDays,
    tableColWidth: weeklyEndingSettings.tableColWidth,
    dateFormatter: weeklyEndingSettings.dateFormatter
  },
  'Weekly Ending Thursday': {
    displayRank: 19,
    maxDays: weeklyEndingSettings.maxDays,
    tableColWidth: weeklyEndingSettings.tableColWidth,
    dateFormatter: weeklyEndingSettings.dateFormatter
  },
  'Weekly Ending Friday': {
    displayRank: 20,
    maxDays: weeklyEndingSettings.maxDays,
    tableColWidth: weeklyEndingSettings.tableColWidth,
    dateFormatter: weeklyEndingSettings.dateFormatter
  },
  'Weekly Ending Saturday': {
    displayRank: 21,
    maxDays: weeklyEndingSettings.maxDays,
    tableColWidth: weeklyEndingSettings.tableColWidth,
    dateFormatter: weeklyEndingSettings.dateFormatter
  },
  Daily: {
    displayRank: 30,
    maxDays: 1,
    tableColWidth: 135,
    dateFormatter: (currPeriod: number) => new Date(currPeriod).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })
  },
  NotSet: {
    displayRank: 1000,
    maxDays: 0,
    tableColWidth: 135,
    dateFormatter: (currPeriod: number) => new Date(currPeriod).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })
  }
} as any;