import { Button, Alert, alpha, AppBar, IconButton, InputBase, Snackbar, Tooltip, Toolbar, Menu, MenuItem } from "@mui/material";
import { ThemeProvider, makeStyles } from '@mui/styles';
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import dvLogo from "./DataView_logo.svg";
import CoatOfArms from "./CoatOfArms_small.svg";
import { Search } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SearchDialog from "../Search/SearchDialog";
import { session, setSession, getSession } from '../App';
import { Auth } from 'aws-amplify';
import LoginDialog from './LoginDialog';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import { useSelector, useDispatch } from 'react-redux'
import { closeStaleSessionAlert, closeAddAlert, closeSaveLoadRecipeAlert } from '../actions'
import { setUserInfo } from '../thunks';
const queryString = require('query-string');

const useStyles = makeStyles((theme: any) => ({
  spacer: {
    height: "150px",
    position: 'relative',
    top: 0,
    [theme.breakpoints.up('sm')]: {
      height: "100px",
    },
  },
  appBar: {
    backgroundColor: "#004785 !important",
  },
  toolBar: {
    // zIndex: 9999,
    display: "flex !important",
    position: "sticky",
    flexDirection: "column",
    maxHeight: "150px",
    width: '100%',
    justifyContent: "space-between",
    alignItems: 'center',
    backgroundColor: "#004785 !important",
    padding: "10px !important",
    '& > *': {
      margin: "3px",
      [theme.breakpoints.up('sm')]: {
        margin: "7px"
      }
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: "100px",
      flexDirection: "row",
      // alignItems: "flex-start",
      alignItems: 'center',
    },
  },
  logo: {
    maxWidth: "400px",
    // width: 'auto',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // width: '100%',
      marginRight: '30px'
    },
  },
  coatOfArms: {
    maxWidth: "31px",
    width: '100%',
    margin: '0px',
    '& > *': {
      maxWidth: "31px",
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: '10px',
    },
  },
  grow: {
    flexGrow: 1,
  },
  rightContainerStatic: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px'
    },
    '& > *:not(:first-child)': {
      marginTop: '7px'
    }
  },
  rightContainerStaticTop: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'start',
  },
  rightContainerSticky: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'start',
  },
  navButton: {
    color: "white",
    margin: '0px 30px 0px 0px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  navMenuIcon: {
    color: 'white',
    padding: '8px !important',
    marginLeft: '10px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
      marginLeft: '17px',
    },
  },
  navMenuBadge: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navMenuItem: {
    justifyContent: 'flex-end',
    padding: '10px 16px',
  },
  navMenuLink: {
    color: '#000',
    textDecoration: 'none',
  },
  signInButton: {
    color: "white",
    margin: '0px',
    padding: '0px',
    whiteSpace: 'nowrap',
    fontSize: '13px',
  },
  profileMenuIcon: {
    color: "white",
    zIndex: 999999999,
    padding: '8px !important'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: 'calc(100% - 14px)',
    [theme.breakpoints.up('sm')]: {
      // width: '100%',
      maxWidth: '300px',
    },
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    position: 'relative',
    paddingLeft: "10px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   width: '100%',
    // },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  iconButton: {
    padding: '8px !important',
    color: "white !important"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 2),
    // // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // transition: theme.transitions.create('width'),
    // width: '100%',
    // [theme.breakpoints.up('md')]: {
    //   width: '20ch',
    // },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));


interface AppBarProps extends MuiAppBarProps {
  open?: boolean
  size: string,
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, size }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${size})`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: size,
  }),
}));

export default function Header(props: any) {
  const classes = useStyles();
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollPositionBreakPoint = 50;
  const [searchString, setSearchString] = useState<string>("");
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  let navigate = useNavigate();
  let loc = useLocation();

  const [sess, setSess] = useState<any>();
  const [permissions, setPermissions] = useState<string[]>([] as string[]);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [forgotPassOpen, setForgotPassOpen ] = useState<boolean>(false);
  const [email, setEmail ] = useState<string>("");
  const [username, setUser] = useState<string>("");
  const [forgotPassError, setForgotPassError ] = useState<string>("");
  const dispatch = useDispatch()
  const staleSessionAlert = useSelector((state: any) => state.staleSessionAlert)
  const addAlert = useSelector((state: any) => state.addAlert)
  const saveRecipeAlert = useSelector((state: any) => state.saveRecipeAlert)
  const userInfo = useSelector((state: any) => state.userInfo)

  async function signOut() {

    try {
      await Auth.signOut({ global: true });
      setSession(null);
    } catch (error) {
      console.log('error signing out: ', error);
      setSession(null);
    }

    handleCloseProfileMenu();
  }

  const handleClickOpen = () => {
    setOpenLogin(true);
  };
  const handleCloseLogin = (value: boolean) => {
    setOpenLogin(false);
    if (value === true) {
      getSession().then(s => {
        setSess(s);
      })
      setOpenSuccess(true);
    }

  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  }

  const handleOpenProfileMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  function forgotPass()
  {
    if(username !== "")
    {
      Auth.forgotPassword(username)
      .then(data => {
        setEmail(data.CodeDeliveryDetails.Destination);
        setForgotPassOpen(true);
      })
      .catch(err => setForgotPassError(err));
    }
    
  }

  function closeForgotPass(code: string, newPass: string)
  {
    if(code === "" || newPass === "")
    {
      setForgotPassOpen(false);
    }
    else{
      Auth.forgotPasswordSubmit(username, code, newPass)
      .then(data => {
        setForgotPassError("");
        setForgotPassOpen(false);
      })
      .catch(err => setForgotPassError(err.message));
    }
    
  }

  function search() {
    if(searchString.trim() !== "")
    {
      setSearchOpen(true);
    }
    
  }

  const handleScroll = () => {  
    //handleCloseNavMenu()
    //handleCloseProfileMenu()

    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onCloseAddAlert = () => {
    dispatch(closeAddAlert());
  }

  const onCloseSaveLoadRecipeAlert = () => {
    dispatch(closeSaveLoadRecipeAlert());
  }

  const onCloseStaleSessionAlert = () => {
    dispatch(closeStaleSessionAlert());
  }
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchString(event.target.value)
  }

  const handleSearchClose = (value: string) =>{
    setSearchOpen(false);
  }

  if (sess === undefined || sess !== session) {
    if (staleSessionAlert.open) {
      onCloseStaleSessionAlert()
    }
    getSession().then(s => {
      setSess(s);
      if ((s === null && userInfo.token !== '') || (s !== null && "Bearer " + s.signInUserSession.accessToken.jwtToken !== userInfo.token)) {
        dispatch(setUserInfo(s))
      }
      if(s != null)
      {
        setPermissions(s.signInUserSession.accessToken.payload["cognito:groups"]);
        setEmail(s.attributes.email)
        setUser(s.username)
      }
    }).catch(e => { 
      console.log(e)
      setSess(null);
      if (userInfo.token !== '') {
        dispatch(setUserInfo(null))
      }
      setPermissions([] as string[]);
      setEmail('')
      setUser('')
    })
  }

  return (
    <div>
      <Snackbar
          open={addAlert.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={6000} onClose={onCloseAddAlert}
      >
          <Alert onClose={onCloseAddAlert} severity={addAlert.success ? "success" : "error"}>
              {addAlert.success ? addAlert.name + " was successfully added!" : addAlert.name !== '' ? 'Add to workspace failed: ' + addAlert.name : "Add to workspace failed!"}
          </Alert>
      </Snackbar>
      <Snackbar
          open={saveRecipeAlert.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={6000} onClose={onCloseSaveLoadRecipeAlert}
      >
          <Alert onClose={onCloseSaveLoadRecipeAlert} severity={saveRecipeAlert.success ? "success" : "error"}>
              {(saveRecipeAlert.success && saveRecipeAlert.type === 'update') ? "Current workspace data was successfully updated!"
              : (saveRecipeAlert.type === 'update') ? "Current workspace data failed to update!"
              : (saveRecipeAlert.success && saveRecipeAlert.type === 'load') ? saveRecipeAlert.name + " was successfully loaded!"
              : (saveRecipeAlert.type === 'load') ? saveRecipeAlert.name + " failed to load!"
              : (saveRecipeAlert.success && saveRecipeAlert.type === 'save') ? saveRecipeAlert.name + " was successfully saved!"
              : saveRecipeAlert.name + " failed to save!"}
          </Alert>
      </Snackbar>
      <Snackbar
          open={staleSessionAlert.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={6000} onClose={onCloseStaleSessionAlert}
      >
          <Alert onClose={onCloseStaleSessionAlert} severity="error">
              Login has timed out! Please <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { window.location.reload(); }}>refresh the page</span> and try again.
          </Alert>
      </Snackbar>
      <div className={classes.spacer}></div>
      <StyledAppBar
        style={(scrollPosition > scrollPositionBreakPoint) ? { position: 'fixed', flexDirection: 'row', alignItems: 'center' } : { position: 'absolute', top: 0 }}
        open={props.open} size={props.drawerWidth}
        classes={{ root: classes.appBar }}
      >
        <Toolbar classes={{ root: classes.toolBar }} style={(scrollPosition > scrollPositionBreakPoint && props.screenSize !== 'big') ? { flexDirection: 'row' } : {}}>
          {(scrollPosition > scrollPositionBreakPoint) ? <Link to={'/'} className={classes.coatOfArms} onClick={(e: any) => { if (window.location.pathname === '/') { e.preventDefault(); window.location.reload(); } }}>
            <img src={CoatOfArms} alt="Penn Wharton Logo" style={{ display: (scrollPosition > scrollPositionBreakPoint) ? 'block' : 'none' }} />
          </Link>
          : <div className={classes.logo}>
            <div style={{ maxWidth: props.screenSize === 'big' ? '180px' : '150px' }}>
              <Link to={'/'} onClick={(e: any) => { if (window.location.pathname === '/') { e.preventDefault(); window.location.reload(); } }}>
                <img src={dvLogo} alt="Data View Logo" />
              </Link>
            </div>
            <div style={{ textAlign: 'left', marginLeft: '30px', fontSize: '0.9em' }}>
              by <a href={'https://budgetmodel.wharton.upenn.edu/'} style={{ color: 'inherit', textDecorationColor: 'inherit' }}>Penn Wharton Budget Model</a>
            </div>
          </div>}
          <div className={classes.rightContainerStatic}>
            <div className={classes.rightContainerStaticTop}>
              <div className={classes.searchContainer}>
                <form className={classes.search} onSubmit={(e: React.SyntheticEvent) => {
                  search()
                  e.preventDefault();
                }}>

                  <InputBase
                    value={searchString}
                    style={{ color: "white" }}
                    onChange={handleChange}
                    placeholder="Search to add workspace.."
                    onFocus={(ev: any): any => setTimeout(ev.target.select.bind(ev.target), 10)}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                  <Tooltip title="Search">
                    <IconButton type="submit" className={classes.iconButton} aria-label="search">
                      <Search />
                    </IconButton>
                  </Tooltip>
                </form>
              </div>
              {props.screenSize === 'big' && <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={props.onClick}
              >
                <MenuIcon />
              </IconButton>}
            </div>
            {!(scrollPosition > scrollPositionBreakPoint) && 
              ((sess != null)
                ? <div>
                  <Typography variant="caption" onClick={handleOpenProfileMenu} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'flex-end' }}>Welcome, {username} <KeyboardArrowDownIcon fontSize='small' /></Typography>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseProfileMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={signOut}>Sign Out</MenuItem>
                    <MenuItem onClick={forgotPass}>Change Password</MenuItem>
                  </Menu>
                </div>
                : <Button className={classes.signInButton} onClick={handleClickOpen}>
                  Sign In
                </Button>
              )
            }
          </div>
        </Toolbar>
      </StyledAppBar>
      <SearchDialog open={searchOpen} searchTerm={searchString} onClose={handleSearchClose} />
      <LoginDialog open={openLogin} onClose={handleCloseLogin} />
      <ForgotPasswordDialog open={forgotPassOpen} onClose={closeForgotPass} email={email} error={forgotPassError}/>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <Alert variant="filled" onClose={handleCloseSuccess} severity="success">
          Your permanent password has now been set!
        </Alert>
      </Snackbar>
    </div>
  )
}