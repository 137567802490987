import { 
  REMOVE_VIZ_SERIES,
  ADD_VIZ_SERIES_LOADING,
  ADD_VIZ_SERIES_SUCCESS,
  RELOAD_VIZ_SERIES_SUCCESS,
  ADD_VIZ_SERIES_FAILURE,
  SHOW_CHART_VIZ_SERIES,
  CHANGE_AXIS_VIZ_SERIES,
  CLEAR_TABLE,
  SET_IS_MODIFIED,
  INCREMENT_DERIVED_SERIES_COUNT,
  CLOSE_ADD_ALERT,
  SAVE_RECIPE_SUCCESS,
  SAVE_LOAD_RECIPE_IN_PROGRESS,
  SAVE_RECIPE_FAILURE,
  LOAD_RECIPE_SUCCESS,
  LOAD_RECIPE_FAILURE,
  UPDATE_RECIPE_DATA_SUCCESS,
  UPDATE_RECIPE_DATA_FAILURE,
  CLOSE_SAVE_LOAD_ALERT,
  NEW_RECIPE,
  UPDATE_USER,
  UPDATE_RECIPE_LIST_SUCCESS,
  UPDATE_RECIPE_LIST_FAILURE,
  USER_RECIPE_LIST_LOADING,
  CLEAR_RECIPE_INFO,
  STALE_SESSION_ALERT,
  CLOSE_STALE_SESSION_ALERT,
  SET_VIZ_VIEW,
  SET_UNIT_CHANGE,
  SET_UNIT_CHANGE_FROM,
  SET_CHART_TABLE_LOC_OPTIONS,
  SET_CHART_TABLE_LOC_SELECTED_OBJ,
  SET_MAP_PRIMARY_ID,
  SET_MAP_SECONDARY_ID,
  SET_SIDE_CHART_VIEW,
  SET_SIDE_CHART_FIXED_AXIS,
  SET_MAP_SHOW_ALL,
  SET_MAP_SHOW_ALL_SUMMARY,
  SET_MAP_SELECTED_LOCATIONS,
} from './actions'
import { stateInfo } from './definitions/StateInfo';

export const isLoadingVizNode = (state: boolean = false, action: any) => {
  const { type } = action;

  switch (type) {
    case ADD_VIZ_SERIES_LOADING: 
      return true
    case ADD_VIZ_SERIES_SUCCESS:
    case RELOAD_VIZ_SERIES_SUCCESS:
    case ADD_VIZ_SERIES_FAILURE: 
    case UPDATE_RECIPE_DATA_SUCCESS:
    case LOAD_RECIPE_SUCCESS:
    case SAVE_RECIPE_SUCCESS:
    case UPDATE_RECIPE_DATA_FAILURE:
    case LOAD_RECIPE_FAILURE:
    case SAVE_RECIPE_FAILURE:
      return false
    default:
      return state
  }
}
 
export const nodeCounters = (state: { series: number, maps: number } = { series: 0, maps: 0 }, action: any) =>{
  const { type, payload } = action;
  switch (type) {
    case REMOVE_VIZ_SERIES: {
      const { nodeType } = payload;
      var output = {...state}
      if (nodeType === 'series') {
        output.series -= 1
      }
      if (nodeType === 'map') {
        output.maps -= 1
      }
      return output;
    }
    case ADD_VIZ_SERIES_SUCCESS:{
      const { result } = payload;
      var output = {...state}
      var nodeType = 'series'
      if (result.maps !== null && result.maps.length > 0) {
        nodeType = 'map'
      }
      if (nodeType === 'series') {
        output.series += 1
      }
      if (nodeType === 'map') {
        output.maps += 1
      }
      return output;
    }
    case NEW_RECIPE:
    case CLEAR_TABLE:{
      return { series: 0, maps: 0 };
    }
    case UPDATE_RECIPE_DATA_SUCCESS:
    case LOAD_RECIPE_SUCCESS:
    case SAVE_RECIPE_SUCCESS: {
      const { nodes } = payload;
      var output = { series: 0, maps: 0 }
      nodes.forEach((n: any, i: number) => {
        if (n.displayed && n.result.series !== undefined && n.result.series !== null  && n.result.series.length > 0) {
          output.series++
        } else if (n.displayed && n.result.maps !== undefined && n.result.maps !== null  && n.result.maps.length > 0) {
          output.maps++
        }
      })
      return output
    }
    default:{
      return state;
    }
      
  }

}

export const addAlert = (state: any = {open: false, name: "", id: "", success: false, isRegression: false}, action: any) =>{
  const { type, payload } = action;

  switch (type) {
    case ADD_VIZ_SERIES_SUCCESS:{
      const { recipeInfo } = payload;
      return {open: true, name: recipeInfo.name, id: recipeInfo.id, success: true, isRegression: recipeInfo.type === 'transformation' && recipeInfo.transformation_id.includes('regressions')} ;
    }
    case ADD_VIZ_SERIES_FAILURE:{
      const { message } = payload;
      return {open: true, name: message, id: '', success: false, isRegression: false};
    }
    case CLOSE_ADD_ALERT:{
      return {...state, open: false}
    }
    default:{
      return state;
    }
      
  }
}

export const derivedSeriesCounters = (state: any = {}, action: any) =>{
  const { type, payload } = action;
  //console.log(state);
  switch (type) {
    case INCREMENT_DERIVED_SERIES_COUNT:{
      const { transformType } = payload;
      var output = {...state}
      if (output[transformType] === undefined) {
        output[transformType] = 0
      }

      output[transformType]++
      
      return output;
    }
    case UPDATE_RECIPE_DATA_SUCCESS:
    case LOAD_RECIPE_SUCCESS:
    case SAVE_RECIPE_SUCCESS: {
      const { nodes } = payload;
      var output: any = {} as any
      nodes.forEach((n: any, i: number) => {
        if (n.type === 'transformation') {
          var transformType = 'Transformation'
          if (n.transformation_id.indexOf('frequency_changes') !== -1) {
            transformType = 'ChangeFrequency'
          } else if (n.transformation_id.indexOf('unit_changes') !== -1) {
            transformType = 'ChangeUnit'
          } else if (n.transformation_id.indexOf('calculations') !== -1) {
            transformType = 'Calculate'
          } else if (n.transformation_id.indexOf('regressions') !== -1) {
            transformType = 'Regression'
          }

          if (output[transformType] === undefined) {
            output[transformType] = 0
          }
          
          var currNumber = parseInt(n.id.split(transformType)[1])

          if (currNumber > output[transformType]) {
            output[transformType] = currNumber
          }
        }
      })
      return output
    }
    case NEW_RECIPE:
    case CLEAR_TABLE:{
      return {} as any;
    }
    default: {
      return state;
    }
      
  }

}

export const userInfo = (state: { token: string, recipeList: any[], loading: boolean, success: boolean } = { token: '', recipeList: [] as any[], loading: false, success: true }, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case USER_RECIPE_LIST_LOADING: {
      return { ...state, loading: true }
    }
    case UPDATE_USER: {
      const { token, recipeList, success } = payload;
      return { token, recipeList, loading: false, success }
    }
    case UPDATE_RECIPE_LIST_SUCCESS: {
      const { recipeList } = payload;
      return { ...state, recipeList, loading: false, success: true }
    }
    case UPDATE_RECIPE_LIST_FAILURE: {
      return { ...state, loading: false, success: false }
    }
    default:
      return state
  }
}

export const staleSessionAlert = (state: any = { open: false }, action: any) =>{
  const { type, payload } = action;

  switch (type) {
    case STALE_SESSION_ALERT: {
      return { open: true }
    }
    case CLOSE_STALE_SESSION_ALERT:{
      return {...state, open: false}
    }
    default:{
      return state;
    }
      
  }
}

export const saveRecipeAlert = (state: any = {open: false, name: "", success: false, type: 'save'}, action: any) =>{
  const { type, payload } = action;

  switch (type) {
    case UPDATE_RECIPE_DATA_SUCCESS: {
      return { open: true, name: 'Current session', success: true, type: 'update' }
    }
    case UPDATE_RECIPE_DATA_FAILURE: {
      return { open: true, name: 'Current session', success: false, type: 'update' }
    }
    case LOAD_RECIPE_SUCCESS: {
      const { name } = payload;
      return { open: true, name, success: true, type: 'load' }
    }
    case LOAD_RECIPE_FAILURE: {
      const { name } = payload;
      return { open: true, name, success: false, type: 'load' }
    }
    case SAVE_RECIPE_SUCCESS: {
      const { name } = payload;
      return { open: true, name, success: true, type: 'save' }
    }
    case SAVE_RECIPE_FAILURE: {
      const { name } = payload;
      return { open: true, name, success: false, type: 'save' }
    }
    case CLOSE_SAVE_LOAD_ALERT:{
      return {...state, open: false}
    }
    default:{
      return state;
    }
      
  }
}

const baseMeta: any = {
  vizView: 'chart',
  unitChangeSelected: 'none',
  unitChangeFromSelected: 'prevPeriod',
  chartTableLocOptionsObj: {} as any,
  chartTableLocSelectedObj: {} as any,
  mapPrimaryId: null,
  mapPrimaryFrequency: null,
  mapPrimaryScale: null,
}

export const sessionRecipeInfo = (state: { id: string, name: string, isModified: boolean, modified: Date, loading: boolean, meta: any } = { id: '', name: '', isModified: false, modified: new Date(), loading: false, meta: baseMeta }, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SAVE_LOAD_RECIPE_IN_PROGRESS: {
      return { ...state, loading: true }
    }
    case LOAD_RECIPE_SUCCESS:{
      const { id, name, modified, meta } = payload;
      var newMeta: any = {...baseMeta, ...meta}
      if (id === state.id) {
        newMeta = {
          ...newMeta,
          ...state.meta
        }
      }
      return { ...state, id, name, modified, isModified: false, loading: false, meta: newMeta }
    }
    case SAVE_RECIPE_SUCCESS: {
      const { id, name, modified, meta } = payload;
      return { ...state, id, name, modified, isModified: false, loading: false, meta: {...baseMeta, ...meta} }
    }
    case UPDATE_RECIPE_DATA_SUCCESS:
    case UPDATE_RECIPE_DATA_FAILURE:
    case LOAD_RECIPE_FAILURE:
    case SAVE_RECIPE_FAILURE: {
      return { ...state, loading: false, meta: {...baseMeta, ...state.meta} }
    }
    case SET_IS_MODIFIED:
    case ADD_VIZ_SERIES_SUCCESS:
    case REMOVE_VIZ_SERIES:
    case RELOAD_VIZ_SERIES_SUCCESS:
    case CLEAR_TABLE: {
      return {...state, isModified: true}
    }
    case CLEAR_RECIPE_INFO: {
      return { id: '', name: '', isModified: true, modified: new Date(), loading: false, meta: {...baseMeta, ...state.meta} }
    }
    case NEW_RECIPE: {
      return { id: '', name: '', isModified: false, modified: new Date(), loading: false, meta: baseMeta }
    }
    case SET_VIZ_VIEW: {
      const { view } = payload;
      return {...state, meta: {...state.meta, vizView: view }}
    }
    case SET_UNIT_CHANGE: {
      const { unitChange } = payload;
      return {...state, meta: {...state.meta, unitChangeSelected: unitChange }}
    }
    case SET_UNIT_CHANGE_FROM: {
      const { unitChangeFrom } = payload;
      return {...state, meta: {...state.meta, unitChangeFromSelected: unitChangeFrom }}
    }
    case SET_CHART_TABLE_LOC_OPTIONS: {
      const { nodes } = payload;

      var locOptionsObj = getPossibleLocationsObj(nodes, true)
      var locSelectedObj = {} as any
      if (state.meta.chartTableLocSelectedObj) {
        Object.keys(state.meta.chartTableLocSelectedObj).forEach((map_scale: string) => {
          if (locOptionsObj[map_scale] !== undefined) {
            locSelectedObj[map_scale] = [] as any[]
            state.meta.chartTableLocSelectedObj[map_scale].forEach((l: any) => {
              if (locOptionsObj[map_scale].some((o: any) => l.locationCode === o.locationCode && (l.locationCode !== '-1' || l.location === o.location))) {
                locSelectedObj[map_scale].push(l)
              }
            })
            locSelectedObj[map_scale].sort((a: any, b: any) => {
              if (a.locationCode === b.locationCode) {
                return a.location.localeCompare(b.location)
              } else {
                if (a.locationCode === '-1') {
                  return 1
                }
                if (b.locationCode === '-1') {
                  return -1
                }
                return a.locationCode.localeCompare(b.locationCode)
              }
            })
          }
        });
      }
      // iterate through options keys to make sure at least one selected per map_scale
      Object.keys(locOptionsObj).forEach((map_scale: string) => {
        if (locSelectedObj[map_scale] === undefined) {
          locSelectedObj[map_scale] = [] as any[]
        }
        if (locSelectedObj[map_scale].length == 0 && locOptionsObj[map_scale].length > 0) {
          locSelectedObj[map_scale].push(locOptionsObj[map_scale][0])
        }
      });
      // console.log(locOptionsObj)
      // console.log(locSelectedObj)
      return {
        ...state, 
        meta: {
          ...state.meta, 
          chartTableLocOptionsObj: locOptionsObj,
          chartTableLocSelectedObj: locSelectedObj,
        }
      }
    }
    case SET_CHART_TABLE_LOC_SELECTED_OBJ: {
      const { map_scale, locSelected } = payload;
      var newSelected = [] as any[]
      var locSelectedObj = {...state.meta.chartTableLocSelectedObj} as any
      if (state.meta.chartTableLocOptionsObj && state.meta.chartTableLocOptionsObj[map_scale] !== undefined) {
        locSelected.forEach((l: any) => {
          if (state.meta.chartTableLocOptionsObj[map_scale].some((o: any) => l.locationCode === o.locationCode && (l.locationCode !== '-1' || l.location === o.location))) {
            newSelected.push(l)
          }
        })

        locSelectedObj[map_scale] = newSelected
        return {...state, meta: {...state.meta, chartTableLocSelectedObj: locSelectedObj }}
      } else {
        return state
      }
    }
    case SET_MAP_PRIMARY_ID: {
      const { mapPrimaryId, mapPrimaryFrequency, mapPrimaryScale } = payload;
      var vizView = state.meta.vizView
      if (vizView === 'map' && mapPrimaryId === null) {
        vizView = 'chart'
      }
      return {...state, meta: {...state.meta, mapPrimaryId, mapPrimaryFrequency, mapPrimaryScale, vizView }}
    }
    default:
      return state
  }
}
export const sessionRecipe = (state: any[] = [] as any[], action: any) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_VIZ_SERIES_SUCCESS: {
      const { result, recipeInfo } = payload;
      var output: any[] = [...state];
      var vizSeries = {
        ...recipeInfo,
        result: {
          ...result,
        },
      }
      if (vizSeries.meta === undefined || vizSeries.meta === null) {
        vizSeries.meta = {}
      }
      if (result.series !== null && result.series.length > 0) {
        vizSeries.meta.dataType = 'series'
      }
      if (result.maps !== null && result.maps.length > 0) {
        vizSeries.meta.dataType = 'map'
      }
      
      vizSeries.meta.chart_attributes = {
        show: true,
        axisRight: false,
        frequency: null,
        ...vizSeries.meta.chart_attributes,
      }

      if (vizSeries.meta.dataType === 'series') {
        if (result.series.length > 0) {
          vizSeries.meta.chart_attributes.frequency = result.series[0].frequency
        }
      }

      if (vizSeries.meta.dataType === 'map') {
        vizSeries.meta.chart_attributes = {
          sideChartView: 'bar',
          sideChartFixedAxis: true,
          selectedLocationsObj: [] as any[],
          showAll: false,
          showAllSummary: true,
          mapSecondaryId: null,
          map_scale: null,
          map_location_name: '',
          map_location_abbreviation: '',
          ...vizSeries.meta.chart_attributes,
          // overwrites meta
          locationsObj: getPossibleLocationsObj([vizSeries], false),
        }
        if (result.maps.length > 0 && result.maps[0].children.length > 0) {
          vizSeries.meta.chart_attributes.frequency = result.maps[0].children[0].frequency

          vizSeries.meta.chart_attributes.map_scale = result.maps[0].map_scale

          if (vizSeries.meta.chart_attributes.map_scale === 'city') {
            var fips_county = result.maps[0].children.find((s: any) => s.locationCode !== '-1' && s.locationCode.length > 1).locationCode
            var fips_state = fips_county.substring(0, 2)
            if (fips_state !== '00' && stateInfo[fips_state] !== undefined && !result.maps[0].children.some((s: any) => s.locationCode !== '-1' && s.locationCode.substring(0, 2) !== fips_state)) {
              vizSeries.meta.chart_attributes.map_location_name = stateInfo[fips_state].name + ' Cities'
              vizSeries.meta.chart_attributes.map_location_abbreviation = stateInfo[fips_state].abbreviation + ': City'
            } else {
              vizSeries.meta.chart_attributes.map_location_name = 'US Cities'
              vizSeries.meta.chart_attributes.map_location_abbreviation = 'City'
            }
          } else if (vizSeries.meta.chart_attributes.map_scale === 'county') {
            var fips_county = result.maps[0].children.find((s: any) => s.locationCode !== '-1' && s.locationCode.length > 1).locationCode
            var fips_state = fips_county.substring(0, 2)
            if (fips_state !== '00' && stateInfo[fips_state] !== undefined && !result.maps[0].children.some((s: any) => s.locationCode !== '-1' && s.locationCode.substring(0, 2) !== fips_state)) {
              vizSeries.meta.chart_attributes.map_location_name = stateInfo[fips_state].name
              vizSeries.meta.chart_attributes.map_location_abbreviation = stateInfo[fips_state].abbreviation
            } else {
              vizSeries.meta.chart_attributes.map_location_name = 'US Counties'
              vizSeries.meta.chart_attributes.map_location_abbreviation = 'County'
            }
          } else if (vizSeries.meta.chart_attributes.map_scale === 'state') {
            vizSeries.meta.chart_attributes.map_location_name = 'United States'
            vizSeries.meta.chart_attributes.map_location_abbreviation = 'USA'
          } else if (vizSeries.meta.chart_attributes.map_scale === 'country') {
            vizSeries.meta.chart_attributes.map_location_name = 'World'
            vizSeries.meta.chart_attributes.map_location_abbreviation = 'World'
          }

          vizSeries.meta.chart_attributes.selectedLocationsObj = vizSeries.meta.chart_attributes.selectedLocationsObj.filter((l: any) => vizSeries.meta.chart_attributes.locationsObj.some((o: any) => l.locationCode === o.locationCode && (l.locationCode !== '-1' || l.location === o.location)))

          if (vizSeries.meta.chart_attributes.mapSecondaryId !== null) {
            var mapSecondary = output.find((node: any) => node.displayed && node.id === vizSeries.meta.chart_attributes.mapSecondaryId)
            if (mapSecondary === undefined || mapSecondary.result.maps === null || mapSecondary.result.maps.length === 0 || mapSecondary.result.maps[0].children.length === 0 || mapSecondary.result.maps[0].children[0].frequency !== vizSeries.meta.chart_attributes.frequency) {// || mapSecondary.result.maps[0].map_scale !== vizSeries.meta.chart_attributes.map_scale) {
              vizSeries.meta.chart_attributes.mapSecondaryId = null
            }
          }

          if (vizSeries.meta.chart_attributes.mapSecondaryId === null) {
            var mapSecondary = output.find((node: any) => node.displayed && node.id !== vizSeries.id && node.result.maps !== undefined && node.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0 && node.result.maps[0].children[0].frequency === vizSeries.meta.chart_attributes.frequency && node.result.maps[0].map_scale === vizSeries.meta.chart_attributes.map_scale && node.meta.chart_attributes.map_location_name === vizSeries.meta.chart_attributes.map_location_name)
            if (mapSecondary !== undefined) {
              vizSeries.meta.chart_attributes.mapSecondaryId = mapSecondary.id
            }
          }
        }
      }

      vizSeries.meta.ancestors = getAncestors(vizSeries, output)

      vizSeries.meta = {
        aliasCreated: false,
        original_name: vizSeries.name,
        ...vizSeries.meta
      }

      var objIndex = output.findIndex(s => (s.id === vizSeries.id) || (s.type === 'db_datanode' && s.meta.result_id === vizSeries.meta.result_id && s.meta.result_index === vizSeries.meta.result_index))
      if (objIndex === -1) {
        output = output.concat(vizSeries);
      }
      
      return output
    }
    case RELOAD_VIZ_SERIES_SUCCESS: {
      const { result, recipeInfo } = payload;
      var output: any[] = [...state];
      var vizSeries = {
        ...recipeInfo,
        result: {
          ...result,
        },
      }
      var objIndex = output.findIndex(s => (s.id === vizSeries.id) || (s.type === 'db_datanode' && s.meta.result_id === vizSeries.meta.result_id && s.meta.result_index === vizSeries.meta.result_index))
      
      if (objIndex !== -1) {
        if (vizSeries.meta === undefined || vizSeries.meta === null) {
          vizSeries.meta = {}
        }
        if (result.series !== null && result.series.length > 0) {
          vizSeries.meta.dataType = 'series'
        }
        if (result.maps !== null && result.maps.length > 0) {
          vizSeries.meta.dataType = 'map'
        }
        vizSeries.meta.chart_attributes = {
          show: true,
          axisRight: false,
          frequency: null,
          ...vizSeries.meta.chart_attributes,
        }

        if (objIndex !== -1) {
          vizSeries.meta.chart_attributes = {
            ...vizSeries.meta.chart_attributes,
            ...output[objIndex].meta.chart_attributes
          }
        }

        if (vizSeries.meta.dataType === 'series') {
          if (result.series.length > 0) {
            vizSeries.meta.chart_attributes.frequency = result.series[0].frequency
          }
        }

        if (vizSeries.meta.dataType === 'map') {
          vizSeries.meta.chart_attributes = {
            sideChartView: 'bar',
            sideChartFixedAxis: true,
            selectedLocationsObj: [] as any[],
            showAll: false,
            showAllSummary: true,
            mapSecondaryId: null,
            map_scale: null,
            map_location_name: '',
            map_location_abbreviation: '',
            ...vizSeries.meta.chart_attributes,
            // overwrites meta
            locationsObj: getPossibleLocationsObj([vizSeries], false),
          }
          if (result.maps.length > 0 && result.maps[0].children.length > 0) {
            vizSeries.meta.chart_attributes.frequency = result.maps[0].children[0].frequency

            vizSeries.meta.chart_attributes.map_scale = result.maps[0].map_scale

            if (vizSeries.meta.chart_attributes.map_scale === 'city') {
              var fips_county = result.maps[0].children.find((s: any) => s.locationCode !== '-1' && s.locationCode.length > 1).locationCode
              var fips_state = fips_county.substring(0, 2)
              if (fips_state !== '00' && stateInfo[fips_state] !== undefined && !result.maps[0].children.some((s: any) => s.locationCode !== '-1' && s.locationCode.substring(0, 2) !== fips_state)) {
                vizSeries.meta.chart_attributes.map_location_name = stateInfo[fips_state].name + ' Cities'
                vizSeries.meta.chart_attributes.map_location_abbreviation = stateInfo[fips_state].abbreviation + ': City'
              } else {
                vizSeries.meta.chart_attributes.map_location_name = 'US Cities'
                vizSeries.meta.chart_attributes.map_location_abbreviation = 'City'
              }
            } else if (vizSeries.meta.chart_attributes.map_scale === 'county') {
              var fips_county = result.maps[0].children.find((s: any) => s.locationCode !== '-1' && s.locationCode.length > 1).locationCode
              var fips_state = fips_county.substring(0, 2)
              if (fips_state !== '00' && stateInfo[fips_state] !== undefined && !result.maps[0].children.some((s: any) => s.locationCode !== '-1' && s.locationCode.substring(0, 2) !== fips_state)) {
                vizSeries.meta.chart_attributes.map_location_name = stateInfo[fips_state].name
                vizSeries.meta.chart_attributes.map_location_abbreviation = stateInfo[fips_state].abbreviation
              } else {
                vizSeries.meta.chart_attributes.map_location_name = 'US Counties'
                vizSeries.meta.chart_attributes.map_location_abbreviation = 'County'
              }
            } else if (vizSeries.meta.chart_attributes.map_scale === 'state') {
              vizSeries.meta.chart_attributes.map_location_name = 'United States'
              vizSeries.meta.chart_attributes.map_location_abbreviation = 'USA'
            } else if (vizSeries.meta.chart_attributes.map_scale === 'country') {
              vizSeries.meta.chart_attributes.map_location_name = 'World'
              vizSeries.meta.chart_attributes.map_location_abbreviation = 'World'
            }

            vizSeries.meta.chart_attributes.selectedLocationsObj = vizSeries.meta.chart_attributes.selectedLocationsObj.filter((l: any) => vizSeries.meta.chart_attributes.locationsObj.some((o: any) => l.locationCode === o.locationCode && (l.locationCode !== '-1' || l.location === o.location)))

            if (vizSeries.meta.chart_attributes.mapSecondaryId !== null) {
              var mapSecondary = output.find((node: any) => node.displayed && node.id === vizSeries.meta.chart_attributes.mapSecondaryId)
              if (mapSecondary === undefined || mapSecondary.result.maps === null || mapSecondary.result.maps.length === 0 || mapSecondary.result.maps[0].children.length === 0 || mapSecondary.result.maps[0].children[0].frequency !== vizSeries.meta.chart_attributes.frequency) { // || mapSecondary.result.maps[0].map_scale !== vizSeries.meta.chart_attributes.map_scale) {
                vizSeries.meta.chart_attributes.mapSecondaryId = null
              }
            }

            if (vizSeries.meta.chart_attributes.mapSecondaryId === null) {
              var mapSecondary = output.find((node: any) => node.displayed && node.id !== vizSeries.id && node.result.maps !== undefined && node.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0 && node.result.maps[0].children[0].frequency === vizSeries.meta.chart_attributes.frequency && node.result.maps[0].map_scale === vizSeries.meta.chart_attributes.map_scale && node.meta.chart_attributes.map_location_name === vizSeries.meta.chart_attributes.map_location_name)
              if (mapSecondary !== undefined) {
                vizSeries.meta.chart_attributes.mapSecondaryId = mapSecondary.id
              }
            }
          }
        }

        vizSeries.meta.ancestors = getAncestors(vizSeries, output)

        vizSeries.meta = {
          aliasCreated: false,
          original_name: vizSeries.name,
          ...vizSeries.meta
        }

        var prev_id = output[objIndex].id
        output[objIndex] = vizSeries
        
        if (prev_id !== vizSeries.id) {
          output.forEach((n: any, i: number) => {
            // update all mapSecondaries
            if (n.type === 'transformation') {
              var inputIndex = n.input.findIndex((id: string) => id === prev_id)
              if (inputIndex !== -1) {
                n.input[inputIndex] = vizSeries.id
                if (n.transformation_id.indexOf('calculations') !== -1) {
                  n.params.expression = updateCalculateExpressionId(n.params.expression, prev_id, vizSeries.id)
                } else if (n.transformation_id.indexOf('regressions.OLS') !== -1) {
                  if (n.params.dependent_id === prev_id) {
                    n.params.dependent_id = vizSeries.id
                  }
                  var indIndex = n.params.independent_ids.findIndex((id: string) => id === prev_id)
                  if (indIndex !== -1) {
                    n.params.independent_ids[indIndex] = vizSeries.id
                  }
                } else if (n.transformation_id.indexOf('regressions.PanelOLS') !== -1) {
                  if (n.params.dependent_id === prev_id) {
                    n.params.dependent_id = vizSeries.id
                  }
                  var indTimeIndex = n.params.time_independent_ids.findIndex((id: string) => id === prev_id)
                  if (indTimeIndex !== -1) {
                    n.params.time_independent_ids[indTimeIndex] = vizSeries.id
                  }
                  var indMapIndex = n.params.map_independent_ids.findIndex((id: string) => id === prev_id)
                  if (indMapIndex !== -1) {
                    n.params.map_independent_ids[indMapIndex] = vizSeries.id
                  }
                }
              }

              // update ancestor field
              if (n.meta.ancestors !== undefined) {
                var ancestorIndex = n.meta.ancestors.findIndex((id: string) => id === prev_id)
                if (ancestorIndex !== -1) {
                  n.meta.ancestors[ancestorIndex] = vizSeries.id
                }
              } else {
                n.meta.ancestors = [] as string[]
              }
            }

            // update all mapSecondary ids if id was changed
            if (n.meta.dataType === 'map') {
              if (n.meta.chart_attributes.mapSecondaryId === prev_id) {
                n.meta.chart_attributes.mapSecondaryId = vizSeries.id
              }
            }
          })
        }
      }

      return output
    }
    case REMOVE_VIZ_SERIES: {
      const { id } = payload;
      var output: any[] = [...state]
      var seriesIndex = output.findIndex((s: any) => id === s.id)
      if (seriesIndex !== -1) {
        output[seriesIndex] = {...output[seriesIndex], displayed: false, result: { ...output[seriesIndex].result, maps: null, series: null }}
      }
      return removeUnnecessaryNodes(output)
    }
    case SHOW_CHART_VIZ_SERIES: {
      const { id } = payload;
      // console.log(id)
      var output = [...state]
      var sIndex = output.findIndex((series: any, i: number) => id === series.id)
      if (sIndex !== -1) {
        output[sIndex].meta.chart_attributes.show = !output[sIndex].meta.chart_attributes.show
      }
      return output
    }
    case CHANGE_AXIS_VIZ_SERIES: {
      const { id, axisRight } = payload;
      var output = [...state]
      var seriesIndex = output.findIndex((series: any) => series.id === id)
      if (seriesIndex !== -1) {
        output[seriesIndex].meta.chart_attributes.axisRight = axisRight
      }
      return output
    }
    case SET_MAP_SECONDARY_ID: {
      const { id, mapSecondaryId } = payload;
      var output = [...state]
      var mapPrimary = output.find((n: any) => n.id === id)
      if (mapPrimary !== undefined) {
        var mapSecondary = output.find((n: any) => n.displayed && n.id === mapSecondaryId)
        if (mapSecondary === undefined || mapSecondary.result.maps === null || mapSecondary.result.maps.length === 0 || mapSecondary.result.maps[0].children.length === 0 || mapSecondary.result.maps[0].children[0].frequency !== mapPrimary.meta.chart_attributes.frequency) {// || mapSecondary.result.maps[0].map_scale !== mapPrimary.meta.chart_attributes.map_scale) {
          mapPrimary.meta.chart_attributes.mapSecondaryId = null
        } else {
          mapPrimary.meta.chart_attributes.mapSecondaryId = mapSecondaryId
        }

        if (mapPrimary.meta.chart_attributes.mapSecondaryId === null) {
          var mapSecondary = output.find((n: any) => n.displayed && n.id !== id && n.result.maps !== undefined && n.result.maps !== null && n.result.maps.length > 0 && n.result.maps[0].children.length > 0 && n.result.maps[0].children[0].frequency === mapPrimary.meta.chart_attributes.frequency && n.result.maps[0].map_scale === mapPrimary.meta.chart_attributes.map_scale && n.meta.chart_attributes.map_location_name === mapPrimary.meta.chart_attributes.map_location_name)
          if (mapSecondary !== undefined) {
            mapPrimary.meta.chart_attributes.mapSecondaryId = mapSecondary.id
          }
        }
      }
      return output
    }
    case SET_SIDE_CHART_VIEW: {
      const { id, sideChartView } = payload;
      var output = [...state]
      var seriesIndex = output.findIndex((series: any) => series.id === id)
      if (seriesIndex !== -1) {
        output[seriesIndex].meta.chart_attributes.sideChartView = sideChartView
      }
      return output
    }
    case SET_SIDE_CHART_FIXED_AXIS: {
      const { id, sideChartFixedAxis } = payload;
      var output = [...state]
      var seriesIndex = output.findIndex((series: any) => series.id === id)
      if (seriesIndex !== -1) {
        output[seriesIndex].meta.chart_attributes.sideChartFixedAxis = sideChartFixedAxis
      }
      return output
    }
    case SET_MAP_SHOW_ALL: {
      const { id, showAll } = payload;
      var output = [...state]
      var seriesIndex = output.findIndex((series: any) => series.id === id)
      if (seriesIndex !== -1) {
        output[seriesIndex].meta.chart_attributes.showAll = showAll
      }
      return output
    }
    case SET_MAP_SHOW_ALL_SUMMARY: {
      const { id, showAllSummary } = payload;
      var output = [...state]
      var seriesIndex = output.findIndex((series: any) => series.id === id)
      if (seriesIndex !== -1) {
        output[seriesIndex].meta.chart_attributes.showAllSummary = showAllSummary
      }
      return output
    }
    case SET_MAP_SELECTED_LOCATIONS: {
      const { id, selectedLocations } = payload;
      var output = [...state]
      var seriesIndex = output.findIndex((series: any) => series.id === id)
      if (seriesIndex !== -1) {
        output[seriesIndex].meta.chart_attributes.selectedLocationsObj = selectedLocations.filter((l: any) => output[seriesIndex].meta.chart_attributes.locationsObj.some((o: any) => l.locationCode === o.locationCode && (l.locationCode !== '-1' || l.location === o.location)))
      }
      return output
    }
    case SET_MAP_PRIMARY_ID: {
      const { mapPrimaryId } = payload;
      // console.log(id)
      var output = [...state]
      if (mapPrimaryId !== null) {
        var mapPrimary = output.find((series: any, i: number) => mapPrimaryId === series.id)
        if (mapPrimary !== undefined) {
          if (mapPrimary.meta.dataType === 'map' && mapPrimary.result.maps !== null && mapPrimary.result.maps.length > 0) {
            mapPrimary.meta.chart_attributes = {
              sideChartView: 'bar',
              sideChartFixedAxis: true,
              selectedLocationsObj: [] as any[],
              showAll: false,
              showAllSummary: true,
              mapSecondaryId: null,
              map_scale: null,
              map_location_name: '',
              map_location_abbreviation: '',
              ...mapPrimary.meta.chart_attributes,
              // overwrites meta
              locationsObj: getPossibleLocationsObj([mapPrimary], false),
            }
            if (mapPrimary.result.maps[0].children.length > 0) {
              mapPrimary.meta.chart_attributes.frequency = mapPrimary.result.maps[0].children[0].frequency

              mapPrimary.meta.chart_attributes.map_scale = mapPrimary.result.maps[0].map_scale

              if (mapPrimary.meta.chart_attributes.map_scale === 'city') {
                var fips_county = mapPrimary.result.maps[0].children.find((s: any) => s.locationCode !== '-1' && s.locationCode.length > 1).locationCode
                var fips_state = fips_county.substring(0, 2)
                if (fips_state !== '00' && stateInfo[fips_state] !== undefined && !mapPrimary.result.maps[0].children.some((s: any) => s.locationCode !== '-1' && s.locationCode.substring(0, 2) !== fips_state)) {
                  mapPrimary.meta.chart_attributes.map_location_name = stateInfo[fips_state].name + ' Cities'
                  mapPrimary.meta.chart_attributes.map_location_abbreviation = stateInfo[fips_state].abbreviation + ': City'
                } else {
                  mapPrimary.meta.chart_attributes.map_location_name = 'US Cities'
                  mapPrimary.meta.chart_attributes.map_location_abbreviation = 'City'
                }
              } else if (mapPrimary.meta.chart_attributes.map_scale === 'county') {
                var fips_county = mapPrimary.result.maps[0].children.find((s: any) => s.locationCode !== '-1' && s.locationCode.length > 1).locationCode
                var fips_state = fips_county.substring(0, 2)
                if (fips_state !== '00' && stateInfo[fips_state] !== undefined && !mapPrimary.result.maps[0].children.some((s: any) => s.locationCode !== '-1' && s.locationCode.substring(0, 2) !== fips_state)) {
                  mapPrimary.meta.chart_attributes.map_location_name = stateInfo[fips_state].name
                  mapPrimary.meta.chart_attributes.map_location_abbreviation = stateInfo[fips_state].abbreviation
                } else {
                  mapPrimary.meta.chart_attributes.map_location_name = 'US Counties'
                  mapPrimary.meta.chart_attributes.map_location_abbreviation = 'County'
                }
              } else if (mapPrimary.meta.chart_attributes.map_scale === 'state') {
                mapPrimary.meta.chart_attributes.map_location_name = 'United States'
                mapPrimary.meta.chart_attributes.map_location_abbreviation = 'USA'
              } else if (mapPrimary.meta.chart_attributes.map_scale === 'country') {
                mapPrimary.meta.chart_attributes.map_location_name = 'World'
                mapPrimary.meta.chart_attributes.map_location_abbreviation = 'World'
              }

              mapPrimary.meta.chart_attributes.selectedLocationsObj = mapPrimary.meta.chart_attributes.selectedLocationsObj.filter((l: any) => mapPrimary.meta.chart_attributes.locationsObj.some((o: any) => l.locationCode === o.locationCode && (l.locationCode !== '-1' || l.location === o.location)))
    
              if (mapPrimary.meta.chart_attributes.mapSecondaryId !== null) {
                var mapSecondary = output.find((n: any) => n.displayed && n.id === mapPrimary.meta.chart_attributes.mapSecondaryId)
                if (mapSecondary === undefined || mapSecondary.result.maps === null || mapSecondary.result.maps.length === 0 || mapSecondary.result.maps[0].children.length === 0 || mapSecondary.result.maps[0].children[0].frequency !== mapPrimary.meta.chart_attributes.frequency) { //} || mapSecondary.result.maps[0].map_scale !== mapPrimary.meta.chart_attributes.map_scale) {
                  mapPrimary.meta.chart_attributes.mapSecondaryId = null
                }
              }
    
              if (mapPrimary.meta.chart_attributes.mapSecondaryId === null) {
                var mapSecondary = output.find((n: any) => n.displayed && n.id !== mapPrimaryId && n.result.maps !== undefined && n.result.maps !== null && n.result.maps.length > 0 && n.result.maps[0].children.length > 0 && n.result.maps[0].children[0].frequency === mapPrimary.meta.chart_attributes.frequency && n.result.maps[0].map_scale === mapPrimary.meta.chart_attributes.map_scale && n.meta.chart_attributes.map_location_name === mapPrimary.meta.chart_attributes.map_location_name)
                if (mapSecondary !== undefined) {
                  mapPrimary.meta.chart_attributes.mapSecondaryId = mapSecondary.id
                }
              }
            }
          }
        }
      }
      return output
    }
    case NEW_RECIPE:
    case CLEAR_TABLE: {
      return [] as any[];
    }
    case UPDATE_RECIPE_DATA_SUCCESS:
    case LOAD_RECIPE_SUCCESS:
    case SAVE_RECIPE_SUCCESS: {
      const { nodes } = payload;
      var output = [] as any[]
      nodes.forEach((n: any, i: number) => {
        var node = {...n}
        if (node.meta === undefined || node.meta === null) {
          node.meta = {}
        }
        
        node.meta = {
          aliasCreated: false,
          original_name: '',
          ...node.meta
        }

        if (node.meta.chart_attributes === undefined) {
          node.meta.chart_attributes = {}
        }
        node.meta.chart_attributes = {
          show: true,
          axisRight: false,
          frequency: null,
          ...node.meta.chart_attributes,
        }

        if (node.result.raw_error !== undefined && node.result.raw_error !== null) {
          if (node.name === '' && node.meta.original_name !== undefined) {
            node.name = node.meta.original_name
          }
          node.meta.error = {...node.result}
          node.result = {
            name: node.name,
            series: null,
            maps: null,
          }
        } else {
          node.meta.error = null
          if (node.result.series !== null && node.result.series.length > 0) {
            node.meta.dataType = 'series'
          }
          if (node.result.maps !== null && node.result.maps.length > 0) {
            node.meta.dataType = 'map'
          }

          var origName = node.result.name
          if (node.meta.dataType === 'series' && node.result.series.length > 0) {
            origName += ', ' + node.result.series[0].name
            node.meta.chart_attributes.frequency = node.result.series[0].frequency
          }
          if (node.meta.dataType === 'map' && node.result.maps.length > 0) {
            origName += ', ' + node.result.maps[0].name
            node.meta.chart_attributes = {
              sideChartView: 'bar',
              sideChartFixedAxis: true,
              selectedLocationsObj: [] as any[],
              showAll: false,
              showAllSummary: true,
              mapSecondaryId: null,
              map_scale: null,
              map_location_name: '',
              map_location_abbreviation: '',
              ...node.meta.chart_attributes,
              // overwrites meta
              locationsObj: getPossibleLocationsObj([node], false),
            }
            if (node.result.maps[0].children.length > 0) {
              node.meta.chart_attributes.frequency = node.result.maps[0].children[0].frequency

              node.meta.chart_attributes.map_scale = node.result.maps[0].map_scale

              if (node.meta.chart_attributes.map_scale === 'city') {
                var fips_county = node.result.maps[0].children.find((s: any) => s.locationCode !== '-1' && s.locationCode.length > 1).locationCode
                var fips_state = fips_county.substring(0, 2)
                if (fips_state !== '00' && stateInfo[fips_state] !== undefined && !node.result.maps[0].children.some((s: any) => s.locationCode !== '-1' && s.locationCode.substring(0, 2) !== fips_state)) {
                  node.meta.chart_attributes.map_location_name = stateInfo[fips_state].name + ' Cities'
                  node.meta.chart_attributes.map_location_abbreviation = stateInfo[fips_state].abbreviation + ': City'
                } else {
                  node.meta.chart_attributes.map_location_name = 'US Cities'
                  node.meta.chart_attributes.map_location_abbreviation = 'City'
                }
              } else if (node.meta.chart_attributes.map_scale === 'county') {
                var fips_county = node.result.maps[0].children.find((s: any) => s.locationCode !== '-1' && s.locationCode.length > 1).locationCode
                var fips_state = fips_county.substring(0, 2)
                if (fips_state !== '00' && stateInfo[fips_state] !== undefined && !node.result.maps[0].children.some((s: any) => s.locationCode !== '-1' && s.locationCode.substring(0, 2) !== fips_state)) {
                  node.meta.chart_attributes.map_location_name = stateInfo[fips_state].name
                  node.meta.chart_attributes.map_location_abbreviation = stateInfo[fips_state].abbreviation
                } else {
                  node.meta.chart_attributes.map_location_name = 'US Counties'
                  node.meta.chart_attributes.map_location_abbreviation = 'County'
                }
              } else if (node.meta.chart_attributes.map_scale === 'state') {
                node.meta.chart_attributes.map_location_name = 'United States'
                node.meta.chart_attributes.map_location_abbreviation = 'USA'
              } else if (node.meta.chart_attributes.map_scale === 'country') {
                node.meta.chart_attributes.map_location_name = 'World'
                node.meta.chart_attributes.map_location_abbreviation = 'World'
              }

              node.meta.chart_attributes.selectedLocationsObj = node.meta.chart_attributes.selectedLocationsObj.filter((l: any) => node.meta.chart_attributes.locationsObj.some((o: any) => l.locationCode === o.locationCode && (l.locationCode !== '-1' || l.location === o.location)))
    
              if (node.meta.chart_attributes.mapSecondaryId !== null) {
                var mapSecondary = nodes.find((n: any) => n.displayed && n.id === node.meta.chart_attributes.mapSecondaryId)
                if (mapSecondary === undefined || mapSecondary.result.maps === undefined || mapSecondary.result.maps === null || mapSecondary.result.maps.length === 0 || mapSecondary.result.maps[0].children.length === 0 || mapSecondary.result.maps[0].children[0].frequency !== node.meta.chart_attributes.frequency) { // || mapSecondary.result.maps[0].map_scale !== node.meta.chart_attributes.map_scale) {
                  node.meta.chart_attributes.mapSecondaryId = null
                }
              }
    
              if (node.meta.chart_attributes.mapSecondaryId === null) {
                var mapSecondary = nodes.find((n: any) => n.displayed && n.id !== node.id && n.result.maps !== undefined && n.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0 && n.result.maps[0].children[0].frequency === node.meta.chart_attributes.frequency && n.result.maps[0].map_scale === node.meta.chart_attributes.map_scale && n.meta.chart_attributes.map_location_name === node.meta.chart_attributes.map_location_name)
                if (mapSecondary !== undefined) {
                  node.meta.chart_attributes.mapSecondaryId = mapSecondary.id
                }
              }
            }
          }

          if (node.meta.aliasCreated) {
            node.meta.original_name = origName
          } else {
            node.name = origName
            node.meta.original_name = origName
          }
        }
        if (!node.displayed) {
          // result: { ...output[seriesIndex].result, maps: null, series: null }
          node.result = {
            ...node.result,
            maps: null,
            series: null,
          }
        }
        output.push(node)
      })

      // reset all nodes to have no ancestors so re-checked. could add in logic to assume previous ancestors correct if not undefined.
      output.forEach((node: any, i: number) => {
        node.meta.ancestors = [] as string[]
      })

      // get ancestors for each node
      output.forEach((node: any, i: number) => {
        node.meta.ancestors = getAncestors(node, output)
      })
      
      return output
    }
    default:
      return state
  }
}

const removeUnnecessaryNodes = (nodes: any[]) => {
  var output = [] as any[]
  var notDisplayed = [] as any[]
  var outputIds = [] as string[]
  var displayedAncestorIds = new Set<string> ()
  nodes.forEach((n: any, i: number) => {
    if (n.displayed) {
      output.push(n)
      outputIds.push(n.id)
      if (n.input !== undefined) {
        n.input.forEach((input: any) => {
          displayedAncestorIds.add(input)
        })
      }
    } else {
      notDisplayed.push(n)
    }
  })

  while (displayedAncestorIds.size > 0) {
    var newAncestors = new Set<string> ()
    displayedAncestorIds.forEach((id: string) => {
      if (!outputIds.includes(id)) {
        var currSeries: any = notDisplayed.find((n: any) => n.id === id)
        if (currSeries !== undefined) {
          output.push(currSeries)
          outputIds.push(currSeries.id)
          if (currSeries.input !== undefined && currSeries.input !== null) {
            currSeries.input.forEach((input: any) => {
              if (!outputIds.includes(input)) {
                newAncestors.add(input)
              }
            })
          }
        }
      }
    })
    displayedAncestorIds = newAncestors
  }

  return output
}

const getPossibleLocationsObj = (nodes: any[], isRecipeMeta: boolean) => {
  var output = {} as any
  nodes.forEach((n: any, i: number) => {
    if (n.displayed && (n.result.raw_error === undefined || n.result.raw_error === null) && n.result.maps !== null && n.result.maps.length > 0 && (!isRecipeMeta || !n.meta || !n.meta.chart_attributes || n.meta.chart_attributes.show)) {
      var map_scale = n.result.maps[0].map_scale ? n.result.maps[0].map_scale : 'state'
      if (output[map_scale] === undefined) {
        output[map_scale] = [] as any[]
      }

      n.result.maps[0].children.forEach((s: any, j: number) => {
        var locIndex = output[map_scale].findIndex((l: any) => l.locationCode === s.locationCode && (l.locationCode !== '-1' || l.location === s.location))
        if (locIndex === -1) {
          output[map_scale].push({
            location: s.location,
            locationCode: s.locationCode,
          })
        }
      })
    }
  })
  Object.keys(output).forEach((key: string) => {
    output[key].sort((a: any, b: any) => {
      if (a.locationCode === b.locationCode) {
        return a.location.localeCompare(b.location)
      } else {
        if (a.locationCode === '-1') {
          return 1
        }
        if (b.locationCode === '-1') {
          return -1
        }
        return a.locationCode.localeCompare(b.locationCode)
      }
    })
  });
  // console.log(output)

  if (isRecipeMeta) {
    return output
  } else {
    return Object.keys(output).length > 0 ? output[Object.keys(output)[0]] : [] as any[]
  }
}

const updateCalculateExpressionId = (expression: any[], prev_id: string, new_id: string) => {
  var output = [...expression]
  output.forEach((item: any, i: number) => {
    if (item.type === 'series' || item.type === 'map') {
      if (item.payload.id === prev_id) {
        item.payload.id = new_id
      }
    } else if (item.type === 'parentheses') {
      item.payload.expression = updateCalculateExpressionId(item.payload.expression, prev_id, new_id)
    }
  })
  return output
}

const getAncestors = (currNode: any, nodes: any[]) => {
  if (currNode.type === 'transformation') {
    // ancestors changed in place for input nodes, so use what is there if already set
    if (currNode.meta.ancestors === undefined || currNode.meta.ancestors.length == 0) {
      // console.log(currNode.name)
      var ancestors = [] as string[]
      currNode.input.forEach((input_id: string) => {
        var input_node = nodes.find((n: any) => n.id === input_id)
        if (input_node !== undefined) {
          input_node.meta.ancestors = getAncestors(input_node, nodes)
          ancestors = ancestors.concat(input_id, input_node.meta.ancestors)
        }
      })
      return ancestors
    } else {
      return currNode.meta.ancestors
    }
  } else {
    return [] as string[]
  }
}