import React from 'react';
import { Button, CardContent, Card, Typography, IconButton } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { useResizeDetector } from 'react-resize-detector';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: any) => ({
  sectionContainer: {
    marginBottom: '50px',
    '& > *': {
      marginBottom: '15px'
    },
  }
}));


function AboutPage(props: any) {

    document.title = 'PWBM DataView';

    const classes = useStyles();
    const { width, height, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 0 });

    return (
    <div ref={ref} style={{display: 'flex', width: 'auto'}}>
      <div style={{width: '100%', maxWidth: '1000px', margin: '0 auto', textAlign: 'left'}}>
        <CardContent style={{padding: (width && width < 700) ? '20px' : '40px'}}>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Link to={'/'}>
              <IconButton size={'large'} style={{ textDecoration: 'none', color: '#c5093b', textDecorationColor: 'rgba(255,255,255,0)' }} >
                <ClearIcon fontSize="inherit" />
              </IconButton>
            </Link>
          </div>
          <div className={classes.sectionContainer}>
            <Typography variant="h4">About</Typography>
            <Typography variant="body1">DataView is a powerful new tool that simplifies collecting, visualizing, and analyzing government and other public data:</Typography>
            <ul>
              <li>Search across millions of data series available from dozens of sources.</li>
              <li>Transform and combine data as desired using simple “point and click”.</li>
              <li>Visualize your data with graphs, tables, scatterplots, and animated US maps.</li>
              <li>Test your ideas using integrated regression analysis.</li>
              <li>Create an account to save your work for later as well as share with others.</li>
            </ul>
            <Typography variant="body1">DataView provides content from a variety of government and academic sources with a focus on public policy and macroeconomics. Government revenue and spending, jobs data, economic indicators, population surveys and demographic data are just a few. Stock market data is also available simply by typing the name of the firm --- no ticker symbols required.</Typography>
            <Typography variant="body1">Penn Wharton Budget Model is a nonpartisan, research-based initiative at the University of Pennsylvania that provides accurate, accessible and transparent economic analysis of public policy’s fiscal impact.</Typography>
            <Button href="https://budgetmodel.wharton.upenn.edu/" target="_blank" rel="noopener" variant="contained" style={{margin: '0', backgroundColor: '#004785'}}>
              Learn More
            </Button>
          </div>
          <div id="contact-us" className={classes.sectionContainer}>
            <Typography variant="h4">Contact Us</Typography>
            <Typography variant="body1">If you would like to contact us or report an issue, please email us at <a href="mailto:budgetmodelapi@wharton.upenn.edu">budgetmodelapi@wharton.upenn.edu</a>.</Typography>
          </div>
          <div id="privacy" className={classes.sectionContainer}>
            <Typography variant="h4">Privacy Policy</Typography>
            <Typography variant="body1">We follow the privacy policy of the University of Pennsylvania. Please view the <a href="https://www.upenn.edu/about/privacy_policy" target="_blank" rel="noopener noreferrer">University of Pennsylvania Privacy Policy</a> for a complete description of our information-collection practices and an explanation of how we use and protect your personal information.</Typography>
          </div>
          {/* <div id="release-notes" className={classes.sectionContainer}>
            <Button href="/release-notes" variant="contained" color="primary" style={{margin: '0'}}>
              Website Release Notes
            </Button>
          </div> */}
        </CardContent>
      </div>
    </div>
    
    );
  }
  
  export default AboutPage;