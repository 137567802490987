import * as React from 'react';
import { Checkbox, Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TablePagination, Tooltip } from '@mui/material';
import { useState } from 'react';
import { DataResult } from '../Models/DataResult';
import { frequencySettings } from '../definitions/FrequencySettings';
import { ConsoleLogger } from '@aws-amplify/core';
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';



interface DataTableProps {
  data?: DataResult,
  handleRowClick?: (selected: number[]) => void,
  selectable: boolean,
  showLocation: boolean,
  vizPage?: boolean,
  vizDataNodes?: any[],
  locSelectedObj?: any,
  mapTable?: boolean,
  selected: number[] | null,
  page?: number
}

function formatNumberDecimal(x: string) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export default function DataTableDialog(props: DataTableProps) {
  const [rows, setRows] = useState<any[]>([] as any[]);
  const [columns, setColumns] = useState<any[]>([] as any[]);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [page, setPage] = React.useState(props.page != null ? props.page : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dataList, setDataList] = React.useState<any[]>([]);

  React.useEffect(() => {
    // console.log(props);

    var data = [] as any[]
    if (props.data !== undefined) {
      data.push(props.data)
    }
    if (props.vizDataNodes !== undefined) {
      props.vizDataNodes.forEach((dn: any) => {
        if (dn.displayed && dn.meta.chart_attributes !== undefined && dn.meta.chart_attributes.show) {
          data.push({ ...dn.result, node_name: dn.name, chart_attributes: dn.meta.chart_attributes })
        }
      })
    }
    parseData(data, page, rowsPerPage)

  }, [props])

  const parseData = (data: any[], page: number, rows: number) => {
    var periodsInData = [] as any[]
    var parsedData = [] as any[]
    var c = props.mapTable ? [] as any[] : [{ id: "name", label: "Name", align: 'left', }] as any[];
    if (props.showLocation) {
      c.push({ id: "location", label: "Location", align: props.mapTable ? 'left' : 'center', })
    }

    if (data.length === 1) {
      data.forEach((d: any) => {
        if (d.series !== null && d.series.length > 0) {
          if (dataList.length !== d.series.length) {
            setDataList(d.series);
          }
          d.series.slice(page * rows, page * rows + rows).forEach((s: any) => {
            var rowData = {
              name: props.vizPage ? d.node_name : s.name,
              location: s.location,
              locationCode: s.locationCode,
              frequency: s.frequency,
              isMapRow: false,
            } as any
            var skip = Math.floor(s.data_points.length / 1000);
            s.data_points.forEach((p: any, j: number) => {
              if (j % (skip === 0 ? 1 : skip) === 0) {
                var periodDisplay = frequencySettings['NotSet'].dateFormatter(p.key);
                var displayRank = frequencySettings['NotSet'].displayRank;
                if (frequencySettings[rowData.frequency] && frequencySettings[rowData.frequency].displayRank < 15) {
                  periodDisplay = frequencySettings[rowData.frequency].dateFormatter(p.key)
                  displayRank = frequencySettings[rowData.frequency].displayRank;
                }
                rowData[periodDisplay] = p.value
                var currPeriodObj = periodsInData.find((element: any) => element.periodDisplay === periodDisplay);
                if (currPeriodObj === undefined) {
                  periodsInData.push({
                    periodDisplay: periodDisplay,
                    frequency: rowData.frequency,
                    displayRank: displayRank,
                    period: p.key,
                    // width: Math.max(tableColWidth, tempObject[periodDisplay].length * 12)
                  })
                }
              }

            })
            parsedData.push(rowData)
          })
        }
        if (d.maps !== null && d.maps.length > 0) {
          if (dataList.length !== d.maps.length) {
            setDataList(d.maps);
          }
          d.maps.slice(page * rows, page * rows + rows).forEach((m: any) => {
            if (props.locSelectedObj !== undefined) {
              Object.keys(props.locSelectedObj).forEach((map_scale: string) => {
                if (m.map_scale === map_scale || (map_scale === 'state' && (m.map_scale === null || m.map_scale === undefined))) {
                  props.locSelectedObj[map_scale].forEach((l: any) => {
                    var s = m.children.find((s: any) => s.locationCode === l.locationCode && (l.locationCode !== '-1' || l.location === s.location))
                    if (s !== undefined) {
                      var rowData = {
                        name: props.vizPage ? d.node_name : s.name,
                        location: s.location,
                        locationCode: s.locationCode,
                        frequency: s.frequency,
                        isMapRow: true,
                        map_scale: map_scale,
                        map_location_abbreviation: map_scale === 'country' ? 'World' : map_scale === 'county' ? 'County' : map_scale === 'city' ? 'City' : map_scale === 'state' ? 'USA' : map_scale,
                      } as any

                      if (d.chart_attributes !== undefined) {
                        rowData.map_location_abbreviation = d.chart_attributes.map_location_abbreviation
                      }

                      s.data_points.forEach((p: any) => {
                        var periodDisplay = frequencySettings['NotSet'].dateFormatter(p.key);
                        var displayRank = frequencySettings['NotSet'].displayRank;
                        if (frequencySettings[rowData.frequency] && frequencySettings[rowData.frequency].displayRank < 15) {
                          periodDisplay = frequencySettings[rowData.frequency].dateFormatter(p.key)
                          displayRank = frequencySettings[rowData.frequency].displayRank;
                        }
                        rowData[periodDisplay] = p.value
                        var currPeriodObj = periodsInData.find((element: any) => element.periodDisplay === periodDisplay);
                        if (currPeriodObj === undefined) {
                          periodsInData.push({
                            periodDisplay: periodDisplay,
                            frequency: rowData.frequency,
                            displayRank: displayRank,
                            period: p.key,
                            // width: Math.max(tableColWidth, tempObject[periodDisplay].length * 12)
                          })
                        }
                      })
                      parsedData.push(rowData)
                    }
                  })
                }
              })
            }
          })
        }
      })
    }
    else {

      if (dataList.length !== data.length) {
        setDataList(data);
      }
      data.slice(page * rows, page * rows + rows).forEach((d: any) => {
        if (d.series !== null && d.series.length > 0) {
          d.series.forEach((s: any) => {
            var rowData = {
              name: props.vizPage ? d.node_name : s.name,
              location: s.location,
              locationCode: s.locationCode,
              frequency: s.frequency,
              isMapRow: false,
            } as any
            s.data_points.forEach((p: any) => {
              var periodDisplay = frequencySettings['NotSet'].dateFormatter(p.key);
              var displayRank = frequencySettings['NotSet'].displayRank;
              if (frequencySettings[rowData.frequency] && frequencySettings[rowData.frequency].displayRank < 15) {
                periodDisplay = frequencySettings[rowData.frequency].dateFormatter(p.key)
                displayRank = frequencySettings[rowData.frequency].displayRank;
              }
              rowData[periodDisplay] = p.value
              var currPeriodObj = periodsInData.find((element: any) => element.periodDisplay === periodDisplay);
              if (currPeriodObj === undefined) {
                periodsInData.push({
                  periodDisplay: periodDisplay,
                  frequency: rowData.frequency,
                  displayRank: displayRank,
                  period: p.key,
                  // width: Math.max(tableColWidth, tempObject[periodDisplay].length * 12)
                })
              }
            })
            parsedData.push(rowData)
          })
        }
        if (d.maps !== null && d.maps.length > 0) {
          d.maps.forEach((m: any) => {
            if (props.locSelectedObj !== undefined) {
              Object.keys(props.locSelectedObj).forEach((map_scale: string) => {
                if (m.map_scale === map_scale || (map_scale === 'state' && (m.map_scale === null || m.map_scale === undefined))) {
                  props.locSelectedObj[map_scale].forEach((l: any) => {
                    var s = m.children.find((s: any) => s.locationCode === l.locationCode && (l.locationCode !== '-1' || l.location === s.location))
                    if (s !== undefined) {
                      var rowData = {
                        name: props.vizPage ? d.node_name : s.name,
                        location: s.location,
                        locationCode: s.locationCode,
                        frequency: s.frequency,
                        isMapRow: true,
                        map_scale: map_scale,
                        map_location_abbreviation: map_scale === 'country' ? 'World' : map_scale === 'county' ? 'County' : map_scale === 'city' ? 'City' : 'USA'
                      } as any

                      if (d.chart_attributes !== undefined) {
                        rowData.map_location_abbreviation = d.chart_attributes.map_location_abbreviation
                      }

                      s.data_points.forEach((p: any) => {
                        var periodDisplay = frequencySettings['NotSet'].dateFormatter(p.key);
                        var displayRank = frequencySettings['NotSet'].displayRank;
                        if (frequencySettings[rowData.frequency] && frequencySettings[rowData.frequency].displayRank < 15) {
                          periodDisplay = frequencySettings[rowData.frequency].dateFormatter(p.key)
                          displayRank = frequencySettings[rowData.frequency].displayRank;
                        }
                        rowData[periodDisplay] = p.value
                        var currPeriodObj = periodsInData.find((element: any) => element.periodDisplay === periodDisplay);
                        if (currPeriodObj === undefined) {
                          periodsInData.push({
                            periodDisplay: periodDisplay,
                            frequency: rowData.frequency,
                            displayRank: displayRank,
                            period: p.key,
                            // width: Math.max(tableColWidth, tempObject[periodDisplay].length * 12)
                          })
                        }
                      })
                      parsedData.push(rowData)
                    }
                  })
                }
              })
            }
          })
        }
      })
    }



    periodsInData.sort((a: any, b: any) => {
      var aPeriod = Date.parse(a.period)
      var bPeriod = Date.parse(b.period)
      if (aPeriod === bPeriod) {
        return a.displayRank - b.displayRank
      } else {
        return aPeriod - bPeriod
      }
    });

    setRows(parsedData)
    // console.log(periodsInData);
    // console.log(parsedData);

    periodsInData.forEach((p) => {
      c.push({
        id: p.periodDisplay,
        label: p.period === 'Estimated Effects' ? p.period : p.periodDisplay,
        align: 'center',
      })
    })
    // if(Object.keys(props.data.series[0]).includes("map"))
    // {
    //   c.push({name: "Location", value: "map"})
    // }
    // props.data.series.forEach(d =>{
    //   console.log(d);
    // })
    // console.log(c)
    setColumns(c);

    if (props.selected != null) {
      setSelected(props.selected);
    }
  }

  const handleClick = (event: React.MouseEvent<unknown>, rowNum: number) => {
    const selectedIndex = selected.indexOf(rowNum);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowNum);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    if (props.handleRowClick !== undefined) {
      props.handleRowClick(newSelected)
    }
  };

  const isSelected = (rowNum: number) => selected.indexOf(rowNum) !== -1;

  const handleChangePage = (event: unknown, newPage: number) => {
    var data = [] as any[]
    if (props.data !== undefined) {
      data.push(props.data)
    }
    if (props.vizDataNodes !== undefined) {
      props.vizDataNodes.forEach((dn: any) => {
        if (dn.displayed && dn.meta.chart_attributes !== undefined && dn.meta.chart_attributes.show) {
          data.push({ ...dn.result, node_name: dn.name })
        }
      })
    }
    parseData(data, newPage, rowsPerPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    var data = [] as any[]
    if (props.data !== undefined) {
      data.push(props.data)
    }
    if (props.vizDataNodes !== undefined) {
      props.vizDataNodes.forEach((dn: any) => {
        if (dn.displayed && dn.meta.chart_attributes !== undefined && dn.meta.chart_attributes.show) {
          data.push({ ...dn.result, node_name: dn.name })
        }
      })
    }
    parseData(data, 0, parseInt(event.target.value, 10))
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 'inherit', display: 'flex', flexDirection: 'column' }}>
      {(columns.length > 1 && rows.length > 0) ? <Table sx={{ minWidth: 650, width: '100%' }} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.selectable && <TableCell
              padding="checkbox"
              sx={{
                position: "sticky",
                left: 0,
                background: "white",
                // boxShadow: "2px 0px 5px grey",
                // borderRight: '2px solid rgb(224, 224, 224)',
                borderBottomWidth: '2px',
                zIndex: 3,
              }}
            ></TableCell>}
            {columns.map((c: any, i: number) => {
              return (
                <TableCell
                  key={i}
                  align={c.align}
                  style={{ whiteSpace: "nowrap" }}
                  sx={(i === 0 && !(!props.showLocation && props.mapTable)) ? {
                    position: "sticky",
                    left: props.selectable ? 45 : 0,
                    background: "white",
                    // boxShadow: "2px 0px 5px grey",
                    borderRight: '2px solid rgb(224, 224, 224)',
                    borderBottomWidth: '2px',
                    zIndex: 3,
                    maxWidth: '200px',
                  } : {
                    borderBottomWidth: '2px',
                  }}
                >{c.label}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            // console.log(selected);
            var index = rowsPerPage * page + i;
            const isItemSelected = isSelected(index);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                hover
                onClick={props.selectable ? (event) => handleClick(event, index) : undefined}
                role="checkbox"
                aria-checked={props.selectable && isItemSelected}
                tabIndex={-1}
                key={row.name + '-' + index}
                selected={props.selectable && isItemSelected}
              >
                {props.selectable && <TableCell padding="checkbox" sx={{
                  position: "sticky",
                  left: 0,
                  background: "white",
                  // boxShadow: "2px 0px 5px grey",
                  // borderRight: '2px solid rgb(224, 224, 224)',
                }}>
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </TableCell>}
                {columns.map((column, j) => {
                  var truncated = false;
                  var value = row[column.id] as string;
                  if (column.id === "name" && value.length > 65) {
                    value = value.slice(0, 65) + "...";
                    truncated = true;
                  }
                  if (column.id !== 'name' && column.id !== 'location' && value !== undefined) {
                    if (value === null) {
                      value = 'n/a'
                    } else {
                      value = formatNumberDecimal(value)
                    }
                  }

                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      id={column.id === 'name' ? labelId : undefined}
                      sx={(j === 0 && !(!props.showLocation && props.mapTable)) ? {
                        position: "sticky",
                        left: props.selectable ? 45 : 0,
                        background: "white",
                        // boxShadow: "2px 0px 5px grey",
                        borderRight: '2px solid rgb(224, 224, 224)',
                        maxWidth: '200px',
                      } : undefined}
                    >
                      <div style={{ minWidth: j === 0 ? "175px" : "inherit", display: 'flex', alignItems: 'center', justifyContent: column.align === 'center' ? 'center' : 'flex-start', overflowWrap: "break-word" }}>
                        {(row.isMapRow && column.id === 'name') && <div className="mapIcon">
                          <div className="mapIcon_map">Map</div>
                          {row.map_location_abbreviation && <div>{row.map_location_abbreviation}</div>}
                        </div>}
                        {truncated === true ? <Tooltip title={row[column.id]}>
                          <span>
                            {value}
                          </span>
                        </Tooltip> : <span>{value}</span>}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table> : <Table sx={{ minWidth: 650, width: '100%' }} stickyHeader aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell
              align='left'
              style={{ whiteSpace: "nowrap", fontStyle: "normal", fontWeight: 'bold', fontSize: '16px', color: '#666666' }}
            >No data selected for display</TableCell>
          </TableRow>
        </TableBody>
      </Table>}
      <TablePagination
        style={{ position: 'sticky', left: 0, bottom: 0, backgroundColor: 'white', flexShrink: 0 }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={dataList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}