import React, {  useState, useEffect } from 'react';
import { makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { InputLabel, FormHelperText, FormControl, Select, IconButton, TextField,  DialogContent, MenuItem, Menu, CircularProgress, DialogActions, MenuList, Tooltip, Snackbar, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { endpoint, MAX_DATA_VIZ, MAX_MAP_DATA_VIZ } from '../App';
import { useSelector, useDispatch } from 'react-redux'
import { fetchChangeUnitSeries } from '../thunks'
import { frequencySettings } from '../definitions/FrequencySettings';
import { DataPoint } from '../Models/DataResult'
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme: any) => ({
  loadingRoot: {
    width: "75px",
    height: "75px",
    color: "#004785"
  },
  dialogRoot: {
    // height: '100%',
    maxWidth: '800px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "30%",
  },
}));

export interface ChangeUnitDialogProps {
  open: boolean,
  onClose: () => void,
  editNode?: any,
}

export default function ChangeUnitDialog(props: ChangeUnitDialogProps) {
  const classes = useStyles();
  const { onClose, open, editNode } = props;
  const[seriesSelected, setSeriesSelected] = useState<string>("");
  const[seriesId, setSeriesId] = useState<string>("");
  const[initialFrequency, setInitialFrequency] = useState<string>("");
  const[mapScale, setMapScale] = useState<string>("");
  const[unitChangeType, setUnitChangeType] = useState<string>("");
  const[initialValue, setInitialValue] = useState<string>("");
  const[availablePeriods, setAvailablePeriods] = useState<Date[]>([] as Date[]);
  const[specifiedPeriod, setSpecifiedPeriod] = useState<string>("");
  const[messageOpen, setMessageOpen] = useState<boolean>(false);
  const[message, setMessage] = useState<string>("");
  const[messageStatus, setMessageStatus] = useState<boolean>(false);
  const[seriesError, setSeriesError] = useState<boolean>(false);
  const[unitChangeTypeError, setUnitChangeTypeError] = useState<boolean>(false);
  const[initialValueError, setInitialValueError] = useState<boolean>(false);
  const[specifiedPeriodError, setSpecifiedPeriodError] = useState<boolean>(false);

  const dispatch = useDispatch()
  const isLoadingVizNode = useSelector((state: any) => state.isLoadingVizNode)
  const nodeCounters = useSelector((state: any) => state.nodeCounters)
  const sessionRecipe = useSelector((state: any) => state.sessionRecipe)

  useEffect(() => {
    // on open
    if (editNode !== undefined && editNode.type === 'transformation' && editNode.transformation_id.indexOf('unit_changes') !== -1) {
      var id = editNode.input[0]
      var frequency = ''
      var map_scale = ''
      if (editNode.meta && editNode.meta.chart_attributes && editNode.meta.chart_attributes.frequency) {
        frequency = editNode.meta.chart_attributes.frequency
      }
      if (editNode.meta && editNode.meta.chart_attributes && editNode.meta.chart_attributes.map_scale) {
        map_scale = editNode.meta.chart_attributes.map_scale
      }
      var unitChangeEditNode = editNode.transformation_id.split('.')[1]
      var initialValueEditNode = ''
      var specifiedPeriodEditNode = ''
      var availablePeriodsEditNode = [] as Date[]

      if (unitChangeEditNode === 'ChangeFrom' || unitChangeEditNode === 'PercentChangeFrom') {
        initialValueEditNode = editNode.params.initial_value
      }

      if (unitChangeEditNode === 'ChangeFromDate' || unitChangeEditNode === 'PercentChangeFromDate') {
        initialValueEditNode = 'specifiedPeriod'
        specifiedPeriodEditNode = editNode.params.period
        unitChangeEditNode = unitChangeEditNode.split('Date')[0]
      }
      
      if (unitChangeEditNode === 'Index') {
        specifiedPeriodEditNode = editNode.params.period
      }

      if (editNode.result.series !== null && editNode.result.series.length > 0) {
        editNode.result.series[0].data_points.forEach((item: DataPoint) => {
          if (item.value !== null) {
            availablePeriodsEditNode.push(item.key)
          }
        })
      }
      if (editNode.result.maps !== null && editNode.result.maps.length > 0) {
        var availSet = new Set();
        editNode.result.maps[0].children.forEach((series: any) => {
          series.data_points.forEach((item: DataPoint) => {
            if (item.value !== null) {
              availSet.add(item.key)
            }
          })
        })
        availSet.forEach((p: any) => { availablePeriodsEditNode.push(p) })
      }

      setSeriesId(id);
      setInitialFrequency(frequency);
      setMapScale(map_scale)
      setSeriesSelected(id + ';' + frequency + ';' + map_scale);
      setUnitChangeType(unitChangeEditNode);
      setInitialValue(initialValueEditNode);
      setSpecifiedPeriod(specifiedPeriodEditNode);
      setAvailablePeriods(availablePeriodsEditNode);
    }
  }, [open]);

  const handleClose = () => {
    setSeriesId("");
    setSeriesSelected("");
    setInitialFrequency("");
    setMapScale("");
    setUnitChangeType("");
    setInitialValue("");
    setSeriesError(false);
    setUnitChangeTypeError(false);
    setInitialValueError(false);
    setSpecifiedPeriodError(false);
    setSpecifiedPeriod("")
    setAvailablePeriods([] as Date[])
    onClose();
  };

  const closeMessage = () =>{
    setMessageOpen(false);
    setMessage("");
  }

  const handleChangeSeries = (event: any) => {
    var selectString = event.target.value
    var selectArray = selectString.split(';')
    setSeriesSelected(selectString);
    setSeriesId(selectArray[0])
    setInitialFrequency(selectArray[1])
    setMapScale(selectArray[2])

    var newAvailPeriods = [] as Date[]
    var currNode = sessionRecipe.find((element: any) => element.id === selectArray[0])

    if (currNode !== undefined) {
      if (currNode.result.series !== null && currNode.result.series.length > 0) {
        currNode.result.series[0].data_points.forEach((item: DataPoint) => {
          if (item.value !== null) {
            newAvailPeriods.push(item.key)
          }
        })
      }
      if (currNode.result.maps !== null && currNode.result.maps.length > 0) {
        var availSet = new Set();
        currNode.result.maps[0].children.forEach((series: any) => {
          series.data_points.forEach((item: DataPoint) => {
            if (item.value !== null) {
              availSet.add(item.key)
            }
          })
        })
        availSet.forEach((p: any) => { newAvailPeriods.push(p) })
      }
    }
    
    setAvailablePeriods(newAvailPeriods)
  };

  const handleChangeType = (event: any) => {
    setUnitChangeType(event.target.value)
  };

  const handleChangeInitValue = (event: any) => {
    setInitialValue(event.target.value)
  };

  const handleChangePeriod = (event: any) => {
    setSpecifiedPeriod(event.target.value)
  };


  return (
    <div>
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="md" fullWidth={true} scroll='paper' classes={{ paper: classes.dialogRoot }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle >Change Unit</DialogTitle>
        {/* <Tooltip title="More info" arrow>
          <IconButton  
            style={{ flexGrow: 0, flexShrink: 0, color: '#326b9c', backgroundColor: '#ffffff', textDecoration: 'none', textDecorationColor: 'rgba(255,255,255,0)', margin: '16px 24px', padding: '0' }}  
            target="_blank" href="https://visualization-page.s3.amazonaws.com/How+to+Use+the+Visualization+Page_20210729.pdf#page=16"
          >
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
      </div>
      {editNode !== undefined && <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        <i>Editing {editNode.name}</i><br/>
        Cannot change the frequency, map scale, or type (i.e. series or map).
      </div>}
      <DialogContent style={{ boxSizing: 'border-box', alignSelf: "center", width: "90%", position: 'relative' }}>
        <div style={{ position: 'relative', width: '100%' }}>
          {(isLoadingVizNode) && <div style={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', zIndex: 1, backgroundColor: 'rgba(255,255,255,0.5)' }}><CircularProgress thickness={3} className={classes.loadingRoot} /></div>}
          <div style={{ display: 'flex', flexDirection: 'column', width: '90%', margin: '0px auto' }}>
            <FormControl error={seriesError} className={classes.formControl}>
              <InputLabel id="series-select-label">Series</InputLabel>
              <Select
                labelId="series-select-label"
                id="series-select"
                label="Series"
                value={seriesSelected}
                onChange={handleChangeSeries}
                style={{ fontSize: '16px' }}
              >
                {(sessionRecipe.length > 0) 
                  ? sessionRecipe.map((node: any, i: number) => {
                    var output = [] as any[]
                    // if editing params, restrict to not self, not descendent, and same frequency
                    if (editNode === undefined || (node.id !== editNode.id && node.meta && node.meta.ancestors && !node.meta.ancestors.includes(editNode.id) && node.meta.dataType && node.meta.dataType === editNode.meta.dataType)) {
                      if (node.displayed && (node.meta.dataType === undefined || node.meta.dataType === 'series') && (editNode !== undefined || nodeCounters.series < MAX_DATA_VIZ)) {
                        if (node.result.series !== null && node.result.series.length > 0) {
                          var frequency = node.result.series[0].frequency
                          var displayRank = 1000;
                          if (frequency !== null && frequencySettings[frequency] !== undefined) {
                            displayRank = frequencySettings[frequency].displayRank
                          }
                          if (displayRank < 1000 && (editNode === undefined || frequency === initialFrequency)) {
                            output.push(<MenuItem value={node.id + ';' + frequency + ';'} key={node.id + 'unitChange'}>{node.name}</MenuItem>)
                          }
                        }
                      } else if (node.displayed && (node.meta.dataType !== undefined && node.meta.dataType === 'map') && (editNode !== undefined || nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
                        if (node.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0) {
                          var frequency = node.result.maps[0].children[0].frequency
                          var map_scale = node.result.maps[0].map_scale
                          var displayRank = 1000;
                          if (frequency !== null && frequencySettings[frequency] !== undefined) {
                            displayRank = frequencySettings[frequency].displayRank
                          }
                          if (displayRank < 1000 && (editNode === undefined || (frequency === initialFrequency && map_scale === mapScale))) {
                            output.push(<MenuItem value={node.id + ';' + frequency + ';' + map_scale} key={node.id + 'unitChange'}>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="mapIcon mapIcon_inSelect">
                                  <div className="mapIcon_map">Map</div>
                                  {node.meta.chart_attributes.map_scale && <div>{(node.meta.chart_attributes.map_location_abbreviation !== '') ? node.meta.chart_attributes.map_location_abbreviation : node.meta.chart_attributes.map_scale}</div>}
                                </div>
                                {node.name}
                              </div>
                            </MenuItem>)
                          }
                        }
                      }
                    }
                    return output
                  })
                  : <MenuItem value=""><em>No series available.</em></MenuItem>
                }
                {(editNode === undefined && nodeCounters.series >= MAX_DATA_VIZ) && <MenuItem value="" disabled><em>Max time series in workspace reached</em></MenuItem>}
                {(editNode === undefined && nodeCounters.maps >= MAX_MAP_DATA_VIZ) && <MenuItem value="" disabled><em>Max maps in workspace reached</em></MenuItem>}
              </Select>
              <FormHelperText>{seriesError ? "*Required" : ""}</FormHelperText>
            </FormControl>
            <FormControl error={unitChangeTypeError} className={classes.formControl}>
              <InputLabel id="type-select-label">Unit Change Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                label="Unit Change Type"
                value={unitChangeType}
                onChange={handleChangeType}
                style={{ fontSize: '16px' }}
              >
                <MenuItem value='ChangeFrom'>Change From...</MenuItem>
                <MenuItem value='PercentChangeFrom'>Percent Change From...</MenuItem>
                <MenuItem value='Index'>Index (Scale value to 100 for specified period)</MenuItem>
                <MenuItem value='CompoundedAnnualRateOfChange'>Compounded Annual Rate of Change</MenuItem>
                <MenuItem value='ContinuouslyCompoundedRateOfChange'>Continuously Compounded Rate of Change</MenuItem>
                <MenuItem value='ContinuouslyCompoundedAnnualRateOfChange'>Continuously Compounded Annual Rate of Change</MenuItem>
                <MenuItem value='Interpolate'>Interpolate</MenuItem>
              </Select>
              <FormHelperText>{unitChangeTypeError ? "*Required" : ""}</FormHelperText>
            </FormControl>
            {(unitChangeType === 'ChangeFrom' || unitChangeType === 'PercentChangeFrom') && <FormControl error={initialValueError} className={classes.formControl}>
              <InputLabel id="from-select-label">Unit Change From</InputLabel>
              <Select
                labelId="from-select-label"
                id="from-select"
                label="Unit Change From"
                value={initialValue}
                onChange={handleChangeInitValue}
                style={{ fontSize: '16px' }}
              >
                <MenuItem value='previousPeriod'>Previous Period</MenuItem>
                <MenuItem value='firstValue'>First non-zero, non-null value</MenuItem>
                <MenuItem value='yearAgo'>A Year Ago</MenuItem>
                <MenuItem value='specifiedPeriod'>Specified Period</MenuItem>
              </Select>
              <FormHelperText>{initialValueError ? "*Required" : ""}</FormHelperText>
            </FormControl>}
            {(unitChangeType === 'Index' || ((unitChangeType === 'ChangeFrom' || unitChangeType === 'PercentChangeFrom') && initialValue === 'specifiedPeriod')) && <FormControl error={specifiedPeriodError} className={classes.formControl}>
              <InputLabel id="period-select-label">Specified Period</InputLabel>
              <Select
                labelId="period-select-label"
                id="period-select"
                label="Specified Period"
                value={specifiedPeriod}
                onChange={handleChangePeriod}
                style={{ fontSize: '16px' }}
              >
                {availablePeriods.map((period: any) => {
                  var dateFormatter = frequencySettings['NotSet'].dateFormatter;
                  if (frequencySettings[initialFrequency] !== undefined) {
                    dateFormatter = frequencySettings[initialFrequency].dateFormatter
                  }
                  return <MenuItem value={period} key={period + 'unitChange'}>{dateFormatter(Date.parse(period))}</MenuItem>
                })}
              </Select>
              <FormHelperText>{specifiedPeriodError ? "*Required" : ""}</FormHelperText>
            </FormControl>}
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '16px' }}>
        <Button 
          variant="contained" 
          // style={{ backgroundColor:"#026cb5", color: "white"}}
          disabled={isLoadingVizNode}
          onClick={() => { 
            var hasError = false;
            var node: any = sessionRecipe.find((element: any) => element.displayed && element.id === seriesId)
            if(seriesId === "" || node === undefined)
            {
              setSeriesError(true);
              hasError = true;
            }
            else {
              setSeriesError(false);
            }
            if (unitChangeType === "") {
              setUnitChangeTypeError(true);
              hasError = true;
            }
            else {
              setUnitChangeTypeError(false);
            }
            if ((unitChangeType === 'ChangeFrom' || unitChangeType === 'PercentChangeFrom') && initialValue === "") {
              setInitialValueError(true);
              hasError = true;
            }
            else {
              setInitialValueError(false);
            }
            if ((unitChangeType === 'Index' || ((unitChangeType === 'ChangeFrom' || unitChangeType === 'PercentChangeFrom') && initialValue === 'specifiedPeriod')) && specifiedPeriod === "") {
              setSpecifiedPeriodError(true);
              hasError = true;
            }
            else {
              setSpecifiedPeriodError(false);
            }
            if (!hasError && sessionRecipe.length > 0) {
              setSeriesError(false);
              var parsedUnitChangeType = unitChangeType
              if ((unitChangeType === 'ChangeFrom' || unitChangeType === 'PercentChangeFrom') && initialValue === 'specifiedPeriod') {
                parsedUnitChangeType += 'Date'
              }

              var params = {} as any
              if (unitChangeType === 'ChangeFrom' || unitChangeType === 'PercentChangeFrom') {
                if (initialValue === 'specifiedPeriod') {
                  params['period'] = specifiedPeriod
                  params['period_string'] = frequencySettings[initialFrequency].dateFormatter(specifiedPeriod)
                } else {
                  params['initial_value'] = initialValue
                }
              } else if (unitChangeType === 'Index') {
                params['period'] = specifiedPeriod
                params['period_string'] = frequencySettings[initialFrequency].dateFormatter(specifiedPeriod)
              }
              dispatch(fetchChangeUnitSeries(node, parsedUnitChangeType, params, editNode))
              if (editNode !== undefined) {
                handleClose()
              }
            }
          }}
        >
          {editNode !== undefined ? 'Update workspace' : 'Add to workspace'}
        </Button>
        <Button onClick={handleClose} variant="contained" style={{ backgroundColor:"#c5093b", color: "white"}} >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
    <Snackbar anchorOrigin={{vertical: "top", horizontal:"center"}} open={messageOpen} autoHideDuration={6000} onClose={closeMessage}>
      <Alert onClose={closeMessage} severity={messageStatus === true? "success" : "error"}>
          {message}
      </Alert>
    </Snackbar>
    </div>
  );
}