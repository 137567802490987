import React, { useState } from 'react';
import { ThemeProvider, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import { TextField, InputBase, DialogContent, IconButton, Icon } from '@mui/material';
import Amplify, { Auth } from 'aws-amplify';
import { setSession } from '../App';
import Alert from '@mui/material/Alert';
import ConfirmPasswordDialog from './ConfirmPasswordDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import CreateAccountDialog from './CreateAccountDialog';

Auth.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: ' us-east-2',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_xLemYVNwY',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '1flh76qlg1no0gfji8c4pcjdje',


        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',

         // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'auth.pwbm-beta.net',
            scope: [ 'email',  'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});


const useStyles = makeStyles((theme: any) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  search: {
    alignItems: 'center',
    textAlign: "center",
    justifyContent: "space-between",
    position: 'relative',
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    borderColor: "gray",
    '&:hover': {
      borderColor: "#004785",
    },
    '&:focus-within': {
      borderColor: "#004785",
    },
    borderStyle: "solid",
    borderWidth: "1px"

  },
  iconButton: {
    marginRight: 5,
    // marginTop: 18,
    color: "black"
  },
  inputInput: {
    padding: theme.spacing(1.5, 2),

  },
}));

export interface LoginDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
}

function LoginDialog(props: LoginDialogProps) {
  const classes = useStyles();
  const [error, setError] = useState<any>(null);
  const [username, setUser] = useState<string>("");
  const [pass, setPass] = useState<string>("");
  const { onClose, open } = props;
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [visible, setVisible]  = useState<string>("password");
  const [confirmError, setConfirmError] = useState<string>("");
  const [forgotPassOpen, setForgotPassOpen ] = useState<boolean>(false);
  const [email, setEmail ] = useState<string>("");
  const [forgotPassError, setForgotPassError ] = useState<string>("");
  const [createAccountOpen, setCreateAccountOpen] = React.useState<boolean>(false);

  const handleClose = () => {

    onClose(false);
  };

  const handleConfirmClose = (value: string) => {
    if(value !=="")
    {
      Auth.signIn(username, pass)
      .then(user => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          Auth.completeNewPassword(
              user,               // the Cognito User Object
              value,       // the new password
              // OPTIONAL, the required attributes
              {
                email: user.challengeParam.userAttributes.email,
              }
          ).then(user => {
              // at this time the user is logged in if no MFA required
              setConfirmError("");
              setSession(user);
              setError(null);
              setConfirmOpen(false);
              onClose(true);
          }).catch(e => {
            setConfirmError(e.message);
          });
      } 
      }).catch(e => {
      console.log(e);
      });
    }
    else{
      setConfirmError("");
      setConfirmOpen(false);
    }
    
  };

  async function login(){
    
    try {
      let u  = await Auth.signIn(username, pass);
      if(u.challengeName === 'NEW_PASSWORD_REQUIRED')
      {
        setConfirmOpen(true);
      }
      else{
        setSession(u);
        setError(null);
        onClose(false);
      }
    } catch (e) {
      setError(e);
      setSession(null);
    }
  }

  function changeUsername(s: string) {
    setUser(s);
  }

  function changePassword(s: string) {
    setPass(s);
  }

  function show()
  {
    setVisible("");
  }

  function hide()
  {
    setVisible("password");
  }

  function forgotPass()
  {
    if(username !== "")
    {
      Auth.forgotPassword(username)
      .then(data => {
        setEmail(data.CodeDeliveryDetails.Destination);
        setForgotPassOpen(true);
      })
      .catch(err => setError(err));
    }
    
  }

  function closeForgotPass(code: string, newPass: string)
  {
    if(code === "" || newPass === "")
    {
      setForgotPassOpen(false);
    }
    else{
      Auth.forgotPasswordSubmit(username, code, newPass)
      .then(data => {
        setForgotPassError("");
        setForgotPassOpen(false);
        setPass(newPass);
      })
      .catch(err => setForgotPassError(err.message));
    }
    
  }

  function openCreateAccount()
  {
    setCreateAccountOpen(true);
  }

  const handleCloseCreateAccount = (value: string) =>{
    setCreateAccountOpen(false);
  }


  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle><div style={{ margin: "0px", padding: "20px 0px 5px calc(10% - 24px)", fontSize: '30px', fontWeight: 700 }}>Sign In<IconButton onClick={handleClose} style={{color: "#D11A2A", float: "right", padding: "0px"}}><CloseIcon/></IconButton></div></DialogTitle>
      <DialogContent style={{ alignSelf: "center", width: "80%", position: "relative", left: "-22px", padding: "0", margin: "5px 0px 20px 0px" }}>
        {error != null && <Alert style={{marginBottom: "15px", placeContent: "center"}} severity="error">{(error.code !== undefined && error.code === 'UserNotConfirmedException') ? 'User is not confirmed. Please check your email to confirm account.' : error.message}</Alert>}
        <form className={classes.search} noValidate onSubmit={(e: React.SyntheticEvent) => {
           e.preventDefault();
           login()}}>
          <div style={{ width: "100%", display: "flex", marginBottom: "15px", alignItems: 'center' }}>
            <PersonIcon className={classes.iconButton} />
            <InputBase
              placeholder="Username"
              id="username"
              value={username}
              onChange={(
                ev: React.ChangeEvent<HTMLInputElement>,
              ): void => changeUsername(ev.target.value)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'username' }}
            />
          </div>
          <div style={{ width: "100%", display: "flex", alignItems: 'center' }}>
            <LockIcon className={classes.iconButton} />
            <InputBase
              placeholder="Password"
              name="password" 
              value={pass}
              onChange={(
                ev: React.ChangeEvent<HTMLInputElement>,
              ): void => changePassword(ev.target.value)}
              type={visible}
              id="password"
              autoComplete="current-password"
              onFocus={(ev: any): void => ev.target.select()}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'password' }}
            />
           {visible === "password" ? <IconButton style={{ position: 'absolute', right: '0', fontSize: '18px' }} onClick={show}><VisibilityIcon fontSize="inherit"/></IconButton> :
           <IconButton style={{ position: 'absolute', right: '0', fontSize: '18px' }} onClick={hide}><VisibilityOffIcon fontSize="inherit"/></IconButton>
           }     
          </div>
          <Button type="submit" variant="contained" style={{ width: "30%", margin: "30px 0" }} color="primary">
            sign in
        </Button>
        </form>
        <Button size="small" style={{ fontSize: ".65rem", marginLeft: "5px", marginBottom: "6px" }} onClick={forgotPass} color="primary">
          Forgot Password?
        </Button>
        <Button onClick={openCreateAccount} size="small" style={{ fontSize: ".65rem", marginLeft: "10px", marginBottom: "6px"}} color="primary">
          Create an Account
        </Button>

      </DialogContent>
      <ForgotPasswordDialog open={forgotPassOpen} onClose={closeForgotPass} email={email} error={forgotPassError}/>
      <ConfirmPasswordDialog  open={confirmOpen} error={confirmError} onClose={handleConfirmClose} oldPassword={pass}/>
      <CreateAccountDialog open={createAccountOpen} onClose ={handleCloseCreateAccount} />
    </Dialog>
  );
}

export default LoginDialog;