import React, {  useState, useEffect } from 'react';
import { makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { FormControl, IconButton, TextField,  DialogContent, CircularProgress, DialogActions, Tooltip, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { saveRecipe } from '../thunks'
import InfoIcon from '@mui/icons-material/Info';
import SaveOverConfirmDialog from './SaveOverConfirmDialog';

const useStyles = makeStyles((theme: any) => ({
  loadingRoot: {
    width: "75px",
    height: "75px",
    color: "#004785"
  },
  dialogRoot: {
    // height: '100%',
    maxWidth: '800px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "30%",
  },
}));

export interface SaveAsDialogProps {
  open: boolean,
  onClose: () => void,
  loadOther?: boolean,
  loadSessionId?: string,
  loadSessionName?: string,
}

export default function SaveAsDialog(props: SaveAsDialogProps) {
  const classes = useStyles();
  const { onClose, open, loadOther = false, loadSessionId = '', loadSessionName = '' } = props;
  const[messageOpen, setMessageOpen] = useState<boolean>(false);
  const[message, setMessage] = useState<string>("");
  const[messageStatus, setMessageStatus] = useState<boolean>(false);
  const[sessionName, setSessionName] = useState<string>('');
  const[nameError, setNameError] = useState<boolean>(false);
  const[nameEmptyError, setNameEmptyError] = useState<boolean>(false);
  const[openSaveOverConfirm, setOpenSaveOverConfirm] = useState<boolean>(false);

  const dispatch = useDispatch()
  const sessionRecipeInfo = useSelector((state: any) => state.sessionRecipeInfo)
  const userInfo = useSelector((state: any) => state.userInfo)

  useEffect(() => {
    setSessionName(sessionRecipeInfo.name)
    setNameError(false)
    setNameEmptyError(false)
  }, [open]);

  
  const handleClose = () => {
    onClose();
  };

  const closeMessage = () =>{
    setMessageOpen(false);
    setMessage("");
  }

  const handleChangeName = (event: any) => {
    setSessionName(event.target.value);
    var newName = event.target.value.trim()
    var regexp = /^[a-zA-Z0-9-_\s]+$/;
    if (newName !== '' && newName.search(regexp) == -1) { 
      setNameError(true)
    } else {
      if (nameError) {
        setNameError(false)
      }
      if (newName === '') {
        setNameEmptyError(true)
      } else {
        if (nameEmptyError) {
          setNameEmptyError(false)
        }
      }
    }
  };

  const handleSubmit = () => {
    var nameTrim = sessionName.trim()
    setSessionName(nameTrim);
    var hasError = false;
    if (nameTrim === "") {
      setNameEmptyError(true);
      hasError = true;
    } else {
      setNameEmptyError(false);
    }
    if (nameError) {
      hasError = true;
    }
    if (!hasError) {
      // check if workspace name taken.
      if (userInfo.recipeList.some((r: any) => r.name === nameTrim)) {
        // if yes, open confirm dialog
        setOpenSaveOverConfirm(true)
      } else {
        // if no, save
        save(nameTrim)
      }
    }
  }

  const save = (nameTrim: string) => {
    // whether to load this workspace after save or another one
    if (loadOther) {
      dispatch(saveRecipe(nameTrim, userInfo.token, true, loadSessionName, loadSessionId))
    } else {
      dispatch(saveRecipe(nameTrim, userInfo.token))
    }
    // on success close save as, change url, and open share. reload chart and map.
    handleClose()
  }

  return (
    <div>
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="md" fullWidth={true} scroll='paper' classes={{ paper: classes.dialogRoot }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle >Save As</DialogTitle>
        {/* <Tooltip title="More info" arrow style={{ display: 'none' }}>
          <IconButton  
            style={{ flexGrow: 0, flexShrink: 0, color: '#326b9c', backgroundColor: '#ffffff', textDecoration: 'none', textDecorationColor: 'rgba(255,255,255,0)', margin: '16px 24px', padding: '0' }}  
            target="_blank" href="https://visualization-page.s3.amazonaws.com/How+to+Use+the+Visualization+Page_20210729.pdf#page=22"
          >
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
      </div>
      <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        Saves your current workspace with the specified name. Please note that the workspace name can only contain letters, numbers, inner spaces, underscores and dashes. Any other symbols are not allowed. Leading and trailing spaces will be trimmed. Saving will also reload your workspace data, so the workspace will have the most up-to-date data.
      </div>
      <DialogContent style={{ boxSizing: 'border-box', alignSelf: "center", width: "90%", position: 'relative' }}>
        <div style={{ position: 'relative', width: '100%' }}>
          {(sessionRecipeInfo.loading) && <div style={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', zIndex: 1, backgroundColor: 'rgba(255,255,255,0.5)' }}><CircularProgress thickness={3} className={classes.loadingRoot} /></div>}
          <div style={{ display: 'flex', flexDirection: 'column', width: '90%', margin: '0px auto' }}>
            <FormControl error={nameEmptyError} className={classes.formControl}>
              <form noValidate onSubmit={(e: React.SyntheticEvent) => {
                e.preventDefault();
                handleSubmit()
              }}>
                <TextField
                  value={sessionName}
                  error={nameError || nameEmptyError}
                  onChange={handleChangeName}
                  id="workspace-name"
                  label="Workspace Name *"
                  helperText={nameError ? "* Workspace name can only contain letters, numbers, spaces, underscores and dashes." : nameEmptyError ? "* Required" : ""}
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </form>
            </FormControl>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '16px' }}>
        <Button 
          variant="contained" 
          // style={{ backgroundColor:"#026cb5", color: "white"}}
          disabled={sessionRecipeInfo.loading}
          onClick={handleSubmit}
        >
          Save Workspace As
        </Button>
        <Button onClick={handleClose} variant="contained" style={{ backgroundColor:"#c5093b", color: "white"}} >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
    <Snackbar anchorOrigin={{vertical: "top", horizontal:"center"}} open={messageOpen} autoHideDuration={6000} onClose={closeMessage}>
      <Alert onClose={closeMessage} severity={messageStatus === true? "success" : "error"}>
          {message}
      </Alert>
    </Snackbar>
    <SaveOverConfirmDialog open={openSaveOverConfirm} onClose={() => { setOpenSaveOverConfirm(false) }} recipeName={sessionName} onConfirm={() => { save(sessionName) }} />
    </div>
  );
}