import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import { TextField, InputBase, DialogContent, IconButton, Typography, DialogActions, FormControl, Input, InputLabel, FormHelperText, CircularProgress } from '@mui/material';
import Amplify, { Auth } from 'aws-amplify';
import { setSession } from '../App';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';

Auth.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: ' us-east-2',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_xLemYVNwY',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '1flh76qlg1no0gfji8c4pcjdje',


        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',

         // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'auth.pwbm-beta.net',
            scope: [ 'email',  'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});


const useStyles = makeStyles((theme: any) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  search: {
    alignItems: 'center',
    textAlign: "center",
    justifyContent: "space-between",
    position: 'relative',
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    borderColor: "gray",
    '&:hover': {
      borderColor: "#004785",
    },
    '&:focus-within': {
      borderColor: "#004785",
    },
    borderStyle: "solid",
    borderWidth: "1px"

  },
  iconButton: {
    color: "black",
    marginRight: 5,
    marginTop: 18
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 2),

  },
  buttondisabled:{
    backgroundColor: "grey!important",
    color: "white!important"
  }
}));

export interface CreateAccountDialogProps {
  open: boolean;
  onClose: (value: string) => void;
}

function CreateAccountDialog(props: CreateAccountDialogProps) {
  const classes = useStyles();
  const { onClose, open } = props;
  const [visible, setVisible]  = useState<string>("password");
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword ] = useState<string>("");
  const [confirmPass, setConfirmPass] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [passMatch, setPassMatch] = useState<boolean>(true);
  const [submitLoad, setSubmitLoad] = React.useState<boolean>(false);
  


  function handleClose(){
    onClose("");
    setError("");
    setUsername("");
    setPassword("");
    setConfirmPass("");
    setPassMatch(true);
    setEmail("");
  }

  async function handleSubmit(){
    setSubmitLoad(true);
    if(username.includes(" "))
    {
      setError("Error signing up: Username cannot contain spaces")
      setSubmitLoad(false);
    }
    else{
      try {
        const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
                email    
            }
        });
        onClose("");
        setError('')
        
      } catch (error: any) {
          setError('Error signing up: ' + error.message);
      } finally {
        setSubmitLoad(false);
      }
    }

  }



  function changeUsername(s: string) {
    setUsername(s);
  }

  function changePassword(s: string){
    if(s !== confirmPass)
    {
      setPassMatch(false);
    }
    else
    {
      setPassMatch(true);
    }
    setPassword(s);
  }

  function changeConfirmPass(s: string){
    if(s !== password)
    {
      setPassMatch(false);
    }
    else
    {
      setPassMatch(true);
    }
    setConfirmPass(s);
  }

  function show()
  {
    setVisible("");
  }

  function hide()
  {
    setVisible("password");
  }

  function changeEmail(s: string)
  {
    setEmail(s);
  }

  function validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


 

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle><div style={{ margin: "0px", padding: "10px 0px 5px calc(10% - 24px)", fontSize: '30px', fontWeight: 700 }}>Create Account<IconButton onClick={handleClose} style={{color: "#D11A2A", float: "right", padding: "0px"}}><CloseIcon/></IconButton></div></DialogTitle>
      <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', padding: "0px 20px 20px calc(10% - 24px)", margin: '0px', marginBottom: '16px' }}>
        A confirmation email will be sent to the email provided once submitted. Please click the link in this email to finish account creation.
      </div>
      {error != "" && <Alert style={{marginBottom: "15px", placeContent: "center"}} severity="error">{error}</Alert>}
      <DialogContent style={{ alignSelf: "center", width: "80%", position: "relative", left: "-5px", padding: "0", margin: "5px 0px 20px 0px" }}>
        <form style={{marginTop: "7px", height: "100%"}} className={classes.search} noValidate onSubmit={(e: React.SyntheticEvent) => {
           e.preventDefault();
           handleSubmit()}}>
          <div style={{ width: "100%", display: "flex", marginBottom: "15px"}}>
            <PersonIcon className={classes.iconButton} />
            <FormControl style={{ flexGrow: 1 }}>
              <InputLabel >Username</InputLabel>
              <Input
                value={username}
                name="username" 
                onChange={(
                  ev: React.ChangeEvent<HTMLInputElement>,
                ): void => changeUsername(ev.target.value)}
                id="username"

              />
            </FormControl>
          </div>

          <div style={{ width: "100%", display: "flex", marginBottom: "15px" }}>
            <LockIcon className={classes.iconButton} />
            <FormControl style={{ flexGrow: 1 }} error={!passMatch}>
              <InputLabel >Password *</InputLabel>
              <Input
                name="password" 
                value={password}
                onChange={(
                  ev: React.ChangeEvent<HTMLInputElement>,
                ): void => changePassword(ev.target.value)}
                type={visible}
                id="password"
                inputProps={{ 'aria-label': 'password' }}
              />
              {visible === "password" ? <IconButton style={{ position: 'absolute', right: '0', fontSize: '18px' }} onClick={show}><VisibilityIcon fontSize="inherit"/></IconButton> :
                <IconButton style={{ position: 'absolute', right: '0', fontSize: '18px' }} onClick={hide}><VisibilityOffIcon fontSize="inherit"/></IconButton>
              }    
           
           </FormControl>
            </div>
            <div style={{ width: "100%", display: "flex", marginBottom: "15px"}}>
              <div style={{ width: "28px" }}></div>
              <FormControl style={{ flexGrow: 1 }} error={!passMatch}>
                <InputLabel >Confirm Password</InputLabel>
                <Input
                
                name="confirmPass" 
                value={confirmPass}
                onChange={(
                  ev: React.ChangeEvent<HTMLInputElement>,
                ): void => changeConfirmPass(ev.target.value)}
                type={visible}
                id="ConfirmPass"

                inputProps={{ 'aria-label': 'password' }}
                /> 
              </FormControl>

          </div>
          <div style={{ width: "100%", display: "flex", marginBottom: "15px"}}>
            <EmailIcon className={classes.iconButton} />
            <FormControl style={{ flexGrow: 1 }} error={email !== '' && !validateEmail(email)}>
              <InputLabel >Email</InputLabel>
              <Input
                value={email}
                name="email" 
                onChange={(
                  ev: React.ChangeEvent<HTMLInputElement>,
                ): void => changeEmail(ev.target.value)}
                id="email"

              />
            </FormControl>
          </div>
          <div style={{ textAlign: 'left', display: 'flex' }}>
            <Typography variant="caption" style={{ marginRight: '4px' }}>*</Typography>
            <Typography variant="caption">Password requires at least one number, one special character, one uppercase and lowercase letter, and a minimum of eight characters </Typography>
          </div>
        </form>

      </DialogContent>
      <DialogActions style={{justifyContent: "center", marginBottom: "10px"}}>
          <Button classes={{
              disabled: classes.buttondisabled
            }}  disabled={!passMatch || password === '' || username === '' || email === '' || !validateEmail(email) || submitLoad === true} onClick={handleSubmit} variant="contained" color="primary" style={{ color: "white", marginRight: '10px'}} >
            Submit
            {submitLoad === true ? <CircularProgress size={21} style={{ marginLeft: '10px' }} className="buttonProgress" />
            : <span></span>}
          </Button>
          <Button variant="contained" style={{ backgroundColor:"#c5093b", color: "white"}} onClick={handleClose} >
            Cancel
          </Button>
        </DialogActions>
    </Dialog>
  );
}

export default CreateAccountDialog;