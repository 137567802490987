export const mapScaleInfo = {
    'country': {
        'level': 0,
        'ancestors': []
    },
    'state': {
        'level': 1,
        'ancestors': ['country']
    },
    'county': {
        'level': 2,
        'ancestors': ['state', 'country']
    },
    'city': {
        'level': 2,
        'ancestors': ['state', 'country']
    }
} as any