import React from 'react';
import { Button, CardContent, Card, Typography, IconButton, Table, TableRow, TableCell, TableBody } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { useResizeDetector } from 'react-resize-detector';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: any) => ({
  sectionContainer: {
    marginBottom: '50px',
    '& > *': {
      marginBottom: '15px'
    },
  }
}));


function DataFilesPage(props: any) {

  document.title = 'PWBM DataView';

  const classes = useStyles();
  const { width, height, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 0 });

  return (
    <div ref={ref} style={{ display: 'flex', width: 'auto' }}>
      <div style={{ width: '100%', maxWidth: '1100px', margin: '0 auto', textAlign: 'left' }}>
        <CardContent style={{ padding: (width && width < 700) ? '20px' : '40px' }}>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Link to={'/'}>
              <IconButton size={'large'} style={{ textDecoration: 'none', color: '#c5093b', textDecorationColor: 'rgba(255,255,255,0)' }} >
                <ClearIcon fontSize="inherit" />
              </IconButton>
            </Link>
          </div>
          <Typography style={{ textAlign: "center", marginBottom: "20px" }} variant='h4'><strong>Statistics of Income Files</strong></Typography>
          <Typography variant="body1">
            Penn Wharton Budget Model uses what is known as a &ldquo;stochastic simulation.&rdquo; Hundreds of thousands of individuals across a wide range of population subgroups are calibrated to Census-level data. Using a variety of additional large data sets, households are assigned key economic attributes including fertility, mortality, immigration, labor-force participation rates, education, marriage, divorce, capital, disabilities, and earnings. This data is matched statistically to the IRS Statistics of Income (SOI) files.
          </Typography>
          <br />
          <Typography variant="body1">
            The SOI files include data from the individual, corporate, estate, and nonprofit returns. You can read more about them <a href="https://www.irs.gov/statistics/soi-tax-stats-the-work-soi-does">here</a>. PWBM takes this data and reformats it to be more compatible with our simulation model, using the reformatted data to forecast federal tax revenues.
          </Typography>
          <br />
          <Typography variant="body1">As a public service PWBM presents our reformatted SOI data here. The original files can all be found at the<a href="https://www.irs.gov/statistics/soi-tax-stats-corporation-income-tax-returns-complete-report-publication-16"> IRS SOI data site</a></Typography>
          <br />
          <Typography variant="h5"><strong>Basic Tables - Returns of Active Corporations</strong></Typography>
          <br />
          <Typography variant="body1"><br /><strong>Table 1 (Part 1 of 2): Returns of Active Corporations</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }} >
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }} >Selected Income Statement, Balance Sheet, and Tax Items&nbsp;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:&nbsp;
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Minor Industry
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table01(2014-2018).csv">Table01(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 1 (Part 2 of 2): Coefficients of Variation for Returns of Active Corporations</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }} >
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }} >Selected Income Statement, Balance Sheet, and Tax Items&nbsp;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:&nbsp;
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Minor Industry
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table01P2(2014-2018).csv">Table01P2(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 2.1: Returns of Active Corporations</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>Balance Sheet, Income Statement, and Selected Other Items</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Size of Total Assets
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table21(2014-2018).csv">Table21(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 2.1A: Returns of Active Corporations</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>Percentage Distribution of Total Assets</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Sector, Size of Total Assets
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table21A(2014-2018).csv">Table21A(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 2.2: Returns with Net Income</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>Balance Sheet, Income Statement, and Selected Other Items</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Size of Total Assets
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table22(2014-2018).csv">Table22(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 2.3: Returns of Active Corporations, other than Forms 1120S, 1120-REIT, and 1120-RIC</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>Balance Sheet, Income Statement, and Selected Other Items</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Size of Total Assets
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table23(2014-2018).csv">Table23(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 2.4: Returns of Active Corporations, Form 1120S</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>Balance Sheet, Income Statement, and Selected Other Items</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Size of Total Assets
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table24(2014-2018).csv">Table24(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 2.4A: Returns of Active Corporations, Form 1120S</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>Balance Sheet, Income Statement, and Selected Other Items</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Sector, Size of Total Assets
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table24A(2014-2018).csv">Table24A(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 3.1: Returns of Active Corporations</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>Selected Balance Sheet, Income Statement, and Tax Items</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Size of Business Receipts
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table31(2014-2018).csv">Table31(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 3.2: Returns of Active Corporations, Form 1120S</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>Total Receipts and Deductions, Portfolio Income, Rental Income, and Total Net Income</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Size of Business Receipts
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table32(2014-2018).csv">Table32(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 3.3: Returns of Active Corporations, other than Forms 1120S, 1120-REIT, and 1120-RIC </strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>Selected Balance Sheet, Income Statement, and Tax items</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Size of Business Receipts
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table33(2014-2018).csv">Table33(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 4: Returns with Total Income Tax after Credits, other than Forms 1120S, 120-REIT, and 1120-RIC&nbsp;</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>Number of Returns and Selected Tax Items</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Size of Total Income Tax After Credits
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}><a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table04(2014-2018).csv">Table04(2014-2018).csv</a></TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="body1"><br /><strong>Table 11: Returns of Active Corporations</strong></Typography>
          <Table size="small" style={{ border: "0px", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>Data Presented:</TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>Dividends, Net Income (Less Deficit), Statutory Special Deductions, Income Subject to Tax, Taxes, Credits, and Payments</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Classified by:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  Selected Sectors
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "18%", whiteSpace: "nowrap", borderBottom: "0px" }}>
                  Download:
                </TableCell>
                <TableCell style={{ width: "82%", borderBottom: "0px" }}>
                  <a href="https://datascrapingproject.s3-us-east-2.amazonaws.com/SOI/Table11(2014-2018).csv">Table11(2014-2018).csv</a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

        </CardContent>
      </div>
    </div>

  );
}

export default DataFilesPage;