import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SwipeableDrawer, IconButton, Button, Autocomplete, Typography, Tooltip, TextField, InputAdornment, CircularProgress, Menu, MenuItem } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { makeStyles, } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TimelineIcon from '@mui/icons-material/Timeline';
import ClearIcon from '@mui/icons-material/Clear';
import GetAppIcon from '@mui/icons-material/GetApp';
import VizTable from './VizTable';
import { clearTable, clearRecipeInfo, setChartTableLocOptions, newRecipe } from '../actions'
import { setRecipeList, loadRecipe, saveRecipe, updateRecipeData } from '../thunks'
import { useSelector, useDispatch } from 'react-redux'
import { MAX_DATA_VIZ, MAX_MAP_DATA_VIZ } from '../App';
import SaveAsDialog from './SaveAsDialog'
import ShareDialog from './ShareDialog'
import DeleteRecipeDialog from './DeleteRecipeDialog'
import UnsavedWarningDialog from './UnsavedWarningDialog'
import ChangeUnitDialog from './ChangeUnitDialog'
import ChangeFrequencyDialog from './ChangeFrequencyDialog'
import CalculateDialog from './CalculateDialog'
import RunRegressionDialog from './RunRegressionDialog'
import RegressionResultsDialog from './RegressionResultsDialog'
import { DataPoint } from '../Models/DataResult';
import { frequencySettings } from '../definitions/FrequencySettings';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const queryString = require('query-string');

// https://stackoverflow.com/questions/69485778/new-typescript-version-does-not-include-window-navigator-mssaveblob
declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

interface VisualizationDrawerProps {
  screenSize: string,
  view: string,
  drawerSmallWidth: string,
  drawerBigWidth: string,
  handleDrawerSmall: () => void,
  handleDrawerBig: () => void,
  handleDrawerClose: () => void,
}

const useStyles = makeStyles((theme: any) => ({
  unsavedIndicator: {
    '& > p': {
      fontSize: '0.9em'
    }
  },
  loadingRoot: {
    color: "#004785"
  },
  sessionRecipeSelect: {
    width: '100%',
    maxWidth: 400,
    // margin: '5px 10px',
    backgroundColor: '#f4f7f9',
    padding: '8px',
    borderRadius: '5px'
  },
  contained: {
    marginLeft: '10px',
    marginTop: '5px',
    marginBottom: '5px', 
    backgroundColor: "#026cb5",
    '&:hover': {
      backgroundColor: alpha("#026cb5", 0.8),
    },
    color: "#fff",
  },
  containedRecipeBar: {
    marginLeft: '10px',
    marginTop: '5px',
    marginBottom: '5px', 
    backgroundColor: "#262460",
    '&:hover': {
      backgroundColor: alpha("#262460", 0.8),
    },
    color: "#fff",
  },
  containedRecipeBarUpdate: {
    marginLeft: '10px',
    marginTop: '5px',
    marginBottom: '5px', 
    backgroundColor: "#282f85",
    '&:hover': {
      backgroundColor: alpha("#282f85", 0.8),
    },
    color: "#fff",
  },
  containedRecipeBarShare: {
    marginLeft: '10px',
    marginTop: '5px',
    marginBottom: '5px', 
    backgroundColor: "#96227d",
    '&:hover': {
      backgroundColor: alpha("#96227d", 0.8),
    },
    color: "#fff",
  },

  containedRight: {
    marginRight: '10px',
    marginTop: '5px',
    marginBottom: '5px', 
    backgroundColor: "#326b9c",
    '&:hover': {
      backgroundColor: alpha("#326b9c", 0.8),
    },
    color: "#fff",
  },
  containedRightDownload: {
    marginTop: '5px',
    marginBottom: '5px',
    backgroundColor: "#004785",
    '&:hover': {
      backgroundColor: alpha("#004785", 0.8),
    },
    color: "#fff",
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function VisualizationDrawer(props: VisualizationDrawerProps) {
  const [bottomOpen, setBottomOpen] = useState(false);

  const [onMount, setOnMount] = useState(true);
  const [openSaveAs, setOpenSaveAs] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openDeleteRecipe, setOpenDeleteRecipe] = useState(false);
  const [openUnsavedWarning, setOpenUnsavedWarning] = useState(false);
  const [unsavedWarningNewModified, setUnsavedWarningNewModified] = useState(false);
  const [loadSessionName, setLoadSessionName] = useState('');
  const [loadSessionId, setLoadSessionId] = useState('');
  const [sessionRecipeId, setSessionRecipeId] = useState('');
  const [recipeToDelete, setRecipeToDelete] = useState(null);
  const [openChangeUnit, setOpenChangeUnit] = useState(false);
  const [openChangeFreq, setOpenChangeFreq] = useState(false);
  const [openCalculate, setOpenCalculate] = useState(false);
  const [openRunRegression, setOpenRunRegression] = useState(false);
  const [openRegressionResults, setOpenRegressionResults] = useState(false);
  const [anchorSaveMenu, setAnchorSaveMenu] = useState<null | HTMLElement>(null);

  let location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch()
  const sessionRecipe = useSelector((state: any) => state.sessionRecipe)
  const sessionRecipeInfo = useSelector((state: any) => state.sessionRecipeInfo)
  const userInfo = useSelector((state: any) => state.userInfo)
  const nodeCounters = useSelector((state: any) => state.nodeCounters)

  useEffect(() => {
    // on mount

    dispatch(setRecipeList())

    if (sessionRecipeInfo.meta === undefined) {
      dispatch(newRecipe())
    } else { // if (location.pathname === "/") { // commented out location.pathname code would trigger re-load on naviating to '/' only
      // check if there is a recipe id in url.
      var urlSearch = queryString.parse(location.search)

      if (urlSearch.id === undefined) {
        if (sessionRecipeInfo.id === '') {
          dispatch(updateRecipeData())
          setOnMount(false)
        } else {
          if (userInfo.token !== '' && sessionRecipeInfo.isModified) {
            setOpenUnsavedWarning(true)
            setLoadSessionName(sessionRecipeInfo.name)
            setLoadSessionId(sessionRecipeInfo.id)
          } else {
            dispatch(loadRecipe(sessionRecipeInfo.name, sessionRecipeInfo.id))
            setOnMount(false)
          }
        }
      } else {
        // if modified and signed in, display option to save session
        if (userInfo.token !== '' && sessionRecipeInfo.isModified) {
          setOpenUnsavedWarning(true)
          if (urlSearch.id === sessionRecipeInfo.id) {
            setLoadSessionName(sessionRecipeInfo.name)
            setLoadSessionId(sessionRecipeInfo.id)
          } else {
            setLoadSessionName("Unknown Name")
            setLoadSessionId(urlSearch.id)
          }
        } else {
          // load recipe from id
          if (urlSearch.id === sessionRecipeInfo.id) {
            dispatch(loadRecipe(sessionRecipeInfo.name, sessionRecipeInfo.id))
          } else {
            dispatch(loadRecipe("Unknown Name", urlSearch.id))
          }
          setOnMount(false)
        }

      }
    }
  }, []) // [location.pathname]);

  useEffect(() => {
    // on update
    if (sessionRecipeInfo.id !== undefined && userInfo.token === '' && sessionRecipeInfo.id !== '' && sessionRecipeInfo.isModified) {
      // if not signed in and session modified, clear recipe info.
      // doesn't clear any series just makes into new session so can't be confused with the saved session. 
      dispatch(clearRecipeInfo())
      // dispatch(setChartTableLocOptions([] as any[]))
    }
  }, [sessionRecipeInfo, userInfo]);

  // useEffect(() => {
  //   // on update
  //   if (userInfo.token !== '') {
  //     var recipeListItem = userInfo.recipeList.find((r: any) => r.id === sessionRecipeInfo.id)
  //     if (recipeListItem !== undefined && recipeListItem.modified !== sessionRecipeInfo.modified) {
  //       if (sessionRecipeInfo.isModified) {
  //         setOpenUnsavedWarning(true)
  //         setUnsavedWarningNewModified(true)
  //         setLoadSessionName(sessionRecipeInfo.name)
  //         setLoadSessionId(sessionRecipeInfo.id)
  //       } else {
  //         // load recipe from id
  //         dispatch(loadRecipe("Unknown Name", sessionRecipeInfo.id))
  //       }
  //     }
  //   }
  // }, [userInfo]);

  const handleOpenSaveMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSaveMenu(event.currentTarget);
  };

  const handleCloseSaveMenu = () => {
    setAnchorSaveMenu(null);
  };

  function getPeriodsbyLocation(columns: any[]){
    var locations = {} as any;
    var output = [] as any[];
    columns.forEach((s:any) =>{
      s.data_points.forEach((item: any) => {
        var currPeriod = Date.parse(item.key);
        var frequency = item.frequency
        var location = item.location
        var locationCode = item.locationCode
        var displayRank = frequencySettings['NotSet'].displayRank;
        var periodDisplay = frequencySettings['NotSet'].dateFormatter(currPeriod);
  
        if (frequency !== null && frequencySettings[frequency]) {
          displayRank = frequencySettings[frequency].displayRank;
          periodDisplay = frequencySettings[frequency].dateFormatter(currPeriod)
        }

        if (locations[location + locationCode] === undefined) {
          locations[location + locationCode] = {
            location,
            locationCode,
            periodsInData: [] as any[]
          }
        }
  
        var currPeriodObj = locations[location + locationCode].periodsInData.find((element: any) => element.date === item.key && element.frequency === frequency);
        if (currPeriodObj === undefined) {
          locations[location + locationCode].periodsInData.push({
            periodDisplay: periodDisplay,
            frequency: frequency,
            displayRank: displayRank,
            period: currPeriod,
            date: item.key,
          });
        }
      })
    })

    output = Object.values(locations)
    output.sort((a: any, b: any) => {
      if (a.locationCode === b.locationCode) {
        return a.location.localeCompare(b.location)
      } else {
        if (a.locationCode === '-1') {
          return 1
        }
        if (b.locationCode === '-1') {
          return -1
        }
        return a.locationCode.localeCompare(b.locationCode)
      }
    })
    output.forEach((loc: any) => {
      loc.periodsInData.sort((a: any, b: any) => {
        if (a.period === b.period) {
          return a.displayRank - b.displayRank
        } else {
          return a.period - b.period
        }
      });
    })
    
    return output;

  }

  function exportToCsv(){

    var columns = [] as any[]

    sessionRecipe.forEach((node: any) => {
      if (node.displayed) {
        if (node.result.series !== null && node.result.series.length > 0) {
          node.result.series.forEach((s: any) => {
            var colInfo: any = {
              id: node.id,
              name: node.name,
              original_name: node.meta.aliasCreated ? node.meta.original_name : 'N/A',
              description: node.result.description !== null ? node.result.description : '',
              source: node.type === 'transformation' ? 'Created in workspace.' : node.result.search !== null ? node.result.search.scraper_name : '',
              isMap: false,
              summary_row: null,
              data_points: s.data_points.map((p: any) => {
                return {
                  ...p,
                  frequency: s.frequency,
                  location: s.location,
                  locationCode: s.locationCode,
                }
              }),
            }
            columns.push(colInfo)
          })
        }
        if (node.result.maps !== null && node.result.maps.length > 0) {
          node.result.maps.forEach((m: any) => {
            var colInfo: any = {
              id: node.id,
              name: node.name,
              original_name: node.meta.aliasCreated ? node.meta.original_name : 'N/A',
              description: node.result.description !== null ? node.result.description : '',
              source: node.type === 'transformation' ? 'Created in workspace.' : node.result.search !== null ? node.result.search.scraper_name : 'N/A',
              isMap: true,
              summary_row: m.summary_row,
              data_points: [] as any[],
            }
            
            m.children.forEach((s: any) => {
              colInfo.data_points = colInfo.data_points.concat(s.data_points.map((p: any) => {
                return {
                  ...p,
                  frequency: s.frequency,
                  location: s.location,
                  locationCode: s.locationCode,
                }
              }))
            })

            columns.push(colInfo)
          })
        }
      }
    })

    if(!columns || columns.length === 0){
      return;
    }

    const periodsByLocation = getPeriodsbyLocation(columns);
    // console.log(periodsByLocation);
    // console.log(columns);

    const columnNames = ["", "Location Name", "Location Code (FIPS or ISO 3166-1 alpha-3)", "Date String","Date / Time", "Frequency"];
    const seperator = ',';
    const csvContent = 
      columnNames.join(seperator) + "," + 
      columns.map(s =>{
        var name = s.name.replace(/"/g, '""');
        if(name.search(/("|,|\n)/g) >= 0)
        {
          name = '"' + name.replace(/\n/g, " ") + '"';
        }
        return name;
      }).join(seperator) + "\nID,,,,,," +
      columns.map(s =>{
        var id = s.id.replace(/"/g, '""');
        if(id.search(/("|,|\n)/g) >= 0)
        {
          id = '"' + id.replace(/\n/g, " ") + '"';
        }
        return id;
      }).join(seperator) + "\nOriginal name,,,,,," +
      columns.map(s =>{
        var original_name = s.original_name.replace(/"/g, '""');
        if(original_name.search(/("|,|\n)/g) >= 0)
        {
          original_name = '"' + original_name.replace(/\n/g, " ") + '"';
        }
        return original_name;
      }).join(seperator) + "\nDescription,,,,,," +
      columns.map(s =>{
        var description = s.description != null && s.description !== ""  ? s.description.replace(/"/g, '""') : "N/A";
        if(description.search(/("|,|\n)/g) >= 0)
        {
          description = '"' + description.replace(/\n/g, " ") + '"';
        }
        return description;
      }).join(seperator) + "\nSource,,,,,," + 
      columns.map(s=>{
        var source = s.source;
        source = source.replace(/"/g, '""');
        if(source.search(/("|,|\n)/g) >= 0)
        {
          source = '"' + source.replace(/\n/g, " ") + '"';
        }
        return source;
      }).join(seperator) + "\nIs Map,,,,,," + 
      columns.map(s =>{
        var l =  s.isMap ? "True" : "False";
        if(l.search(/("|,|\n)/g) >= 0)
        {
          l = '"' + l.replace(/\n/g, " ") + '"';
        }
        return l;
      }).join(seperator) + "\nSummary Row,,,,,," + 
      columns.map(s =>{
        var l =  (s.summary_row === null) ? "N/A" : s.summary_row;
        if(l.search(/("|,|\n)/g) >= 0)
        {
          l = '"' + l.replace(/\n/g, " ") + '"';
        }
        return l;
      }).join(seperator) + "\n" + 
      periodsByLocation.map(l =>{
        return l.periodsInData.map((p: any) =>{
          var period = p.date.toString().trim();
          period = period.replace(/"/g, '""');
              if (period.search(/("|,|\n)/g) >= 0)
                  period = '"' + period + '"';

          var locationString = l.location
          if(locationString.search(/("|,|\n)/g) >= 0)
          {
            locationString = '"' + locationString.replace(/\n/g, " ") + '"';
          }
          return 'Data Item,' + locationString + "," + l.locationCode + "," + p.periodDisplay  +  "," + period  +  "," + '"' + p.frequency + '"' +  "," + 
          columns.map(s=> {
              var i = s.data_points.findIndex((d: any) => d.location === l.location && d.locationCode === l.locationCode && d.frequency === p.frequency && d.key === p.date);
              if(i > -1)
              { 
                return '"' + (s.data_points[i].value !== null ? s.data_points[i].value : 'N/A') + '"';
              }
              else
              {
                return "";
              }
          }).join(seperator)
        }).join("\n");
      }).join("\n");

    
    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'});
    if(navigator.msSaveBlob){
      navigator.msSaveBlob(blob, "PWBM_Data_Workspace.csv");
    }
    else{
      const link = document.createElement('a');
      if(link.download !== undefined){
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "PWBM_Data_Workspace.csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
      }
    }
  }

  return (<div>
    {(props.screenSize === 'small' && !bottomOpen) && <IconButton 
      style={{ position: 'fixed', bottom: 0, left: '50%', transform: 'translate(-50%, 0)', backgroundColor: '#cccccc', zIndex: 1150, borderRadius: '5px 5px 0px 0px', width: '50px' }}
      onClick={() => { setBottomOpen(true) }}
    >
      <ArrowDropUpIcon />
    </IconButton>}
    <SwipeableDrawer
      sx={{
        position: 'fixed',
        width: props.screenSize === 'small' ? '100%' : props.view === 'big' ? props.drawerBigWidth : props.view === 'small' ? props.drawerSmallWidth : '0px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: props.screenSize === 'small' ? '100%' : props.view === 'big' ? props.drawerBigWidth : props.view === 'small' ? props.drawerSmallWidth : '0px',
          backgroundColor: '#CAD9E5',
          borderColor: '#CAD9E5',
        },
      }}
      variant={props.screenSize === 'small' ? 'temporary' : "permanent"}
      anchor={props.screenSize === 'small' ? 'bottom' : "right"}
      open={props.screenSize === 'small' ? bottomOpen : props.view !== 'close'}
      onClose={() => { setBottomOpen(false) }}
      onOpen={() => { setBottomOpen(true) }}
      disableSwipeToOpen={false}
      swipeAreaWidth={15}
    >
      {(sessionRecipeInfo.loading) && <div style={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', zIndex: 11, backgroundColor: 'rgba(255,255,255,0.5)' }}><CircularProgress thickness={3.5} size={100} className={classes.loadingRoot} /></div>}
      {props.screenSize === 'small' ? <div style={{ width: '100%', textAlign: 'right' }}>
        <IconButton onClick={() => { setBottomOpen(false) }} style={{ margin: '3px', padding: '5px', color: '#c5093b' }}>
          <ClearIcon />
        </IconButton>
      </div> : <div style={{ justifyContent: 'space-between', margin: '5px' }}>
        <div style={{ display: 'flex' }}>
          <IconButton onClick={props.handleDrawerBig} disabled={props.view === 'big'}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton onClick={props.view === 'big' ? props.handleDrawerSmall : props.handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </div>
      </div>}
      {userInfo.token !== ''  ? <div id="select-workspace-recipe-container" style={{ display: "flex", alignItems: 'center', justifyContent: 'center', margin: '2px 10px', }}>
        <Autocomplete
          id="select-workspace-recipe"
          // autoComplete={true}
          autoHighlight={true}
          // autoSelect={true}
          disableClearable
          // includeInputInList
          loading={userInfo.loading}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          value={(userInfo.recipeList !== undefined && userInfo.recipeList.some((r: any) => r.id === sessionRecipeInfo.id)) ? userInfo.recipeList.find((r: any) => r.id === sessionRecipeInfo.id) 
          : (sessionRecipeInfo.id !== '') ? {
            id: sessionRecipeInfo.id,
            name: '( Shared )  ' + sessionRecipeInfo.name,
            modified: '',
          } : {
            id: '',
            name: '',
            modified: '',
          }}
          onOpen={async () => {
            dispatch(setRecipeList())
          }}
          onChange={(event: any, value: any) => {
            // console.log(value)
            if (value !== null) {
              if (sessionRecipeInfo.isModified) {
                setOpenUnsavedWarning(true)
                setLoadSessionName(value.name)
                setLoadSessionId(value.id)
              } else {
                // load recipe from id
                dispatch(loadRecipe(value.name, value.id))
              }
            }
          }}
          options={[{
            id: '',
            name: '',
            modified: '',
            }].concat(userInfo.recipeList)}
          getOptionLabel={(option) => (userInfo.loading) ? 'Loading workspace...' : option !== undefined && option.id !== '' ? option.name : '[ new workspace ]'}
          className={classes.sessionRecipeSelect}
          renderOption={(props: object, option) => {
            if (option.id === '') {
              return <li {...props}><Typography><i>[ new workspace ]</i></Typography></li>
            } else {
              return <li {...props} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* TODO Add date modified if time */}
                <Typography>{option.name}</Typography>
                <Tooltip title={"Delete saved workspace."} arrow>
                  <IconButton 
                    style={{ flexGrow: 0, flexShrink: 0, textDecoration: 'none', color: '#c5093b', backgroundColor: 'rgba(255,255,255,0)', textDecorationColor: 'rgba(255,255,255,0)', marginLeft: '8px', padding: '1px', fontSize: '20px', borderRadius: '4px' }} 
                    onClick={(event: any) => {
                      event.stopPropagation()
                      setOpenDeleteRecipe(true)
                      setRecipeToDelete(option)
                    }}
                  >
                    <ClearIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </li>
            }
          }}
          renderInput={(params) => <TextField 
            {...params} 
            inputProps={{ 
              ...params.inputProps, 
              // autoComplete: 'off',
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            InputProps={{
              ...params.InputProps, 
              endAdornment: (
                <React.Fragment>
                  {(sessionRecipeInfo.isModified) ? <InputAdornment position="start" classes={{ root: classes.unsavedIndicator }}>Unsaved</InputAdornment> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            label="Select Saved Workspace" 
            variant="outlined"
            />}
        />
        <Tooltip title="Save / Share Options" arrow>
          <IconButton 
            style={{ color: '#262460', borderRadius: '5px', marginLeft: '5px' }}
            onClick={handleOpenSaveMenu}
          >
            <SaveIcon />
            <KeyboardArrowDownIcon fontSize='small' />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorSaveMenu}
          open={Boolean(anchorSaveMenu)}
          onClose={handleCloseSaveMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => {
            if (sessionRecipeInfo.id === '' || !userInfo.recipeList.some((r: any) => r.id === sessionRecipeInfo.id)) {
              setOpenSaveAs(true)
            } else {
              dispatch(saveRecipe(sessionRecipeInfo.name, userInfo.token))
            }
            handleCloseSaveMenu()
          }}>Save</MenuItem>
          <MenuItem onClick={() => { setOpenSaveAs(true); handleCloseSaveMenu() }}>Save As</MenuItem>
          <MenuItem onClick={() => { setOpenShare(true); handleCloseSaveMenu() }} disabled={sessionRecipeInfo.id === ''}>Share</MenuItem>
        </Menu>
      </div> : <div style={{ display: 'flex', alignItems: 'center' }}>
        {(sessionRecipeInfo.id !== '') && <div style={{ display: 'flex', alignItems: 'center', margin: '5px 15px' }}>
          <Typography variant='h5' style={{ fontSize: '0.92em', color: '#777', whiteSpace: 'nowrap' }}>( Shared )</Typography>
          <Typography variant='h5' style={{ textAlign: 'left', marginLeft: '10px', marginRight: '20px' }}>{sessionRecipeInfo.name}</Typography>
        </div>}
      </div>}
      <div style={{ display: 'flex', margin: '5px', justifyContent: 'center', flexWrap: 'wrap' }}>
        <Button classes={{ contained: classes.containedRightDownload }} style={{ marginRight: "10px" }} variant="contained" onClick={exportToCsv}>
            Download <GetAppIcon style={{ marginLeft: '7px' }} />
        </Button>
        {sessionRecipe.some((node: any, i: number) => node.displayed && node.type === 'transformation' && node.transformation_id.indexOf('regressions') !== -1 && (node.meta.error === undefined || node.meta.error === null)) && <Button classes={{ contained: classes.containedRight }} style={{ marginRight: "10px", backgroundColor: '#326b9c'}} variant="contained" onClick={() => { setOpenRegressionResults(true) }}>
          Regression Results
        </Button>}
        <Button classes={{ contained: classes.containedRight }} style={{ backgroundColor: "#c5093b" }} variant="contained" onClick={() => { dispatch(clearTable()) }}>
          Clear Table
        </Button>
      </div>
      <VizTable />
      <div style={{ display: "flex", justifyContent: props.view === 'big' ? 'center' : 'space-around', flexWrap: 'wrap', margin: '5px' }}>
        <Button disabled={nodeCounters.series >= MAX_DATA_VIZ && nodeCounters.maps >= MAX_MAP_DATA_VIZ} classes={{ contained: classes.contained }} style={{marginLeft: "10px"}} variant="contained" onClick={() => { setOpenChangeUnit(true) }}>
          Change Unit
        </Button>
        <Button disabled={nodeCounters.series >= MAX_DATA_VIZ && nodeCounters.maps >= MAX_MAP_DATA_VIZ} classes={{ contained: classes.contained }} style={{marginLeft: "10px"}} variant="contained" onClick={() => { setOpenChangeFreq(true) }}>
          Change Frequency
        </Button>
        <Button disabled={nodeCounters.series >= MAX_DATA_VIZ && nodeCounters.maps >= MAX_MAP_DATA_VIZ} classes={{contained: classes.contained}} style={{marginLeft: "10px"}} variant="contained" onClick={() => { setOpenCalculate(true) }}>
          Calculate
        </Button>
        <Button disabled={nodeCounters.series >= MAX_DATA_VIZ && nodeCounters.maps >= MAX_MAP_DATA_VIZ} classes={{ contained: classes.contained }} style={{marginLeft: "10px"}} variant="contained" onClick={() => { setOpenRunRegression(true) }}>
          Run Regression
        </Button>
      </div>
    </SwipeableDrawer>
    <SaveAsDialog open={openSaveAs} onClose={() => { setOpenSaveAs(false) }} />
    <ShareDialog open={openShare} onClose={() => { setOpenShare(false) }} />
    <DeleteRecipeDialog open={openDeleteRecipe} onClose={() => { setOpenDeleteRecipe(false) }} recipeToDelete={recipeToDelete} />
    <UnsavedWarningDialog 
      open={openUnsavedWarning}
      unsavedWarningNewModified={unsavedWarningNewModified}
      onMount={onMount}
      onClose={() => { 
        setOpenUnsavedWarning(false); 
        setUnsavedWarningNewModified(false)
        setOnMount(false)
      }}
      loadSessionName={loadSessionName}
      loadSessionId={loadSessionId}
    />
    <ChangeUnitDialog open={openChangeUnit} onClose={() => { setOpenChangeUnit(false) }} />
    <ChangeFrequencyDialog open={openChangeFreq} onClose={() => { setOpenChangeFreq(false) }} />
    <CalculateDialog open={openCalculate} onClose={() => { setOpenCalculate(false) }} />
    <RunRegressionDialog open={openRunRegression} onClose={() => { setOpenRunRegression(false) }} />
    <RegressionResultsDialog open={openRegressionResults} onClose={() => { setOpenRegressionResults(false) }} />
  </div>);
}