import React from 'react';
import { Button, CardContent, Card, Typography, IconButton } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { useResizeDetector } from 'react-resize-detector';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: any) => ({
  sectionContainer: {
    marginBottom: '50px',
    '& > *': {
      marginBottom: '15px'
    },
  }
}));


function VideoPage(props: any) {

    document.title = 'PWBM DataView';

    const classes = useStyles();
    const { width, height, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 0 });

    return (
    <div ref={ref} style={{display: 'flex', width: 'auto'}}>
      <div style={{width: '100%', maxWidth: '1000px', margin: '0 auto', textAlign: 'left'}}>
        <CardContent style={{padding: (width && width < 700) ? '20px' : '40px'}}>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Link to={'/'}>
              <IconButton size={'large'} style={{ textDecoration: 'none', color: '#c5093b', textDecorationColor: 'rgba(255,255,255,0)' }} >
                <ClearIcon fontSize="inherit" />
              </IconButton>
            </Link>
          </div>
          <div className={classes.sectionContainer}>
            <Typography variant="h4">Video Tutorials</Typography>
            <Typography variant="h6">Overview</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/K-PKmqcwDog" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <Typography variant="h6">Transformations</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/FhUWe5NvYIg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <Typography variant="h6">Local Data</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/LVfb34q8TBc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <Typography variant="h6">Visualizing Geography</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/upfkpszdEpE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <Typography variant="h6">Data Sources</Typography>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/B7Iwg1BfkfE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </CardContent>
      </div>
    </div>
    
    );
  }
  
  export default VideoPage;