import React, { useCallback, useEffect } from 'react';
import './App.css';
import AboutPage from './AboutPage/AboutPage';
import VideoPage from './VideoPage/VideoPage';
import { createTheme, ThemeProvider } from '@mui/material';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Route, Routes } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AdvancedSearch from './AdvancedSearch/AdvancedSearch';
import VisualizationPage from './VisualizationTool/VisualizationPage';
import VisualizationDrawer from './VisualizationTool/VisualizationDrawer';
import * as Highcharts from 'highcharts/highstock';
import * as Highmaps from 'highcharts/highmaps';
import { useResizeDetector } from 'react-resize-detector';
import { Auth } from 'aws-amplify';
import DataFilesPage from './DataFilesPage/DataFilesPage';


const theme = createTheme();
export let endpoint: string;

if (window.location.hostname.includes('dev-ui.pwbm-api')) {
  endpoint = "https://dev-wmdb.pwbm-api.net/"; // Dev
} 
// else if(window.location.hostname.includes('master.pwbm-api') || window.location.hostname.includes('master.d30qhrz24vg36p.amplifyapp')) {
//   apiSearchCall = "https://staging.pwbm-api.net/api/v0/"; //Staging
// }
else if(window.location.hostname.includes('ui.pwbm-api') || window.location.hostname.includes("pwbm-data.wharton.upenn.edu")) {  // window.location.hostname.includes('pwbm-api.wharton.upenn.edu'
  endpoint = "https://prod-wmdb.pwbm-api.net/"; // Production
}
else if(window.location.hostname.includes('localhost')){
  endpoint = "https://dev-wmdb.pwbm-api.net/"; // Dev
  //endpoint = "https://prod-wmdb.pwbm-api.net/"; // Production
  //endpoint = "http://localhost:8008/"; // Local API
}
// else if(window.location.hostname.includes('dev-prod.d2zuavu6z6bx7j.amplifyapp')){
//   apiSearchCall = "https://pwbm-api.net:8080/api/v0/"; //dev-prod
// }
else{
  endpoint = "https://dev-wmdb.pwbm-api.net/"; //Dev
}

if(endpoint.includes("staging"))
{
  Auth.configure({   
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: ' us-east-2',
  
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_xLemYVNwY',
  
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '1flh76qlg1no0gfji8c4pcjdje',
  
  
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,
  
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',
  
         // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'auth.pwbm-beta.net',
            scope: [ 'email',  'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
  });
}
else if(endpoint.includes("dev"))
{
  Auth.configure({   
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: ' us-east-2',
  
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_EuBla6HwJ',
  
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '4a0t0jkdp2g1fbj3prkcd4g1ef',
  
  
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,
  
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',
  
         // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'dev-auth.pwbm-api.net',
            scope: [ 'email',  'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
  });
}
else
{
  Auth.configure({   
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: ' us-east-2',
  
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_xLemYVNwY',
  
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '1flh76qlg1no0gfji8c4pcjdje',
  
  
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,
  
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',
  
         // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'auth.pwbm-beta.net',
            scope: [ 'email',  'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
  });
}

export var session = null as any;

export function setSession(s: any){
  session = s;
}

export async function getSession(){
  var s = await Auth.currentAuthenticatedUser();
  if (s.signInUserSession.accessToken.payload["cognito:groups"] !== undefined && s.signInUserSession.accessToken.payload["cognito:groups"] !== null) {
    await setSession(s);
    return session;
  } else {
    await Auth.signOut({ global: true });
    setSession(null);
    return null;
  }
}

export const MAX_DATA_VIZ = 25;
export const MAX_MAP_DATA_VIZ = 25;

const drawerSmallWidth = '33%';
const drawerBigWidth = '66%';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  view?: string;
}>(({ theme, view }) => ({
  width: '100%',
  flexGrow: 1,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 0,
  ...(view === 'small' && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${drawerSmallWidth})`,
  }),
  ...(view === 'big' && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${drawerBigWidth})`,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));


function App() {
  const theme = useTheme();
  const [view, setView] = React.useState("small");
  const [screenSize, setScreenSize] = React.useState("big");

  useEffect(() => {
    // on update
    var innerWidth = window.innerWidth
    // console.log(window.innerWidth)
    if (innerWidth < 900 && screenSize === 'big') {
      setScreenSize('small')
      if (view === 'small' || view === 'big') {
        handleDrawerClose()
      }
    } else if (innerWidth >= 900 && screenSize === 'small') {
      setScreenSize('big')
    }
  }, [window.innerWidth]);

  const { width, height, ref } = useResizeDetector({
    targetRef: React.createRef(),
    refreshMode: "debounce",
    refreshRate: 0,
    onResize: useCallback(() => {
      // console.log('hit')
      for (var i = 0; i < Highcharts.charts.length; i++) {
        if (Highcharts.charts[i] !== undefined) {
          Highcharts.charts[i]?.reflow();
        }
      }
      for (var i = 0; i < Highmaps.charts.length; i++) {
        if (Highmaps.charts[i] !== undefined) {
          Highmaps.charts[i]?.reflow();
        }
      }
    }, [])
  });

  const handleDrawerSmall = () => {
    setView('small');
  };

  const handleDrawerBig = () => {
    setView('big');
  };

  const handleDrawerClose = () => {
    setView('close');
  };

  return (<ThemeProvider theme={theme}>
    <Box sx={{ display: 'flex', width: '100%', minHeight: '100vh', alignItems: 'stretch' }} className="App">
      <CssBaseline />
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <Header screenSize={screenSize} open={view !== 'close'} onClick={view === 'close' ? handleDrawerSmall : handleDrawerClose} drawerWidth={view === 'big' ? drawerBigWidth : drawerSmallWidth} />
        <Main ref={ref} view={view} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <div style={{ flexGrow: 1, boxSizing: 'border-box' }}>
            <Routes>
              <Route
                path="about"
                element={<AboutPage />}
              />
              <Route
                path="videos"
                element={<VideoPage />}
              />
              <Route
                path="dataFiles"
                element={<DataFilesPage />}
              />
              <Route
                path="visualization"
                element={<VisualizationPage />}
              />
              <Route path="/" element={<VisualizationPage />} />
            </Routes>
          </div>
          <Footer />
        </Main>
      </div>
      <VisualizationDrawer
        screenSize={screenSize}
        view={view}
        drawerSmallWidth={drawerSmallWidth}
        drawerBigWidth={drawerBigWidth}
        handleDrawerSmall={handleDrawerSmall}
        handleDrawerBig={handleDrawerBig}
        handleDrawerClose={handleDrawerClose}
      />
    </Box>
  </ThemeProvider>);
}

export default App; 
