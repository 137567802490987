import React, {  useState, useRef } from 'react';
import { makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { FormControl, IconButton, TextField,  DialogContent, CircularProgress, DialogActions, Tooltip, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import InfoIcon from '@mui/icons-material/Info';
const queryString = require('query-string');

const useStyles = makeStyles((theme: any) => ({
  loadingRoot: {
    width: "75px",
    height: "75px",
    color: "#004785"
  },
  dialogRoot: {
    // height: '100%',
    maxWidth: '800px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "30%",
  },
}));

export interface ShareDialogProps {
  open: boolean,
  onClose: () => void,
}

export default function ShareDialog(props: ShareDialogProps) {
  const classes = useStyles();
  const { onClose, open } = props;
  const[messageOpen, setMessageOpen] = useState<boolean>(false);
  const[message, setMessage] = useState<string>("");
  const[messageStatus, setMessageStatus] = useState<boolean>(false);
  const urlInput = useRef<HTMLInputElement>(null);

  const sessionRecipeInfo = useSelector((state: any) => state.sessionRecipeInfo)

  
  const handleClose = () => {
    onClose();
  };

  const closeMessage = () =>{
    setMessageOpen(false);
    setMessage("");
  }

  // console.log(window.location)

  return (
    <div>
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="md" fullWidth={true} scroll='paper' classes={{ paper: classes.dialogRoot }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle >Share Saved Workspace {sessionRecipeInfo.isModified && '*'}</DialogTitle>
        {/* <Tooltip title="More info" arrow style={{ display: 'none' }}>
          <IconButton  
            style={{ flexGrow: 0, flexShrink: 0, color: '#326b9c', backgroundColor: '#ffffff', textDecoration: 'none', textDecorationColor: 'rgba(255,255,255,0)', margin: '16px 24px', padding: '0' }}  
            target="_blank" href="https://visualization-page.s3.amazonaws.com/How+to+Use+the+Visualization+Page_20210729.pdf#page=25"
          >
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
      </div>
      <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        Share your saved workspace using the following URL.
      </div>
      <DialogContent style={{ boxSizing: 'border-box', alignSelf: "center", width: "90%", position: 'relative' }}>
        <div style={{ position: 'relative', width: '100%' }}>
          {(sessionRecipeInfo.loading) && <div style={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', zIndex: 1, backgroundColor: 'rgba(255,255,255,0.5)' }}><CircularProgress thickness={3} className={classes.loadingRoot} /></div>}
          <div style={{ display: 'flex', flexDirection: 'column', width: '90%', margin: '0px auto' }}>
            <FormControl className={classes.formControl}>
              <TextField
                value={window.location.origin + '/?' + queryString.stringify({
                  id: sessionRecipeInfo.id
                }, {
                  skipNull: true,
                  skipEmptyString: true
                })}
                onFocus={(event: any) => { event.target.select() }}
                inputProps={{
                  ref: urlInput
                }}
                autoFocus
                id="workspace-url"
                label="Shareable Workspace URL"
                variant="outlined"
              />
            </FormControl>
          </div>
        </div>
      </DialogContent>
      <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', alignItems: 'center' }}>
        <DialogActions style={{ padding: '16px' }}>
          <Button 
            variant="contained" 
            // style={{ backgroundColor:"#026cb5", color: "white"}}
            disabled={sessionRecipeInfo.loading}
            onClick={() => {
              if (urlInput.current !== null) {
                urlInput.current.select()
                document.execCommand("copy")
              }
            }}
          >
            copy URL
          </Button>
          <Button onClick={handleClose} variant="contained" style={{ backgroundColor:"#c5093b", color: "white"}} >
            cancel
          </Button>
        </DialogActions>
        {sessionRecipeInfo.isModified && <div style={{ padding: '16px' }}>* The current workspace has unsaved changes.</div>}
      </div>
    </Dialog>
    <Snackbar anchorOrigin={{vertical: "top", horizontal:"center"}} open={messageOpen} autoHideDuration={6000} onClose={closeMessage}>
      <Alert onClose={closeMessage} severity={messageStatus === true? "success" : "error"}>
          {message}
      </Alert>
    </Snackbar>
    </div>
  );
}