import React, {  useState, useRef } from 'react';
import { makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { IconButton, DialogActions, Tooltip, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { endpoint } from '../App';
import { useSelector, useDispatch } from 'react-redux'
import InfoIcon from '@mui/icons-material/Info';
import { newRecipe, staleSessionAlert } from '../actions';
import { setRecipeList } from '../thunks';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    // height: '100%',
    maxWidth: '800px'
  },
}));

export interface DeleteRecipeDialogProps {
  open: boolean,
  onClose: () => void,
  recipeToDelete: any,
}

export default function DeleteRecipeDialog(props: DeleteRecipeDialogProps) {
  const classes = useStyles();
  const { onClose, open, recipeToDelete } = props;
  const[messageOpen, setMessageOpen] = useState<boolean>(false);
  const[message, setMessage] = useState<string>("");
  const[messageStatus, setMessageStatus] = useState<boolean>(false);
  const dispatch = useDispatch()
  const sessionRecipeInfo = useSelector((state: any) => state.sessionRecipeInfo)
  const userInfo = useSelector((state: any) => state.userInfo)

  
  const handleClose = () => {
    onClose();
  };

  const closeMessage = () =>{
    setMessageOpen(false);
    setMessage("");
  }

  return (
    <div>
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="md" fullWidth={true} scroll='paper' classes={{ paper: classes.dialogRoot }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle >Delete Saved Workspace</DialogTitle>
        {/* <Tooltip title="More info" arrow style={{ display: 'none' }}>
          <IconButton  
            style={{ flexGrow: 0, flexShrink: 0, color: '#326b9c', backgroundColor: '#ffffff', textDecoration: 'none', textDecorationColor: 'rgba(255,255,255,0)', margin: '16px 24px', padding: '0' }}  
            target="_blank" href="https://visualization-page.s3.amazonaws.com/How+to+Use+the+Visualization+Page_20210729.pdf#page=24"
          >
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
      </div>
      <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        Are you sure you want to delete "{recipeToDelete !== null ? recipeToDelete.name : ''}"?
      </div>
      <DialogActions style={{ padding: '16px' }}>
        <Button 
          variant="contained" 
          style={{ backgroundColor:"#026cb5", color: "white"}}
          disabled={sessionRecipeInfo.loading}
          onClick={async () => {
            handleClose()
            var requestOptions = {
              method: 'DELETE',
              headers: { 'Content-Type': 'application/json',
                          'Authorization': userInfo.token },
              body: JSON.stringify([recipeToDelete.id])
            }
            await fetch(endpoint + "transformations/recipes", requestOptions)
            .then( async response => {
              var r = await response;
              if (r.ok) {
                if (sessionRecipeInfo.id === recipeToDelete.id) {
                  dispatch(newRecipe())
                }
                dispatch(setRecipeList())
              } else {
                var e = await r.json();
                // console.log(r)
                // console.log(e)
                if (r.status === 403 && e.detail === 'Invalid token: Signature has expired.') {
                  dispatch(staleSessionAlert())
                  console.log('stale session')
                }
                throw Error(r.statusText);
              }
              
            }
            ).catch(error =>{
              console.log(error);
            });
          }}
        >
          Delete Workspace
        </Button>
        <Button onClick={handleClose} variant="contained" style={{ backgroundColor:"#c5093b", color: "white"}} >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
    <Snackbar anchorOrigin={{vertical: "top", horizontal:"center"}} open={messageOpen} autoHideDuration={6000} onClose={closeMessage}>
      <Alert onClose={closeMessage} severity={messageStatus === true? "success" : "error"}>
          {message}
      </Alert>
    </Snackbar>
    </div>
  );
}