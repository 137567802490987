import { Search } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, InputBase, List, ListItem, Typography, alpha, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Button, Link, Dialog, DialogTitle, DialogContent, DialogActions, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Box, Theme, Collapse, InputLabel, Select, SelectChangeEvent, MenuItem, Pagination, Backdrop, Popover, Paper, Snackbar, Alert, LinearProgress, ListItemButton, ListItemText } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { endpoint } from '../App';
import { DeepSearchResult } from '../Models/DeepSearchResult';
import { Scraper } from '../Models/Scraper';
import { useLocation, useNavigate } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { QuickSearchResult } from '../Models/QuickSearchResult';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataResult } from '../Models/DataResult';
import ChartViewDialog from '../AdvancedSearch/ChartViewDialog';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LinkIcon from '@mui/icons-material/Link';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
const queryString = require('query-string');

const useStyles = makeStyles((theme: any) => ({ 
    search: {
        // margin: 'auto',
        // marginTop: '15vh',
        marginTop: '50px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: "space-between",
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            // marginTop: '7vh',
            marginTop: '30px',
            // marginBottom: "45%",
            // maxHeight: "7vh",
        },
        borderRadius: theme.shape.borderRadius,
        border: '3px solid #bbb',
        backgroundColor: alpha(theme.palette.common.white, 0.85),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 1),
            border: '3px solid rgba(103,166,210, 0.5)',
        },
        '&:focus-within': {
            backgroundColor: alpha(theme.palette.common.white, 1),
            border: '3px solid rgba(103,166,210, 0.5)',
        },
        width: '80%',
        maxWidth: '600px',
        // width: 'auto',
        // [theme.breakpoints.up('sm')]: {
        //   width: '100%',
        // },
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 2),
        '&::placeholder': {
            fontWeight: '450'
        }
    },
    iconButton: {
        padding: 15
    },
    paper: {
        "& .MuiDialog-paper": {
            height: "88vh",
            marginTop: "20px",
            minWidth: "95%"
        },
        "& .MuiDialog-container": {
            width: "100%"
        }
    },
}));

const StlyedTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: theme.palette.common.white,
        // color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        letterSpacing: '0.3px',
        fontSize: 14,
        lineHeight: 1.3,
        fontWeight: 'normal',
        padding: '10px',
    },
}));

export interface SearchDialogProps {
    open: boolean,
    onClose: (value: string) => void,
    searchTerm: string
}

export default function SearchDialog(props: SearchDialogProps) {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [quickResults, setQuickResults] = useState<QuickSearchResult[]>([] as QuickSearchResult[]);
    const [deepResults, setDeepResults] = useState<DeepSearchResult[]>([] as DeepSearchResult[]);
    const [advanced, setAdvanced] = useState<boolean>(false);
    const [scraperOptions, setScraperOptions] = useState<any[]>([] as any[]);
    const [scrapers, setScrapers] = useState<Scraper[]>([] as Scraper[]);
    const [selectedScraper, setSelectedScraper] = useState<number>(0);
    const [deepSearchPageCount, setDeepSearchPageCount] = useState<number>(0);
    const [deepSearchPage, setDeepSearchPage] = useState<number>(1);
    const [quickSearchLoading, setQuickSearchLoading] = useState<boolean>(false);
    const [chartViewOpen, setChartViewOpen] = useState<boolean>(false);
    const [selectedResult, setSelectedResult] = useState<DataResult>({} as DataResult);
    const [selectedResultId, setSelectedResultId] = useState<string>('');
    const [selectedDeepSearch, setSelectedDeepSearch] = useState<any>(null);
    const [scraperLoading, setScraperLoading] = useState<boolean>(false);
    const [deepSearchLoading, setDeepSearchLoading] = useState<boolean>(false);
    const [selectedParams, setSelectedParams] = useState<string[]>([] as string[]);
    const [quickSearchError, setQuickSearchError] = useState<string>("");
    const [quickSearchPage, setQuickSearchPage] = React.useState(1);
    const [quickSearchPageCount, setQuickSearchPageCount] = useState<number>(0);
    const [deepSelectedLoading, setDeepSelectedLoading] = useState<boolean>(false);
    const[quickSelectedLoading, setQuickSelectedLoading] = useState<boolean>(false);
    const [moreInfoAnchorEl, setMoreInfoAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [moreInfoSelected, setMoreInfoSelected] = React.useState<QuickSearchResult | null>(null);
    const [pointsDownloaded, setPointsDownloaded] = React.useState<number>(0);
    const [totalPoints, setTotalPoints] = React.useState<number>(0);
    const [fetchDataSetError, setFetchDataSetError] = React.useState<string>("");
    const [progress, setProgress] = React.useState(0);
    const [deepSelectedLoadingPhrase, setDeepSelectedLoadingPhrase] = React.useState<string>("has very large datasets that can take up to 3 minutes to download. Please be patient");
    const [loadingEllipses, setLoadingEllipses] = React.useState<string>("   ");
    const [showAll, setShowAll] = React.useState<number[]>([] as number[]);
    const [selectedRow, setSelectedRow] = React.useState<number>(0);
    const controllerRef = useRef<AbortController | null>();
    const deepSearchRef = useRef<AbortController | null>()
    useEffect(() => {
        if (props.open === true) {

            setQuickSearchLoading(true);
            setSearchTerm(props.searchTerm);
            quickSearch(props.searchTerm, 0);
            if (scraperOptions.length === 0) {
                setScraperLoading(true);
                fetchScrapers();
            }

        }
    }, [props.open])

    useEffect(() =>{
        setTimeout(()=>{
            if(deepSelectedLoading === true)
            {
                var count = loadingEllipses.split('.');
                console.log(count);
                if(count.length === 1)
                {
                    setLoadingEllipses(".  ");
                }
                else if(count.length === 2){
                    setLoadingEllipses(".. ");
                }
                else if(count.length === 3)
                {
                    setLoadingEllipses("...");
                }
                else if(count.length === 4)
                {
                    setLoadingEllipses("   ");
                }
                
            }
            else{
                setDeepSelectedLoadingPhrase(" has very large datasets that can take up to 3 minutes to download. Please be patient");
            }
           }, 500)


    },[deepSelectedLoading, loadingEllipses])

    async function quickSearch(value: string, page: number) {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;
        var requestOptions = {
            headers: { 'Content-Type': 'application/json' },
            signal: controllerRef.current?.signal
        }
        try {
            await fetch(endpoint + "quickcache/?search_term=" + value + "&skip=" + page + "&limit=25", requestOptions)
                .then(async response => {
                    if (response.ok) {

                        var s = await response.json() as any;
                        setQuickSearchPageCount(s.pages % 1 > 0 ? s.pages - (s.pages % 1) : s.pages);
                        setQuickSearchError("");
                        setQuickResults(s.result);
                        setQuickSearchLoading(false);



                    } else {
                        var m = await response.json() as any;
                        throw Error(m.detail);
                    }
                    controllerRef.current = null;
                });
        }
        catch (error: any) {
            if (error.message === "No results found!") {
                setQuickSearchPageCount(0);
                setQuickSearchError("");
                setQuickResults([] as QuickSearchResult[]);
                setQuickSearchLoading(false);
                switchSearch();
            }
            if (error.message !== "The user aborted a request.") {
                console.log(error.message);
                setQuickSearchLoading(false);
                setQuickSearchError(error.message);
                switchSearch();
            }

        }
    }

    const classes = useStyles();

    const handleClose = () => {
        if (deepSearchRef.current) {
            deepSearchRef.current.abort();
        }
        setQuickResults([] as any[]);
        props.onClose("");
        setScraperLoading(false);
        setAdvanced(false);
        setDeepSearchLoading(false);
        setSelectedScraper(0);
        setQuickSearchPage(1);
        setQuickSearchPageCount(0);
        setDeepSearchPage(1);
        setDeepSearchPageCount(0);
        setSelectedRow(0);
        setDeepResults([] as DeepSearchResult[]);
    }

    const switchSearch = () => {
        setAdvanced(!advanced);
    }

    const fetchScrapers = async () => {
        var requestOptions = {
            headers: { 'Content-Type': 'application/json' },
        }
        try {
            await fetch(endpoint + "scrapers/active", requestOptions)
                .then(async response => {
                    if (response.ok) {
                        var s = await response.json() as Scraper[];
                        var opt = [] as any[];

                        s.forEach(sour1 => {
                            if (opt.length === 0 && sour1.category != null) {
                                opt.push({ value: -2, label: sour1.category });
                            }
                            else if (opt.filter(o => o.label === sour1.category).length === 0 && sour1.category != null) {
                                opt.push({ value: -2, label: sour1.category });
                            }
                        })
                        opt.push({ label: null, value: -2 });
                        var sortedScrap: any[] = s.sort((n1, n2) => {
                            if (n1.name > n2.name) {
                                return 1;
                            }

                            if (n1.name < n2.name) {
                                return -1;
                            }

                            return 0;
                        });
                        var sortedOpt: any[] = opt.sort((n1, n2) => {
                            if (n1.label > n2.label) {
                                return 1;
                            }

                            if (n1.label < n2.label) {
                                return -1;
                            }

                            return 0;
                        });
                        setScraperOptions(sortedOpt);
                        setScrapers(sortedScrap);
                        setScraperLoading(false);

                    }
                });
        }
        catch (error: any) {

        }
    }

    const handleChangeSelectedScraper = (id: number) => {
        setSelectedScraper(id);
        if (id !== selectedScraper) {
            setDeepSearchLoading(true);
            setSelectedParams([] as string[]);
            deepSearch(id.toString());
        }
    }

    async function deepSearch(id: string) {
        const controller = new AbortController();
        deepSearchRef.current = controller;
        setDeepSearchPage(1);
        setDeepSearchPageCount(0);
        var requestOptions = {
            headers: { 'Content-Type': 'application/json' },
            signal: deepSearchRef.current?.signal
        }
        try {
            await fetch(endpoint + "search/?id=" + id + "&search_term=" + searchTerm, requestOptions)
                .then(async response => {
                    if (response.ok) {
                        var s = await response.json() as DeepSearchResult[];
                        if (s.length % 25 !== 0) {
                            setDeepSearchPageCount((s.length - (s.length % 25)) / 25 + 1);
                        }
                        else {
                            setDeepSearchPageCount((s.length / 25));
                        }
                        setDeepResults(s);
                        setDeepSearchLoading(false);

                    } else {
                        throw Error(response.statusText);
                    }
                    deepSearchRef.current = null;
                });
        }
        catch (error: any) {
            setDeepResults([] as DeepSearchResult[]);
            setDeepSearchPage(0);
            setDeepSearchPageCount(0);
            setDeepSearchLoading(false);

        }
    }

    const handleSelectResult = async (value: any, row: string) => {
        setQuickSelectedLoading(true);
        setTotalPoints(value.data_values_count);
        var requestOptions = {
            headers: { 'Content-Type': 'application/json' },
            signal: controllerRef.current?.signal
        }
        console.log()
        if((value.data_values_count/ 41151) < 1)
        {
                  setProgress(Math.min(99,100));
                  setPointsDownloaded(Math.round(value.data_values_count * (Math.min(99, 100) / 100)));
        }
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
              if((value.data_values_count/ 41151) < 1)
              {
                  return Math.min(99,100);
              }
              if(oldProgress >= 97)
              {
                  var diff = (100 - oldProgress / 3)
              }
              else{
                var diff = 97  / (value.data_values_count/ 41151);
              }   
              if(diff + oldProgress <= 97)
              {
                setPointsDownloaded(Math.round(value.data_values_count * (Math.min(oldProgress + diff, 100) / 100)));
                return Math.min(oldProgress + diff, 100);
              } 
              else{
                  return Math.min(99,100);
              }
              
            });
          }, 500);
        try {
            await fetch(endpoint + "quickcache/" + value.id, requestOptions)
                .then(async response => {
                    if (response.ok) {
                        var s = await response.json() as any;
                        var index = 0;
                        if(row !== "")
                        {
                            index = Number(row.split("!!")[1]);
                            console.log(index);
                        }
                        var temp = Object.assign({}, s.search_results) as any;
                        setSelectedResult(temp);
                        setSelectedResultId(value.result_id);
                        setQuickSelectedLoading(false);
                        clearInterval(timer);
                        setProgress(0);
                        setPointsDownloaded(0);
                        setTotalPoints(0);
                        setSelectedRow(index);
                        setChartViewOpen(true);
                    } else {
                        setQuickSelectedLoading(false)
                        var m = await response.json() as any;
                        throw Error(m.detail);
                    }
                });
        }
        catch (error: any) {
            setQuickSelectedLoading(false)
            clearInterval(timer);
            setProgress(0);
            console.log(error);
        }

    }

    const handleCloseChartView = (submitted: boolean) => {
        setChartViewOpen(false);
        setSelectedResult({} as DataResult);
        setSelectedResultId('');
        if (submitted === true) {
            handleClose();
        }
    }

    const handleSelectDeepSearchResult = (value: DeepSearchResult) => {

        if (Object.keys(value.required_params).length > 0) {
            var params = [] as string[];
            Object.keys(value.required_params).map(p => {
                params.push(value.required_params[p][Object.keys(value.required_params[p])[0]]);
            })
            setSelectedParams(params);
            setSelectedDeepSearch(value.id);
        }
        else {
            fetchDeepSearchDataSet(value);
        }

    }

    const handleChangeParams = (event: SelectChangeEvent, index: number) => {
        var temp = Object.assign([], selectedParams) as string[];
        temp[index] = event.target.value;
        setSelectedParams(temp);
    };

    const fetchDeepSearchDataSet = async (value: DeepSearchResult) => {
        setDeepSelectedLoading(true);
        var params = Object.keys(value.required_params);
        var vals = {} as any
        params.forEach((p: string, i: number) => {
            vals[p] = [selectedParams[i]];
        })
        var j = {
            scraper_id: selectedScraper,
            parameters: vals,
            result_type: value.result_type,
            result_id: value.id,
            overwrite_cache: true,
            info: {
                search_term: searchTerm,
                search_name: value.name,
                search_description: value.description,
                search_other_metadata: value.other_metadata,
                scraper_id: selectedScraper
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },

            body: JSON.stringify(j)
        }
        try {
            await fetch(endpoint + "data/", requestOptions)
                .then(async response => {
                    if (response.ok) {
                        var r = await response.json() as DataResult;
                        setSelectedResult(r);
                        setSelectedResultId(r.result_id ? r.result_id : '');
                        setDeepSelectedLoading(false);
                        setChartViewOpen(true);
                    }
                    else {
                        var error = await response.json();
                        console.log(error.message)
                        setFetchDataSetError(error.message);
                        setDeepSelectedLoading(false);
                    }
                });
        }
        catch (error: any) {
            setFetchDataSetError("Error: Could not fetch data")
            setDeepSelectedLoading(false);
        }
    }

    const handleChangeQuickSearchPage = (event: React.ChangeEvent<unknown>, value: number) => {
        setQuickSearchLoading(true);
        setQuickSearchPage(value);
        quickSearch(searchTerm, value - 1)
    };

    const handleChangeDeepSearchPage = (event: React.ChangeEvent<unknown>, value: number) => {
        setDeepSearchPage(value);
        const titleElement = document.getElementById('deepSearchTitleRef') as HTMLElement;
        titleElement.scrollIntoView({ behavior: 'smooth' })

    };

    const handleOpenMoreInfo = (event: React.MouseEvent<HTMLButtonElement>, value: QuickSearchResult) => {
        setMoreInfoSelected(value);
        setMoreInfoAnchorEl(event.currentTarget);
    };

    const handleCloseMoreInfo = () => {
        setMoreInfoAnchorEl(null);
        setMoreInfoSelected(null);
    };

    const moreInfoOpen = Boolean(moreInfoAnchorEl);
    const moreInfoId = moreInfoOpen ? 'simple-popover' : undefined;

    const handleCloseFetchError = () => {
        setFetchDataSetError("");
    }

    const selectShowAll = (id: number) =>{
        var temp = Object.assign([], showAll) as number[];
        if(temp.includes(id))
        {
            temp = temp.filter(i => i !== id);
        }
        else{
            if(temp.length === 0)
            {
                temp = [id];
            }
            else
            {
                temp.push(id);
            }
        }
        console.log(temp);
        setShowAll(temp);
    }
    return (
        <React.Fragment><Dialog
            fullWidth={true}
            open={props.open}
            onClose={handleClose}
            className={classes.paper}
        >
            <DialogTitle>

                <Typography style={{ textAlign: "center" }} variant="h5">Add Data to Workspace<IconButton onClick={handleClose} style={{ float: "right", color: "#990000" }} aria-label="close" component="span">
                    <CloseIcon />
                </IconButton></Typography>
            </DialogTitle>
            {advanced === true ? <DialogContent style={{ display: "flex" }}>
                <div style={{ width: "30%", overflowY: "auto", paddingRight: "3px", paddingBottom: "5px" }}>
                    <Typography style={{ marginBottom: "10px", textAlign: "center" }} variant="h6">Sources</Typography>
                    {scraperLoading === true ? <div style={{ height: "55px", width: "55px", margin: "auto", marginTop: "15%" }}><CircularProgress style={{ color: "#004785" }} size={55} /></div> : scraperOptions.map((op: any, i: number) => {
                        if ((!endpoint.includes("prod") || op.label !== "Staging")) {
                            if (op.label != null && op.value === -2) {
                                var scraps = scrapers.filter(s => s.category === op.label);
                                return (<Accordion style={{ margin: "0px" }} key={i}>
                                    <AccordionSummary
                                        style={{ minHeight: "0px", margin: "0px" }}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={op.label}
                                        id={op.label}
                                    >
                                        <Typography><strong>{op.label}</strong></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ paddingTop: "0px", paddingBottom: "0px", paddingRight: "0px" }}>
                                        <List style={{ padding: "0px" }}>
                                            {scraps.map((s: Scraper, i: number) => {
                                                return (<StlyedTooltip 
                                                    // arrow
                                                    describeChild
                                                    placement="right"
                                                    title={s.description ? s.description : ''}
                                                >
                                                    <ListItemButton 
                                                        component="a" 
                                                        dense 
                                                        selected={selectedScraper === s.id} 
                                                        disabled={deepSearchLoading}
                                                        onClick={() => {
                                                            handleChangeSelectedScraper(s.id);
                                                        }}
                                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                                    >
                                                            <FormControlLabel 
                                                                control={<Radio
                                                                    name="radio-buttons"
                                                                    inputProps={{ 'aria-label': 'B' }}
                                                                />}
                                                                label={s.name}
                                                                checked={selectedScraper === s.id}
                                                            />
                                                        {s.source_url && <Tooltip arrow placement="top" title="Source Link">
                                                            <IconButton 
                                                                color="primary" 
                                                                aria-label="Source Link" 
                                                                href={s.source_url}
                                                                target="_blank"
                                                                rel="noopener"
                                                                onClick={(e: any) => { e.stopPropagation() }}
                                                            >
                                                                <LinkIcon />
                                                            </IconButton>
                                                        </Tooltip>}
                                                    </ListItemButton>
                                                </StlyedTooltip>)
                                            })}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>)
                            }
                            else {
                                var scraps = scrapers.filter(s => s.category === op.label);
                                return (
                                    <Paper>
                                        <List style={{ padding: "0px" }}>
                                            {scraps.map((s: Scraper, i: number) => {
                                                return (<StlyedTooltip 
                                                    // arrow
                                                    describeChild
                                                    placement="right"
                                                    title={s.description ? s.description : ''}
                                                >
                                                    <ListItemButton 
                                                        component="a" 
                                                        dense 
                                                        selected={selectedScraper === s.id} 
                                                        disabled={deepSearchLoading}
                                                        onClick={() => {
                                                            handleChangeSelectedScraper(s.id);
                                                        }}
                                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                                    >
                                                            <FormControlLabel 
                                                                control={<Radio
                                                                    name="radio-buttons"
                                                                    inputProps={{ 'aria-label': 'B' }}
                                                                />}
                                                                label={s.name}
                                                                checked={selectedScraper === s.id}
                                                            />
                                                        {s.source_url && <Tooltip arrow placement="top" title="Source Link">
                                                            <IconButton 
                                                                color="primary" 
                                                                aria-label="Source Link" 
                                                                href={s.source_url}
                                                                target="_blank"
                                                                rel="noopener"
                                                                onClick={(e: any) => { e.stopPropagation() }}
                                                            >
                                                                <LinkIcon />
                                                            </IconButton>
                                                        </Tooltip>}
                                                    </ListItemButton>
                                                </StlyedTooltip>)
                                            })}
                                        </List>
                                    </Paper>
                                )
                            }
                        }
                    })

                    }
                </div>
                <div style={{ width: "70%", overflowY: "auto" }}>
                    {selectedScraper !== 0 && <Typography style={{ marginLeft: "16px" }} id={"deepSearchTitleRef"} variant="h6">Select an Item</Typography>}
                    {deepSearchLoading === true ? <div style={{ height: "75px", width: "75px", margin: "auto", marginTop: "15%" }}><CircularProgress style={{ color: "#004785" }} size={75} /></div> :
                        (deepResults.length === 0 ? <div style={{ width: "100%", paddingTop: "15%", textAlign: "center" }}>{selectedScraper === 0 ? <Typography variant="h5">Select a Source to Continue</Typography> :
                            <Typography variant="h5">No Results Found</Typography>}</div> :
                            <List style={{ width: "100%" }}>
                                {deepResults.slice((deepSearchPage - 1) * 25, deepSearchPage * 25).map((r: DeepSearchResult, i: number) => {

                                    return (
                                        <ListItem style={{ display: "block", width: "100%" }} key={i}>
                                            <div style={{ width: "100%" }}><Button onClick={() => handleSelectDeepSearchResult(r)} style={{ maxWidth: "100%", textAlign: "left" }}>{r.name}</Button></div><div style={{ width: "100%", marginLeft: "15px" }}> <Typography style={{ width: "100%" }} variant="caption">{"\n" + r.description}</Typography></div>
                                            {r.required_params != null && selectedParams.length > 0 && <Collapse in={selectedDeepSearch === r.id}>
                                                <div style={{ textAlign: "center", marginTop: "15px" }}>
                                                    {Object.keys(r.required_params).map((p: string, i: number) => {
                                                        var labels = p.split("_");
                                                        labels[0] = labels[0].charAt(0).toUpperCase() + labels[0].slice(1)
                                                        var label = "";
                                                        labels.forEach((l: string, i: number) => {
                                                            label += ((i + 1) == labels.length ? l : l + " ");
                                                        })
                                                        return (
                                                            <FormControl key={i} style={{ width: "48%", marginLeft: (i % 2 === 1) ? "2%": "0", marginBottom: "12px" }} >
                                                                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    fullWidth
                                                                    onChange={(ev: any) => { handleChangeParams(ev, i) }}
                                                                    label={label}
                                                                    value={selectedParams[i]}
                                                                >
                                                                    {Object.keys(r.required_params[p]).map((o: any, j: number) => {
                                                                        return (

                                                                            <MenuItem key={j} value={r.required_params[p][o]}>{r.required_params[p][o]}</MenuItem>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            </FormControl>)
                                                    })}
                                                    <div style={{ width: "75%", textAlign: "right" }}><Button style={{ color: "#004785" }} onClick={() => fetchDeepSearchDataSet(r)}>Submit</Button></div>
                                                </div>
                                            </Collapse>}
                                            {/* {(resultData.name === r.name && resultData.series && resultData.series.length > 0) && <TimeSeriesChart 
                                            frequency={resultData.series[0].frequency}
                                            series={resultData.series}
                                            vizPage={false}
                                            // unitChange={seriesState.unitChangeSelected}
                                            // unitChangeFrom={seriesState.unitChangeFromSelected}
                                            unitChange="none"
                                            unitChangeFrom="none"
                                        />} */}
                                        </ListItem>
                                    )

                                })}
                            </List>)}
                    {deepSearchPageCount > 1 && <div ><Pagination sx={{ ul: { placeContent: "center" } }} count={deepSearchPageCount} page={deepSearchPage} onChange={handleChangeDeepSearchPage} /></div>}
                </div>
            </DialogContent> : <DialogContent >
                {quickSearchLoading === true ? <div style={{ height: "75px", width: "75px", margin: "auto", marginTop: "15%" }}><CircularProgress style={{ color: "#004785" }} size={75} /></div> :
                    (quickSearchError !== "" ? <div style={{ width: "100%", paddingTop: "15%", textAlign: "center" }}><Typography variant="h5">No Results Found</Typography></div> : <div style={{ width: "98%", marginLeft: "2%" }}>
                        <Typography variant="h6">Select an Item</Typography>
                        <List style={{ width: "70%" }}>
                            {quickResults.length > 0 && quickResults.map((r: QuickSearchResult, i: number) => {
                                var s = scrapers.filter(sc => sc.id === r.scraper_id)[0]
                                return (
                                    <ListItem style={{ display: "block" }} key={i}>
                                        <div style={{ width: "100%", display: "flex" }}>{r.params.result_type.toLocaleLowerCase().includes("map") && <Typography variant="overline" display="block" className="mapIcon" style={{ fontSize: '0.63em', padding: '0px 5px', marginRight: '10px', marginTop: "3px", width: "fit-content", maxHeight: "23px" }}>Map</Typography>}
                                            <Button onClick={() => handleSelectResult(r, "")} style={{ maxWidth: "100%", textAlign: "left", fontSize: "0.75em", width: "calc(100%,-25)" }} >{r.params.info.search_name}</Button>
                                            {Object.keys(r.params.parameters).length > 0 && <Typography style={{ fontSize: "0.7em", paddingTop: "6px" }} variant="caption">
                                                {Object.keys(r.params.parameters).map((k, i) => {
                                                    if (i + 1 === Object.keys(r.params.parameters).length) {
                                                        return (
                                                            r.params.parameters[k][0]
                                                        )
                                                    }
                                                    return (
                                                        r.params.parameters[k][0] + ", "
                                                    )
                                                })}
                                            </Typography>}
                                        </div>
                                        <div style={{ width: "100%", marginLeft: "15px", display: "block" }}>
                                        {r.cached_series != null && r.cached_series !== ""  && <React.Fragment><List style={{width: "100%"}}>{r.cached_series.split("||").map((s: string, i: number) =>{
                                                var length = r.cached_series.split("||")[0].length;
                                                var max = 3;
                                                var width = "100%";
                                                if(length < 12)
                                                {
                                                    width = "25%"
                                                    max = 8;
                                                }
                                                else if(length < 30)
                                                {
                                                    width = "33%"
                                                    max = 6;
                                                }
                                                else if(length < 80)
                                                {
                                                    width = "50%"
                                                    max = 4;
                                                }
                                                console.log(width);
                                                if((i < max || showAll.includes(r.id)) && r.cached_series.split("||").filter(c => s.split("!!")[0] === c.split("!!")[0]).length === 1)
                                                {
                                                    return (<ListItem key={i} style={{width: width, display: "inline-block", paddingTop: "0px", paddingBottom: "0px"}}>
                                                        <Button  onClick={() => handleSelectResult(r, s)}  style={{ maxWidth: "100%", textAlign: "left", fontSize: "0.7em" }} >{s.split("!!")[0]}</Button>
                                                        </ListItem>
                                                    )
                                                }

                                            })}
                                            </List>
                                            {r.cached_series.split("||").length > (r.cached_series.split("|")[0].length < 30 ? 6 : 3 ) &&  <div style={{width: "100%"}}>
                                                <Button onClick={()=>{selectShowAll(r.id)}}  style={{ maxWidth: "100%", textAlign: "left", fontSize: "0.7em", marginLeft: "16px" }} >
                                                    {showAll.includes(r.id) === false ? <React.Fragment>Show all<ArrowDropDownIcon /></React.Fragment> : <React.Fragment>Show Less<ArrowDropUpIcon /></React.Fragment>}</Button>
                                                </div>}
                                            </React.Fragment>}
                                        </div>
                                        <div style={{ width: "100%", marginLeft: "15px" }}>
                                            {s && <Typography style={{ fontStyle: 'italic' }} variant="caption">Source: {s.name}</Typography>}<br/>
                                            <Typography style={{ width: "100%", fontSize: "0.7em" }} variant="caption">{"\n" + r.params.info.search_description}</Typography>
                                        </div>
                                        {/* {(resultData.name === r.name && resultData.series && resultData.series.length > 0) && <TimeSeriesChart 
                                            frequency={resultData.series[0].frequency}
                                            series={resultData.series}
                                            vizPage={false}
                                            // unitChange={seriesState.unitChangeSelected}
                                            // unitChangeFrom={seriesState.unitChangeFromSelected}
                                            unitChange="none"
                                            unitChangeFrom="none"
                                        />} */}
                                    </ListItem>
                                )
                            })}
                        </List>
                        {quickSearchPageCount > 1 && <div ><Pagination sx={{ ul: { placeContent: "center" } }} count={quickSearchPageCount} page={quickSearchPage} onChange={handleChangeQuickSearchPage} /></div>}
                    </div>)}

            </DialogContent>}
            {advanced === true ? <DialogActions>
                {quickResults.length > 0 && <div style={{ textAlign: "right", marginRight: "15px" }} >
                    <Button onClick={switchSearch} >Back to quick search</Button>
                </div>}

            </DialogActions>
                : <DialogActions>
                    <div style={{ textAlign: "right", marginRight: "15px" }} >
                        <Typography variant="body1">Don't see what you're looking for? Use <Link onClick={switchSearch} style={{ cursor: "pointer" }}>Advanced Search</Link></Typography>
                    </div>
                </DialogActions>}
        </Dialog>
            {selectedResult.name !== undefined && <ChartViewDialog page={(selectedRow + 1) % 5 === 0 ? ((selectedRow + 1) / 5) - 1 : ((selectedRow + 1) - ((selectedRow + 1) % 5)) / 5} selectedRow={selectedRow} open={chartViewOpen} onClose={handleCloseChartView} data={selectedResult} result_id={selectedResultId} />}
            <Backdrop
                sx={{ color: '#fff', zIndex: "9999" }}
                open={quickSelectedLoading}
            >
                <Paper style={{width: "50%", height: "80px"}}>
                 <LinearProgress variant="determinate" value={progress} style={{width: "90%", marginTop: "35px", marginLeft: "5%", height: "10px"}} />
                 <Typography variant="caption">{pointsDownloaded.toLocaleString()} / {totalPoints.toLocaleString()} Data Points Downloaded</Typography>
                 </Paper>
            </Backdrop>
            <Backdrop 
                sx={{ color: '#fff', zIndex: "9999" }}
                open={deepSelectedLoading} 
            >
                <div style={{width: "100%"}}>
                 <CircularProgress size={60} color="inherit" />
                 {selectedScraper !== 0 && (scrapers.filter(s => s.id === selectedScraper)[0].name === "Census" || 
                 scrapers.filter(s => s.id === selectedScraper)[0].name === "OECD" || 
                 scrapers.filter(s => s.id === selectedScraper)[0].name === "DOLUI" ||
                 scrapers.filter(s => s.id === selectedScraper)[0].name === "ACS 1 Year" || 
                 scrapers.filter(s => s.id === selectedScraper)[0].name === "EIA V2" || 
                 scrapers.filter(s => s.id === selectedScraper)[0].name === "ACS 5 Year") && <Typography style={{whiteSpace: "pre"}} variant="body2">{ scrapers.filter(s => s.id === selectedScraper)[0].name + deepSelectedLoadingPhrase + loadingEllipses}</Typography>}
                 
                </div>

            </Backdrop>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={fetchDataSetError !== ""} autoHideDuration={6000} onClose={handleCloseFetchError}>
                <Alert onClose={handleCloseFetchError} severity="error" sx={{ width: '100%' }}>
                    {fetchDataSetError}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}
