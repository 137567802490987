import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { IconButton, DialogContent, CircularProgress, DialogActions, Tooltip, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { saveRecipe, loadRecipe, updateRecipeData } from '../thunks'
import SaveAsDialog from './SaveAsDialog'
import InfoIcon from '@mui/icons-material/Info';
const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
  containedRecipeBar: {
    marginLeft: '10px',
    marginTop: '5px',
    marginBottom: '5px', 
    backgroundColor: "#262460",
    '&:hover': {
      backgroundColor: alpha("#262460", 0.8),
    },
    color: "#fff",
  },
  containedRecipeBarNoSave: {
    marginLeft: '10px',
    marginTop: '5px',
    marginBottom: '5px', 
    backgroundColor: "#a8204e",
    '&:hover': {
      backgroundColor: alpha("#a8204e", 0.8),
    },
    color: "#fff",
  },
  loadingRoot: {
    width: "75px",
    height: "75px",
    color: "#004785"
  },
  dialogRoot: {
    // height: '100%',
    maxWidth: '800px'
  },
}));

export interface UnsavedWarningDialogProps {
  open: boolean,
  onClose: () => void,
  loadSessionId: string,
  loadSessionName: string,
  unsavedWarningNewModified: boolean,
  onMount: boolean,
}

export default function UnsavedWarningDialog(props: UnsavedWarningDialogProps) {
  const classes = useStyles();
  const { onClose, open, loadSessionId, loadSessionName, unsavedWarningNewModified, onMount } = props;
  const[messageOpen, setMessageOpen] = useState<boolean>(false);
  const[message, setMessage] = useState<string>("");
  const[messageStatus, setMessageStatus] = useState<boolean>(false);
  const[openSaveAs, setOpenSaveAs] = useState<boolean>(false);

  const dispatch = useDispatch()
  const sessionRecipeInfo = useSelector((state: any) => state.sessionRecipeInfo)
  const userInfo = useSelector((state: any) => state.userInfo)

  
  const handleClose = () => {
    if (onMount) {
      dispatch(updateRecipeData())
    }
    onClose();
  };

  const closeMessage = () =>{
    setMessageOpen(false);
    setMessage("");
  }

  return (
    <div>
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="md" fullWidth={true} scroll='paper' classes={{ paper: classes.dialogRoot }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle >Save your changes to {sessionRecipeInfo.id !== '' ? '"' + sessionRecipeInfo.name + '"' : '[ new workspace ]'}?</DialogTitle>
        {/* <Tooltip title="More info" arrow style={{ display: 'none' }}>
          <IconButton  
            style={{ flexGrow: 0, flexShrink: 0, color: '#326b9c', backgroundColor: '#ffffff', textDecoration: 'none', textDecorationColor: 'rgba(255,255,255,0)', margin: '16px 24px', padding: '0' }}  
            target="_blank" href="https://visualization-page.s3.amazonaws.com/How+to+Use+the+Visualization+Page_20210729.pdf#page=23"
          >
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
      </div>
      {unsavedWarningNewModified ? <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        Workspace has unsaved changes and new saved version of workspace available.
      </div> : sessionRecipeInfo.id === loadSessionId ? <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        Workspace has unsaved changes and attempting to load last saved version of workspace.
      </div> : <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        Workspace has unsaved changes. Save your current workspace before loading {(loadSessionName !== "" && loadSessionName !== "Unknown Name") ? loadSessionName : "requested workspace"}.
      </div>}
      <DialogContent style={{ boxSizing: 'border-box', alignSelf: "center", width: "90%", position: 'relative' }}>
        <div style={{ position: 'relative', width: '100%' }}>
          {(sessionRecipeInfo.loading) && <div style={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', zIndex: 1, backgroundColor: 'rgba(255,255,255,0.5)' }}><CircularProgress thickness={3} className={classes.loadingRoot} /></div>}
          <div style={{ display: 'flex', justifyContent: 'center', width: '90%', margin: '0px auto' }}>
            <Button classes={{ contained: classes.containedRecipeBar }} variant="contained" onClick={() => {
              if (sessionRecipeInfo.id === '' || !userInfo.recipeList.some((r: any) => r.id === sessionRecipeInfo.id)) {
                setOpenSaveAs(true)
              } else {
                // console.log(token)
                dispatch(saveRecipe(sessionRecipeInfo.name, userInfo.token, true, loadSessionName, loadSessionId))
              }
              onClose()
            }}>
              Save {sessionRecipeInfo.id === loadSessionId && " Over"}
            </Button>
            <Button classes={{ contained: classes.containedRecipeBar }} variant="contained" onClick={() => { 
              setOpenSaveAs(true);
              onClose()
            }}>
              Save {sessionRecipeInfo.id === loadSessionId ? " A Copy" : " As"}
            </Button>
            <Button classes={{ contained: classes.containedRecipeBarNoSave }} variant="contained" onClick={() => { 
              dispatch(loadRecipe(loadSessionName, loadSessionId))
              onClose()
            }}>
              Discard Changes
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '16px' }}>
        <Button onClick={handleClose} variant="contained" style={{ backgroundColor:"#c5093b", color: "white"}} >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
    <Snackbar anchorOrigin={{vertical: "top", horizontal:"center"}} open={messageOpen} autoHideDuration={6000} onClose={closeMessage}>
      <Alert onClose={closeMessage} severity={messageStatus === true? "success" : "error"}>
          {message}
      </Alert>
    </Snackbar>
    <SaveAsDialog open={openSaveAs} onClose={() => { setOpenSaveAs(false) }} loadOther={true} loadSessionId={loadSessionId} loadSessionName={loadSessionName} />
    </div>
  );
}