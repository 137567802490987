import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import LockIcon from '@mui/icons-material/Lock';
import {  InputBase, DialogContent, IconButton, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';

Auth.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: ' us-east-2',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_xLemYVNwY',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '1flh76qlg1no0gfji8c4pcjdje',


        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',

         // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'auth.pwbm-beta.net',
            scope: [ 'email',  'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});


const useStyles = makeStyles((theme: any) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  search: {
    alignItems: 'center',
    textAlign: "center",
    justifyContent: "space-between",
    position: 'relative',
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    borderColor: "gray",
    '&:hover': {
      borderColor: "#004785",
    },
    '&:focus-within': {
      borderColor: "#004785",
    },
    borderStyle: "solid",
    borderWidth: "1px"

  },
  iconButton: {
    padding: 10,
    color: "black"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 2),

  },
}));

export interface ConfirmPasswordDialogProps {
  open: boolean;
  onClose: (value: string) => void;
  oldPassword: string;
  error: string;
}

function ConfirmPasswordDialog(props: ConfirmPasswordDialogProps) {
  const classes = useStyles();
  const [pass, setPass] = useState<string>(props.oldPassword);
  const { onClose, open } = props;
  const [visible, setVisible]  = useState<string>("");
  


  function handleClose(){

    onClose("");
  }

  function handleSubmit(){
      onClose(pass);
  }



  function changePassword(s: string) {
    setPass(s);
  }

  function show()
  {
    setVisible("");
  }

  function hide()
  {
    setVisible("password");
  }

 

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle><div style={{ margin: "0px", padding: "20px 0px 5px calc(10% - 24px)", fontSize: '30px', fontWeight: 700 }}>Confirm New User Password</div></DialogTitle>
      <DialogContent style={{ alignSelf: "center", width: "80%", position: "relative", left: "-22px", padding: "0", margin: "5px 0px 20px 0px" }}>
      {props.error !== "" && <Alert style={{marginBottom: "15px", placeContent: "center"}} severity="error">Confirm Error: {props.error}</Alert>}
        <form className={classes.search} noValidate onSubmit={(e: React.SyntheticEvent) => {
           e.preventDefault();
           handleSubmit()}}>
          <div style={{ width: "100%", display: "flex" }}>
            <LockIcon className={classes.iconButton} />
            <InputBase
              placeholder="Password"
              name="password" 
              value={pass}
              onChange={(
                ev: React.ChangeEvent<HTMLInputElement>,
              ): void => changePassword(ev.target.value)}
              type={visible}
              id="password"
              autoComplete="current-password"
              onFocus={(ev: any): void => ev.target.select()}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'password' }}
            />
           {visible === "password" ? <IconButton style={{ position: 'absolute', right: '0', fontSize: '18px' }} onClick={show}><VisibilityIcon fontSize="inherit"/></IconButton> :
           <IconButton style={{ position: 'absolute', right: '0', fontSize: '18px' }} onClick={hide}><VisibilityOffIcon fontSize="inherit"/></IconButton>
           }    
          </div>
          <Typography variant="caption">**Please confirm your temporary password or set a new permanent password</Typography> 
          <Button type="submit" variant="contained" style={{ width: "30%", margin: "30px 0" }} color="primary">
            Set password
        </Button>
        </form>

      </DialogContent>

    </Dialog>
  );
}

export default ConfirmPasswordDialog;