import { ContentCutSharp, NoAccounts, Search } from '@mui/icons-material';
import { TextField, Checkbox, Autocomplete, createFilterOptions, CardContent, IconButton, InputBase, List, ListItem, Tooltip, Typography, alpha, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Button, InputLabel, Select, SelectChangeEvent, MenuItem, FormHelperText, ToggleButton, ToggleButtonGroup, Switch, CircularProgress, NativeSelect } from '@mui/material';
import { ThemeProvider, makeStyles, withStyles,  } from '@mui/styles';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { endpoint } from '../App';
import { DeepSearchResult } from '../Models/DeepSearchResult';
import { Scraper } from '../Models/Scraper';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import TimeSeriesChart from '../components/TimeSeriesChart';
import { stateInfo } from '../definitions/StateInfo';
import TimeSliderMap from '../components/TimeSliderMap';
import StatesBarChart from '../components/StatesBarChart';
import StatesScatterChart from '../components/StatesScatterChart';
import DataTable from '../components/DataTable';
import { setRecipeList, setMapPrimary } from '../thunks'
import { setVizView, setUnitChange, setUnitChangeFrom, setChartTableLocSelectedObj, setMapSecondaryId, setSideChartFixedAxis, setSideChartView, setMapShowAll, setMapShowAllSummary, setMapSelectedLocations } from '../actions'
import { Link } from 'react-router-dom';
import SourceIcon from '@mui/icons-material/Source';
const queryString = require('query-string'); 

const useStyles = makeStyles((theme: any) => ({
  loadingRoot: {
    color: "#004785"
  },
  input:{
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":{
      padding: "10px 10px"
    }
  },
  formControl: {
    marginTop: '20px',
    minWidth: 120,
    '&:not(:first-of-type)': {
      marginLeft: '15px'
    }
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      width: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        width: '100%'
      },
    },
  },
  showAllRoot: {
    marginTop: '8px'
  },
  showAllLabel: {
    fontSize: '0.87em',
    color: '#666666'
  },
  showAllCheckbox: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
}));

const Styledbutton = withStyles((theme: any) => ({
  root: {
    color: "black",
    backgroundColor: "white",
  },
  selected: {
    color: "white !important",
    backgroundColor: "#004785 !important"
  }
}))(ToggleButton);

export default function VisualizationPage(props: any) {
  const [mapDate, setMapDate] = useState<number>(0);
  const classes = useStyles();
  const loc = useLocation();
  const navigate = useNavigate();
 
  const dispatch = useDispatch()
  const sessionRecipe = useSelector((state: any) => state.sessionRecipe)
  const sessionRecipeInfo = useSelector((state: any) => state.sessionRecipeInfo)
  
  useEffect(() => {
    var urlSearch = queryString.parse(loc.search)

    if ((urlSearch.id !== undefined && urlSearch.id !== sessionRecipeInfo.id) || (urlSearch.id === undefined && sessionRecipeInfo.id !== '')) {
      if (sessionRecipeInfo.id === '') {navigate('/')} else {
        dispatch(setRecipeList())
        navigate({ 
          search: queryString.stringify({
            id: sessionRecipeInfo.id
          }, {
            skipNull: true,
            skipEmptyString: true
          })
        });
      }
    }
  }, [sessionRecipeInfo])
  
  const dataView = (sessionRecipeInfo && sessionRecipeInfo.meta && sessionRecipeInfo.meta.vizView) ? sessionRecipeInfo.meta.vizView : 'chart'
  const unitChangeSelected = (sessionRecipeInfo && sessionRecipeInfo.meta && sessionRecipeInfo.meta.unitChangeSelected) ? sessionRecipeInfo.meta.unitChangeSelected : 'none'
  const unitChangeFromSelected = (sessionRecipeInfo && sessionRecipeInfo.meta && sessionRecipeInfo.meta.unitChangeFromSelected) ? sessionRecipeInfo.meta.unitChangeFromSelected : 'prevPeriod'
  const chartTableLocOptionsObj = (sessionRecipeInfo && sessionRecipeInfo.meta && sessionRecipeInfo.meta.chartTableLocOptionsObj) ? sessionRecipeInfo.meta.chartTableLocOptionsObj : {} as any
  const chartTableLocSelectedObj = (sessionRecipeInfo && sessionRecipeInfo.meta && sessionRecipeInfo.meta.chartTableLocSelectedObj) ? sessionRecipeInfo.meta.chartTableLocSelectedObj : {} as any
  const mapPrimaryId: any = (sessionRecipeInfo && sessionRecipeInfo.meta && sessionRecipeInfo.meta.mapPrimaryId) ? sessionRecipeInfo.meta.mapPrimaryId : null
  const mapPrimary: any = mapPrimaryId !== null ? sessionRecipe.find((node: any) => node.id === mapPrimaryId) : null
  const mapPrimaryFrequency: any = (sessionRecipeInfo && sessionRecipeInfo.meta && sessionRecipeInfo.meta.mapPrimaryFrequency) ? sessionRecipeInfo.meta.mapPrimaryFrequency : null
  const mapSecondary: any = (mapPrimary !== null && mapPrimary !== undefined && mapPrimary.meta && mapPrimary.meta.chart_attributes && mapPrimary.meta.chart_attributes.mapSecondaryId) ? sessionRecipe.find((node: any) => node.id === mapPrimary.meta.chart_attributes.mapSecondaryId) : null
  const sideChartView: string = (mapPrimary !== null && mapPrimary !== undefined && mapPrimary.meta && mapPrimary.meta.chart_attributes && mapPrimary.meta.chart_attributes.sideChartView) ? mapPrimary.meta.chart_attributes.sideChartView : 'bar'
  const sideChartFixedAxis: boolean = (mapPrimary !== null && mapPrimary !== undefined && mapPrimary.meta && mapPrimary.meta.chart_attributes && mapPrimary.meta.chart_attributes.sideChartFixedAxis !== undefined) ? mapPrimary.meta.chart_attributes.sideChartFixedAxis : true
  const mapShowAll: boolean = (mapPrimary !== null && mapPrimary !== undefined && mapPrimary.meta && mapPrimary.meta.chart_attributes && mapPrimary.meta.chart_attributes.showAll !== undefined) ? mapPrimary.meta.chart_attributes.showAll : false
  const mapShowAllSummary: boolean = (mapPrimary !== null && mapPrimary !== undefined && mapPrimary.meta && mapPrimary.meta.chart_attributes && mapPrimary.meta.chart_attributes.showAllSummary !== undefined) ? mapPrimary.meta.chart_attributes.showAllSummary : true
  const mapSelectedLocationsObj: any[] = (mapPrimary !== null && mapPrimary !== undefined && mapPrimary.meta && mapPrimary.meta.chart_attributes && mapPrimary.meta.chart_attributes.selectedLocationsObj) ? mapPrimary.meta.chart_attributes.selectedLocationsObj : [] as any[]

  function setDataView(view: string) {
    dispatch(setVizView(view))
  }

  function setUnitChangeSelected(unitChange: string) {
    dispatch(setUnitChange(unitChange))
  }

  function setUnitChangeFromSelected(unitChangeFrom: string) {
    dispatch(setUnitChangeFrom(unitChangeFrom))
  }


  return (<div style={{ margin: '20px', position: 'relative' }}>
    {(sessionRecipeInfo.loading) && <div style={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', zIndex: 1100, backgroundColor: 'rgba(255,255,255,0.5)' }}><CircularProgress thickness={3.5} size={100} className={classes.loadingRoot} /></div>}
    <div>
      <div style={{display: "flex", alignItems: 'center', justifyContent: "space-between", width: "100%", margin: '10px auto'}}>
        <ToggleButtonGroup
          exclusive
          size="small"
        >
          <Styledbutton 
            value="chart" 
            selected={dataView === 'chart'}
            onClick={() => {
              setDataView('chart')
            }}
          >
            Chart
          </Styledbutton>
          <Styledbutton 
            value ="table" 
            selected={dataView === 'table'}
            onClick={() => { 
              setDataView('table')
            }}
          >
            Table
          </Styledbutton>
          <Styledbutton 
            value ="map" 
            selected={dataView === 'map'}
            disabled={mapPrimaryId === null}
            onClick={() => { 
              setDataView('map')
            }}
          >
            Map
          </Styledbutton>
        </ToggleButtonGroup>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <Link to={'/dataFiles'} style={{ textDecoration: 'none', textDecorationColor: 'rgba(255,255,255,0)', paddingRight: "10px" }}>
            <Button size="small" >
              <SourceIcon style={{ marginRight: '7px' }} /> Data Files
            </Button>
          </Link>
          <Link to={'/videos'} style={{ textDecoration: 'none', textDecorationColor: 'rgba(255,255,255,0)' }}>
            <Button size="small" >
              <OndemandVideoIcon style={{ marginRight: '7px' }} /> Tutorials
            </Button>
          </Link>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }}>
        {dataView === 'chart' && <div>
          <FormControl style={{ minWidth: '165px', marginRight: '15px', marginTop: '15px' }}>
            <InputLabel id="unit-change-select-label">Unit Change</InputLabel>
            <Select
              labelId="unit-change-select-label"
              id="unit-change-select"
              value={unitChangeSelected}
              label="Unit Change"
              onChange={(event: SelectChangeEvent) => {
                setUnitChangeSelected(event.target.value as string);
              }}
            >
              <MenuItem value='none'>None</MenuItem>
              <MenuItem value='change'>Change</MenuItem>
              <MenuItem value='percentChange'>Percent Change</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ minWidth: '211px', marginRight: '15px', marginTop: '15px' }}>
            <InputLabel id="unit-change-from-select-label">Unit Change From</InputLabel>
            <Select
              labelId="unit-change-from-select-label"
              id="unit-change-from-select"
              value={unitChangeFromSelected}
              disabled={unitChangeSelected === 'none'}
              label="Unit Change From"
              onChange={(event: SelectChangeEvent) => {
                setUnitChangeFromSelected(event.target.value as string);
              }}
            >
              <MenuItem value='prevPeriod'>Previous Period</MenuItem>
              <MenuItem value='rangeShown'>Start of Range Shown*</MenuItem>
              <MenuItem value='rangeStart'>Start of Total Range*</MenuItem>
            </Select>
            {unitChangeFromSelected !== 'prevPeriod' && <FormHelperText>* First non-null, non-zero value</FormHelperText>}
          </FormControl>
        </div>}
        {((dataView === 'chart' || dataView === 'table') && sessionRecipe.some((x: any) => x.displayed && x.result.maps !== null && x.result.maps.length > 0 && x.meta.chart_attributes !== undefined && x.meta.chart_attributes.show)) && Object.keys(chartTableLocOptionsObj).map((map_scale: string) => <Autocomplete
          // className={classes.formControl}
          key={'locSelector-' + map_scale}
          style={{ flexGrow: 1, maxWidth: '500px', marginRight: '15px', marginTop: '15px' }}
          fullWidth={true}
          autoHighlight={true}
          multiple
          options={chartTableLocOptionsObj[map_scale]}
          value={chartTableLocSelectedObj[map_scale]}
          isOptionEqualToValue={(l: any, o: any) => l.locationCode === o.locationCode && (l.locationCode !== '-1' || l.location === o.location)}
          // disabled={!sessionRecipe.some((x: any) => x.displayed && x.result.maps !== null && x.result.maps.length > 0 && x.meta.chart_attributes !== undefined && x.meta.chart_attributes.show)}
          size='small'
          disableCloseOnSelect
          limitTags={10}
          filterOptions={createFilterOptions({
            stringify: (option: any) => {
              return (option.locationCode === '-1' || map_scale === 'city' || map_scale === 'county') ? option.location : stateInfo[option.locationCode] === undefined ? option.locationCode + ' - ' + option.location : stateInfo[option.locationCode].abbreviation + ' - ' + stateInfo[option.locationCode].name
            },
          })}
          getOptionLabel={(option: any) => (option.locationCode === '-1' || map_scale === 'city' || map_scale === 'county') ? option.location : stateInfo[option.locationCode] === undefined ? option.locationCode : stateInfo[option.locationCode].abbreviation}
          onChange={(event: any, value: any[]) => {
            // console.log(value)
            dispatch(setChartTableLocSelectedObj(map_scale, [...value]))
          }}
          renderOption={(props: any, option: any, { selected }) => (
            <li {...props}>
              <Checkbox
                size='small'
                checked={selected}
              />
              <span style={{ fontSize: '0.95em' }}>{(option.locationCode === '-1' || map_scale === 'city' || map_scale === 'county') ? option.location : stateInfo[option.locationCode] === undefined ? option.locationCode + ' - ' + option.location : stateInfo[option.locationCode].abbreviation + ' - ' + stateInfo[option.locationCode].name}</span>
            </li>
          )}
          renderInput={(params) => (<div>
            <TextField
              {...params}
              inputProps={{ 
                ...params.inputProps, 
                // autoComplete: 'off',
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              variant="standard"
              label=""
              size='small'
              placeholder={"Select locations to show from " + map_scale + "-level maps*"}
            />
            <FormHelperText>* Only impacts {map_scale}-level maps.</FormHelperText>
          </div>)}
        />)}
      </div>
      {dataView === 'chart' && <TimeSeriesChart
        vizPage={true}
        frequency={'All'}
        series={[]}
        mapSeries={[]}
        sessionRecipe={sessionRecipe}
        locSelectedObj={chartTableLocSelectedObj}
        unitChange={unitChangeSelected}
        unitChangeFrom={unitChangeFromSelected}
      />}
      {dataView === 'table' && <div style={{ marginTop: '10px' }}>
        <DataTable 
          vizDataNodes={sessionRecipe} 
          selectable={false} 
          showLocation={true}
          vizPage={true}
          locSelectedObj={chartTableLocSelectedObj}
          selected={null}
        />
      </div>}
      {dataView === 'map' && <div style={{ width: '100%' }}>
        <div className={classes.mapContainer}>
          <div style={{ margin: '29px 15px 20px 0px' }}> {/* 117 */}
            <FormControl variant="standard" className={classes.formControl} style={{ width: '100%', maxWidth: '600px', textAlign: 'left', marginBottom: '20px' }}>
              <InputLabel id="map-primary-select-label">Select Map</InputLabel>
              <Select
                labelId="map-primary-select-label"
                id="map-primary-select"
                value={(mapPrimaryId === null) ? '' : mapPrimaryId}
                label="Select Map"
                renderValue={(value:any) => {
                  var map = sessionRecipe.find((m: any) => m.displayed && m.id === value)
                  if (map !== undefined) {
                    var name = map.name
                    if (mapPrimaryFrequency !== null) {
                      name += ', ' + mapPrimaryFrequency
                    }
                    return name
                  } else {
                    dispatch(setMapPrimary(null))
                  }
                  return value
                }}
                // displayEmpty={true}
                onChange={(event: any) => {
                  var value = event.target.value
                  if (sessionRecipe.some((m: any) => m.id === value)) {
                    dispatch(setMapPrimary(value))
                  } else {
                    dispatch(setMapPrimary(null))
                  }
                }}
              >
                {(sessionRecipe.length > 0 && sessionRecipe.some((o: any) => o.displayed && o.result.maps !== null && o.result.maps !== undefined)) 
                  ? sessionRecipe.map((m: any, i: number) => { 
                    var output = [] as any[]
                    if (m.displayed && m.result.maps !== null && m.result.maps !== undefined && m.result.maps.length > 0) {
                      output.push(<MenuItem value={m.id} key={m.id + 'primaryMap'}>
                        <div className="mapIcon mapIcon_inSelect">
                          <div className="mapIcon_map">Map</div>
                          {m.meta.chart_attributes.map_scale && <div>{(m.meta.chart_attributes.map_location_abbreviation !== '') ? m.meta.chart_attributes.map_location_abbreviation : m.meta.chart_attributes.map_scale}</div>}
                        </div>
                        {m.name}{m.result.maps[0].children.length > 0 && ', ' + m.result.maps[0].children[0].frequency}
                      </MenuItem>)
                    }
                    return output
                  })
                  : <MenuItem value=""><em>No maps available.</em></MenuItem>
                }
              </Select>
            </FormControl>
            <TimeSliderMap 
              key={'map' + ((mapPrimary !== null && mapPrimary !== undefined) && mapPrimary.id)}
              seriess={(mapPrimary !== null && mapPrimary !== undefined && mapPrimary.result.maps !== null) ? mapPrimary.result.maps[0].children : [] as any[]}
              seriesName={(mapPrimary !== null && mapPrimary !== undefined) ? mapPrimary.name : 'Unnamed Map'}
              frequency={mapPrimaryFrequency !== null ? mapPrimaryFrequency : 'NotSet'}
              unitSuffix={''}
              posNegColorAxis={true}
              colorAxisCrossoverPoint={0}
              sessionRecipeInfo={sessionRecipeInfo}
              yPlotLines={[{
                color: '#bbbbbb',
                width: 2,
                value:  0
              }]}
              onMapDateChange={setMapDate}
              // mapDate={mapDate}
              map_scale={(mapPrimary !== null && mapPrimary !== undefined && mapPrimary.result.maps !== null && mapPrimary.meta.chart_attributes.map_scale) ? mapPrimary.meta.chart_attributes.map_scale : 'state'}
              summary_row={(mapPrimary !== null && mapPrimary !== undefined && mapPrimary.result.maps !== null) ? mapPrimary.result.maps[0].summary_row : null}
            />
          </div>
          <div style={{ paddingTop: '10px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ToggleButtonGroup
                exclusive
                size="small"
              >
                <Styledbutton 
                  value="bar" 
                  selected={sideChartView === 'bar'}
                  onClick={() => {
                    dispatch(setSideChartView(mapPrimaryId, 'bar'))
                  }}
                >
                  Bar
                </Styledbutton>
                <Styledbutton 
                  value ="scatter" 
                  selected={sideChartView === 'scatter'}
                  onClick={() => { 
                    dispatch(setSideChartView(mapPrimaryId, 'scatter'))
                  }}
                >
                  Scatter
                </Styledbutton>
                <Styledbutton 
                  value ="map" 
                  selected={sideChartView === 'map'}
                  onClick={() => { 
                    dispatch(setSideChartView(mapPrimaryId, 'map'))
                  }}
                >
                  Map
                </Styledbutton>
              </ToggleButtonGroup>
              {(sideChartView === 'scatter' || sideChartView === 'bar') && <FormControlLabel
                control={
                  <Switch
                    checked={sideChartFixedAxis}
                    disabled={mapPrimary === null || mapPrimary === undefined}
                    onChange={(event: any) => {
                      dispatch(setSideChartFixedAxis(mapPrimaryId, event.target.checked))
                    }}
                    name="fixed-axis"
                    color="primary"
                  />
                }
                label="Fixed Axis"
              />}
            </div>
            {(sideChartView === 'scatter' || sideChartView === 'bar') && <div style={{ padding: '10px 0px', textAlign: 'left' }}>
              <Autocomplete
                fullWidth={true}
                autoHighlight={true}
                multiple
                options={(mapPrimary !== undefined && mapPrimary !== null && mapPrimary.meta && mapPrimary.meta.chart_attributes && mapPrimary.meta.chart_attributes.locationsObj) ? mapPrimary.meta.chart_attributes.locationsObj : [] as any[]}
                value={mapSelectedLocationsObj as any[]}
                disabled={(mapPrimary === undefined || mapPrimary === null) || mapShowAll}
                size='small'
                disableCloseOnSelect
                limitTags={10}
                filterOptions={createFilterOptions({
                  stringify: (option: any) => {
                    // console.log(option)
                    return (option.locationCode === '-1' || mapPrimary.meta.chart_attributes.map_scale === 'city' || mapPrimary.meta.chart_attributes.map_scale === 'county') ? option.location : stateInfo[option.locationCode] === undefined ? option.locationCode + ' - ' + option.location : stateInfo[option.locationCode].abbreviation + ' - ' + stateInfo[option.locationCode].name
                  },
                })}
                getOptionLabel={(option: any) => (option.locationCode === '-1' || mapPrimary.meta.chart_attributes.map_scale === 'city' || mapPrimary.meta.chart_attributes.map_scale === 'county') ? option.location : stateInfo[option.locationCode] === undefined ? option.locationCode : stateInfo[option.locationCode].abbreviation}
                onChange={(event: any, value: any[]) => {
                  dispatch(setMapSelectedLocations(mapPrimaryId, [...value]))
                }}
                isOptionEqualToValue={(l: any, o: any) => l.locationCode === o.locationCode && (l.locationCode !== '-1' || l.location === o.location)}
                renderOption={(props: any, option: any, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      size='small'
                      checked={selected}
                    />
                    <span style={{ fontSize: '0.95em' }}>{(option.locationCode === '-1' || mapPrimary.meta.chart_attributes.map_scale === 'city' || mapPrimary.meta.chart_attributes.map_scale === 'county') ? option.location : stateInfo[option.locationCode] === undefined ? option.locationCode + ' - ' + option.location : stateInfo[option.locationCode].abbreviation + ' - ' + stateInfo[option.locationCode].name}</span>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField 
                    {...params}
                    inputProps={{ 
                      ...params.inputProps, 
                      // autoComplete: 'off',
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    variant="standard" 
                    label="" 
                    size='small'
                    placeholder="Select locations to show from your maps" 
                  />
                )}
              />
              <div style={{ display: 'flex' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{ root: classes.showAllCheckbox }}
                      checked={mapShowAll}
                      disabled={mapPrimary === undefined || mapPrimary === null}
                      onChange={(event: any) => {
                        dispatch(setMapShowAll(mapPrimaryId, event.target.checked))
                      }}
                      color="primary"
                      size="small"
                    />
                  }
                  label="Show all"
                  classes={{ 
                    root: classes.showAllRoot,
                    label: classes.showAllLabel
                  }}
                  style={{marginRight: '10px'}}
                />
                {(mapPrimary !== undefined && mapPrimary !== null && mapPrimary.result.maps !== null && mapPrimary.result.maps.length > 0 && mapPrimary.result.maps[0].summary_row !== null) &&
                  <FormControl style={{ marginTop: '3px' }}>
                    <NativeSelect
                      value={mapShowAllSummary ? 'includeSummary' : 'innerOnly'}
                      style={{ fontSize: '13px' }}
                      disabled={mapPrimary === undefined || mapPrimary === null || !mapShowAll} //!mapPrimary.chart_attributes.showAll}
                      onChange={(event: any) => {
                        var includeSummary = event.target.value === 'includeSummary'
                        dispatch(setMapShowAllSummary(mapPrimaryId, includeSummary))
                      }}
                    >
                      <option value="includeSummary">
                        {(mapPrimary.meta.chart_attributes.map_scale && mapPrimary.meta.chart_attributes.map_scale === 'country') ? 'Countries & World' : (mapPrimary.meta.chart_attributes.map_scale && mapPrimary.meta.chart_attributes.map_scale === 'county') ? 'Counties & ' + mapPrimary.meta.chart_attributes.map_location_abbreviation : (mapPrimary.meta.chart_attributes.map_scale && mapPrimary.meta.chart_attributes.map_scale === 'city') ? 'Cities & USA' : 'States & USA'}
                      </option>
                      <option value="innerOnly">
                        {(mapPrimary.meta.chart_attributes.map_scale && mapPrimary.meta.chart_attributes.map_scale === 'country') ? 'Countries Only' : (mapPrimary.meta.chart_attributes.map_scale && mapPrimary.meta.chart_attributes.map_scale === 'county') ? 'Counties Only' : (mapPrimary.meta.chart_attributes.map_scale && mapPrimary.meta.chart_attributes.map_scale === 'city') ? 'Cities Only' : 'States Only'}
                      </option>
                    </NativeSelect>
                  </FormControl>
                }
              </div>
            </div>}
            {(sideChartView === 'scatter' || sideChartView === 'map') && <FormControl variant="standard" style={{ width: '100%', marginBottom: '20px', textAlign: 'left' }}>
              <InputLabel id="map-secondary-select-label">{sideChartView === 'scatter' ? 'Y-Values' : 'Select Map'}</InputLabel>
              <Select
                labelId="map-secondary-select-label"
                id="map-secondary-select"
                value={(mapSecondary !== null && mapSecondary !== undefined) ? mapSecondary.id : ''}
                label={sideChartView === 'scatter' ? 'Y-Values' : 'Select Map'}
                disabled={mapPrimary === undefined || mapPrimary === null}
                renderValue={(value:any) => {
                  var map = sessionRecipe.find((m: any) => m.displayed && m.id === value)
                  if (map !== undefined) {
                    var name = map.name
                    if (map.displayed && map.result.maps !== null && map.result.maps !== undefined && map.result.maps.length > 0 && map.id !== mapPrimary.id && map.result.maps[0].children.length > 0) {
                      name += ', ' + map.result.maps[0].children[0].frequency
                    }
                    return name
                  } else {
                    dispatch(setMapSecondaryId(mapPrimary.id, null))
                  }
                  return value
                }}
                // displayEmpty={true}
                onChange={(event: any) => {
                  // console.log(event.target.value)
                  var map = sessionRecipe.find((m: any) => m.id === event.target.value)
                  if (map !== undefined) {
                    dispatch(setMapSecondaryId(mapPrimary.id, map.id))
                  } else {
                    dispatch(setMapSecondaryId(mapPrimary.id, null))
                  }
                }}
              >
                {((sessionRecipe.length > 0 && mapPrimary !== undefined && mapPrimary !== null && mapPrimary.result.maps !== null && mapPrimary.result.maps.length > 0) && sessionRecipe.some((m: any) => m.displayed && m.result.maps !== null && m.result.maps !== undefined && m.result.maps.length > 0 && m.id !== mapPrimary.id && m.result.maps[0].children.length > 0 && m.result.maps[0].children[0].frequency === mapPrimaryFrequency && (sideChartView === 'map' || m.result.maps[0].map_scale === mapPrimary.meta.chart_attributes.map_scale))) 
                  ? sessionRecipe.map((m: any, i: number) => { 
                    var output = [] as any[]
                    if (m.displayed && m.result.maps !== null && m.result.maps !== undefined && m.result.maps.length > 0 && m.id !== mapPrimary.id && m.result.maps[0].children.length > 0 && m.result.maps[0].children[0].frequency === mapPrimaryFrequency && (sideChartView === 'map' || (m.result.maps[0].map_scale === mapPrimary.meta.chart_attributes.map_scale && m.meta.chart_attributes.map_location_abbreviation === mapPrimary.meta.chart_attributes.map_location_abbreviation))) {
                      output.push(<MenuItem value={m.id} key={m.id + 'secondaryMap'}>
                        <div className="mapIcon mapIcon_inSelect">
                          <div className="mapIcon_map">Map</div>
                          {m.meta.chart_attributes.map_scale && <div>{(m.meta.chart_attributes.map_location_abbreviation !== '') ? m.meta.chart_attributes.map_location_abbreviation : m.meta.chart_attributes.map_scale}</div>}
                        </div>
                        {m.name}, {m.result.maps[0].children[0].frequency}
                      </MenuItem>)
                    }
                    return output
                  })
                  : <MenuItem value=""><em>No maps available.</em></MenuItem>
                }
              </Select>
            </FormControl>}
            <div style={{ flexGrow: 1 }}>
              {sideChartView === 'bar' && <StatesBarChart
                // key={this.state.mapStringSelected}
                forwardedRef={React.createRef()}
                // mapSeries={(mapSecondary !== null && mapSecondary !== undefined) ? [mapSecondary] : [] as any[]} // empty array
                mapSeries={[] as any[]} // empty array
                mapPrimary={(mapPrimary !== undefined && mapPrimary !== null) ? {
                  ...mapPrimary.result,
                  name: mapPrimary.name,
                  chart_attributes: { showAll: mapShowAll, showStates: mapSelectedLocationsObj }
                } : null}
                frequency={mapPrimaryFrequency !== null ? mapPrimaryFrequency : 'NotSet'}
                dateUTC={mapDate}
                fixedAxis={sideChartFixedAxis}
                includeSummary={mapShowAllSummary}
              />}
              {sideChartView === 'scatter' && <StatesScatterChart
                // key={this.state.mapStringSelected}
                forwardedRef={React.createRef()}
                mapSeries={(mapSecondary !== null && mapSecondary !== undefined) ? [{
                  ...mapSecondary.result,
                  name: mapSecondary.name,
                  chart_attributes: {}
                }] : [] as any[]}
                mapPrimary={(mapPrimary !== undefined && mapPrimary !== null) ? {
                  ...mapPrimary.result,
                  name: mapPrimary.name,
                  chart_attributes: { showAll: mapShowAll, showStates: mapSelectedLocationsObj }
                } : null}
                frequency={mapPrimaryFrequency !== null ? mapPrimaryFrequency : 'NotSet'}
                dateUTC={mapDate}
                fixedAxis={sideChartFixedAxis}
                includeSummary={mapShowAllSummary}
              />}
              {sideChartView === 'map' && <TimeSliderMap 
                key={'map' + ((mapSecondary !== null && mapSecondary !== undefined) && mapSecondary.id)}
                seriess={(mapSecondary !== null && mapSecondary !== undefined && mapSecondary.result.maps !== null) ? mapSecondary.result.maps[0].children : [] as any[]}
                seriesName={(mapSecondary !== null && mapSecondary !== undefined) ? mapSecondary.name : 'Unnamed Map'}
                frequency={mapPrimaryFrequency !== null ? mapPrimaryFrequency : 'NotSet'}
                unitSuffix={''}
                posNegColorAxis={true}
                colorAxisCrossoverPoint={0}
                sessionRecipeInfo={sessionRecipeInfo}
                yPlotLines={[{
                  color: '#bbbbbb',
                  width: 2,
                  value:  0
                }]}
                // onMapDateChange={setMapDate}
                mapDate={mapDate}
                map_scale={(mapSecondary !== null && mapSecondary !== undefined && mapSecondary.result.maps !== null && mapSecondary.meta.chart_attributes.map_scale) ? mapSecondary.meta.chart_attributes.map_scale : 'state'}
                summary_row={(mapSecondary !== null && mapSecondary !== undefined && mapSecondary.result.maps !== null) ? mapSecondary.result.maps[0].summary_row : null}
              />}
            </div>
          </div>
        </div>
      </div>}
    </div>

  </div>);
}
