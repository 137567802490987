import React, { useEffect, useRef, ReactElement } from 'react';
import { createStyles, Slider, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ReplayIcon from '@mui/icons-material/Replay';
import * as Highcharts from 'highcharts/highmaps';
import NoData from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
// import { TableSeries } from '../SeriesPage/SeriesPage';
import { frequencySettings } from '../definitions/FrequencySettings';
// import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ReactDOMServer from 'react-dom/server';
import { stateInfo } from '../definitions/StateInfo';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { relative } from 'path';

const lodash = require('lodash');

const styles = (theme: any) => createStyles({
  colorPrimary: {
    color: '#026cb5',
  },
  rail: {
    height: '7px'
  },
  track: {
    height: '7px'
  },
  mark: {
    height: '7px'
  },
  thumb: {
    marginTop: '-1px',
    width: '15px',
    height: '15px',
    color: '#999'
  },
  playPauseContainedSecondary: {
    color: '#026cb5',
  },
  playPauseIcon: {
    margin: '0px'
  },
  playPauseLabel: {
    width: '25px',
  },
  playPauseRoot: {
    padding: '6px',
    marginRight: '10px',
    minWidth: 'initial',
  },
  mapContainer: {
    width: "100%",
    height: "500px",
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      height: '400px',
    },
  }
})

const renderWrapper = (status: Status) => {
  // if (status === Status.LOADING) return <h3>{status} ..</h3>;
  // if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <h3>{status} ...</h3>;
};

function MyMapComponent({
  mapData,
  dataObject,
  posNegColorAxis,
  reverseColorAxis,
  colorAxisCrossoverPoint,
  map_scale,
  unitSuffix,
  classes
}: {
  mapData: any,
  dataObject: any,
  posNegColorAxis?: boolean,
  reverseColorAxis?: boolean,
  colorAxisCrossoverPoint?: number,
  map_scale?: string,
  unitSuffix: string,
  classes: any,
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [mouseLoc, setMouseLoc] = React.useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [tooltipLocationCode, setTooltipLocationCode] = React.useState('');
  const [tooltipLocation, setTooltipLocation] = React.useState('');
  const [tooltipValue, setTooltipValue] = React.useState('');
  const [tooltipColor, setTooltipColor] = React.useState('#bbb');
  const [tooltipPercent, setTooltipPercent] = React.useState<number | null>(null);
  const [colorAxisStops, setColorAxisStops] = React.useState<number[]>([0, 1] as number[]);
  const [colorAxisColors, setColorAxisColors] = React.useState<any>({
    0: { r: 252, g: 252, b: 254 },
    100: { r: 40, g: 47, b: 133 } 
  } as any);
  const [colorAxisTicks, setColorAxisTicks] = React.useState<number[]>([] as number[]);
  
  // TODO: export image of map. name is name of series
  // var exportFilename: string = '';
  // var seriesName = dataObject.name
  // if (seriesName.length > 50) {
  //   exportFilename += seriesName.substring(0,30) + ' . . . '
  //   exportFilename += seriesName.substring(seriesName.length - 10, seriesName.length)
  // } else {
  //   exportFilename += seriesName
  // }
  // // exportFilename += ' (' + frequency + ')'
  // exportFilename += ' - ' + dataObject.dateString

  // exportFilename += ' - Map'

  // TODO: more responsive map height
  // height: (500 / 700) * 100 + '%',

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        center: { lat: 40, lng: -100 },
        zoom: 3,
        gestureHandling: 'cooperative', // 'greedy' disables ctrl + scroll to zoom
        streetViewControl: false,
        fullscreenControl: false,
        styles: [{
          stylers: [{ visibility: "off" }],
        }, {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [{ visibility: "simplified" }, { "saturation": -60 }, { "lightness": 50 }],
        }, {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ visibility: "on" }, { color: "#bfd4ff" }],
        }, {
          featureType: "administrative.country",
          elementType: "geometry",
          stylers: [{ visibility: 'on' }], // (map_scale === 'city' || map_scale === 'county') ? "on" : "off" }],
        }, {
          featureType: "administrative.province",
          elementType: "geometry",
          stylers: [{ visibility: (map_scale !== 'country') ? "on" : "off" }],
        // }, {
        //   featureType: "administrative",
        //   elementType: "labels",
        //   stylers: [{ visibility: map_scale === 'city' ? "on" : "off" }],
        }] as google.maps.MapTypeStyle[]
      }));

    }
  }, [ref, map]);

  useEffect(() => {
    if (map && mapData) {
      // console.log(mapData)
      // NOTE: This uses cross-domain XHR, and may not work on older browsers.
      map.data.addGeoJson(mapData);
      zoom(map);

      // currently in beta, should add when we 
      // console.log(map.getFeatureLayer(google.maps.FeatureType.COUNTRY))

      // set up the style rules and events for google.maps.Data
      map.data.setStyle(map_scale === 'city' ? styleFeatureBubble : styleFeature);
      map.data.addListener("mouseover", mouseInToRegion);
      map.data.addListener("mouseout", mouseOutOfRegion);
    }
  }, [map, mapData]);

  useEffect(() => {
    if (map) {
      clearMapData()
      if (dataObject) {
        // console.log(dataObject)
        dataObject.data.forEach((v: any) => {
          var state = map.data.getFeatureById(v.locationCode);
          // update the existing row with the new data
          if (state) {
            state.setProperty("value", v.value);
          }
        })
        if (showTooltip) {
          if (tooltipLocationCode !== '') {
            var state = map.data.getFeatureById(tooltipLocationCode);
            if (state) {
              var value = state.getProperty('value')
              if (value !== undefined && value !== null) {
                setTooltipValue(formatNumberDecimal(value))
                setTooltipColor(state.getProperty('color'))
                setTooltipPercent(state.getProperty('percent') === undefined ? null : state.getProperty('percent'))
              } else {
                setTooltipValue('')
                setTooltipColor(state.getProperty('color'))
                setTooltipPercent(null)
              }
            }
          }
        }
        if (map_scale === 'city') {
          map.data.setStyle(styleFeatureBubble);
        } else {
          var stops = [0, 100] as number[]
          var colors = {
            0: { r: 252, g: 252, b: 254 },
            100: { r: 40, g: 47, b: 133 } 
          } as any;
          var tickPositions = [dataObject.min, dataObject.max] as number[]
          
          if (posNegColorAxis) {
            var color1 = { r: 139, g: 0, b: 0 }
            var color2 = { r: 255, g: 43, b: 43 }
            var color3 = { r: 255, g: 191, b: 0 }
            var color4 = { r: 255, g: 255, b: 255 }
            var color5 = { r: 0, g: 205, b: 0 }
            var color6 = { r: 0, g: 154, b: 0 }
            var color7 = { r: 0, g: 102, b: 0 }

            if (reverseColorAxis) {
              color1 = { r: 0, g: 102, b: 0 }
              color2 = { r: 0, g: 154, b: 0 }
              color3 = { r: 0, g: 205, b: 0 }
              color4 = { r: 255, g: 255, b: 255 }
              color5 = { r: 255, g: 191, b: 0 }
              color6 = { r: 255, g: 43, b: 43 }
              color7 = { r: 139, g: 0, b: 0 }
            }

            var crossoverValue = 0;

            if (colorAxisCrossoverPoint && colorAxisCrossoverPoint !== null) {
              crossoverValue = colorAxisCrossoverPoint;
            }

            if (dataObject.min < crossoverValue && dataObject.max > crossoverValue) {
              var crossoverPoint = ((crossoverValue - dataObject.min) / (dataObject.max - dataObject.min))
              if (((crossoverValue - dataObject.min) / (dataObject.max - dataObject.min)) < 0.1) {
                stops = [
                  0,
                  crossoverPoint,
                  1 - ((2 * (1 - crossoverPoint)) / 3),
                  1 - ((1 - crossoverPoint) / 3),
                  1
                ]
                colors = {}
                colors[stops[0]] = color2
                colors[stops[1]] = color4
                colors[stops[2]] = color5
                colors[stops[3]] = color6
                colors[stops[4]] = color7
              } else if (((dataObject.max - crossoverValue) / (dataObject.max - dataObject.min)) < 0.1) {
                stops = [
                  0,
                  crossoverPoint / 3,
                  (2 * crossoverPoint) / 3,
                  crossoverPoint,
                  1
                ]
                colors = {}
                colors[stops[0]] = color1
                colors[stops[1]] = color2
                colors[stops[2]] = color3
                colors[stops[3]] = color4
                colors[stops[4]] = color6
              } else {
                if ((((crossoverValue - dataObject.min) / (dataObject.max - dataObject.min)) < 0.25) 
                  || (((dataObject.max - crossoverPoint) / (dataObject.max - dataObject.min)) < 0.25)) {
                    tickPositions = [dataObject.min, dataObject.max];
                } else {
                  tickPositions = [dataObject.min, crossoverValue, dataObject.max];
                }
                stops = [
                  0,
                  crossoverPoint / 3,
                  (2 * crossoverPoint) / 3,
                  crossoverPoint,
                  1 - ((2 * (1 - crossoverPoint)) / 3),
                  1 - ((1 - crossoverPoint) / 3),
                  1
                ]
                colors = {}
                colors[stops[0]] = color1
                colors[stops[1]] = color2
                colors[stops[2]] = color3
                colors[stops[3]] = color4
                colors[stops[4]] = color5
                colors[stops[5]] = color6
                colors[stops[6]] = color7
              }
            } else if (dataObject.min >= crossoverValue && dataObject.max >= crossoverValue) {
              if (Math.abs((crossoverValue - dataObject.min) / (dataObject.max - dataObject.min)) < 0.25) {
                tickPositions = [crossoverValue, dataObject.max]
              }
              stops = [
                0,
                1 / 3,
                2 / 3,
                1
              ]
              colors = {}
              colors[stops[0]] = color4
              colors[stops[1]] = color5
              colors[stops[2]] = color6
              colors[stops[3]] = color7
            } else if (dataObject.min <= crossoverValue && dataObject.max <= crossoverValue) {
              if (Math.abs((dataObject.max - crossoverValue) / (dataObject.min - dataObject.max)) < 0.25) {
                tickPositions = [dataObject.min, crossoverValue]
              }
              stops = [
                0,
                1 / 3,
                2 / 3,
                1
              ]
              colors = {}
              colors[stops[0]] = color1
              colors[stops[1]] = color2
              colors[stops[2]] = color3
              colors[stops[3]] = color4
            }
          }

          setColorAxisStops(stops)
          setColorAxisColors(colors)
          setColorAxisTicks(tickPositions)
        }
      }
    }
  }, [map, dataObject]);

  useEffect(() => {
    if (map && colorAxisColors) {
      if (map_scale !== 'city') {
        map.data.setStyle(styleFeature);
      }
    }
  }, [colorAxisColors]);

  /**
   * Update a map's viewport to fit each geometry in a dataset
   */
  function zoom(map: google.maps.Map) {
    const bounds = new google.maps.LatLngBounds();
    map.data.forEach((feature) => {
      const geometry = feature.getGeometry();
      if (geometry) {
        processPoints(geometry, bounds.extend, bounds);
      }
    });

    if (map_scale === 'country') {
      if (bounds.getNorthEast().lng() < bounds.getSouthWest().lng()) {
        map.setCenter({ lat: 0, lng: 0 })
        map.setZoom(1)
      } else {
        map.fitBounds(bounds);
      }
    } else if (map_scale === 'state' || map_scale === 'city' || map_scale === 'county') {
      // console.log(bounds)
      if ((bounds.getSouthWest().lng() < -130 || bounds.getSouthWest().lng() > -35) && bounds.getNorthEast().lng() > -75) {
        map.setCenter({ lat: 40, lng: -100 })
        map.setZoom(3)
      } else {
        map.fitBounds(bounds);
      }
    } else {
      map.fitBounds(bounds);
    }
  }

  /**
   * Process each point in a Geometry, regardless of how deep the points may lie.
   */
  function processPoints(
    geometry: google.maps.LatLng | google.maps.Data.Geometry,
    callback: any,
    thisArg: google.maps.LatLngBounds
  ) {
    if (geometry instanceof google.maps.LatLng) {
      callback.call(thisArg, geometry);
    } else if (geometry instanceof google.maps.Data.Point) {
      callback.call(thisArg, geometry.get());
    } else {
      // @ts-ignore
      geometry.getArray().forEach((g) => {
        processPoints(g, callback, thisArg);
      });
    }
  }

  /** Removes census data from each shape on the map and resets the UI. */
  function clearMapData() {
    if (map) {
      map.data.forEach((row) => {
        row.setProperty("value", undefined);
      });
    }
  }

  /**
   * Applies a gradient style based on the 'census_variable' column.
   * This is the callback passed to data.setStyle() and is called for each row in
   * the data set.  Check out the docs for Data.StylingFunction.
   */
  function getColor(percent: number) {
    function getInterval(range: number[]) {
        function getRatio(color: string) {
            return Math.floor((colorAxisColors[range[0]][color] * right + colorAxisColors[range[1]][color] * left) / delta);
        }

        var left = percent - range[0],
            right = range[1] - percent,
            delta = range[1] - range[0];

        return { r: getRatio('r'), g: getRatio('g'), b: getRatio('b') };
    }

    return colorAxisColors[percent] || getInterval(colorAxisStops.reduce(function (r, a, i, aa) {
        return a < percent ? [a, aa[i + 1]] : r;
    }, [0, 0]));
  }
  function styleFeature(feature: google.maps.Data.Feature) {
    var color = { r: 211, g: 211, b: 211 };
    var isNoVal = true
    const value: any = feature.getProperty("value")

    if (dataObject) {
      // console.log(dataObject)

      if (value !== undefined && value !== null && !isNaN(value)) {
        // delta represents where the value sits between the min and max
        const delta =
          (value - colorAxisTicks[0]) /
          (colorAxisTicks[colorAxisTicks.length - 1] - colorAxisTicks[0]);

        color = getColor(delta)
        isNoVal = false
        feature.setProperty('percent', delta * 100)
      } else {
        feature.setProperty('percent', undefined)
      }

      // determine whether to show this shape or not
      let showRow = true;

      if (value === undefined) {
        showRow = false;
      }

      let outlineWeight = 0.8,
        zIndex = 1;

      if (feature.getProperty("state") === "hover") {
        outlineWeight = zIndex = 3;
      }

      feature.setProperty('color', "rgb(" + color.r + "," + color.g + "," + color.b + ")")

      return {
        cursor: "grab",
        strokeWeight: outlineWeight,
        strokeColor: isNoVal ? "#ccc" : feature.getProperty("state") === "hover" ? "rgb(" + Math.max(color.r - 50, 0) + "," + Math.max(color.g - 50, 0) + "," + Math.max(color.b - 50, 0) + ")" : "#bbb",
        // strokeColor: isNoVal ? "#ccc" : "rgb(" + color.r + "," + color.g + "," + color.b + ")",
        zIndex: zIndex,
        fillColor: "rgb(" + color.r + "," + color.g + "," + color.b + ")",
        fillOpacity: isNoVal ? 0.5 : feature.getProperty("state") === "hover" ? 0.9 : 0.75,
        visible: showRow,
      };
    } else {
      return {
        cursor: "grab",
        strokeWeight: 0.8,
        strokeColor: "#ccc",
        fillColor: "rgb(" + color.r + "," + color.g + "," + color.b + ")",
        fillOpacity: 0.5,
      }
    }
  }

  function styleFeatureBubble(feature: google.maps.Data.Feature) {
    var color = { r: 150, g: 150, b: 150 };
    const value: any = feature.getProperty("value")

    if (dataObject) {
      var colorMin = dataObject.min
      var colorMax = dataObject.max
      var showRow = false
      var radius = 0

      if (value !== undefined && value !== null && !isNaN(value)) {
        showRow = true
        if (value === 0) {
          radius = 0
        } else if (value < 0 && colorMax <= 0) {
          radius = Math.abs((value - colorMin) / (colorMax - colorMin))
          color = { r: 255, g: 50, b: 50 }
        } else if (value < 0) {
          radius = Math.abs(value / Math.max(-colorMin, colorMax))
          color = { r: 255, g: 50, b: 50 }
        } else if (colorMin >= 0) {
          radius = Math.abs((value - colorMin) / (colorMax - colorMin))
          color = { r: 0, g: 216, b: 0 }
        } else {
          radius = Math.abs(value / Math.max(-colorMin, colorMax))
          color = { r: 0, g: 216, b: 0 }
        }
      }

      // if (
      //   value == null ||
      //   isNaN(value)
      // ) {
      //   showRow = false;
      // }

      let outlineWeight = 1;

      if (feature.getProperty("state") === "hover") {
        outlineWeight = 3;
      }

      feature.setProperty('color', "rgb(" + color.r + "," + color.g + "," + color.b + ")")

      return {
        optimized: true,
        visible: showRow,
        zIndex: 100 - (radius * 100),
        cursor: "grab",
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: (Math.sqrt(radius) * 40) + 1,
          strokeWeight: outlineWeight,
          strokeColor: "rgb(" + color.r + "," + color.g + "," + color.b + ")",
          fillColor: "rgb(" + color.r + "," + color.g + "," + color.b + ")",
          fillOpacity: 0.18,
        },
      };
    } else {
      return {
        cursor: "grab",
        visible: false,
        strokeWeight: 0.8,
        strokeColor: "rgb(" + color.r + "," + color.g + "," + color.b + ")",
        fillColor: "rgb(" + color.r + "," + color.g + "," + color.b + ")",
        fillOpacity: 0.18,
      }
    }
  }

  /**
   * Responds to the mouse-in event on a map shape (state).
   *
   * @param {?google.maps.MapMouseEvent} e
   */
  function mouseInToRegion(e: any) {
    // set the hover state so the setStyle function can change the border
    e.feature.setProperty('state', 'hover');

    var suffixValue = ''
    if (unitSuffix !== undefined && unitSuffix !== null && unitSuffix !== '') {
      if (unitSuffix !== '%') {
        suffixValue = ' ' + unitSuffix
      } else {
        suffixValue = unitSuffix
      }
    }

    setShowTooltip(true)
    setTooltipLocation(e.feature.getProperty('NAME') + (map_scale === 'county' || map_scale === 'city' ? e.feature.getProperty('stateAbbrev') : ''))
    setTooltipLocationCode(e.feature.getId())
    if (e.feature.getProperty('value') !== undefined && e.feature.getProperty('value') !== null) {
      setTooltipValue(formatNumberDecimal(e.feature.getProperty('value')) + suffixValue)
    } else {
      setTooltipValue('')
    }
    setTooltipColor(e.feature.getProperty('color'))
    setTooltipPercent(e.feature.getProperty('percent') === undefined ? null : e.feature.getProperty('percent'))
  }

  /**
   * Responds to the mouse-out event on a map shape (state).
   *
   * @param {?google.maps.MapMouseEvent} e
   */ 
  function mouseOutOfRegion(e: any) {
    // reset the hover state, returning the border to normal
    e.feature.setProperty('state', 'normal');

    // TODO delay the hide
    setShowTooltip(false)
    setTooltipLocation('')
    setTooltipLocationCode('')
    setTooltipValue('')
    setTooltipColor('#bbb')
    setTooltipPercent(null)
  }

  return<div className={classes.mapContainer} onMouseMove={({ clientX, clientY }) => setMouseLoc({ x: clientX, y: clientY })} onMouseLeave={() => setShowTooltip(false)}>
    {dataObject ? <div ref={tooltipRef} style={{ 
      display: showTooltip ? 'block' : 'none', position:'fixed', 
      top: (mouseLoc.y + 5) + 'px', 
      left: (mouseLoc.x > window.innerWidth - 200 ? (window.innerWidth - 210) + 'px' : mouseLoc.x + 10 + 'px'), 
      minWidth: '50px', 
      maxWidth: '400px',
      zIndex: 1000, 
      backgroundColor: 'rgba(255,255,255, 0.9)', 
      textAlign: 'left', 
      padding: '6px 10px', 
      borderRadius: '3px', 
      border: '2px solid ' + tooltipColor, 
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' 
    }}>
      <span style={{ fontSize: '11px' }}>{dataObject['dateString']}</span><br/>
      <span style={{ fontSize: '13px', fontStyle: 'italic' }}>{dataObject['name']}</span><br/>
      <span style={{ fontSize: '15px' }}>{tooltipLocation}: {tooltipValue === '' ? <i>No value</i> : <b>{tooltipValue}</b>}</span><br/>
    </div> : <div style={{ width: "100%", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 0, left: 0, zIndex: 999, backgroundColor: 'rgba(255,255,255, 0.7)' }}>
      <p style={{ fontWeight: 'bold', color: '#777' }}>No data to display</p>
    </div>}
    {(dataObject && map_scale !== 'city' && colorAxisTicks.length > 1) && <div style={{
      position: 'absolute',
      textAlign: 'center',
      fontFamily: '"Roboto", "Arial", sans-serif',
      fontSize: '13px',
      zIndex: 5,
      boxShadow: '0 4px 6px -4px #333',
      padding: '5px 10px',
      background: 'rgb(255, 255, 255)',
      border: 'rgb(229, 229, 229) 1px solid',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '7px',
      bottom: '30px',
      left: 'calc(50% - 20px)',
      transform: 'translateX(calc(-50%))',
      // right: '70px',
      maxWidth: '400px',
      width: 'calc(100% - 100px)',
      height: '50px',
    }}>
      <div style={{ 
        background: 'linear-gradient(to right,' + colorAxisStops.map((stop: number) => colorAxisColors[stop] ? ' rgba(' + colorAxisColors[stop].r + ',' + colorAxisColors[stop].g + ',' + colorAxisColors[stop].b + ',0.9) ' + (stop * 100) + '%' : '') + ')',
        flex: 1,
        margin: '0 5px',
        textAlign: 'left',
        fontSize: '1em',
        lineHeight: '1em',
        position: 'relative',
      }}>
        <span style={{ display: (showTooltip && tooltipPercent !== null) ? 'block' : 'none', marginLeft: (showTooltip && tooltipPercent !== null) ? 'calc(' + tooltipPercent + '% - 7px)' : 'calc(0% - 8px)', marginTop: '1.5px', fontSize: '16px', width: '14px', textShadow: '-1px 0 2px rgba(255,255,255,0.7), 0 -1px 2px rgba(255,255,255,0.7), 0 1px 2px rgba(255,255,255,0.7), 1px 0 2px rgba(255,255,255,0.7)' }}>&#x25c6;</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0', position: 'relative', }}>
        <div style={{ backgroundColor: '#fff', zIndex: 1 }}>{roundNumberDecimal(formatNumberDecimal(colorAxisTicks[0].toString()), 4)}</div>
        {/* {colorAxisTicks.length > 2 && <div style={{ backgroundColor: '#fff', zIndex: 0, position: 'absolute', left: ((-colorAxisTicks[0] / (colorAxisTicks[2] - colorAxisTicks[0])) * 100) + '%', transform: 'translateX(-' + ((1 - (-colorAxisTicks[0] / (colorAxisTicks[2] - colorAxisTicks[0]))) * 100) + '%)' }}>{roundNumberDecimal(formatNumberDecimal(colorAxisTicks[1].toString()), 4)}</div>} */}
        <div style={{ backgroundColor: '#fff', zIndex: 1 }}>{roundNumberDecimal(formatNumberDecimal(colorAxisTicks[colorAxisTicks.length - 1].toString()), 4)}</div>
      </div>
    </div>}
    <div ref={ref} style={{ width: "100%", height: "100%" }} />
  </div>;
}

function formatNumberDecimal(x: string) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

function roundNumberDecimal(x: string, n: number) {
  var parts = x.toString().split(".");
  if (parts.length > 1 && parts[1].length > n) {
    parts[1] = parts[1].substring(0,n) + '...';
  }
  return parts.join(".");
}

NoData(Highcharts)
Exporting(Highcharts)
OfflineExporting(Highcharts)

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
    thousandsSep: ',',
    numericSymbols: ['k', 'M', 'B', 'T'],
    contextButtonTitle: 'Download chart image',
  }
});

Highcharts.SVGRenderer.prototype.symbols.download = () => {
  const iconString = ReactDOMServer.renderToString(<PhotoCameraIcon />);
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(iconString, 'image/svg+xml');
  const iconPath = svgDoc.querySelector('path')?.getAttribute('d') as string;

  return [iconPath];
}


var interval: any;

interface TimeSliderMapProps {
  classes: any,
  seriess: any[],
  seriesName: string,
  frequency: string,
  posNegColorAxis?: boolean,
  reverseColorAxis?: boolean,
  colorAxisCrossoverPoint?: number,
  unitSuffix?: string | null,
  onMapDateChange?: (dateUTC: number) => void,
  mapDate?: number,
  xPlotLines?: any[],
  yPlotLines?: any[],
  showZeroOnSlider?: boolean,
  sessionRecipeInfo?: any,
  map_scale?: string,
  summary_row?: string | null,
}

interface TimeSliderMapState {
  dataSeq: any[],
  summaryRowData: any[] | null,
  summaryRowName: string,
  sliderValue: number,
  sliderDateString: string,
  dataObject: any,
  isPlaying: boolean,
  chartOptions: any, // switched from options because of type issue w/ exporting
  unitSuffix: string,
  mapData: any,
  // mapRef: any,
  // map: any,
}

class TimeSliderMap extends React.PureComponent<TimeSliderMapProps, TimeSliderMapState> {

  state = {
    dataSeq: [] as any[],
    summaryRowData: null,
    summaryRowName: '',
    sliderValue: 0,
    isPlaying: false,
    unitSuffix: '',
    mapData: null,
    // mapRef: React.createRef(),
    // map: null,
    sliderDateString: '',
    dataObject: null,
    chartOptions: {
      chart: {
        height: (500 / 700) * 100 + '%',
        animation: false
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: true
      },
      exporting: {
        // sourceWidth: 800,
        enabled: false,
        buttons: {
          contextButton: {
            align: 'left',
            // verticalAlign: 'bottom',
            symbol: 'download',
            menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] // removed "viewFullscreen", "printChart", "separator", 
          }
        },
        chartOptions: {
          title: {
            y: -10
          },
          subtitle: {
            text: ''
          },
          caption: {
            text: '<a href="' + window.location.href + '">' + window.location.href + '</a>',
          },
        },
      },
      title: {
        text: ''
      },
      series: []
    }
  }
  
  // mapChartRef: any;
  summaryRowChartRef: any;

  baseState = this.state 

  private myRef;
  constructor(props: TimeSliderMapProps) {
    super(props);
    this.myRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount() {


    // this.mapChartRef = React.createRef();
    this.summaryRowChartRef = React.createRef();

    var dataSeq = this.parseTableToDataSeq(this.props.seriess, this.props.frequency, this.props.map_scale, this.props.summary_row)
    if (dataSeq && dataSeq.length > 0) {
      this.setState({
        dataSeq: dataSeq,
        sliderValue: dataSeq[dataSeq.length - 1].dateUTC
      }, () => { 
        if (this.props.mapDate !== undefined) {
          this.updateTimeUTCMapDate(this.props.mapDate)
        } else {
          this.setChartOptions(dataSeq[dataSeq.length - 1]);
        }
      });
    }
  }

  componentDidUpdate(prevProps: TimeSliderMapProps) {
    if (prevProps.seriess !== this.props.seriess || prevProps.seriesName !== this.props.seriesName || (prevProps.sessionRecipeInfo && prevProps.sessionRecipeInfo.loading && !this.props.sessionRecipeInfo.loading)) {
      // console.log(this.props)
      setTimeout(() => { 
        var dataSeq = this.parseTableToDataSeq(this.props.seriess, this.props.frequency, this.props.map_scale, this.props.summary_row)
        if (dataSeq && dataSeq.length > 0) {
          // console.log(dataSeq)
          this.setState({
            dataSeq: dataSeq,
            sliderValue: dataSeq[dataSeq.length - 1].dateUTC
          }, () => { 
            if (this.props.mapDate !== undefined) {
              this.updateTimeUTCMapDate(this.props.mapDate)
            } else {
              this.setChartOptions(dataSeq[dataSeq.length - 1]);
            }
          });
        } else {
          this.setState(this.baseState)
        }
       }, 100);
    } else if (prevProps.mapDate !== this.props.mapDate && this.props.mapDate !== undefined) {
      this.updateTimeUTCMapDate(this.props.mapDate)
    }
  }

  parseTableToDataSeq(seriess: any[], frequencyType = 'NotSet', map_scale = "state", summary_row: string | null = null) {
    var parsed = {} as any;
    var output = [] as any[];
    var min = Number.MAX_VALUE;
    var max = -Number.MAX_VALUE;
    var tempSummaryRowData: any[] | null = null;
    var tempSummaryRowName = ''
    var suffixObj: any;
    var suffixValue = '';
    var mapData: any;

    if (map_scale === 'country') {
      var features = [] as any[]
      mapData = lodash.cloneDeep(require('../definitions/maps/ne_50m_admin_0_countries.json'));
      mapData.features.forEach((f: any) => {
        var code = f.properties.ISO_A3
        if (code === '-99') {
          code = f.properties.ISO_A3_EH // ISO_A3_EH seems to have better coverage
        }
        f.id = code
        f.properties.locationCode = code
      })
      seriess.forEach(async (s: any) => {
        if (s.locationCode !== '-1' && s.locationCode.length > 1) {
          var iso = s.locationCode
          var currFeature = mapData.features.find((p: any) => p.id === iso)
          if (currFeature !== undefined) {
            features.push(currFeature)
          }
        }
      })
      mapData.features = features
    } else if (map_scale === 'county') {
      // find series with locationCode not -1 and use to determine which map to use
      var fips_county = seriess.find((s: any) => s.locationCode !== '-1' && s.locationCode.length > 1).locationCode
      var fips_state = fips_county.substring(0, 2)
      if (!seriess.some((s: any) => s.locationCode !== '-1' && s.locationCode.length > 1 && s.locationCode.substring(0, 2) !== fips_state) && fips_state !== '00' && stateInfo[fips_state] !== undefined) {
        mapData = lodash.cloneDeep(require('../definitions/maps/cb_2021_us_county_5m.json'));
        // console.log(mapData)
        mapData.features = mapData.features.filter((f: any) => f.properties.STATEFP === fips_state)
        mapData.features.forEach((f: any) => {
          f.id = f.properties.STATEFP + f.properties.COUNTYFP
          f.properties.locationCode = f.properties.STATEFP + f.properties.COUNTYFP
          f.properties.stateAbbrev = stateInfo[f.properties.STATEFP] === undefined ? '' : ', ' + stateInfo[f.properties.STATEFP].abbreviation
        })
      } else {
        mapData = lodash.cloneDeep(require('../definitions/maps/cb_2021_us_county_5m.json'));
        mapData.features.forEach((f: any) => {
          f.id = f.properties.STATEFP + f.properties.COUNTYFP
          f.properties.locationCode = f.properties.STATEFP + f.properties.COUNTYFP
          f.properties.stateAbbrev = stateInfo[f.properties.STATEFP] === undefined ? '' : ', ' + stateInfo[f.properties.STATEFP].abbreviation
        })
      }
    } else if (map_scale === 'city') {
      // find series with locationCode not -1 and use to determine which map to use
      var fips_combined = seriess.find((s: any) => s.locationCode !== '-1' && s.locationCode.length > 1).locationCode
      var fips_state = fips_combined.substring(0, 2)
      var map_fips = ''
      var features = [] as any[]
      var imports = {} as any

      seriess.forEach(async (s: any) => {
        if (s.locationCode !== '-1' && s.locationCode.length > 1) {
          var fips_combined = s.locationCode.split('-')
          var fips_state = fips_combined[0]
          var fips_place = fips_combined[1]
          if (map_fips === '') {
            map_fips = fips_state
          } else if (map_fips !== fips_state) {
            map_fips = '00'
          }
          
          if (imports[fips_state] === undefined) {
            imports[fips_state] = lodash.cloneDeep(require('../definitions/maps/cities/tl_2021_' + fips_state + '_place.json'))
          }

          var currFeature = imports[fips_state]['features'].find((p: any) => p.properties && p.properties['PLACEFP'] === fips_place)

          if (currFeature !== undefined) {
            features.push(currFeature)
          }
        }
      })

      // console.log(features)

      imports = null

      mapData = {
        type: 'FeatureCollection',
        features: features
      }

      mapData.features.forEach((f: any) => {
        f.id = f.properties['STATEFP'] + '-' + f.properties['PLACEFP']
        f.properties.locationCode = f.properties['STATEFP'] + '-' + f.properties['PLACEFP']
        if (stateInfo[f.properties['STATEFP']] !== undefined) {
          // console.log(f.properties)
          f.properties.stateAbbrev = ', ' + stateInfo[f.properties['STATEFP']].abbreviation
        } else {
          f.properties.stateAbbrev = ''
        }
      })

      // console.log(mapData)

    } else {
      mapData = lodash.cloneDeep(require('../definitions/maps/cb_2021_us_state_5m.json'));
      mapData.features.forEach((f: any) => {
        f.id = f.properties.STATEFP // Highcharts used to require "US" + 
        f.properties.locationCode = f.properties.STATEFP
      })
    }

    this.setState({ mapData: mapData })

    // console.log(mapData)
    
    if (this.props.unitSuffix !== undefined && this.props.unitSuffix !== null && this.props.unitSuffix !== '') {
      if (this.props.unitSuffix !== '%') {
        suffixValue = ' ' + this.props.unitSuffix
      } else {
        suffixValue = this.props.unitSuffix
      }
    }

    this.setState({ unitSuffix: suffixValue })
  
    for (let series of seriess) {
      if (series.frequency === frequencyType) {
        var locationCode = series.locationCode // Highcharts we used 'US' + locationCode for state data
        var inMap = mapData.features.some((location: any) => location.id === locationCode) // used to use various properties to match to data

        if (locationCode === summary_row || inMap) {
          for (let item of series.data_points) {
            var value: number | null = parseFloat(item.value)
            if (isNaN(value)) {
              value = null;
            }
            var dateUTC = Date.parse(item.key);
            var dateString = frequencySettings["NotSet"].dateFormatter(dateUTC)
            if (frequencySettings[frequencyType] && frequencySettings[frequencyType].displayRank < 15) {
              dateString = frequencySettings[frequencyType].dateFormatter(dateUTC)
            }
            if (!parsed[dateUTC]) {
              parsed[dateUTC] = {
                date: item.key,
                dateUTC: dateUTC,
                dateString,
                // data_type: series.info.data_type,
                data: []
              }
            }
    
            if (locationCode !== summary_row) {
              parsed[dateUTC]['data'].push({
                value: value,
                locationCode: locationCode,
              })
              if (value !== null && value < min) {
                min = value;
              }
              if (value !== null && value > max) {
                max = value;
              }
            } else {
              if (tempSummaryRowData === null) {
                tempSummaryRowData = []
                tempSummaryRowName = series.location
              }
              
              tempSummaryRowData.push([dateUTC, value])
            }
          }
        }
      }
    }

    // console.log(parsed)
  
    output = Object.keys(parsed).map((keyName: string, keyIndex: number) => {
      // parsed[keyName].data.sort(function (a: any, b: any) {
      //   return Math.abs(b['value']) - Math.abs(a['value']);
      // })
      return {
        data: parsed[keyName].data,
        dateUTC: parsed[keyName].dateUTC,
        dateString: parsed[keyName].dateString,
        // data_type: parsed[keyName].data_type,
        name: this.props.seriesName,
        min: min,
        max: max,
      }
    })

    // output = output.filter((dataObj: any) => dataObj !== null)

    if (tempSummaryRowData !== null && tempSummaryRowData.length > 0) {

      // check that each date in dataSeq is in in summary row data
      output.forEach((item: any) => {
        var date = item.dateUTC
        if (!tempSummaryRowData?.some((x: any) => x[0] === date)) {
          tempSummaryRowData?.push([date, null])
        }
      })

      // check that each date in summary row data is in dataSeq
      tempSummaryRowData.forEach((item: any) => {
        var date = item[0]
        if (!output.some((x: any) => x.dateUTC === date)) {
          var dateString = frequencySettings["NotSet"].dateFormatter(date)
          if (frequencySettings[frequencyType] && frequencySettings[frequencyType].displayRank < 15) {
            dateString = frequencySettings[frequencyType].dateFormatter(date)
          }
          output.push({
            data: [] as any[],
            dateUTC: date,
            dateString,
            // data_type: seriess.length > 0 ? seriess[0].info.data_type : '',
            name: this.props.seriesName,
            min: min,
            max: max,
          })
        }
      })
      
      tempSummaryRowData.sort((a: any, b: any) => a[0] - b[0])

      // console.log(tempSummaryRowData)
    }

    this.setState({ summaryRowData: tempSummaryRowData, summaryRowName: tempSummaryRowName })
  
    return output.sort((a: any, b: any) => a.dateUTC - b.dateUTC)
  }

  setChartOptions(dataObject: any) {
    if (dataObject === null || dataObject === undefined) {
      this.setState({
        sliderDateString: this.baseState.sliderDateString,
        dataObject: this.baseState.dataObject,
      })
    } else {
      this.setState({
        sliderDateString: dataObject.dateString,
        dataObject: dataObject,
      })
      if (this.props.onMapDateChange) { this.props.onMapDateChange(dataObject.dateUTC) }
    }
  }

  makeSummaryRowChartOptions = (summaryRowData: any[] | null, seriesName: string, frequency: string, data_type: string, unitSuffix: string) => {
    if (summaryRowData === null) {
      return {
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        series: []
      }
    } else {
      var summRowName = this.state.summaryRowName
      return {
        chart: {
          type: 'line',
          backgroundColor: 'rgba(255,255,255,0)',
          margin: [0, 1, 25, 1],
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        colors: this.props.mapDate !== undefined ? ['#89b3d2'] : ['#026cb5'], // a90533
        title: {
          text: summRowName,
          style: { "color": "#666666", "fontSize": "13px" },
          y: 10,
          floating: true
        },
        legend: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          minPadding: 0,
          maxPadding: 0,
          min: summaryRowData[0][0],
          max: summaryRowData[summaryRowData.length - 1][0],
          title: {
            text: ''
          },
          plotLines: this.props.xPlotLines ? this.props.xPlotLines : []
        },
        yAxis: {
          startOnTick: false,
          endOnTick: false,
          tickAmount: 3,
          minPadding: 0.05,
          maxPadding: 0.1,
          softMin: this.props.showZeroOnSlider ? 0 : undefined,
          softMax: this.props.showZeroOnSlider ? 0 : undefined,
          title: {
            text: ''
          },
          labels: {
            align: 'left',
            x: 5,
            y: -3,
            // format: '{value:,.0f}'
          },
          plotLines: this.props.yPlotLines ? this.props.yPlotLines : []
        },
        tooltip: {
          outside: true,
          shape: 'square',
          borderColor: '#aaa',
          borderWidth: 0,
          //padding: 0,
          useHTML: true,
          formatter: function () {
            var self: any = this;
            var dateString = frequencySettings["NotSet"].dateFormatter(self.x)
            if (frequencySettings[frequency] && frequencySettings[frequency].displayRank < 15) {
              dateString = frequencySettings[frequency].dateFormatter(self.x)
            }
            var str = '<span style="font-size: 10px">' + dateString + '</span><br/>' + seriesName + '<br/>'
            // str += '<span style="font-size: 11px; font-style: italic">' + data_type + '</span><br/>'
            str += '<div style="margin-top: 3px; font-size: 12px">' + summRowName + ': <b>' + Highcharts.numberFormat(self.y, -1, '.', ',') + unitSuffix + '</b></div>'

            return str
          },
        },
        plotOptions: {
          series: {
            marker: {
              // enabled: false,
              radius: 2,
              enabledThreshold: 4,
            },
          }
        },
        series: [{
          name: summRowName,
          data: lodash.cloneDeep(summaryRowData)
          // data: summaryRowData
        }]
      }
    }
  }

  updateTime = (index: number) => {
    if (this.state.dataSeq.length > 0) {
      if (index < 0) {
        this.setState({
          sliderValue: 0
        }, () => { this.setChartOptions(this.state.dataSeq[0]) })
      } else if (index >= this.state.dataSeq.length) {
        this.setState({
          sliderValue: this.state.dataSeq.length - 1
        }, () => { this.setChartOptions(this.state.dataSeq[this.state.dataSeq.length - 1]) })
      } else {
        this.setState({
          sliderValue: index
        }, () => { this.setChartOptions(this.state.dataSeq[index]) })
      }
    }
  }

  updateTimeUTCIndex = (index: number) => {
    if (this.state.dataSeq.length > 0) {
      if (index < 0) {
        this.setState({
          sliderValue: this.state.dataSeq[0].dateUTC
        }, () => { this.setChartOptions(this.state.dataSeq[0]) })
      } else if (index >= this.state.dataSeq.length) {
        this.setState({
          sliderValue: this.state.dataSeq[this.state.dataSeq.length - 1].dateUTC
        }, () => { this.setChartOptions(this.state.dataSeq[this.state.dataSeq.length - 1]) })
      } else {
        this.setState({
          sliderValue: this.state.dataSeq[index].dateUTC
        }, () => { this.setChartOptions(this.state.dataSeq[index]) })
      }
    }
  }

  updateTimeUTCMapDate = (dateUTC: number) => {
    if (this.state.dataSeq.length > 0) {
      if (this.props.mapDate !== undefined) {
        const closest = this.state.dataSeq.find((a: any) => a.dateUTC === dateUTC);
        this.setState({
          sliderValue: dateUTC
        }, () => { this.setChartOptions(closest) })
      } else {
        const closest = this.state.dataSeq.reduce((a, b) => {
          return Math.abs(b.dateUTC - dateUTC) < Math.abs(a.dateUTC - dateUTC) ? b : a;
        });
        this.setState({
          sliderValue: closest.dateUTC
        }, () => { this.setChartOptions(closest) })
      }
    }
  }

  updateTimeUTC = (dateUTC: number) => {
    if (this.state.dataSeq.length > 0) {
      const closest = this.state.dataSeq.reduce((a, b) => {
        return Math.abs(b.dateUTC - dateUTC) < Math.abs(a.dateUTC - dateUTC) ? b : a;
      });
      this.setState({
        sliderValue: closest.dateUTC
      }, () => { this.setChartOptions(closest) })
    }
  }

  pause = () => {
    this.setState({
      isPlaying: false
    }, () => { clearInterval(interval); })
  }

  play = () => {
    this.setState({
      isPlaying: true
    }, () => { 
      interval = setInterval(() => {
        var currIndex = this.state.dataSeq.findIndex((data: any) => data.dateUTC === this.state.sliderValue)

        if (currIndex === -1 || currIndex >= this.state.dataSeq.length - 1) {
          this.pause();
        } else {
          this.updateTimeUTCIndex(currIndex + 1)
        }
      }, 400)
    })
  }

  calcSliderPos(e: any) {
    //return ( e.clientX - e.target.offsetLeft ) / e.target.clientWidth * parseFloat(e.target.getAttribute('max'));
    if (e.target && e.target.offsetWidth) {
      var offset = ((parseFloat(e.target.max) - parseFloat(e.target.min)) / 100) * (e.offsetX / e.target.offsetWidth - 0.5);
      // if (e.offsetX / e.target.offsetWidth < 0.5) {
      //   offset = -0.15;
      // } else {
      //   offset = 0.15;
      // }
      return (e.offsetX / e.target.offsetWidth * (parseFloat(e.target.max) - parseFloat(e.target.min))) + parseFloat(e.target.min) + offset;
    } else {
      return parseFloat(e.target.min)
    }
  }
  
  render() {
    const { classes } = this.props;
    
    return (<ReactResizeDetector handleWidth handleHeight targetRef={this.myRef} refreshMode="debounce" refreshRate={0}>
      {({ width, height }) => <div ref={this.myRef} style={{ userSelect: "none", msUserSelect: "none", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", maxWidth: '100vh', width: "100%", margin: '0 auto' }}>
        <div style={{ width: "100%" }}>

          <Wrapper apiKey="AIzaSyA4uBWe379N2Sqdw3MHR4h5vGOJnnn7d3o" render={renderWrapper}>
            <MyMapComponent 
              mapData={this.state.mapData}
              dataObject={this.state.dataObject} 
              posNegColorAxis={this.props.posNegColorAxis}
              reverseColorAxis={this.props.reverseColorAxis}
              colorAxisCrossoverPoint={this.props.colorAxisCrossoverPoint}
              map_scale={this.props.map_scale}
              unitSuffix={this.state.unitSuffix}
              classes={classes}
            />
          </Wrapper>

          {this.state.sliderDateString !== '' ? <div style={{ margin: '15px', marginBottom: 0, fontSize: '1.4em', textAlign: 'center' }}>
            {this.state.sliderDateString}
          </div> : <div style={{ margin: '15px', marginBottom: 0, fontSize: '1.4em', color: 'rgba(255,255,255,0)' }}>
            filler
          </div>}

        </div>
        {(true || this.props.mapDate === undefined) && <div style={{ maxWidth: "500px", width: "90%", display: 'flex', marginTop: '12px' }}>
          {this.props.mapDate === undefined && <div style={ this.state.summaryRowData === null ? {display: 'flex', justifyContent: 'center', alignItems: 'center'} : {display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={this.state.isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              classes={{
                root: classes.playPauseRoot,
                // label: classes.playPauseLabel,
                startIcon: classes.playPauseIcon,
              }}
              onClick={() => {
                if (this.state.isPlaying) {
                  this.pause();
                } else {
                  if (this.state.dataSeq.length > 0 && this.state.sliderValue === this.state.dataSeq[this.state.dataSeq.length - 1].dateUTC) {
                    this.updateTimeUTC(this.state.dataSeq[0].dateUTC)
                  }
                  this.play();
                }
              }}
            > </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ReplayIcon />}
              classes={{
                root: classes.playPauseRoot,
                // label: classes.playPauseLabel,
                startIcon: classes.playPauseIcon,
              }}
              onClick={() => {
                this.pause();
                if (this.state.dataSeq.length > 0) {
                  this.updateTimeUTC(this.state.dataSeq[0].dateUTC)
                }
                this.play();
              }}
            > </Button>
          </div>}
          {this.state.summaryRowData !== null
            ? <div id="range-timeSliderMap-container">
              <input 
                id="play-range"
                className={this.props.mapDate !== undefined ? "slider-disabled slider" : "slider"}
                type="range" 
                min={this.state.dataSeq.length > 0 ? this.state.dataSeq[0].dateUTC : 0}
                max={this.state.dataSeq.length > 0 ? this.state.dataSeq[this.state.dataSeq.length - 1].dateUTC : 0}
                step={1}
                value={this.state.sliderValue}
                onKeyDown={(ev) => {
                  if (this.props.mapDate === undefined) {
                    var keyboardEvent: any = ev.nativeEvent
                    if (keyboardEvent.key && keyboardEvent.key === 'ArrowLeft') {
                      var currIndex = this.state.dataSeq.findIndex((data: any) => data.dateUTC === this.state.sliderValue)
                      if (currIndex > 0 && currIndex <= this.state.dataSeq.length - 1) {
                        this.updateTimeUTCIndex(currIndex - 1)
                      }
                    } else if (keyboardEvent.key && keyboardEvent.key === 'ArrowRight') {
                      var currIndex = this.state.dataSeq.findIndex((data: any) => data.dateUTC === this.state.sliderValue)
                      if (currIndex >= 0 && currIndex < this.state.dataSeq.length - 1) {
                        this.updateTimeUTCIndex(currIndex + 1)
                      }
                    }
                  }
                }}
                onChange={(ev) => {
                  if (this.props.mapDate === undefined) {
                    var value = parseInt(ev.target.value)
                    if (!Array.isArray(value)) {
                      this.updateTimeUTC(value);
                    }
                  }
                }}
                onMouseMove={(ev) => {
                  var e = ev.nativeEvent
                  var dateUTC = Math.max(
                    this.state.dataSeq[0].dateUTC,
                    Math.min(
                      this.state.dataSeq[this.state.dataSeq.length - 1].dateUTC, 
                      Math.round(this.calcSliderPos(e))
                    )
                  );
                  var timeSliderChart = this.summaryRowChartRef.current.chart;
                  var point = timeSliderChart.series[0].points.reduce((a: any, b: any) => {
                    return Math.abs(b.x - dateUTC) < Math.abs(a.x - dateUTC) ? b : a;
                  });
                  if (point !== undefined) {
                    // console.log('date: ' + dateUTC)
                    // console.log('p: ' + point.x)
                    if (point.y !== null) {
                      point.onMouseOver()
                    } else {
                      timeSliderChart.tooltip.hide()
                    }
                  }
                  timeSliderChart.xAxis[0].drawCrosshair(e, point);

                }}
              />
              <HighchartsReact
                ref={this.summaryRowChartRef}
                id="timeSlider-chart"
                highcharts={Highcharts}
                // options={this.makeSummaryRowChartOptions(this.state.summaryRowData, this.props.seriesName, this.props.frequency, (this.state.dataSeq.length > 0 ? this.state.dataSeq[0].data_type : ''), this.state.unitSuffix)}
                options={this.makeSummaryRowChartOptions(this.state.summaryRowData, this.props.seriesName, this.props.frequency, '', this.state.unitSuffix)}
                constructorType={'chart'}
                {...this.props}
              />
            </div>
            : <Slider
              value={this.state.sliderValue}
              aria-labelledby="discrete-slider"
              step={1}
              // marks
              min={this.state.dataSeq.length > 0 ? this.state.dataSeq[0].dateUTC : -1}
              max={this.state.dataSeq.length > 0 ? this.state.dataSeq[this.state.dataSeq.length - 1].dateUTC : 0}
              classes={{
                colorPrimary: classes.colorPrimary,
                rail: classes.rail,
                track: classes.track,
                mark: classes.mark,
                thumb: classes.thumb,
              }}
              disabled={this.props.mapDate !== undefined}
              onChange={(ev, value) => {
                if (this.props.mapDate === undefined) {
                  if (ev && ev.type && ev.type === 'keydown') {
                    var keyboardEvent: any = ev
                    if (keyboardEvent.key && keyboardEvent.key === 'ArrowLeft') {
                      var currIndex = this.state.dataSeq.findIndex((data: any) => data.dateUTC === this.state.sliderValue)
                      if (currIndex > 0 && currIndex <= this.state.dataSeq.length - 1) {
                        this.updateTimeUTCIndex(currIndex - 1)
                      }
                    } else if (keyboardEvent.key && keyboardEvent.key === 'ArrowRight') {
                      var currIndex = this.state.dataSeq.findIndex((data: any) => data.dateUTC === this.state.sliderValue)
                      if (currIndex >= 0 && currIndex < this.state.dataSeq.length - 1) {
                        this.updateTimeUTCIndex(currIndex + 1)
                      }
                    }
                  } else {
                    if (!Array.isArray(value)) {
                      this.updateTimeUTC(value);
                    }
                  }
                }
              }}
            />
          }
        </div>}
      </div>}
    </ReactResizeDetector>)
  }
}

const pageWithStyles = withStyles(styles, { withTheme: true })(TimeSliderMap)

const mapStateToProps = (state: any) => ({
  sessionRecipeInfo: state.sessionRecipeInfo,
  // saveRecipeAlert: state.saveRecipeAlert,
});

const mapDispatchToProps = (dispatch: any) => ({});

// export default connect (mapStateToProps, mapDispatchToProps) (pageWithStyles);
export default pageWithStyles;