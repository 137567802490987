import React, {  useState } from 'react';
import {  AppBar, Button, Divider, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import ContactUsDialog from '../components/ContactUsDialog'

const useStyles = makeStyles((theme: any) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    position: "relative",
    backgroundColor: "#004785",
    // backgroundColor: "#326b9d",
    padding: "10px!important",
    width: '100%',
  },
  buttonsContainer: {
    
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "5px",
    margingBottom: "5px",
    [theme.breakpoints.up('md')]: {
      flexDirection: "row",
      alignItems: "center",
      display: "flex",
    },
    [theme.breakpoints.down('sm')]: {
      display: "inline",
      marginLeft: "0px!important",
      marginRight: "0px!important",
    },
    
  }
}));

export default function Footer() {
  const classes = useStyles();
  const [openContactUs, setOpenContactUs] = useState<boolean>(false);

  return (<div>
    <AppBar position="absolute" classes={{ root: classes.appBar }}>
      <div className={classes.buttonsContainer}>
        <Button className="aboutFooter"  style={{color: "white"}}>
          <Link to='/about' style={{ color: 'inherit', textDecoration: "none" }}>
            About
          </Link>
        </Button>
        {/* <Button style={{color: "white"}} href='/apiDoc'>
          API Documentation
        </Button> */}
        <Button style={{color: "white"}} onClick={() => { setOpenContactUs(true) }}>
          Contact Us
        </Button>
      </div>
      <div style={{textAlign: "left", margin: '5px 0px', fontSize: '0.80em'}}>
        © 2020-2023 - Penn Wharton Budget Model at the University of Pennsylvania
      </div>
    </AppBar>
    <ContactUsDialog open={openContactUs} onClose={() => { setOpenContactUs(false) }} />
  </div>);
}
