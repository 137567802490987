import React, { useEffect, useState } from 'react';
import * as Highcharts from 'highcharts/highstock';
import NoData from 'highcharts/modules/no-data-to-display';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Boost from 'highcharts/modules/boost';
import HighchartsReact from 'highcharts-react-official';
// import { Series } from '../SeriesPage/SeriesPage';
// import { VizSeries } from '../DataVisualizationPage/DataVisualizationPage';
import { frequencySettings, ONE_DAY } from '../definitions/FrequencySettings';
import { stateInfo } from '../definitions/StateInfo';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ReactDOMServer from 'react-dom/server';

NoData(Highcharts)
Exporting(Highcharts)
OfflineExporting(Highcharts)
// boost should always be last applied
Boost(Highcharts)

const seriesBoostTheshold = 10
const pointBoostTheshold = 1000

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
    thousandsSep: ',',
    numericSymbols: ['k', 'M', 'B', 'T'],
    contextButtonTitle: 'Download chart image',
  }
});

Highcharts.SVGRenderer.prototype.symbols.download = () => {
  const iconString = ReactDOMServer.renderToString(<PhotoCameraIcon />);
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(iconString, 'image/svg+xml');
  const iconPath = svgDoc.querySelector('path')?.getAttribute('d') as string;

  return [iconPath];
}

// need to update all functions. maybe just a matter of updating data structure

function parseDataItemsToSeries(series: any, unitChange = 'none', unitChangeFrom = 'prevPeriod', yAxis = 0) {
  var parsed = {} as any;
  var output = [] as any[];

  parsed = {
    type: 'line',
    name: series.name,
    frequency: series.frequency,
    // data_type: series.info.data_type, // disabled for now
    yAxis: series.chart_attributes.axisRight ? 1 : 0,
    data: [],
    compare: unitChangeFrom !== 'rangeShown' ? undefined : unitChange === 'change' ? 'value' : unitChange === 'percentChange' ? 'percent' : undefined,
    compareStart: true,
    numDecimals: 0
  }

  series.data_points.forEach((item: any, i: number) => {
    var value = parseFloat(item.value)
    var period = Date.parse(item.key)

    if (!isNaN(value)) {
      var numDec = countDecimals(value)
      if (numDec > parsed.numDecimals) {
        parsed.numDecimals = numDec
      }
      parsed.data.push([
        period,
        value
      ])
    }
  })

  if (parsed.data.length > pointBoostTheshold) {
    parsed.marker = { enabled: false }
  }

  // output = Object.keys(parsed).map(function (keyName: string, keyIndex: number) {
  //   var value = parsed[keyName];
  //   value.data.sort((a: any, b: any) => a[0] - b[0])
  //   return value
  // })
  output = [{...parsed}]
  output[0].data.sort((a: any, b: any) => a[0] - b[0])

  if (unitChange === 'change' && unitChangeFrom === 'prevPeriod') {
    output = calcChange(output)
  }

  if (unitChange === 'percentChange' && unitChangeFrom === 'prevPeriod') {
    output = calcPercentChange(output)
  }

  if (unitChange === 'change' && unitChangeFrom === 'rangeStart') {
    output = calcCumulativeChange(output)
  }

  if (unitChange === 'percentChange' && unitChangeFrom === 'rangeStart') {
    output = calcCumulativePercentChange(output)
  }

  if (unitChange === 'change' && unitChangeFrom === 'rangeShown') {
    if (!hasValues(output)) {
      output = calcCumulativeChange(output)
    }
  }

  if (unitChange === 'percentChange' && unitChangeFrom === 'rangeShown') {
    if (!hasValues(output)) {
      output = calcCumulativePercentChange(output)
    }
  }

  output.forEach((s: any, i: number) => {
    var tempData = [] as any[]
    s.data.forEach((p: any, j: number) => {
      if (j !== 0) {
        var maxDays = 0;
        if (frequencySettings[s.frequency] !== undefined) {
          maxDays = frequencySettings[s.frequency].maxDays
        }
        if (p[0] - s.data[j - 1][0] > maxDays * ONE_DAY) {
          if (p.length < 3) {
            tempData.push([p[0] - 1, null])
          } else {
            tempData.push([p[0] - 1, null, null])
          }
        }
      }
      if (p.length < 3) {
        tempData.push([p[0], p[1]])
      } else {
        tempData.push([p[0], p[1], p[2]])
      }
    })
    s.data = tempData
  })

  return output
}

function calcChange(seriesInfo: any[]) {
  var output = [...seriesInfo]
  output.forEach((s: any, i: number) => {
    if (s.data !== undefined && s.data !== null && s.data.length !== 0) {
      var calcSeries = {...s}
      calcSeries.data = [...s.data]
      s.data.forEach((p: any, j: number) => {
        var changeValue: number | null = null
        if (j !== 0) {
          var maxDays = 0;
          if (frequencySettings[s.frequency] !== undefined) {
            maxDays = frequencySettings[s.frequency].maxDays
          }
          if (s.data[j][0] - s.data[j - 1][0] <= maxDays * ONE_DAY) {
            changeValue = s.data[j][1] - s.data[j - 1][1]
          }
        }
        calcSeries.data[j] = [p[0], changeValue, s.data[j][1]]
      })
      output[i] = calcSeries
    }
  })

  return output
}

function calcCumulativeChange(seriesInfo: any[]) {
  var output = [...seriesInfo]
  output.forEach((s: any, i: number) => {
    if (s.data !== undefined && s.data !== null && s.data.length !== 0) {
      var calcSeries = {...s}
      calcSeries.data = [...s.data]
      var indexFirstVal = 0
      var indexSet = false
      s.data.forEach((p: any, j: number) => {
        if (!indexSet && p[1] !== null && p[1] !== 0) {
          indexSet = true
          indexFirstVal = j
        }
      })
      s.data.forEach((p: any, j: number) => {
        if (indexSet) {
          var changeValue: number
          changeValue = s.data[j][1] - s.data[indexFirstVal][1]
          calcSeries.data[j] = [p[0], changeValue, s.data[j][1]]
        } else {
          calcSeries.data[j] = [p[0], null, s.data[j][1]]
        }
      })
      output[i] = calcSeries
    }
  })
  return output
}

function calcPercentChange(seriesInfo: any[]) {
  var output = [...seriesInfo]
  output.forEach((s: any, i: number) => {
    if (s.data !== undefined && s.data !== null && s.data.length !== 0) {
      var calcSeries = {...s}
      calcSeries.data = [...s.data]
      s.data.forEach((p: any, j: number) => {
        var changeValue: number | null = null
        if (j !== 0) {
          var maxDays = 0;
          if (frequencySettings[s.frequency] !== undefined) {
            maxDays = frequencySettings[s.frequency].maxDays
          }
          if (s.data[j][0] - s.data[j - 1][0] <= maxDays * ONE_DAY) {
            changeValue = ((s.data[j][1] - s.data[j - 1][1]) / s.data[j - 1][1]) * 100
          }
        }
        calcSeries.data[j] = [p[0], changeValue, s.data[j][1]]
      })
      output[i] = calcSeries
    }
  })
  return output
}

function calcCumulativePercentChange(seriesInfo: any[]) {
  var output = [...seriesInfo]
  output.forEach((s: any, i: number) => {
    if (s.data !== undefined && s.data !== null && s.data.length !== 0) {
      var calcSeries = {...s}
      calcSeries.data = [...s.data]
      var indexFirstVal = 0
      var indexSet = false
      s.data.forEach((p: any, j: number) => {
        if (!indexSet && p[1] !== null && p[1] !== 0) {
          indexSet = true
          indexFirstVal = j
        }
      })
      s.data.forEach((p: any, j: number) => {
        if (indexSet) {
          var changeValue: number
          changeValue = ((s.data[j][1] - s.data[indexFirstVal][1]) / s.data[indexFirstVal][1]) * 100
          calcSeries.data[j] = [p[0], changeValue, s.data[j][1]]
        } else {
          calcSeries.data[j] = [p[0], null, s.data[j][1]]
        }
      })
      output[i] = calcSeries
    }
  })
  return output
}

function hasValues(seriesInfo: any[]) {
  var output = false
  seriesInfo.forEach((s: any, i: number) => {
    if (s.data !== undefined && s.data !== null && s.data.length !== 0) {
      s.data.forEach((p: any, j: number) => {
        if (!output && p[1] !== null && p[1] !== 0) {
          output = true
        }
      })
    }
  })
  return output
}

var countDecimals = function (value: number) {
  if (Math.floor(value) === value || !value.toString().includes(".")) {
    return 0
  } else {
    return value.toString().split(".")[1].length || 0;
  }
}

function TimeSeriesChart(props: HighchartsReact.Props) {
  const { series, mapSeries, frequency, vizPage, unitChange, unitChangeFrom, locSelectedObj, sessionRecipe } = props;
  const [chartoptions, setChartOptionsState] = useState({
    boost: {
      useGPUTranslations: true,
      // usePreallocated: true,
      // Chart-level boost when there are more than 5 series in the chart
      seriesThreshold: seriesBoostTheshold * 2
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    series: []
  } as Highcharts.Options)

  useEffect(() => {
    // console.log('on change')
    var numYAxis = 0;
    var chartSeries = [] as any[]
    if (!vizPage) {
      chartSeries = series.filter((s: any) => s !== undefined);
      chartSeries.forEach((s: any, i: number) => {
        if (vizPage) {
          s.chart_attributes = {
            show: false,
            frequency: frequency,
            axisRight: false,
            // TODO may be moved under meta when sessions implemented
            ...s.chart_attributes,
          }
        } else {
          s.chart_attributes = {
            show: true,
            frequency: frequency,
            axisRight: false,
          }
        }
        numYAxis++
      })
      // console.log(chartSeries)
      chartSeries = chartSeries.filter((s: any) => s.chart_attributes.show);
    } else {
      // chartSeries = sessionRecipe.filter((s: any) => s !== undefined);
      sessionRecipe.forEach((s: any, i: number) => {
        if (s.displayed && s.result.series !== null && s.result.series.length > 0 && s.meta !== null && s.meta.chart_attributes && s.meta.chart_attributes.show) {
          chartSeries.push({
            ...s.result.series[0],
            name: s.name,
            chart_attributes: {
              show: true,
              frequency: frequency,
              axisRight: false,
              ...s.meta.chart_attributes,
            }
          })
          numYAxis++
        }
        if (s.displayed && s.result.maps !== null && s.result.maps.length > 0 && s.meta !== null && s.meta.chart_attributes && s.meta.chart_attributes.show) {
          var output = [] as any[]
          Object.keys(locSelectedObj).forEach((map_scale: string) => {
            if (s.result.maps[0].map_scale === map_scale || (map_scale === 'state' && (s.result.maps[0].map_scale === null || s.result.maps[0].map_scale === undefined))) {
              locSelectedObj[map_scale].forEach((l: any) => {
                var child: any = s.result.maps[0].children.find((c: any) => c.locationCode === l.locationCode && (l.locationCode !== '-1' || l.location === c.location))
                if (child !== undefined) {
                  output.push({ 
                    ...child,
                    name: s.name + ', ' + ((child.locationCode === '-1' || map_scale === 'city' || map_scale === 'county') ? child.location : stateInfo[child.locationCode] ? stateInfo[child.locationCode].abbreviation : child.locationCode),
                    chart_attributes: {
                      show: true,
                      frequency: frequency,
                      axisRight: false,
                      showYAxis: false,
                      axisName: s.name,
                      ...s.meta.chart_attributes,
                    }
                  })
                }
              })
            }
          })
          if (output.length > 0) {
            output[0].chart_attributes.showYAxis = true
            numYAxis++
          }
          chartSeries = chartSeries.concat(output)
        }
      })
    }

    setChartOptions(chartSeries, numYAxis, frequency, unitChange, unitChangeFrom);
  },[series, mapSeries, frequency, unitChange, unitChangeFrom, sessionRecipe]);
  

  
  function setChartOptions(series: any[], numYAxis: number, frequency: string, unitChange: string, unitChangeFrom: string) {
    // console.log('setChartOptions')
    var parsedSeries: any[] = series.length > 0 ? series.map((s: any, i: number) => parseDataItemsToSeries(s, unitChange, unitChangeFrom, i)).reduce((acc: any[], val: any[]) => acc.concat(val)) : []
    // console.log('parsedSeries')
    // console.log(series)
    // console.log(parsedSeries)
    // console.log(frequency)
    var exportFilename: string = 'Unnamed Series';
    if (vizPage) {
      exportFilename = 'Workspace Chart'
    } else if (series.length > 0) {
      exportFilename = ''
      var seriesName = series[0].name
      if (seriesName.length > 50) {
        exportFilename += seriesName.substring(0,30) + ' . . . '
        exportFilename += seriesName.substring(seriesName.length - 10, seriesName.length)
      } else {
        exportFilename += seriesName
      }
    }
    var yAxis = [{
      opposite: false,
      maxPadding: 0,
      minPadding: 0,
      title: {
        text: ''
      },
      labels: {
        // format: unitChange === 'percentChange' ? '{value:,.-1f}%' :'{value:,.-1f}',
        x: -8,
        y: 3
      }
    }, {
      opposite: true,
      maxPadding: 0,
      minPadding: 0,
      title: {
        text: ''
      },
      labels: {
        // format: unitChange === 'percentChange' ? '{value:,.-1f}%' :'{value:,.-1f}',
        y: series.length > 1 ? 3 : -2,
      },
    }] as any[]
    if (numYAxis < 6) {
      yAxis = yAxis.concat(series.map((s: any, i: number) => {
        var text = ''
        // currently no reliable way to access unit/data_type. by hard coding unit to null will prevent relevant lines from running.
        // leaving in code in case available in the future.
        // var unit = s.info.data_type
        var unit = null
        if (unitChange === 'change') {
          if (unit !== undefined && unit !== null) {
            unit = 'Change (' + unit + ')'
          } else {
            unit = 'Change'
          }
          if (unitChangeFrom === 'rangeShown' || unitChangeFrom === 'rangeStart') {
            unit = 'Cumulative ' + unit
          }
        }
        if (unitChange === 'percentChange') {
          if (unit !== undefined && unit !== null) {
            unit = 'Percent Change (' + unit + ')'
          } else {
            unit = 'Percent Change'
          }
          if (unitChangeFrom === 'rangeShown' || unitChangeFrom === 'rangeStart') {
            unit = 'Cumulative ' + unit
          }
        }
        if (unit !== undefined && unit !== null) {
          text += unit;
        }
        if (series.length > 1) {
          text = '<span style="font-weight: 600">'
          var name = s.name
          if (s.chart_attributes.axisName !== undefined) {
            name = s.chart_attributes.axisName
          }
          if (name.length > 30) {
            text += name.substring(0,18) + ' . . . ' + name.substring(name.length - 9, name.length)
          } else {
            text += name
          }
          if (unit !== undefined && unit !== null) {
            text += '</span> - ' + unit
          }
        }
        return ({
          opposite: s.chart_attributes.axisRight,
          visible: s.chart_attributes.showYAxis === undefined || s.chart_attributes.showYAxis,
          title: {
            text: text,
            style: {
              letterSpacing: '0.3px'
            }
          },
        })
      }))
    }
    // console.log('start setChartOptionsState')
    setChartOptionsState({
      chart: {
        height: parsedSeries.length > 1 ? Math.min(430 + 20 * parsedSeries.length, 710) : 400,
        animation: false
      },
      boost: {
        useGPUTranslations: true,
        // usePreallocated: true,
        // Chart-level boost when there are more than 5 series in the chart
        seriesThreshold: seriesBoostTheshold * 2
      },
      credits: {
        enabled: false
      },
      lang: {
        noData: 'No data to display'
      },
      noData: {
        style: {
          fontSize: '15px',
        },
      },
      exporting: {
        sourceWidth: 800,
        filename: exportFilename,
        buttons: {
          contextButton: {
            symbol: 'download',
            menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] // removed "viewFullscreen", "printChart", "separator", 
          }
        },
        chartOptions: {
          caption: {
            text: '<a href="' + window.location.href + '">' + window.location.href + '</a>'
          },
          legend: {
            enabled: true,
            labelFormatter: function () {
              var self: any = this;
              var str = ''
              if (self.name.length > 75) {
                str += self.name.substring(0,55) + ' . . . '
                str += self.name.substring(self.name.length - 10, self.name.length)
              } else {
                str += self.name
              }
              str += ' (' + self.userOptions.frequency + ')'
              return str
            }
          },
        },
      },
      title: {
        text: ''
      },
      rangeSelector: {
        buttons: [{
          type: 'year',
          count: 5,
          text: '5Y'
        }, {
          type: 'year',
          count: 10,
          text: '10Y'
        }, {
          type: 'all',
          text: 'All'
        }],
        buttonTheme: {
          width: 37
        },
        selected: 2
      },
      legend: {
        enabled: parsedSeries.length > 1 ? true : false,
        layout: 'vertical',
        maxHeight: 250,
        labelFormatter: function () {
          var self: any = this;
          var str = ''
          if (self.name.length > 75) {
            str += self.name.substring(0,55) + ' . . . '
            str += self.name.substring(self.name.length - 10, self.name.length)
          } else {
            str += self.name
          }
          if (frequency === 'All') {
            str += ' (' + self.userOptions.frequency + ')'
          }
          return str
        }
      },
      xAxis: {
        minRange: 1000 * 60 * 60 * 24,
        type: 'datetime',
        ordinal: false,
        labels: {
          formatter: frequency === 'All' ? undefined : function () {
            var self: any = this;
            var periodDisplay = frequencySettings['NotSet'].dateFormatter(self.value);
            if (frequencySettings[frequency] && frequencySettings[frequency].displayRank < 15) {
              periodDisplay = frequencySettings[frequency].dateFormatter(self.value)
            }
            return periodDisplay;
          }
        }
      },
      yAxis: yAxis,
      tooltip: {
        split: false,
        useHTML: true,
        outside: true,
        formatter() {
          var self: any = this;
  
          var periodDisplay = frequencySettings['NotSet'].dateFormatter(self.x);
  
          if (frequencySettings[self.series.userOptions.frequency]) {
            periodDisplay = frequencySettings[self.series.userOptions.frequency].dateFormatter(self.x)
          }

          var unit = ''
          // currently no reliable way to access unit/data_type. data_type will always be undefined for now.
          // leaving in code in case available in the future.
          if (self.series.userOptions.data_type !== undefined && self.series.userOptions.data_type !== null && self.series.userOptions.data_type !== '') {
            unit = self.series.userOptions.data_type
            if (unitChange === 'change') {
              unit = 'Change, ' + unit
            }
            if (unitChange === 'percentChange') {
              unit = 'Percent Change, ' + unit
            }
          } else {
            if (unitChange === 'change') {
              unit = 'Change'
            }
            if (unitChange === 'percentChange') {
              unit = 'Percent Change'
            }
          }

          if (unitChange !== 'none' && (unitChangeFrom === 'rangeShown' || unitChangeFrom === 'rangeStart') && unit !== '') {
            unit = 'Cumulative ' + unit
          }

          if (unit !== '') {
            unit = ' (' + unit + ')'
          }

          var value = self.y
          var origValue = self.y
          var displayValue = ''
          var numDecimals = self.series.userOptions.numDecimals

          if (unitChangeFrom === 'rangeShown') {
            value = self.point.change
          }

          if (unitChangeFrom === 'prevPeriod' || unitChangeFrom === 'rangeStart') {
            origValue = parsedSeries[self.series.index].data[self.point.index][2]
          }

          if (unitChange === 'change') {
            var changeIndicator = ''
            if (value >= 0) {
              changeIndicator = '+'
            }
            displayValue = '<b>' + Highcharts.numberFormat(origValue, numDecimals, '.', ',') + '</b> (' + changeIndicator + Highcharts.numberFormat(value, numDecimals, '.', ',') + ')'
          } else if (unitChange === 'percentChange') {
            var changeIndicator = ''
            if (value >= 0) {
              changeIndicator = '+'
            }
            displayValue = '<b>' + Highcharts.numberFormat(origValue, numDecimals, '.', ',') + '</b> (' + changeIndicator + Highcharts.numberFormat(value, 2, '.', ',') + '%)'
          } else {
            displayValue = '<b>' + Highcharts.numberFormat(value, numDecimals, '.', ',') + '</b>'
          }

          var str = '<div style="overflow-wrap: break-word">'
          str += '<span style="font-size:10px">' + periodDisplay
          if (frequency === 'All') {
            str += ' (' + self.series.userOptions.frequency + ')'
          }
          str += '</span>'
          str += '<br><span style="color:' + self.series.color + '">\u25CF</span> ' + self.series.name
          str += unit
          str += ': ' + displayValue
          str += '</div>'
  
          return str;
        }
      },
      plotOptions: {
        series: {
          boostThreshold: pointBoostTheshold,
          showInNavigator: true,
          animation: false,
          // animation: {
          //   duration: 1000
          // },
          // compare: compare,
          dataGrouping: {
            enabled: false
          },
          label: {
            enabled: false
          },
          // lineWidth: 2,
          marker: {
            // enabled: parsedSeries.length > 1 || (parsedSeries.length === 1 && parsedSeries[0].data.length === 1) ? undefined : false,
            // enabled: true,
            enabled: parsedSeries.length >= seriesBoostTheshold ? false : undefined,
            radius: 2.5,
            enabledThreshold: 2,
          }
        }
      },
      series: parsedSeries
    })
  }

  return (
    <div style={{ userSelect: "none", msUserSelect: "none" }}>
      <HighchartsReact
        key={series.length > 0 ? series.length + frequency + chartoptions.series?.length : series.length + frequency + chartoptions.series?.length}
        highcharts={Highcharts}
        options={chartoptions}
        constructorType={'stockChart'}
        {...props}
      />
    </div>
  )
}

export default TimeSeriesChart;