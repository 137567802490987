import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, FormHelperText, Tooltip } from '@mui/material';
import { useState } from 'react';
import { DataResult, Map } from '../Models/DataResult';
import TimeSeriesChart from '../components/TimeSeriesChart';
import TimeSliderMap from '../components/TimeSliderMap';
import DataTable from '../components/DataTable';
import MapDataTable from '../components/MapDataTable';
import { addVizSeriesSuccess } from '../actions'
import { addResultToViz } from '../thunks'
import { useSelector, useDispatch } from 'react-redux'
import { endpoint, MAX_DATA_VIZ, MAX_MAP_DATA_VIZ } from '../App';
import { find } from 'highcharts';


interface ChartViewDialogProps {
  open: boolean,
  onClose: (submitted: boolean) => void,
  data: DataResult,
  result_id: string,
  selectedRow: number,
  page: number
}

export default function ChartViewDialog(props: ChartViewDialogProps) {
  const [unitChangeSelected, setUnitChangeSelected] = useState<string>("none");
  const [unitChangeFromSelected, setUnitChangeFromSelected] = useState<string>("prevPeriod");
  const [selected, setSelected] = React.useState<number[]>([] as number[]);
  const [selectedMap, setSelectedMap] = React.useState<Map | null>(null);
  const dispatch = useDispatch();
  const [descriptionLength, setDescriptionLength] = useState<number>(115);

  const nodeCounters = useSelector((state: any) => state.nodeCounters)
  const isLoadingVizNode = useSelector((state: any) => state.isLoadingVizNode)
  React.useEffect(() => {
    if (props.open === true) {
      findDescriptionLength(descriptionLength , props.data.description);
      setSelected([props.selectedRow]);
      if (props.data.maps !== null && props.data.maps.length > 0) {
        if (props.data.maps.length > 0) {
          setSelectedMap(props.data.maps[0]);
        } else {
          setSelectedMap(null)
        }
      }
    }
  }, [props])
  // TODO Handle single row maps
  // React.useEffect(() =>{
  //   // console.log(props);
  //   if (props.open && (props.data.series.length > 0 || props.data.maps.length > 0)) {

  //   }
  // }, [props])
  const handleClose = (submitted: boolean) => {
    props.onClose(submitted);
    setUnitChangeSelected('none')
    setUnitChangeFromSelected('prevPeriod')
    setSelected([] as number[])
    setSelectedMap(null)
  };

  const findDescriptionLength = (len: number, des: string) =>{
    var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d") as  CanvasRenderingContext2D ;
      context.font = "1rem Arial";
      var width = context.measureText(des.slice(0, len) + "...").width;
      var formattedWidth = Math.ceil(width);
      // console.log(formattedWidth);
      var diff = formattedWidth - 805;
      // console.log(diff);
      if(diff > 0 && diff >= 100)
      {
        var newLen = len -10
        findDescriptionLength(newLen, des);
      }
      else if(diff > 0 && diff < 100)
      {
        var newLen = len-1
        findDescriptionLength(newLen, des);
      }
      else{
        setDescriptionLength(len);
      }
  }

  const handleSubmit = () => {
    // console.log(selected)
    if (selected.length > 0) {
      selected.forEach((sIndex) => {
        if (props.data.series !== null) {
          var seriesToAdd = props.data.series.find((s, i) => i === sIndex)
          if (seriesToAdd !== undefined) {
            console.log(props)
            dispatch(addResultToViz(props.result_id, sIndex, {
              ...props.data,
              maps: null,
              series: [seriesToAdd], 
            }))
          }
        }

        if (props.data.maps !== null) {
          var mapToAdd = props.data.maps.find((s, i) => i === sIndex)
          if (mapToAdd !== undefined) {
            dispatch(addResultToViz(props.result_id, sIndex, {
              ...props.data,
              maps: [mapToAdd],
              series: null,
            }))
          }
        }
      })
      handleClose(true);
    }
  };

  const handleRowClick = (newSelected: number[]) => {
    setSelected(newSelected);
    if (props.data.maps !== null && props.data.maps.length > 0) {
      if (newSelected.length > 0 && props.data.maps.length > newSelected[0]) {
        setSelectedMap(props.data.maps[newSelected[0]])
      } else {
        setSelectedMap(null)
      }
    }
  };

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    // style={{ zIndex: 1200 }}
    >
      <DialogTitle>{props.data.name}{props.data.description.length > 115 ? <Tooltip title={props.data.description} arrow><Typography variant="body1">{props.data.description.slice(0, descriptionLength)}...</Typography></Tooltip> :
        <Typography variant="body1">{props.data.description}</Typography>}</DialogTitle>
      <DialogContent style={{ paddingTop: "10px" }}>

        {(props.data.series && props.data.series.length > 0) && <div>
          <div>
            <FormControl style={{ minWidth: '165px', marginRight: '15px' }}>
              <InputLabel id="unit-change-select-label">Unit Change</InputLabel>
              <Select
                labelId="unit-change-select-label"
                id="unit-change-select"
                value={unitChangeSelected}
                label="Unit Change"
                onChange={(event: SelectChangeEvent) => {
                  setUnitChangeSelected(event.target.value as string);
                }}
              >
                <MenuItem value='none'>None</MenuItem>
                <MenuItem value='change'>Change</MenuItem>
                <MenuItem value='percentChange'>Percent Change</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: '211px', marginRight: '15px' }}>
              <InputLabel id="unit-change-from-select-label">Unit Change From</InputLabel>
              <Select
                labelId="unit-change-from-select-label"
                id="unit-change-from-select"
                value={unitChangeFromSelected}
                disabled={unitChangeSelected === 'none'}
                label="Unit Change From"
                onChange={(event: SelectChangeEvent) => {
                  setUnitChangeFromSelected(event.target.value as string);
                }}
              >
                <MenuItem value='prevPeriod'>Previous Period</MenuItem>
                <MenuItem value='rangeShown'>Start of Range Shown*</MenuItem>
                <MenuItem value='rangeStart'>Start of Total Range*</MenuItem>
              </Select>
              {unitChangeFromSelected !== 'prevPeriod' && <FormHelperText>* First non-null, non-zero value</FormHelperText>}
            </FormControl>
          </div>
          <div style={{ margin: '15px 0px' }}>
            <TimeSeriesChart
              frequency={props.data.series[0].frequency}
              series={props.data.series.filter((s, i) => selected.includes(i))}
              vizPage={false}
              unitChange={unitChangeSelected}
              unitChangeFrom={unitChangeFromSelected}
            />
          </div>
          <div style={{ maxHeight: '70vh' }}>
            <DataTable data={props.data} handleRowClick={handleRowClick} selectable={true} showLocation={true} page={props.page} selected={selected} />
          </div>
        </div>}
        {(props.data.maps && props.data.maps.length > 0) && <div>
          <div style={{ margin: '15px 0px' }}>
            <TimeSliderMap
              seriess={selectedMap !== null ? selectedMap.children : []}
              frequency={(selectedMap !== null && selectedMap.children.length > 0) ? selectedMap.children[0].frequency : 'NotSet'}
              seriesName={selectedMap !== null ? selectedMap.name : 'Unnamed Map'}
              unitSuffix={''}
              posNegColorAxis={true}
              colorAxisCrossoverPoint={0}
              yPlotLines={[{
                color: '#bbbbbb',
                width: 2,
                value: 0
              }]}
              map_scale={(selectedMap !== null && selectedMap.map_scale) ? selectedMap.map_scale : 'state'}
              summary_row={(selectedMap !== null) ? selectedMap.summary_row : null}
            />
          </div>
          <MapDataTable data={props.data} handleRowClick={handleRowClick} selectable={true} selected={selected} />
        </div>}
      </DialogContent>
      <DialogActions>
        <Tooltip title={(props.data.series !== null && props.data.series.length > 0 && nodeCounters.series >= MAX_DATA_VIZ) ? 'Max time series in workspace reached. Max ' +  MAX_DATA_VIZ + ' allowed.'
        : (props.data.series !== null && props.data.series.length > 0 && nodeCounters.series + selected.length > MAX_DATA_VIZ) ? 'Too many time series selected. Only ' + (MAX_DATA_VIZ - nodeCounters.series) + ' more can be added to workspace and ' + (selected.length) + ' selected.'
        : (props.data.maps !== null && props.data.maps.length > 0 && nodeCounters.maps >= MAX_MAP_DATA_VIZ) ? 'Max maps in workspace reached. Max ' +  MAX_MAP_DATA_VIZ + ' allowed.'
        : ''} arrow placement='top' >
          <span>
            <Button disabled={
              isLoadingVizNode
              || (props.data.series !== null && props.data.series.length > 0 && (nodeCounters.series >= MAX_DATA_VIZ || nodeCounters.series + selected.length > MAX_DATA_VIZ))
              || (props.data.maps !== null && props.data.maps.length > 0 && nodeCounters.maps >= MAX_MAP_DATA_VIZ)} onClick={handleSubmit}>Add to Workspace</Button>
          </span>
        </Tooltip>
        <Button onClick={() => handleClose(false)}>Back to Search</Button>
      </DialogActions>
    </Dialog>
  );
}