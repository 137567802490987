export const REMOVE_VIZ_SERIES = 'REMOVE_VIZ_SERIES'
export const removeVizSeries = (id: string, nodeType: string) => ({
  type: REMOVE_VIZ_SERIES,
  payload: { id, nodeType }
})

export const ADD_VIZ_SERIES_LOADING = 'ADD_VIZ_SERIES_LOADING'
export const addVizSeriesLoading = () => ({
  type: ADD_VIZ_SERIES_LOADING
})

export const CLOSE_ADD_ALERT = 'CLOSE_ADD_ALERT'
export const closeAddAlert = () =>({
  type: CLOSE_ADD_ALERT
})

export const ADD_VIZ_SERIES_SUCCESS = 'ADD_VIZ_SERIES_SUCCESS'
export const addVizSeriesSuccess = (result: any, recipeInfo: any) => ({
  type: ADD_VIZ_SERIES_SUCCESS,
  payload: { result, recipeInfo }
})

export const RELOAD_VIZ_SERIES_SUCCESS = 'RELOAD_VIZ_SERIES_SUCCESS'
export const reloadVizSeriesSuccess = (result: any, recipeInfo: any) => ({
  type: RELOAD_VIZ_SERIES_SUCCESS,
  payload: { result, recipeInfo }
})

export const INCREMENT_DERIVED_SERIES_COUNT = 'INCREMENT_DERIVED_SERIES_COUNT'
export const incrementDerivedSeriesCount = (transformType: string) => ({
  type: INCREMENT_DERIVED_SERIES_COUNT,
  payload: { transformType }
})

export const CLEAR_TABLE = 'CLEAR_TABLE'
export const clearTable = () => ({
  type: CLEAR_TABLE
})

export const SET_IS_MODIFIED = 'SET_IS_MODIFIED'
export const setIsModified = () => ({
  type: SET_IS_MODIFIED,
})

export const ADD_VIZ_SERIES_FAILURE = 'ADD_VIZ_SERIES_FAILURE'
export const addVizSeriesFailure = (message: string) => ({
  type: ADD_VIZ_SERIES_FAILURE,
  payload: { message }
})

export const SHOW_CHART_VIZ_SERIES = 'SHOW_CHART_VIZ_SERIES'
export const showChartVizSeries = (id: string) => ({
  type: SHOW_CHART_VIZ_SERIES,
  payload: { id }
})

export const CHANGE_AXIS_VIZ_SERIES = 'CHANGE_AXIS_VIZ_SERIES'
export const changeAxisVizSeries = (id: string, axisRight: boolean) => ({
  type: CHANGE_AXIS_VIZ_SERIES,
  payload: { id, axisRight }
})

export const SAVE_RECIPE_SUCCESS = 'SAVE_RECIPE_SUCCESS'
export const saveRecipeSuccess = (recipe: any) => ({
  type: SAVE_RECIPE_SUCCESS,
  payload: { ...recipe }
})

export const SAVE_RECIPE_FAILURE = 'SAVE_RECIPE_FAILURE'
export const saveRecipeFailure = (name: string) => ({
  type: SAVE_RECIPE_FAILURE,
  payload: { name }
})

export const LOAD_RECIPE_SUCCESS = 'LOAD_RECIPE_SUCCESS'
export const loadRecipeSuccess = (recipe: any) => ({
  type: LOAD_RECIPE_SUCCESS,
  payload: { ...recipe }
})

export const LOAD_RECIPE_FAILURE = 'LOAD_RECIPE_FAILURE'
export const loadRecipeFailure = (name: string) => ({
  type: LOAD_RECIPE_FAILURE,
  payload: { name }
})

export const UPDATE_RECIPE_DATA_SUCCESS = 'UPDATE_RECIPE_DATA_SUCCESS'
export const updateRecipeDataSuccess = (nodes: any[]) => ({
  type: UPDATE_RECIPE_DATA_SUCCESS,
  payload: { nodes }
})

export const UPDATE_RECIPE_DATA_FAILURE = 'UPDATE_RECIPE_DATA_FAILURE'
export const updateRecipeDataFailure = () => ({
  type: UPDATE_RECIPE_DATA_FAILURE,
})

export const SAVE_LOAD_RECIPE_IN_PROGRESS = 'SAVE_LOAD_RECIPE_IN_PROGRESS'
export const saveLoadRecipeInProgress = () => ({
  type: SAVE_LOAD_RECIPE_IN_PROGRESS,
})

export const CLEAR_RECIPE_INFO = 'CLEAR_RECIPE_INFO'
export const clearRecipeInfo = () => ({
  type: CLEAR_RECIPE_INFO,
})

export const CLOSE_SAVE_LOAD_ALERT = 'CLOSE_SAVE_LOAD_ALERT'
export const closeSaveLoadRecipeAlert = () => ({
  type: CLOSE_SAVE_LOAD_ALERT,
})

export const NEW_RECIPE = 'NEW_RECIPE'
export const newRecipe = () => ({
  type: NEW_RECIPE,
})

export const UPDATE_USER = 'UPDATE_USER'
export const updateUser = (token: string, recipeList: any[], success: boolean) => ({
  type: UPDATE_USER,
  payload: { token, recipeList, success }
})

export const UPDATE_RECIPE_LIST_SUCCESS = 'UPDATE_RECIPE_LIST_SUCCESS'
export const updateRecipeListSuccess = (recipeList: any[]) => ({
  type: UPDATE_RECIPE_LIST_SUCCESS,
  payload: { recipeList }
})

export const UPDATE_RECIPE_LIST_FAILURE = 'UPDATE_RECIPE_LIST_FAILURE'
export const updateRecipeListFailure = () => ({
  type: UPDATE_RECIPE_LIST_FAILURE,
})

export const USER_RECIPE_LIST_LOADING = 'USER_RECIPE_LIST_LOADING'
export const userRecipeListLoading = () => ({
  type: USER_RECIPE_LIST_LOADING,
})

export const STALE_SESSION_ALERT = 'STALE_SESSION_ALERT'
export const staleSessionAlert = () => ({
  type: STALE_SESSION_ALERT,
})

export const CLOSE_STALE_SESSION_ALERT = 'CLOSE_STALE_SESSION_ALERT'
export const closeStaleSessionAlert = () => ({
  type: CLOSE_STALE_SESSION_ALERT,
})

export const SET_VIZ_VIEW = 'SET_VIZ_VIEW'
export const setVizView = (view: string) => ({
  type: SET_VIZ_VIEW,
  payload: { view }
})

export const SET_UNIT_CHANGE = 'SET_UNIT_CHANGE'
export const setUnitChange = (unitChange: string) => ({
  type: SET_UNIT_CHANGE,
  payload: { unitChange }
})

export const SET_UNIT_CHANGE_FROM = 'SET_UNIT_CHANGE_FROM'
export const setUnitChangeFrom = (unitChangeFrom: string) => ({
  type: SET_UNIT_CHANGE_FROM,
  payload: { unitChangeFrom }
})

export const SET_CHART_TABLE_LOC_OPTIONS = 'SET_CHART_TABLE_LOC_OPTIONS'
export const setChartTableLocOptions = (nodes: any[]) => ({
  type: SET_CHART_TABLE_LOC_OPTIONS,
  payload: { nodes }
})

export const SET_CHART_TABLE_LOC_SELECTED_OBJ = 'SET_CHART_TABLE_LOC_SELECTED_OBJ'
export const setChartTableLocSelectedObj = (map_scale: string, locSelected: any[]) => ({
  type: SET_CHART_TABLE_LOC_SELECTED_OBJ,
  payload: { map_scale, locSelected }
})

export const SET_MAP_PRIMARY_ID = 'SET_MAP_PRIMARY_ID'
export const setMapPrimaryId = (mapPrimaryId: string | null, mapPrimaryFrequency: string | null, mapPrimaryScale: string | null) => ({
  type: SET_MAP_PRIMARY_ID,
  payload: { mapPrimaryId, mapPrimaryFrequency, mapPrimaryScale }
})

export const SET_MAP_SECONDARY_ID = 'SET_MAP_SECONDARY_ID'
export const setMapSecondaryId = (id: string , mapSecondaryId: string | null) => ({
  type: SET_MAP_SECONDARY_ID,
  payload: { id, mapSecondaryId }
})

export const SET_SIDE_CHART_VIEW = 'SET_SIDE_CHART_VIEW'
export const setSideChartView = (id: string, sideChartView: string) => ({
  type: SET_SIDE_CHART_VIEW,
  payload: { id, sideChartView }
})

export const SET_SIDE_CHART_FIXED_AXIS = 'SET_SIDE_CHART_FIXED_AXIS'
export const setSideChartFixedAxis = (id: string, sideChartFixedAxis: boolean) => ({
  type: SET_SIDE_CHART_FIXED_AXIS,
  payload: { id, sideChartFixedAxis }
})

export const SET_MAP_SHOW_ALL = 'SET_MAP_SHOW_ALL'
export const setMapShowAll = (id: string, showAll: boolean) => ({
  type: SET_MAP_SHOW_ALL,
  payload: { id, showAll }
})

export const SET_MAP_SHOW_ALL_SUMMARY = 'SET_MAP_SHOW_ALL_SUMMARY'
export const setMapShowAllSummary = (id: string, showAllSummary: boolean) => ({
  type: SET_MAP_SHOW_ALL_SUMMARY,
  payload: { id, showAllSummary }
})

export const SET_MAP_SELECTED_LOCATIONS = 'SET_MAP_SELECTED_LOCATIONS'
export const setMapSelectedLocations = (id: string, selectedLocations: any[]) => ({
  type: SET_MAP_SELECTED_LOCATIONS,
  payload: { id, selectedLocations }
})