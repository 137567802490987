import React, {  useEffect, useState } from 'react';
import { makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { InputLabel, FormHelperText, FormControl, Select, IconButton, TextField,  DialogContent, MenuItem, Menu, CircularProgress, DialogActions, MenuList, Tooltip, Snackbar, List, ListItem, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { MAX_DATA_VIZ, MAX_MAP_DATA_VIZ } from '../App';
import { useSelector, useDispatch } from 'react-redux'
import { fetchCalculateSeries } from '../thunks'
import { frequencySettings } from '../definitions/FrequencySettings';
import { mapScaleInfo } from '../definitions/MapScaleInfo';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme: any) => ({
  loadingRoot: {
    width: "75px",
    height: "75px",
    color: "#004785"
  },
  dialogRoot: {
    // height: '100%',
    maxWidth: '1000px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "30%",
  },
  operatorSelectRoot: {
    fontSize: '1.2em',
    // paddingBottom: '3px',
    '&:focus': {
      backgroundColor: 'initial',
    },
  },
}));

function parseExpressionEditNode(expression: Expression[], nodes: any[] = [] as any[]) {
  var output = expression.map((item) => {
    if (item.type === 'series') {
      var currFunction = item.payload.function
      if (currFunction === null) {
        currFunction = 'none'
      }
      var currValue = ''
      var currNode = nodes.find((n: any) => n.id === item.payload.id)
      if (currNode) {
        currValue = currNode.id + ';' + currNode.meta.chart_attributes.frequency + ';ts'
      }
      return {
        ...item,
        payload: {
          ...item.payload,
          function: currFunction,
          isMap: false,
          value: currValue,
          map_scale: "",
          map_name: "",
        },
      } as Expression
    } if (item.type === 'map') {
      var currFunction = item.payload.function
      if (currFunction === null) {
        currFunction = 'none'
      }
      var currValue = ''
      var currScale = ''
      var currName = ''
      var currNode = nodes.find((n: any) => n.id === item.payload.id)
      if (currNode) {
        currValue = currNode.id + ';' + currNode.meta.chart_attributes.frequency + ';map;' + currNode.meta.chart_attributes.map_scale + ';' + currNode.meta.chart_attributes.map_location_name
        currScale = currNode.meta.chart_attributes.map_scale
        currName = currNode.meta.chart_attributes.map_location_name
      }
      return {
        ...item,
        payload: {
          ...item.payload,
          function: currFunction,
          isMap: true,
          value: currValue,
          map_scale: currScale,
          map_name: currName,
        },
        type: 'series'
      } as Expression
    } else if (item.type === 'parentheses') {
      var currFunction = item.payload.function
      if (currFunction === null) {
        currFunction = 'none'
      }
      return {
        ...item,
        payload: {
          ...item.payload,
          function: currFunction,
          expression: parseExpressionEditNode(item.payload.expression, nodes)
        } as any
      } as Expression
    } else {
      return item
    }
  }) as Expression[]
  return output
}

export interface CalculateDialogProps {
  open: boolean,
  onClose: () => void,
  editNode?: any,
}

export interface Expression{
    type: string,
    payload: any
}

export default function CalculateDialog(props: CalculateDialogProps) {
  const classes = useStyles();

  const FUNCTIONS = [{name: "None", value: "none"}, {name: "Natural Log", value: "ln"} ,{ name: "Absolute Value", value: "abs"}, {name: "Square Root", value: "sqrt"},
                      {name: "Negative", value: "negative"}, {name: "e^x", value: "e_x"}, {name: "Lag (t - k)", value: "lag"}]; 
  const CONSTANTS = [{name: "Pi", value: "pi"}, {name: "e (Euler's number)", value: "e"}]
  const OPERATORS = ["+", "-", "*", "/", "^"];
  const { onClose, open, editNode } = props;
  const [anchorEl, setAnchorEl] = React.useState<(null | HTMLElement)[]>([null]);
  const [expression, setExpression] = React.useState<Expression[]>([{type: "series", payload: {function: "none", k: 1, id: "", isMap: false, value: "", map_scale: "", map_name: ""}}] as Expression[]); 
  const [selectedFrequency, setSelectedFrequencyState] = React.useState<string>("");
  const [mapScale, setMapScaleState] = useState<string>("");
  const [mapName, setMapNameState] = useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  // TODO condense series/maps used/short to nodes used/short
  const [seriesUsed, setSeriesUsed] = React.useState<any[]>([] as any[]);
  const [mapsUsed, setMapsUsed] = React.useState<any[]>([] as any[]);
  const [parenCount, setParenCount] = React.useState<number>(0);
  const [shortNames, setShortNamesState] = React.useState<string[]>([] as string[]);
  const [mapShortNames, setMapShortNamesState] = React.useState<string[]>([] as string[]);
  const [editMapError, setEditMapError] = useState<boolean>(false);

  const dispatch = useDispatch()
  const isLoadingVizNode = useSelector((state: any) => state.isLoadingVizNode)
  const nodeCounters = useSelector((state: any) => state.nodeCounters)
  const sessionRecipe = useSelector((state: any) => state.sessionRecipe)

  useEffect(() => {
    // on open
    if (editNode !== undefined && editNode.type === 'transformation' && editNode.transformation_id.indexOf('calculations') !== -1) {
      var map_scale = ''
      var map_name = ''
      if (editNode.meta && editNode.meta.chart_attributes && editNode.meta.chart_attributes.map_scale) {
        map_scale = editNode.meta.chart_attributes.map_scale
        if (editNode.meta.chart_attributes.map_location_name) {
          map_name = editNode.meta.chart_attributes.map_location_name
        }
      }
      var expressionEditNode = parseExpressionEditNode(editNode.params.expression, sessionRecipe)
      var { series_ids, maps_ids } = getSeriesIdsInExpression(expressionEditNode)
      var series = [] as any[];
      series_ids.forEach((id: string) => {
        var s = sessionRecipe.find((s: any) => s.displayed && s.id === id)
        if (s !== undefined) {
          series.push(s)
        }
      })
      var maps = [] as any[]
      maps_ids.forEach((id: string) => {
        var m = sessionRecipe.find((m: any) => m.displayed && m.id === id)
        if (m !== undefined) {
          maps.push(m)
        }
      })

      setShortNames(series)
      setMapShortNames(maps)

      setSeriesUsed(series)
      setMapsUsed(maps)
      setSelectedFrequencyState(editNode.params.frequency);
      setMapScaleState(map_scale)
      setMapNameState(map_name)
      // console.log(map_scale, map_name)
      // set last because triggers set description (readable version of expression)
      setExpression(expressionEditNode);
    }
  }, [open]);

  useEffect(() =>{
    setAnchorEl(Array(expression.length).fill(null))
    setDescription(processExpression(expression, seriesUsed, shortNames, mapsUsed, mapShortNames));
  },[expression]);

  // enforce selected frequency/map_scale if editing a node
  function setSelectedFrequency(f: string) {
    if (editNode === undefined) {
      setSelectedFrequencyState(f)
    }
  }

  function setMapScale(s: string) {
    // allow mapscale to change
    // if (editNode === undefined) {
    //   setMapScaleState(s)
    // }
    setMapScaleState(s)
  }

  function setMapName(s: string) {
    // allow map name to change
    // if (editNode === undefined) {
    //   setMapNameState(s)
    // }
    setMapNameState(s)
  }

  function processExpression(exp: Expression[], series_used: any[], short_names: string[], maps_used: any[], map_short_names: string[]){
    var d = "";
    exp.forEach((e) => {
      if(e.type === "series" && e.payload.id !== ""){
        var shortNameIndex = -1
        var shortName = ''
        if (e.payload.isMap) {
          shortNameIndex = maps_used.findIndex((m: any) => m.id === e.payload.id)
          shortName = ''
          if (shortNameIndex > -1) {
            shortName = 'Map: ' + map_short_names[shortNameIndex]
          }
        } else {
          shortNameIndex = series_used.findIndex(s => s.id === e.payload.id)
          shortName = ''
          if (shortNameIndex > -1) {
            shortName = short_names[shortNameIndex]
          }
        }
        if(e.payload.function === "none")
        {
          d +=  " ( " + shortName + " ) ";
        }
        else if(e.payload.function === "abs"){
          d += " | " + shortName + " | ";
        }
        else if(e.payload.function === "e_x")
        {
          d += " e^( " + shortName + " ) ";
        }
        else if(e.payload.function === "sqrt")
        {
          d += " sqrt( " + shortName + " ) ";
        }
        else if(e.payload.function === "negative")
        {
          d += " -( " + shortName + " ) ";
        }
        else if(e.payload.function === "lag")
        {
          if (e.payload.k === -1) {
            d += " Lag [t + 1] ( " + shortName + " ) ";
          } else {
            d += " Lag [t - " + e.payload.k + "] ( " + shortName + " ) ";
          }
        }
        else
        {
          // console.log(e.payload.function);
          d +=  " ln( " + shortName + " ) ";
        }
      }
      else if(e.type === "operator" && e.payload !== "")
      {
        d += " " +  e.payload + " ";
      }
      else if(e.type === "number")
      {
        d+= " " + e.payload + " ";
      }
      else if(e.type === "parentheses")
      {
        if(e.payload.function === "none")
        {
          d += " (  " + processExpression(e.payload.expression, series_used, short_names, maps_used, map_short_names) + " ) ";
        }
        else if(e.payload.function === "abs"){
          d += " |(  " + processExpression(e.payload.expression, series_used, short_names, maps_used, map_short_names) + " )| ";
        }
        else if(e.payload.function === "e_x")
        {
          d += " e^(  " + processExpression(e.payload.expression, series_used, short_names, maps_used, map_short_names) +  " ) ";
        } 
        else if(e.payload.function === "sqrt")
        {
          d += " sqrt(  " + processExpression(e.payload.expression, series_used, short_names, maps_used, map_short_names) + " ) ";
        }
        else if(e.payload.function === "negative")
        {
          d += " -(  " + processExpression(e.payload.expression, series_used, short_names, maps_used, map_short_names) + " ) ";
        }
        else if(e.payload.function === "lag")
        {
          if (e.payload.k === -1) {
            d += " Lag [t + 1] ( " + processExpression(e.payload.expression, series_used, short_names, maps_used, map_short_names) + " ) ";
          } else {
            d += " Lag [t - " + e.payload.k + "] ( " + processExpression(e.payload.expression, series_used, short_names, maps_used, map_short_names) + " ) ";
          }
        }
        else
        {
          d += " ln(  " + processExpression(e.payload.expression, series_used, short_names, maps_used, map_short_names) + " ) ";
        }
        
      }
      else if(e.type === "constant")
      {
        if(e.payload === "pi")
        {
          d += " pi ";
        }
        else if(e.payload === "e")
        {
          d += " e ";
        }
      }
    })
    return d;
  }
  
  const handleClose = () => {
    onClose();
  };

  const clearExpression = () =>{
    setExpression([] as Expression[]);
    setSelectedFrequency("");
    setMapScale("");
    setMapName("");
    setSeriesUsed([] as any[])
    setMapsUsed([] as any[])
    setShortNamesState([] as string[])
    setMapShortNamesState([] as string[])
  }

  const openInsertList = (event: React.MouseEvent<HTMLElement>, position: number) => {
    var temp = Object.assign([], anchorEl) as (HTMLElement | null)[];
    temp[position] = event.currentTarget;
    setAnchorEl(temp);
  };

  const addSeriesLine = (position: number) =>{
      if(expression.length > 0)
      {
        var temp = [] as Expression[];
        if(position === expression.length)
        {
          temp = Object.assign([], expression) as Expression[];
          temp.push({type: "series", payload: {function: "none", k: 1, id: "", isMap: false, value: "", map_scale: "", map_name: ""} as any})
        }
        else
        {
          expression.forEach((e, i) => {
            if(i === position){
              temp.push({type: "series", payload: {function: "none", k: 1, id: "", isMap: false, value: "", map_scale: "", map_name: ""} as any});
              temp.push(e);
            }
            else
            {
              temp.push(e);
            }
          })
        }
        setExpression(temp);
      }
      else
      {
          setExpression([{type: "series", payload: {function: "none", k: 1, id: "", isMap: false, value: "", map_scale: "", map_name: ""} as any}]);
      }
  }

  const addOperator = (position: number) =>{
    if(expression.length > 0)
      {
        var temp = [] as Expression[];
        if(position === expression.length)
        {
          temp = Object.assign([], expression) as Expression[];
          temp.push({type: "operator", payload: ""})
        }
        else
        {
          expression.forEach((e, i) => {
            if(i === position){
              temp.push({type: "operator", payload: ""});
              temp.push(e);
            }
            else
            {
              temp.push(e);
            }
          })
        }
        setExpression(temp);
      }
      else
      {
          setExpression([{type: "operator", payload: ""}]);
      }
  }

  const addConstant = (position: number) =>{
    if(expression.length > 0)
      {
        var temp = [] as Expression[];
        if(position === expression.length)
        {
          temp = Object.assign([], expression) as Expression[];
          temp.push({type: "constant", payload: ""})
        }
        else
        {
          expression.forEach((e, i) => {
            if(i === position){
              temp.push({type: "constant", payload: ""});
              temp.push(e);
            }
            else
            {
              temp.push(e);
            }
          })
        }
        setExpression(temp);
      }
      else
      {
          setExpression([{type: "constant", payload: ""}]);
      }
  }

  const addParentheses = (position: number) =>{
    if(expression.length > 0)
      {
        var temp = [] as Expression[];
        if(position === expression.length)
        {
          temp = Object.assign([], expression) as Expression[];
          temp.push({type: "parentheses", payload: {function: "none", k: 1, expression: [] as Expression[]}})
        }
        else
        {
          expression.forEach((e, i) => {
            if(i === position){
              temp.push({type: "parentheses", payload: {function: "none", k: 1, expression: [] as Expression[]}});
              temp.push(e);
            }
            else
            {
              temp.push(e);
            }
          })
        }
        setExpression(temp);
      }
      else
      {
          setExpression([{type: "parentheses", payload: {function: "none", k: 1, expression: [] as Expression[]}}]);
      }
  }

  
  const addNumber = (position: number) =>{
    if(expression.length > 0)
      {
        var temp = [] as Expression[];
        if(position === expression.length)
        {
          temp = Object.assign([], expression) as Expression[];
          temp.push({type: "number", payload: ""})
        }
        else
        {
          expression.forEach((e, i) => {
            if(i === position){
              temp.push({type: "number", payload: ""});
              temp.push(e);
            }
            else
            {
              temp.push(e);
            }
          })
        }
        setExpression(temp);
      }
      else
      {
          setExpression([{type: "number", payload: ""}]);
      }
  }
  const closeInsertList = (position: number) =>{
    var temp = Object.assign([], anchorEl) as (HTMLElement | null)[];
    temp[position] = null;
    setAnchorEl(temp);
  }

  const selectSeries = (position: number, event: React.ChangeEvent<{ value: unknown }>) =>{
      var temp = Object.assign([], expression) as Expression[];
      if(temp[position].type === "series")
      {
        if (event.target.value === '') {
          temp[position].payload.id = '';
          setSelectedFrequency('');
          setMapScale('');
          setMapName('');
        } else {
          var selectedString = (event.target.value as String).split(';');
          temp[position].payload.id = selectedString[0];
          temp[position].payload.value = event.target.value as String;
          if(selectedFrequency === "")
          {
            setSelectedFrequency(selectedString[1]);
          }
          var isMap = selectedString[2] === 'map'
          temp[position].payload.isMap = isMap;
          if (isMap) {
            temp[position].payload.map_scale = selectedString[3];
            temp[position].payload.map_name = selectedString[4];
            if (!mapsUsed.some((series: any) => series.id === selectedString[0])) {
              var mapToAdd = sessionRecipe.find((s: any) => s.id === selectedString[0]);
              if (mapToAdd !== undefined) {
                var newMapsUsed = mapsUsed
                newMapsUsed.push(mapToAdd)
                setMapsUsed(newMapsUsed)
                setMapShortNames(newMapsUsed)
              }
            }
          } else {
            temp[position].payload.map_scale = "";
            temp[position].payload.map_name = "";
            if (!seriesUsed.some((series: any) => series.id === selectedString[0])) {
              var seriesToAdd = sessionRecipe.find((s: any) => s.id === selectedString[0]);
              if (seriesToAdd !== undefined) {
                var newSeriesUsed = seriesUsed
                newSeriesUsed.push(seriesToAdd)
                setSeriesUsed(newSeriesUsed)
                setShortNames(newSeriesUsed)
              }
            }
          }

          var { map_scale, map_name } = getMapInfoFromExpression(temp)
          setMapScale(map_scale)
          setMapName(map_name)
          // console.log(map_scale, map_name)
        }
        setExpression(temp);
      }

  }

  const selectFunction = (position: number, event: React.ChangeEvent<{value: unknown}>) =>{
    var temp = Object.assign([], expression) as Expression[]; 
    if(temp[position].type === "series" || temp[position].type === "parentheses")
    {
      temp[position].payload.function = event.target.value as string;
      setExpression(temp);
    }


  }

  const selectK = (position: number, event: React.ChangeEvent<{value: unknown}>) =>{
    var temp = Object.assign([], expression) as Expression[]; 
    if(temp[position].type === "series" || temp[position].type === "parentheses")
    {
      temp[position].payload.k = event.target.value as number;
      setExpression(temp);
    }


  }

  const selectOperator = (position: number, event: React.ChangeEvent<{value: unknown}>) =>{
    var temp = Object.assign([], expression) as Expression[];
    if(temp[position].type === "operator")
    {
      temp[position].payload = event.target.value as string;
      setExpression(temp);
    }
  }

  const selectConstant = (position: number, event: React.ChangeEvent<{value: unknown}>) =>{
    var temp = Object.assign([], expression) as Expression[];
    if(temp[position].type === "constant")
    {
      temp[position].payload = event.target.value as string;
      setExpression(temp);
    }
  }

  const handleChangeNumber = (position: number, event: React.ChangeEvent<HTMLInputElement>) => {
    var temp = Object.assign([], expression) as Expression[];
    if(temp[position].type === "number")
    {
      temp[position].payload = event.target.value as string;
      setExpression(temp);
    }
  }

  const handleParenChange = (position: number, exp: Expression[], frequency: string, map_scale: string, map_name: string, used: any[], usedMaps: any[]) =>{
    var temp = Object.assign([], expression) as Expression[];
    // setSeriesUsed(used);
    // setShortNames(used)
    var newSeriesUsed = seriesUsed
    used.forEach((used_series: any) => {
      if (!seriesUsed.some((series: any) => series.id === used_series.id)) {
        var seriesToAdd = sessionRecipe.find((s: any) => s.id === used_series.id);
        if (seriesToAdd !== undefined) {
          newSeriesUsed.push(seriesToAdd)
        }
      }
    })
    setSeriesUsed(newSeriesUsed)
    setShortNames(newSeriesUsed)
    var newMapSeriesUsed = mapsUsed
    usedMaps.forEach((used_series: any) => {
      if (!mapsUsed.some((series: any) => series.id === used_series.id)) {
        var seriesToAdd = sessionRecipe.find((s: any) => s.id === used_series.id);
        if (seriesToAdd !== undefined) {
          newMapSeriesUsed.push(seriesToAdd)
        }
      }
    })
    setMapsUsed(newMapSeriesUsed)
    setMapShortNames(newMapSeriesUsed)
    if(temp[position].type === "parentheses")
    {
      temp[position].payload.expression = exp;
      setExpression(temp);
    }
    if(frequency !== selectedFrequency && frequency !== "")
    {
      setSelectedFrequency(frequency);
    }
    else if(frequency === "")
    {
      if(temp.findIndex(e => e.type === "series") === -1)
      {
        setSelectedFrequency("");
      }
      else
      {
        var i = temp.findIndex(e => e.type === "series");
        if(temp[i].payload.id === "")
        {
          setSelectedFrequency("");
        }
      }
    }
    var { map_scale, map_name } = getMapInfoFromExpression(temp)
    setMapScale(map_scale)
    setMapName(map_name)
    // console.log(map_scale, map_name)
  }

  const removeLine = (position: number) =>{
    var temp = Object.assign([], expression) as Expression[];
    var removed = temp.splice(position, 1);
    if(removed[0].type === "series"){
      if(temp.findIndex(e => e.type === "series") === -1)
      {
        setSelectedFrequency("");
        setMapScale('')
        setMapName('')
      }
      else
      {
        var i = temp.findIndex(e => e.type === "series");
        if(temp[i].payload.id === "")
        {
          setSelectedFrequency("");
          setMapScale('')
          setMapName('')
        }
      }
    }
    setExpression(temp);
  }

  const setShortNames = (seriess: any[]) =>{
    var outputShort = [] as string[]
    var outputIds = [] as string[]
    seriess.forEach((series: any) => {
      var shortName = series.name
      if (shortName.length > 25) {
        shortName = shortName.substring(0,22) + '...'
      }

      if (outputShort.includes(shortName)) {
        var i = 1
        while (outputShort.includes(shortName + '[' + i + ']')) { i++ }
        outputShort.push(shortName + '[' + i + ']')
      } else {
        outputShort.push(shortName)
      }
      outputIds.push(series.id)
    })

    setShortNamesState(outputShort)

    return { outputShort, outputIds }
  }

  const setMapShortNames = (seriess: any[]) =>{
    var outputShort = [] as string[]
    var outputIds = [] as string[]
    seriess.forEach((series: any) => {
      var shortName = series.name
      if (shortName.length > 25) {
        shortName = shortName.substring(0,22) + '...'
      }

      if (outputShort.includes(shortName)) {
        var i = 1
        while (outputShort.includes(shortName + '[' + i + ']')) { i++ }
        outputShort.push(shortName + '[' + i + ']')
      } else {
        outputShort.push(shortName)
      }
      outputIds.push(series.id)
    })

    setMapShortNamesState(outputShort)

    return { outputMapShort: outputShort, outputMapIds: outputIds }
  }

  function getSeriesIdsInExpression(exp: Expression[]) {
    var output_ids = {
      series_ids: [] as string[],
      maps_ids: [] as string[]
    };
    exp.forEach((item: any) => {
      if (item.type === 'series') {
        if (item.payload.isMap) {
          if (!output_ids.maps_ids.includes(item.payload.id)) {
            output_ids.maps_ids.push(item.payload.id)
          }
        } else {
          if (!output_ids.series_ids.includes(item.payload.id)) {
            output_ids.series_ids.push(item.payload.id)
          }
        }
      } else if (item.type === 'parentheses') {
        var inner_ids = getSeriesIdsInExpression(item.payload.expression)
        inner_ids.series_ids.forEach((i_id: string) => {
          if (!output_ids.series_ids.includes(i_id)) {
            output_ids.series_ids.push(i_id)
          }
        })
        inner_ids.maps_ids.forEach((i_id: string) => {
          if (!output_ids.maps_ids.includes(i_id)) {
            output_ids.maps_ids.push(i_id)
          }
        })
      }
    })
    return output_ids
  }

  function getMapInfoFromExpression(exp: Expression[]) {
    var outputScale = "";
    var outputName = "";
    exp.forEach((item: any) => {
      if (item.type === 'series') {
        if (item.payload.isMap) {
          var changed = false
          if (!mapScaleInfo[item.payload.map_scale] || !mapScaleInfo[outputScale] || mapScaleInfo[item.payload.map_scale].level > mapScaleInfo[outputScale].level) {
            outputScale = item.payload.map_scale
            changed = true
          }
          if (outputScale === item.payload.map_scale) {
            if (outputName !== item.payload.map_name) { 
              if (changed || (outputScale !== 'city' && outputScale !== 'county') || (!item.payload.map_name.includes('US '))) {
                outputName = item.payload.map_name
              }
            }
          }
        }
      } else if (item.type === 'parentheses') {
        var changed = false
        var inner_map_info = getMapInfoFromExpression(item.payload.expression)
        // console.log(inner_map_info)
        var inner_map_scale = inner_map_info.map_scale
        var inner_map_name = inner_map_info.map_name
        if (inner_map_scale !== '') {
          if (!mapScaleInfo[inner_map_scale] || !mapScaleInfo[outputScale] || mapScaleInfo[inner_map_scale].level > mapScaleInfo[outputScale].level) {
            outputScale = inner_map_scale
            changed = true
          }
          if (outputScale === inner_map_scale) {
            if (outputName !== inner_map_name) { 
              if (changed || (outputScale !== 'city' && outputScale !== 'county') || (!inner_map_name.includes('US '))) {
                outputName = inner_map_name
              }
            }
          }
        }
      }
    })
    return { map_scale: outputScale, map_name: outputName }
  }

  return (
    <div>
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="md" fullWidth={true} scroll='paper' classes={{ paper: classes.dialogRoot }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle >Calculate</DialogTitle>
        {/* <Tooltip title="More info" arrow>
          <IconButton  
            style={{ flexGrow: 0, flexShrink: 0, color: '#326b9c', backgroundColor: '#ffffff', textDecoration: 'none', textDecorationColor: 'rgba(255,255,255,0)', margin: '16px 24px', padding: '0' }}  
            target="_blank" href="https://visualization-page.s3.amazonaws.com/How+to+Use+the+Visualization+Page_20210729.pdf#page=19"
          >
            <InfoIcon fontSize="inherit" />
          </IconButton>
        </Tooltip> */}
      </div>
      {editNode !== undefined && <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
        <i>Editing {editNode.name}</i><br/>
        <span style={{ color: editMapError ? '#d32f2f' : 'inherit' }}>Cannot change the frequency, map scale, or type (i.e. series or map).</span>
      </div>}
      <div style={{ boxSizing: 'border-box', width: "90%", alignSelf: 'center', margin: '0px 24px', marginBottom: '16px' }}>
         All series must have the same frequency to be used in a calculation. Frequency of first series selected will be used as the frequency of the calculation. If any series selected are maps, the result will be a map.
      </div>
      <DialogContent style={{ boxSizing: 'border-box', alignSelf: "center", width: "90%", position: 'relative' }}>
        <div style={{ position: 'relative', width: '100%' }}>
          {(isLoadingVizNode) && <div style={{ boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', zIndex: 1, backgroundColor: 'rgba(255,255,255,0.5)' }}><CircularProgress thickness={3} className={classes.loadingRoot} /></div>}
          {expression.length > 0 && <Typography variant="subtitle2" style={{ fontSize: '16px' }}>
                <strong>{description}</strong>
            </Typography>}
          {expression.length > 0 && <List>
            {expression.map((e, i) =>{
              if(e.type === "series")
              {
                return(
                  <ListItem key={i}>
                            <Tooltip title="Insert Line Above">
                              <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, i + 1)}
                                style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                                  <AddIcon />
                              </IconButton>
                          </Tooltip>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl[i + 1]}
                        keepMounted
                        open={Boolean(anchorEl[i + 1])}
                        onClose={() => closeInsertList(i + 1)}
                        >
                          <MenuItem onClick={() => addSeriesLine(i)}>Series / Map</MenuItem>
                          <MenuItem onClick={() => addOperator(i)} >Operator</MenuItem>
                          <MenuItem onClick={() => addNumber(i)}>Number</MenuItem>
                          <MenuItem onClick={() => addConstant(i)} >Constant</MenuItem>
                          <MenuItem onClick={() => addParentheses(i)}>Parentheses</MenuItem>
                        </Menu>
                        <Tooltip title="Remove Line">
                              <IconButton aria-label="add" onClick={(event: any) => removeLine(i)}
                                style={{ backgroundColor:"#c5093b", color: "white", padding: "5px", marginLeft: "5px", marginRight: '20px'}} >
                                  <RemoveIcon />
                              </IconButton>
                          </Tooltip>
                        <FormControl style={{width: "70%"}} className={classes.formControl}>
                            <InputLabel id="series-label">Series / Map</InputLabel>
                            <Select
                              labelId="series-label"
                              label="Series / Map"
                              value={e.payload.value}
                              onChange={(event: any) => selectSeries(i, event)}
                            >
                              {(sessionRecipe.length > 0) 
                                ? sessionRecipe.map((node: any, i: number) => {
                                  var output = [] as any[]
                                  if (editNode === undefined || (node.id !== editNode.id && node.meta && node.meta.ancestors && !node.meta.ancestors.includes(editNode.id) && node.meta.dataType && (node.meta.dataType === 'series' || node.meta.dataType === editNode.meta.dataType))) {
                                    if (node.displayed && (node.meta.dataType === undefined || node.meta.dataType === 'series') && (editNode !== undefined || nodeCounters.series < MAX_DATA_VIZ || nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
                                      if (node.result.series !== null && node.result.series.length > 0) {
                                        var frequency = node.result.series[0].frequency
                                        var displayRank = 1000;
                                        if (frequency !== null && frequencySettings[frequency] !== undefined) {
                                          displayRank = frequencySettings[frequency].displayRank
                                        }
                                        if (displayRank < 1000 && (selectedFrequency === "" || selectedFrequency === frequency)) {
                                          output.push(<MenuItem value={node.id + ';' + frequency + ';ts'} key={node.id + 'calculate'}>{node.name + ', ' + frequency}</MenuItem>)
                                        }
                                      }
                                    } else if (node.displayed && (node.meta.dataType !== undefined && node.meta.dataType === 'map') && (editNode !== undefined || nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
                                      if (node.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0) {
                                        var frequency = node.result.maps[0].children[0].frequency
                                        var map_scale = node.result.maps[0].map_scale
                                        var map_name = node.meta.chart_attributes.map_location_name
                                        var displayRank = 1000;
                                        if (frequency !== null && frequencySettings[frequency] !== undefined) {
                                          displayRank = frequencySettings[frequency].displayRank
                                        }
                                        var mapScale_ancestor = []
                                        if (mapScaleInfo[mapScale]) {
                                          mapScale_ancestor = mapScaleInfo[mapScale].ancestors
                                        }
                                        var map_scale_ancestor = []
                                        if (mapScaleInfo[map_scale]) {
                                          map_scale_ancestor = mapScaleInfo[map_scale].ancestors
                                        }
                                        if (displayRank < 1000 && (selectedFrequency === "" || selectedFrequency === frequency) 
                                          && (
                                            ((mapScale === '' || map_scale === mapScale) && (mapName === '' || map_name === mapName || ((map_scale === 'county' || map_scale === 'city') && (map_name.includes('US ') || mapName.includes('US ')))))
                                            || (mapScale_ancestor.includes(map_scale) || map_scale_ancestor.includes(mapScale))
                                          )
                                        ) {
                                          output.push(<MenuItem value={node.id + ';' + frequency + ';map;' + map_scale + ';' + map_name} key={node.id + 'calculate'}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                              <div className="mapIcon mapIcon_inSelect">
                                                <div className="mapIcon_map">Map</div>
                                                {node.meta.chart_attributes.map_scale && <div>{(node.meta.chart_attributes.map_location_abbreviation !== '') ? node.meta.chart_attributes.map_location_abbreviation : node.meta.chart_attributes.map_scale}</div>}
                                              </div>
                                              {node.name + ', ' + frequency}
                                            </div>
                                          </MenuItem>)
                                        }
                                      }
                                    }
                                  }
                                  return output
                                })
                                : <MenuItem value=""><em>No series available.</em></MenuItem>
                              }
                              {(editNode === undefined && nodeCounters.series >= MAX_DATA_VIZ && nodeCounters.maps >= MAX_MAP_DATA_VIZ) && <MenuItem value="" disabled><em>Max time series in workspace reached</em></MenuItem>}
                              {(editNode === undefined && nodeCounters.maps >= MAX_MAP_DATA_VIZ) && <MenuItem value="" disabled><em>Max maps in workspace reached</em></MenuItem>}
                            </Select>
                      </FormControl>
                      <div className={classes.formControl} style={{display: "flex"}}>
                        <FormControl style={{width: "100%"}}>
                              <InputLabel id="func-label">Function</InputLabel>
                              <Select
                                labelId="func-label"
                                label="Function"
                                id="func-select"
                                value={e.payload.function}
                                onChange={(event: any) => selectFunction(i, event)}
                              >
                                {FUNCTIONS.map((f, j) =>{
                                  return(
                                    <MenuItem value={f.value} key={f.value}>{f.name}</MenuItem>
                                  )
                                })}
                              </Select>
                        </FormControl>
                        {e.payload.function === 'lag' && <FormControl style={{width: '60%', marginLeft: '8px'}}>
                              <InputLabel id="k-label">k</InputLabel>
                              <Select
                                label="k"
                                labelId="k-label"
                                id="k-select"
                                value={e.payload.k}
                                onChange={(event: any) => selectK(i, event)}
                              >
                                <MenuItem value={-1}>k = -1 (lead)</MenuItem>
                                <MenuItem value={1}>k = 1 (first-order)</MenuItem>
                                <MenuItem value={2}>k = 2 (second-order)</MenuItem>
                              </Select>
                        </FormControl>}
                      </div>
                  </ListItem>
                )
              }
              else if(e.type === "operator")
              {
                return(
                  <ListItem key={i}>
                  <Tooltip title="Insert Line Above">
                    <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, i + 1)}
                      style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[i + 1]}
              keepMounted
              open={Boolean(anchorEl[i + 1])}
              onClose={() => closeInsertList(i + 1)}
              >
                <MenuItem onClick={() => addSeriesLine(i)}>Series / Map</MenuItem>
                <MenuItem onClick={() => addOperator(i)}>Operator</MenuItem>
                <MenuItem onClick={() => addNumber(i)}>Number</MenuItem>
                <MenuItem onClick={() => addConstant(i)} >Constant</MenuItem>
                <MenuItem onClick={() => addParentheses(i)}>Parentheses</MenuItem>
              </Menu>
              <Tooltip title="Remove Line">
                              <IconButton aria-label="add" onClick={(event: any) => removeLine(i)}
                                style={{ backgroundColor:"#c5093b", color: "white", padding: "5px", marginLeft: "5px", marginRight: '20px'}} >
                                  <RemoveIcon />
                              </IconButton>
                          </Tooltip>
              <FormControl style={{width: "70%"}} className={classes.formControl}>
                  <InputLabel id="op-label">Operator</InputLabel>
                  <Select
                    labelId="op-label"
                    label="Operator"
                    value={e.payload}
                    onChange={(event: any) => selectOperator(i, event)}
                    classes={{ root: classes.operatorSelectRoot }}
                  >
                    {OPERATORS.map((op, j) =>{
                      return(
                        <MenuItem style={{fontSize: "1.2em"}} value={op} key={op}>{op}</MenuItem>
                      )
                    })}
                  </Select>
            </FormControl>
            </ListItem>
                )
              }
              else if(e.type === "number")
              {
                return(
                  <ListItem key={i}>
                  <Tooltip title="Insert Line Above">
                    <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, i + 1)}
                      style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[i + 1]}
              keepMounted
              open={Boolean(anchorEl[i + 1])}
              onClose={() => closeInsertList(i + 1)}
              >
                <MenuItem onClick={() => addSeriesLine(i)}>Series / Map</MenuItem>
                <MenuItem onClick={() => addOperator(i)}>Operator</MenuItem>
                <MenuItem onClick={() => addNumber(i)}>Number</MenuItem>
                <MenuItem onClick={() => addConstant(i)} >Constant</MenuItem>
                <MenuItem onClick={() => addParentheses(i)}>Parentheses</MenuItem>
              </Menu>
              <Tooltip title="Remove Line">
                              <IconButton aria-label="add" onClick={(event: any) => removeLine(i)}
                                style={{ backgroundColor:"#c5093b", color: "white", padding: "5px", marginLeft: "5px", marginRight: '20px'}} >
                                  <RemoveIcon />
                              </IconButton>
                          </Tooltip>
              <TextField
                style={{width: "70%", marginLeft: "7px"}}
                label="Number"
                value={e.payload.toString()}
                onChange={(event: any) => {handleChangeNumber(i, event)}}
                />
            </ListItem>
                )
              }
              else if(e.type === "parentheses")
              {
                
                return(
                  <ListItem style={{flexWrap: "wrap", paddingRight: 0}}key={i}>
                  <Tooltip title="Insert Line Above">
                    <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, i + 1)}
                      style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl[i + 1]}
                  keepMounted
                  open={Boolean(anchorEl[i + 1])}
                  onClose={() => closeInsertList(i + 1)}
                  >
                <MenuItem onClick={() => addSeriesLine(i)}>Series / Map</MenuItem>
                <MenuItem onClick={() => addOperator(i)}>Operator</MenuItem>
                <MenuItem onClick={() => addNumber(i)}>Number</MenuItem>
                <MenuItem onClick={() => addConstant(i)} >Constant</MenuItem>
                <MenuItem onClick={() => addParentheses(i)}>Parentheses</MenuItem>
              </Menu>
              <Tooltip title="Remove Line">
                              <IconButton aria-label="add" onClick={(event: any) => removeLine(i)}
                                style={{ backgroundColor:"#c5093b", color: "white", padding: "5px", marginLeft: "5px", marginRight: '20px'}} >
                                  <RemoveIcon />
                              </IconButton>
                          </Tooltip>
              <Typography style={{paddingLeft: "1%", paddingRight: "53%"}} variant="body2"><span style={{ fontSize: '1.4em', fontWeight: 'bold' }}>(</span></Typography>
                <div className={classes.formControl} style={{display: "flex"}}>
                  <FormControl style={{width: "100%"}}>
                        <InputLabel id="func-label">Function</InputLabel>
                        <Select
                          label="Function"
                          labelId="func-label"
                          id="func-select"
                          value={e.payload.function}
                          onChange={(event: any) => selectFunction(i, event)}
                        >
                          {FUNCTIONS.map((f, j) =>{
                            return(
                              <MenuItem value={f.value} key={f.value}>{f.name}</MenuItem>
                            )
                          })}
                        </Select>
                  </FormControl>
                  {e.payload.function === 'lag' && <FormControl style={{width: '60%', marginLeft: '8px'}}>
                        <InputLabel id="k-label">k</InputLabel>
                        <Select
                          label="k"
                          labelId="k-label"
                          id="k-select"
                          value={e.payload.k}
                          onChange={(event: any) => selectK(i, event)}
                        >
                          <MenuItem value={-1}>k = -1 (lead)</MenuItem>
                          <MenuItem value={1}>k = 1 (first-order)</MenuItem>
                          <MenuItem value={2}>k = 2 (second-order)</MenuItem>
                        </Select>
                  </FormControl>}
                </div>
              <div style={{width: "100%"}}>
              </div>
                  <ParenthesesExpressions sessionRecipe={sessionRecipe} nodeCounters={nodeCounters} isLoadingVizNode={isLoadingVizNode} expression={e.payload.expression} selectedFrequency={selectedFrequency} position={i} onChange={handleParenChange} parenCount={0} editNode={editNode} mapScale={mapScale} mapName={mapName} />
                  </ListItem>
                )
              }
              else if(e.type === "constant")
              {
                return(
                <ListItem key={i}>
                <Tooltip title="Insert Line Above">
                  <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, i + 1)}
                    style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                      <AddIcon />
                  </IconButton>
              </Tooltip>
              <Menu
            id="simple-menu"
            anchorEl={anchorEl[i + 1]}
            keepMounted
            open={Boolean(anchorEl[i + 1])}
            onClose={() => closeInsertList(i + 1)}
            >
              <MenuItem onClick={() => addSeriesLine(i)}>Series / Map</MenuItem>
              <MenuItem onClick={() => addOperator(i)}>Operator</MenuItem>
              <MenuItem onClick={() => addNumber(i)}>Number</MenuItem>
              <MenuItem onClick={() => addConstant(i)} >Constant</MenuItem>
              <MenuItem onClick={() => addParentheses(i)}>Parentheses</MenuItem>
            </Menu>
            <Tooltip title="Remove Line">
                              <IconButton aria-label="add" onClick={(event: any) => removeLine(i)}
                                style={{ backgroundColor:"#c5093b", color: "white", padding: "5px", marginLeft: "5px", marginRight: '20px'}} >
                                  <RemoveIcon />
                              </IconButton>
                          </Tooltip>
            <FormControl style={{width: "70%"}} className={classes.formControl}>
                <InputLabel id="con-label">Constant</InputLabel>
                <Select
                  label="Constant"
                  labelId="con-label"
                  value={e.payload}
                  onChange={(event: any) => selectConstant(i, event)}
                >
                  {CONSTANTS.map((c, j) =>{
                    return(
                      <MenuItem key={j} value={c.value}>{c.name}</MenuItem>
                    )
                  })}
                </Select>
          </FormControl>
          </ListItem>
                )
              }

            })}
            </List>}
          <Tooltip title="Add Line">
              <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, 0)}
              style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                  <AddIcon />
              </IconButton>
          </Tooltip>
          <Menu
              id="simple-menu"
              anchorEl={anchorEl[0]}
              keepMounted
              open={Boolean(anchorEl[0])}
              onClose={() => closeInsertList(0)}
          >
              <MenuItem onClick={() => addSeriesLine(expression.length)}>Series / Map</MenuItem>
              <MenuItem onClick={() => addOperator(expression.length)}>Operator</MenuItem>
              <MenuItem onClick={() => addNumber(expression.length)}>Number</MenuItem>
              <MenuItem onClick={() => addConstant(expression.length)} >Constant</MenuItem>
              <MenuItem onClick={() => addParentheses(expression.length)}>Parentheses</MenuItem>
          </Menu>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: '16px' }}>
        <Button onClick={clearExpression} variant="outlined" style={{color:"#026cb5", borderColor: "#026cb5", backgroundColor: "white"}}> Clear</Button>
        <Button 
          variant="contained" 
          // style={{ backgroundColor:"#026cb5", color: "white"}}
          disabled={isLoadingVizNode}
          onClick={() => {
            var { series_ids, maps_ids } = getSeriesIdsInExpression(expression)
            var series = [] as any[];
            series_ids.forEach((id: string) => {
              var s = sessionRecipe.find((s: any) => s.displayed && s.id === id)
              if (s !== undefined) {
                series.push(s)
              }
            })
            var maps = [] as any[]
            maps_ids.forEach((id: string) => {
              var m = sessionRecipe.find((m: any) => m.displayed && m.id === id)
              if (m !== undefined) {
                maps.push(m)
              }
            })
            var { outputShort, outputIds } = setShortNames(series)
            var { outputMapShort, outputMapIds } = setMapShortNames(maps)
            var outputDescription = processExpression(expression, series, outputShort, maps, outputMapShort)
            setSeriesUsed(series)
            setMapsUsed(maps)
            setDescription(outputDescription)
            if (editNode === undefined || (((editNode.meta.dataType === 'series' && maps.length === 0) || (editNode.meta.dataType === 'map' && maps.length > 0)) && editNode.meta.chart_attributes.map_scale === mapScale)) {
              dispatch(fetchCalculateSeries([...series, ...maps], expression, selectedFrequency, editNode));
              setEditMapError(false)
              if (editNode !== undefined) {
                handleClose()
              }
            } else {
              setEditMapError(true)
            }
          }}
        >
          {editNode !== undefined ? 'Update workspace' : 'Add to workspace'}
        </Button>
        <Button onClick={handleClose} variant="contained" style={{ backgroundColor:"#c5093b", color: "white"}} >
          cancel
        </Button>
      </DialogActions>
    </Dialog>

    </div>
  );
}


export interface ParenProps {
  sessionRecipe: any[],
  nodeCounters: any,
  isLoadingVizNode: boolean,
  position: number,
  selectedFrequency: string,
  mapScale: string,
  mapName: string,
  onChange: (p: number, expression: Expression[], frequency: string, map_scale:string, map_name:string, used: any[], usedMaps: any[]) => void,
  expression: Expression[],
  parenCount: number,
  editNode?: any,
} 

function ParenthesesExpressions(props: ParenProps) {
  const classes = useStyles();

  const FUNCTIONS = [{name: "None", value: "none"}, {name: "Natural Log", value: "ln"} ,{ name: "Absolute Value", value: "abs"}, {name: "Square Root", value: "sqrt"},
  {name: "Negative", value: "negative"}, {name: "e^x", value: "e_x"}, {name: "Lag (t - k)", value: "lag"}];
  const OPERATORS = ["+", "-", "*", "/", "^"];
  const CONSTANTS = [{name: "Pi", value: "pi"}, {name: "e (Euler's number)", value: "e"}]
  const [anchorEl, setAnchorEl] = React.useState<(null | HTMLElement)[]>([null]);
  const [expression, setExpression] = React.useState<Expression[]>(props.expression); 
  const [selectedFrequency, setSelectedFrequencyState] = React.useState<string>(props.selectedFrequency);
  const [mapScale, setMapScaleState] = useState<string>(props.mapScale);
  const [mapName, setMapNameState] = useState<string>(props.mapName);
  const [seriesUsed, setSeriesUsed] = React.useState<any[]>([] as any[]);
  const [mapsUsed, setMapsUsed] = React.useState<any[]>([] as any[]);

  const { sessionRecipe, nodeCounters, isLoadingVizNode, editNode } = props

  useEffect(() =>{
    if (selectedFrequency !== props.selectedFrequency) {
      setSelectedFrequency(props.selectedFrequency)
    }
  }, [props.selectedFrequency]);

  useEffect(() =>{
    if (mapScale !== props.mapScale) {
      setMapScale(props.mapScale)
    }
  }, [props.mapScale]);

  useEffect(() =>{
    if (mapName !== props.mapName) {
      setMapName(props.mapName)
    }
  }, [props.mapName]);

  useEffect(() =>{
    var s = Object.assign([], seriesUsed) as any[];
    var m = Object.assign([], mapsUsed) as any[];
    expression.forEach((e) =>{
      if(e.type === "series" )
      {
        if (e.payload.isMap) {
          if(m.length === 0 && sessionRecipe.find((se: any) => se.id === e.payload.id))
          {
            m.push(sessionRecipe.find((se: any) => se.id === e.payload.id) as any);
          }
          else if(m.findIndex((dup: any) => dup.id === e.payload.id) === -1 && sessionRecipe.find((se: any) => se.id === e.payload.id)){
            m.push(sessionRecipe.find((se: any) => se.id === e.payload.id) as any);
          }
        } else {
          if(s.length === 0 && sessionRecipe.find(se => se.id === e.payload.id))
          {
            s.push(sessionRecipe.find((se: any) => se.id === e.payload.id) as any);
          }
          else if(s.findIndex(dup => dup.id === e.payload.id) === -1 && sessionRecipe.find((se: any) => se.id === e.payload.id)){
            s.push(sessionRecipe.find((se: any) => se.id === e.payload.id) as any);
          }
        }
      }
    })
    props.onChange(props.position, expression, selectedFrequency, mapScale, mapName, s, m);
    setSeriesUsed(s);
    setAnchorEl(Array(expression.length).fill(null))
  }, [expression]);

  function setSelectedFrequency(f: string) {
    if (editNode === undefined) {
      setSelectedFrequencyState(f)
    }
  }

  function setMapScale(s: string) {
    if (editNode === undefined) {
      setMapScaleState(s)
    }
  }

  function setMapName(s: string) {
    if (editNode === undefined) {
      setMapNameState(s)
    }
  }

  const openInsertList = (event: React.MouseEvent<HTMLElement>, position: number) => {
    var temp = Object.assign([], anchorEl) as (HTMLElement | null)[];
    temp[position] = event.currentTarget;
    setAnchorEl(temp);
  }; 

  const closeInsertList = (position: number) =>{
    var temp = Object.assign([], anchorEl) as (HTMLElement | null)[];
    temp[position] = null;
    setAnchorEl(temp);
  }

  const addSeriesLine = (position: number) =>{
    if(expression.length > 0)
    {
      var temp = [] as Expression[];
      if(position === expression.length)
      {
        temp = Object.assign([], expression) as Expression[];
        temp.push({type: "series", payload: {function: "none", k: 1, id: "", isMap: false, value: "", map_scale: "", map_name: ""} as any})
      }
      else
      {
        expression.forEach((e, i) => {
          if(i === position){
            temp.push({type: "series", payload: {function: "none", k: 1, id: "", isMap: false, value: "", map_scale: "", map_name: ""} as any});
            temp.push(e);
          }
          else
          {
            temp.push(e);
          }
        })
      }
      setExpression(temp);
    }
    else
    {
        setExpression([{type: "series", payload: {function: "none", k: 1, id: "", isMap: false, value: "", map_scale: "", map_name: ""} as any}]);
    }
}

const addOperator = (position: number) =>{
  if(expression.length > 0)
    {
      var temp = [] as Expression[];
      if(position === expression.length)
      {
        temp = Object.assign([], expression) as Expression[];
        temp.push({type: "operator", payload: ""})
      }
      else
      {
        expression.forEach((e, i) => {
          if(i === position){
            temp.push({type: "operator", payload: ""});
            temp.push(e);
          }
          else
          {
            temp.push(e);
          }
        })
      }
      setExpression(temp);
    }
    else
    {
        setExpression([{type: "operator", payload: ""}]);
    }
}
const addParentheses = (position: number) =>{
  if(expression.length > 0)
    {
      var temp = [] as Expression[];
      if(position === expression.length)
      {
        temp = Object.assign([], expression) as Expression[];
        temp.push({type: "parentheses", payload: {function: "none", k: 1, expression: [] as Expression[]}})
      }
      else
      {
        // console.log(position);
        expression.forEach((e, i) => {
          if(i === position){
            temp.push({type: "parentheses", payload: {function: "none", k: 1, expression: [] as Expression[]}});
            temp.push(e);
            // console.log(temp);
          }
          else
          {
            temp.push(e);
          }
        })
      }
      // console.log(temp);
      setExpression(temp);
    }
    else
    {
        setExpression([{type: "parentheses", payload: {function: "none", k: 1, expression: [] as Expression[]}}]);
        // console.log(expression);
    }
}
  const addConstant = (position: number) =>{
    if(expression.length > 0)
      {
        var temp = [] as Expression[];
        if(position === expression.length)
        {
          temp = Object.assign([], expression) as Expression[];
          temp.push({type: "constant", payload: ""})
        }
        else
        {
          expression.forEach((e, i) => {
            if(i === position){
              temp.push({type: "constant", payload: ""});
              temp.push(e);
            }
            else
            {
              temp.push(e);
            }
          })
        }
        // console.log(temp);
        setExpression(temp);
      }
      else
      {
          setExpression([{type: "constant", payload: ""}]);
      }
  }

const addNumber = (position: number) =>{
  if(expression.length > 0)
    {
      var temp = [] as Expression[];
      if(position === expression.length)
      {
        temp = Object.assign([], expression) as Expression[];
        temp.push({type: "number", payload: ""})
      }
      else
      {
        // console.log(position);
        expression.forEach((e, i) => {
          if(i === position){
            temp.push({type: "number", payload: ""});
            temp.push(e);
            // console.log(temp);
          }
          else
          {
            temp.push(e);
          }
        })
      }
      // console.log(temp);
      setExpression(temp);
    }
    else
    {
        setExpression([{type: "number", payload: ""}]);
        // console.log(expression);
    }
    }

    const selectSeries = (position: number, event: React.ChangeEvent<{ value: unknown }>) =>{
      var temp = Object.assign([], expression) as Expression[];
      if(temp[position].type === "series")
      {
        if (event.target.value === '') {
          temp[position].payload.id = '';
          setSelectedFrequency('');
          setMapScale('');
          setMapName('');
        } else {
          var selectedString = (event.target.value as String).split(';');
          temp[position].payload.id = selectedString[0];
          temp[position].payload.value = event.target.value as String;
          if(selectedFrequency === "")
          {
            setSelectedFrequency(selectedString[1]);
          }
          var isMap = selectedString[2] === 'map'
          temp[position].payload.isMap = isMap;
          if (isMap) {
            temp[position].payload.map_scale = selectedString[3];
            temp[position].payload.map_name = selectedString[4];
          } else {
            temp[position].payload.map_scale = "";
            temp[position].payload.map_name = "";
          }
        }
        setExpression(temp);
      }

  }

  const selectFunction = (position: number, event: React.ChangeEvent<{value: unknown}>) =>{
    var temp = Object.assign([], expression) as Expression[]; 
    if(temp[position].type === "series")
    {
      temp[position].payload.function = event.target.value as string;
      setExpression(temp);
    }
    if(temp[position].type === "parentheses")
    {
      temp[position].payload.function = event.target.value as string;
      setExpression(temp);
    }

  }

  const selectK = (position: number, event: React.ChangeEvent<{value: unknown}>) =>{
    var temp = Object.assign([], expression) as Expression[]; 
    if(temp[position].type === "series" || temp[position].type === "parentheses")
    {
      temp[position].payload.k = event.target.value as number;
      setExpression(temp);
    }


  }

  const selectOperator = (position: number, event: React.ChangeEvent<{value: unknown}>) =>{
    var temp = Object.assign([], expression) as Expression[];
    if(temp[position].type === "operator")
    {
      temp[position].payload = event.target.value as string;
      setExpression(temp);
    }
  } 

  const handleChangeNumber = (position: number, event: React.ChangeEvent<HTMLInputElement>) => {
    var temp = Object.assign([], expression) as Expression[];
    if(temp[position].type === "number")
    {
      temp[position].payload = event.target.value as string;
      setExpression(temp);
    }
  }

  const selectConstant = (position: number, event: React.ChangeEvent<{value: unknown}>) =>{
    var temp = Object.assign([], expression) as Expression[];
    if(temp[position].type === "constant")
    {
      temp[position].payload = event.target.value as string;
      setExpression(temp);
    }
  }

  const handleParenChange = (position: number, exp: Expression[], frequency: string, map_scale: string, map_name: string, used: any[], usedMaps: any[]) =>{
    var temp = Object.assign([], expression) as Expression[];
    setSeriesUsed(used);
    setMapsUsed(usedMaps)
    if(frequency !== selectedFrequency){
      setSelectedFrequency(frequency);
    }
    if(mapScale === '' && map_scale !== mapScale){
      setMapScale(map_scale);
    }
    if(mapName === '' && map_name !== mapName){
      setMapName(map_name);
    }
    if(temp[position].type === "parentheses")
    {
      temp[position].payload.expression = exp;
      setExpression(temp);
    }
    
  }

  const removeLine = (position: number) =>{
    var temp = Object.assign([], expression) as Expression[];
    var removed = temp.splice(position, 1);
    if(removed[0].type === "series"){
      if(temp.findIndex(e => e.type === "series") === -1)
      {
        setSelectedFrequency("");
        setMapScale('');
        setMapName('');
      }
      else
      {
        var i = temp.findIndex(e => e.type === "series");
        if(temp[i].payload.id === "")
        {
          setSelectedFrequency("");
          setMapScale('');
          setMapName('');
        }
      }
    }
    setExpression(temp);
  }

  return(
  <div style={{width: "100%", paddingTop: 5}}>    
            {expression.length > 0 && <List>
          {expression.map((e, i) =>{
            if(e.type === "series")
            {
              return(
                <ListItem key={i}>
                          <Tooltip title="Insert Line Above">
                            <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, i + 1)}
                              style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                     <Menu
                      id="simple-menu"
                      anchorEl={anchorEl[i + 1]}
                      keepMounted
                      open={Boolean(anchorEl[i + 1])}
                      onClose={() => closeInsertList(i + 1)}
                      >
                        <MenuItem onClick={() => addSeriesLine(i)}>Series / Map</MenuItem>
                        <MenuItem onClick={() => addOperator(i)} >Operator</MenuItem>
                        <MenuItem onClick={() => addNumber(i)}>Number</MenuItem>
                        <MenuItem onClick={() => addConstant(i)}>Constant</MenuItem>
                        <MenuItem disabled={props.parenCount === 1} onClick={() => addParentheses(i)}>Parentheses</MenuItem>
                      </Menu>
                      <Tooltip title="Remove Line">
                            <IconButton aria-label="add" onClick={(event: any) => removeLine(i)}
                              style={{ backgroundColor:"#c5093b", color: "white", padding: "5px", marginLeft: "5px", marginRight: '20px'}} >
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                      <FormControl style={{width: "70%"}} className={classes.formControl}>
                          <InputLabel id="series-label">Series / Map</InputLabel>
                          <Select
                            label="Series / Map"
                            labelId="series-label"
                            value={e.payload.value}
                            onChange={(event: any) => selectSeries(i, event)}
                          >
                            {(sessionRecipe.length > 0) 
                              ? sessionRecipe.map((node: any, i: number) => {
                                var output = [] as any[]
                                if (editNode === undefined || (node.id !== editNode.id && node.meta && node.meta.ancestors && !node.meta.ancestors.includes(editNode.id) && node.meta.dataType && (node.meta.dataType === 'series' || node.meta.dataType === editNode.meta.dataType))) {
                                  if (node.displayed && (node.meta.dataType === undefined || node.meta.dataType === 'series') && (editNode !== undefined || nodeCounters.series < MAX_DATA_VIZ || nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
                                    if (node.result.series !== null && node.result.series.length > 0) {
                                      var frequency = node.result.series[0].frequency
                                      var displayRank = 1000;
                                      if (frequency !== null && frequencySettings[frequency] !== undefined) {
                                        displayRank = frequencySettings[frequency].displayRank
                                      }
                                      if (displayRank < 1000 && (selectedFrequency === "" || selectedFrequency === frequency)) {
                                        output.push(<MenuItem value={node.id + ';' + frequency + ';ts'} key={node.id + 'calculate'}>{node.name + ', ' + frequency}</MenuItem>)
                                      }
                                    }
                                  } else if (node.displayed && (node.meta.dataType !== undefined && node.meta.dataType === 'map') && (editNode !== undefined || nodeCounters.maps < MAX_MAP_DATA_VIZ)) {
                                    if (node.result.maps !== null && node.result.maps.length > 0 && node.result.maps[0].children.length > 0) {
                                      var frequency = node.result.maps[0].children[0].frequency
                                      var map_scale = node.result.maps[0].map_scale
                                      var map_name = node.meta.chart_attributes.map_location_name
                                      var displayRank = 1000;
                                      if (frequency !== null && frequencySettings[frequency] !== undefined) {
                                        displayRank = frequencySettings[frequency].displayRank
                                      }
                                      var mapScale_ancestor = []
                                      if (mapScaleInfo[mapScale]) {
                                        mapScale_ancestor = mapScaleInfo[mapScale].ancestors
                                      }
                                      var map_scale_ancestor = []
                                      if (mapScaleInfo[map_scale]) {
                                        map_scale_ancestor = mapScaleInfo[map_scale].ancestors
                                      }
                                      if (displayRank < 1000 && (selectedFrequency === "" || selectedFrequency === frequency) 
                                        && (
                                          ((mapScale === '' || map_scale === mapScale) && (mapName === '' || map_name === mapName || ((map_scale === 'county' || map_scale === 'city') && (map_name.includes('US ') || mapName.includes('US ')))))
                                          || (mapScale_ancestor.includes(map_scale) || map_scale_ancestor.includes(mapScale))
                                        )
                                      ) {
                                        output.push(<MenuItem value={node.id + ';' + frequency + ';map;' + map_scale + ';' + map_name} key={node.id + 'calculate'}>
                                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div className="mapIcon mapIcon_inSelect">
                                              <div className="mapIcon_map">Map</div>
                                              {node.meta.chart_attributes.map_scale && <div>{(node.meta.chart_attributes.map_location_abbreviation !== '') ? node.meta.chart_attributes.map_location_abbreviation : node.meta.chart_attributes.map_scale}</div>}
                                            </div>
                                            {node.name + ', ' + frequency}
                                          </div>
                                        </MenuItem>)
                                      }
                                    }
                                  }
                                }
                                return output
                              })
                              : <MenuItem value=""><em>No series available.</em></MenuItem>
                            }
                            {(editNode === undefined && nodeCounters.series >= MAX_DATA_VIZ && nodeCounters.maps >= MAX_MAP_DATA_VIZ) && <MenuItem value="" disabled><em>Max time series in workspace reached</em></MenuItem>}
                            {(editNode === undefined && nodeCounters.maps >= MAX_MAP_DATA_VIZ) && <MenuItem value="" disabled><em>Max maps in workspace reached</em></MenuItem>}
                          </Select>
                    </FormControl>
                    <div className={classes.formControl} style={{display: "flex"}}>
                      <FormControl style={{width: "100%"}}>
                            <InputLabel id="func-label">Function</InputLabel>
                            <Select
                              label="Function"
                              labelId="func-label"
                              id="func-select"
                              value={e.payload.function}
                              onChange={(event: any) => selectFunction(i, event)}
                            >
                              {FUNCTIONS.map((f, j) =>{
                                return(
                                  <MenuItem value={f.value} key={f.value}>{f.name}</MenuItem>
                                )
                              })}
                            </Select>
                      </FormControl>
                      {e.payload.function === 'lag' && <FormControl style={{width: '60%', marginLeft: '8px'}}>
                            <InputLabel id="k-label">k</InputLabel>
                            <Select
                              label="k"
                              labelId="k-label"
                              id="k-select"
                              value={e.payload.k}
                              onChange={(event: any) => selectK(i, event)}
                            >
                              <MenuItem value={-1}>k = -1 (lead)</MenuItem>
                              <MenuItem value={1}>k = 1 (first-order)</MenuItem>
                              <MenuItem value={2}>k = 2 (second-order)</MenuItem>
                            </Select>
                      </FormControl>}
                    </div>
                </ListItem>
              )
            }
            else if(e.type === "operator")
            {
              return(
                <ListItem key={i}>
                <Tooltip title="Insert Line Above">
                  <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, i + 1)}
                    style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                      <AddIcon />
                  </IconButton>
              </Tooltip>
           <Menu
            id="simple-menu"
            anchorEl={anchorEl[i + 1]}
            keepMounted
            open={Boolean(anchorEl[i + 1])}
            onClose={() => closeInsertList(i + 1)}
            >
              <MenuItem onClick={() => addSeriesLine(i)}>Series / Map</MenuItem>
              <MenuItem onClick={() => addOperator(i)}>Operator</MenuItem>
              <MenuItem onClick={() => addNumber(i)}>Number</MenuItem>
              <MenuItem onClick={() => addConstant(i)}>Constant</MenuItem>
              <MenuItem disabled={props.parenCount === 1}  onClick={() => addParentheses(i)}>Parentheses</MenuItem>
            </Menu>
            <Tooltip title="Remove Line">
                            <IconButton aria-label="add" onClick={(event: any) => removeLine(i)}
                              style={{ backgroundColor:"#c5093b", color: "white", padding: "5px", marginLeft: "5px", marginRight: '20px'}} >
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
            <FormControl style={{width: "70%"}} className={classes.formControl}>
                <InputLabel id="op-label">Operator</InputLabel>
                <Select
                  label="Operator"
                  labelId="op-label"
                  value={e.payload}
                  onChange={(event: any) => selectOperator(i, event)}
                  classes={{ root: classes.operatorSelectRoot }}
                >
                  {OPERATORS.map((op, j) =>{
                    return(
                      <MenuItem style={{fontSize: "1.2em"}} value={op} key={op}>{op}</MenuItem>
                    )
                  })}
                </Select>
          </FormControl>
          </ListItem>
              )
            }
            else if(e.type === "number")
            {
              return(
                <ListItem key={i}>
                <Tooltip title="Insert Line Above">
                  <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, i + 1)}
                    style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                      <AddIcon />
                  </IconButton>
              </Tooltip>
           <Menu
            id="simple-menu"
            anchorEl={anchorEl[i + 1]}
            keepMounted
            open={Boolean(anchorEl[i + 1])}
            onClose={() => closeInsertList(i + 1)}
            >
              <MenuItem onClick={() => addSeriesLine(i)}>Series / Map</MenuItem>
              <MenuItem onClick={() => addOperator(i)}>Operator</MenuItem>
              <MenuItem onClick={() => addNumber(i)}>Number</MenuItem>
              <MenuItem onClick={() => addConstant(i)}>Constant</MenuItem>
              <MenuItem disabled={props.parenCount === 1}  onClick={() => addParentheses(i)}>Parentheses</MenuItem>
            </Menu>
            <Tooltip title="Remove Line">
                            <IconButton aria-label="add" onClick={(event: any) => removeLine(i)}
                              style={{ backgroundColor:"#c5093b", color: "white", padding: "5px", marginLeft: "5px", marginRight: '20px'}} >
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
            <TextField
              style={{width: "70%", marginLeft: "7px"}}
              label="Number"
              value={e.payload.toString()}
              onChange={(event: any) => {handleChangeNumber(i, event)}}
              />
          </ListItem>
              )
            }
            else if(e.type === "parentheses")
            {
              
              return(
                <ListItem style={{flexWrap: "wrap"}}key={i}>
                <Tooltip title="Insert Line Above">
                  <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, i + 1)}
                    style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                      <AddIcon />
                  </IconButton>
              </Tooltip>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl[i + 1]}
                keepMounted
                open={Boolean(anchorEl[i + 1])}
                onClose={() => closeInsertList(i + 1)}
                >
              <MenuItem onClick={() => addSeriesLine(i)}>Series / Map</MenuItem>
              <MenuItem onClick={() => addOperator(i)}>Operator</MenuItem>
              <MenuItem onClick={() => addNumber(i)}>Number</MenuItem>
              <MenuItem onClick={() => addConstant(i)}>Constant</MenuItem>
              <MenuItem disabled={props.parenCount === 1}  onClick={() => addParentheses(i)}>Parentheses</MenuItem>
            </Menu>
            <Tooltip title="Remove Line">
                            <IconButton aria-label="add" onClick={(event: any) => removeLine(i)}
                              style={{ backgroundColor:"#c5093b", color: "white", padding: "5px", marginLeft: "5px", marginRight: '20px'}} >
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
            <Typography style={{paddingLeft: "1%", paddingRight: "53.7%"}} variant="body2"><span style={{ fontSize: '1.4em', fontWeight: 'bold' }}>(</span></Typography>
                <div className={classes.formControl} style={{display: "flex"}}>
                  <FormControl style={{width: "100%"}}>
                        <InputLabel id="func-label">Function</InputLabel>
                        <Select
                          label="Function"
                          labelId="func-label"
                          id="func-select"
                          value={e.payload.function}
                          onChange={(event: any) => selectFunction(i, event)}
                        >
                          {FUNCTIONS.map((f, j) =>{
                            return(
                              <MenuItem value={f.value} key={f.name}>{f.name}</MenuItem>
                            )
                          })}
                        </Select>
                  </FormControl>
                  {e.payload.function === 'lag' && <FormControl style={{width: '60%', marginLeft: '8px'}}>
                        <InputLabel id="k-label">k</InputLabel>
                        <Select
                          label="k"
                          labelId="k-label"
                          id="k-select"
                          value={e.payload.k}
                          onChange={(event: any) => selectK(i, event)}
                        >
                          <MenuItem value={-1}>k = -1 (lead)</MenuItem>
                          <MenuItem value={1}>k = 1 (first-order)</MenuItem>
                          <MenuItem value={2}>k = 2 (second-order)</MenuItem>
                        </Select>
                  </FormControl>}
                </div>
            <div style={{width: "100%"}}>
            </div>
                <ParenthesesExpressions expression={e.payload.expression} selectedFrequency={selectedFrequency} sessionRecipe={sessionRecipe} nodeCounters={nodeCounters} isLoadingVizNode={isLoadingVizNode} position={i} onChange={handleParenChange} parenCount={1} editNode={editNode} mapScale={mapScale} mapName={mapName} />
                </ListItem>
              )
            }
            else if(e.type === "constant")
            {
              return(
              <ListItem key={i}>
              <Tooltip title="Insert Line Above">
                <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, i + 1)}
                  style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Menu
          id="simple-menu"
          anchorEl={anchorEl[i + 1]}
          keepMounted
          open={Boolean(anchorEl[i + 1])}
          onClose={() => closeInsertList(i + 1)}
          >
            <MenuItem onClick={() => addSeriesLine(i)}>Series / Map</MenuItem>
            <MenuItem onClick={() => addOperator(i)}>Operator</MenuItem>
            <MenuItem onClick={() => addNumber(i)}>Number</MenuItem>
            <MenuItem onClick={() => addConstant(i)} >Constant</MenuItem>
            <MenuItem disabled={props.parenCount === 1}  onClick={() => addParentheses(i)}>Parentheses</MenuItem>
          </Menu>
          <Tooltip title="Remove Line">
                            <IconButton aria-label="add" onClick={(event: any) => removeLine(i)}
                              style={{ backgroundColor:"#c5093b", color: "white", padding: "5px", marginLeft: "5px", marginRight: '20px'}} >
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
          <FormControl style={{width: "70%"}} className={classes.formControl}>
              <InputLabel id="con-label">Constant</InputLabel>
              <Select
                label="Constant"
                labelId="con-label"
                value={e.payload}
                onChange={(event: any) => selectConstant(i, event)}
              >
                {CONSTANTS.map((c, j) =>{
                  return(
                    <MenuItem key={j} value={c.value}>{c.name}</MenuItem>
                  )
                })}
              </Select>
        </FormControl>
        </ListItem>
              )
            }
          })}
          </List>}   
          <div style={{display: "flex", alignItems: "center"}}>
          <Tooltip title="Add Line">
            <IconButton aria-label="add" onClick={(event: any) => openInsertList(event, 0)}
            style={{ backgroundColor:"#026cb5", color: "white", padding: "5px"}} >
                <AddIcon />
            </IconButton>
        </Tooltip>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl[0]}
            keepMounted
            open={Boolean(anchorEl[0])}
            onClose={() => closeInsertList(0)}
        >
            <MenuItem onClick={() => addSeriesLine(expression.length)}>Series / Map</MenuItem>
            <MenuItem onClick={() => addOperator(expression.length)}>Operator</MenuItem>
            <MenuItem onClick={() => addNumber(expression.length)}>Number</MenuItem>
            <MenuItem onClick={() => addConstant(expression.length)}>Constant</MenuItem>
            <MenuItem disabled={props.parenCount === 1}  onClick={() => addParentheses(expression.length)}>Parentheses</MenuItem>
        </Menu>
        <Typography style={{paddingLeft: "35px"}} variant="body2"><span style={{ fontSize: '1.4em', fontWeight: 'bold' }}>)</span></Typography>
          </div>

  </div>)

}