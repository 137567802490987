import React, { useEffect, useState } from 'react';
import * as Highcharts from 'highcharts';
import NoData from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
// import { Series } from '../SeriesPage/SeriesPage';
import { frequencySettings, ONE_DAY } from '../definitions/FrequencySettings';
import { stateInfo } from '../definitions/StateInfo';
import { connect } from 'react-redux';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ReactDOMServer from 'react-dom/server';

const lodash = require('lodash');

NoData(Highcharts)
Exporting(Highcharts)
OfflineExporting(Highcharts)

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
    thousandsSep: ',',
    numericSymbols: ['k', 'M', 'B', 'T'],
    contextButtonTitle: 'Download chart image',
  }
});

Highcharts.SVGRenderer.prototype.symbols.download = () => {
  const iconString = ReactDOMServer.renderToString(<PhotoCameraIcon />);
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(iconString, 'image/svg+xml');
  const iconPath = svgDoc.querySelector('path')?.getAttribute('d') as string;

  return [iconPath];
}

function parseSeriesToDataSeq(mapSeries: any, frequencyType = 'NotSet', includeSummary: boolean) {
  var parsed = {} as any;
  var min: number = Number.MAX_SAFE_INTEGER;
  var max: number = Number.MIN_SAFE_INTEGER;

  mapSeries.forEach((m: any) => {
    if (m.maps !== null) {
      if (m.chart_attributes.showAll) {
        m.maps[0].children.forEach((s: any, j: number) => {
          if (includeSummary || m.maps[0].summary_row === null || s.locationCode !== m.maps[0].summary_row) {
            var fips = s.locationCode
            var locationName = s.location
            if (stateInfo[fips] !== undefined) {
              locationName = stateInfo[fips].name
            }
            s.data_points.forEach((item: any) => {
              var dateUTC = Date.parse(item.key)
              if (parsed[dateUTC] === undefined) {
                parsed[dateUTC] = {
                  type: 'column',
                  name: m.name,
                  frequency: frequencyType,
                  // data_type: m.map_attributes.data_type !== undefined ? m.map_attributes.data_type : '',
                  yAxis: 0,// m.chart_attributes.axisRight ? 1 : 0,
                  data: [] as any[],
                  numDecimals: 0
                }
              }

              var value = parseFloat(item.value)
              if (!isNaN(value)) {
                var numDec = countDecimals(value)
                if (numDec > parsed[dateUTC].numDecimals) {
                  parsed[dateUTC].numDecimals = numDec
                }
                if (value > max) {
                  max = value
                }
                if (value < min) {
                  min = value
                }
                parsed[dateUTC].data.push({
                  name: locationName,
                  fips: fips === '-1' ? locationName : fips,
                  y: value
                })
              } else {
                parsed[dateUTC].data.push({
                  name: locationName,
                  fips: fips === '-1' ? locationName : fips,
                  y: null
                })
              }
            })
          }
        })
      } else {
        m.chart_attributes.showStates.forEach((location: any, j: number) => {
          var s: any = m.maps[0].children.find((s: any) => s.locationCode === location.locationCode && (s.locationCode !== '-1' || s.location === location.location))
          if (s !== undefined) {
            s.data_points.forEach((item: any) => {
              var dateUTC = Date.parse(item.key)
              if (parsed[dateUTC] === undefined) {
                parsed[dateUTC] = {
                  type: 'column',
                  name: m.name,
                  frequency: frequencyType,
                  // data_type: m.map_attributes.data_type !== undefined ? m.map_attributes.data_type : '',
                  yAxis: 0,// m.chart_attributes.axisRight ? 1 : 0,
                  data: [] as any[],
                  numDecimals: 0
                }
              }
    
              var value = parseFloat(item.value)
              if (!isNaN(value)) {
                var numDec = countDecimals(value)
                if (numDec > parsed[dateUTC].numDecimals) {
                  parsed[dateUTC].numDecimals = numDec
                }
                if (value > max) {
                  max = value
                }
                if (value < min) {
                  min = value
                }
                var locationName = location.location
                if (stateInfo[location.locationCode] !== undefined) {
                  locationName = stateInfo[location.locationCode].name
                }
                parsed[dateUTC].data.push({
                  name: locationName,
                  fips: location.locationCode,
                  y: value
                })
              } else {
                parsed[dateUTC].data.push({
                  name: locationName,
                  fips: location.locationCode,
                  y: null
                })
              }
            })
          }
        })
      }
    }
  })

  return {data_seq: parsed, min, max}
}

var countDecimals = function (value: number) {
  if (Math.floor(value) === value) {
    return 0
  } else {
    return value.toString().split(".")[1].length || 0;
  }
}

function StatesBarChart(props: HighchartsReact.Props) {
  const { forwardedRef, mapPrimary, mapSeries, frequency, dateUTC, fixedAxis, includeSummary, sessionRecipeInfo } = props;
  const [chartoptions, setChartOptionsState] = useState({
    credits: {
      enabled: false
    },
    exporting: {
      // sourceWidth: 800,
      enabled: false,
      buttons: {
        contextButton: {
          verticalAlign: 'bottom',
          symbol: 'download',
          menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] // removed "viewFullscreen", "printChart", "separator", 
        }
      },
      chartOptions: {
        title: {
          y: -10
        },
        caption: {
          text: '<a href="' + window.location.href + '">' + window.location.href + '</a>',
        },
      },
    },
    title: {
      text: ''
    },
    series: []
  } as Highcharts.Options)
  const [dataSeq, setDataSeq] = useState({} as any)
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(0)

  useEffect(() => {
    if (dataSeq[dateUTC] !== undefined) {
      setChartOptions(dataSeq[dateUTC], min, max, frequency, dateUTC, fixedAxis);
    } else {
      var exportFilename: string = 'No Data';
      var dateString = ''
      var exportEnabled = false
      if (mapPrimary !== null && mapPrimary.name !== '') {
        dateString = (frequencySettings[frequency] !== undefined) ? frequencySettings[frequency].dateFormatter(dateUTC) : frequencySettings['NotSet'].dateFormatter(dateUTC)
        exportFilename = ''
        exportEnabled = true
        var seriesName = mapPrimary.name
        if (seriesName.length > 50) {
          exportFilename += seriesName.substring(0,30) + ' . . . '
          exportFilename += seriesName.substring(seriesName.length - 10, seriesName.length)
        } else {
          exportFilename += seriesName
        }
        // exportFilename += ' (' + frequency + ')'
        exportFilename += ' - ' + dateString
      }

      exportFilename += ' - Bar Chart'

      setChartOptionsState({
        credits: {
          enabled: false
        },
        exporting: {
          // sourceWidth: 800,
          enabled: exportEnabled,
          filename: exportFilename,
          buttons: {
            contextButton: {
              // verticalAlign: 'bottom',
              symbol: 'download',
              menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] // removed "viewFullscreen", "printChart", "separator", 
            }
          },
        },
        title: {
          verticalAlign: 'bottom',
          text: dateString
        },
        series: []
      })
    }

    // on delay so chart height not prematurely set.
    setTimeout(()=>{
      if (forwardedRef.current !== null) {
        forwardedRef.current.chart.reflow()
      }
    }, 100)
  },[dateUTC]);

  useEffect(() => {
    if (mapPrimary !== null) {
      var { data_seq, min, max } = parseSeriesToDataSeq(mapSeries.concat([mapPrimary]), frequency, includeSummary)
      setDataSeq(data_seq)
      setMin(min)
      setMax(max)
      if (data_seq[dateUTC] !== undefined) {
        setChartOptions(data_seq[dateUTC], min, max, frequency, dateUTC, fixedAxis);
      } else {
        var exportFilename: string = 'No Data';
        var dateString = ''
        var exportEnabled = false
        if (mapPrimary !== null && mapPrimary.name !== '') {
          dateString = (frequencySettings[frequency] !== undefined) ? frequencySettings[frequency].dateFormatter(dateUTC) : frequencySettings['NotSet'].dateFormatter(dateUTC)
          exportFilename = ''
          exportEnabled = true
          var seriesName = mapPrimary.name
          if (seriesName.length > 50) {
            exportFilename += seriesName.substring(0,30) + ' . . . '
            exportFilename += seriesName.substring(seriesName.length - 10, seriesName.length)
          } else {
            exportFilename += seriesName
          }
          // exportFilename += ' (' + frequency + ')'
          exportFilename += ' - ' + dateString
        }

        exportFilename += ' - Bar Chart'

        setChartOptionsState({
          credits: {
            enabled: false
          },
          exporting: {
            // sourceWidth: 800,
            enabled: exportEnabled,
            filename: exportFilename,
            buttons: {
              contextButton: {
                // verticalAlign: 'bottom',
                symbol: 'download',
                menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] // removed "viewFullscreen", "printChart", "separator", 
              }
            },
          },
          title: {
            verticalAlign: 'bottom',
            text: dateString
          },
          series: []
        })
      }

      // on delay so chart height not prematurely set.
      setTimeout(()=>{
        if (forwardedRef.current !== null) {
          forwardedRef.current.chart.reflow()
        }
      }, 100)
    } else {
      setChartOptionsState({
        credits: {
          enabled: false
        },
        exporting: {
          // sourceWidth: 800,
          enabled: false,
          buttons: {
            contextButton: {
              verticalAlign: 'bottom',
              symbol: 'download',
              menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] // removed "viewFullscreen", "printChart", "separator", 
            }
          },
          chartOptions: {
            title: {
              y: -10
            },
            caption: {
              text: '<a href="' + window.location.href + '">' + window.location.href + '</a>',
            },
          },
        },
        title: {
          text: ''
        },
        series: []
      } as Highcharts.Options)
      setDataSeq({} as any)
      setMin(0)
      setMax(0)
    }

  },[mapPrimary, mapSeries, frequency, fixedAxis, sessionRecipeInfo.loading]);

  
  function setChartOptions(parsedSeries: any, min: number, max: number, frequency: string, dateUTC: number, fixedAxis: boolean) {
    var exportFilename: string = 'No Data';
    var dateString = (frequencySettings[frequency] !== undefined) ? frequencySettings[frequency].dateFormatter(dateUTC) : frequencySettings['NotSet'].dateFormatter(dateUTC)
    if (mapPrimary !== null && mapPrimary.name !== '') {
      exportFilename = ''
      var seriesName = mapPrimary.name
      if (seriesName.length > 50) {
        exportFilename += seriesName.substring(0,30) + ' . . . '
        exportFilename += seriesName.substring(seriesName.length - 10, seriesName.length)
      } else {
        exportFilename += seriesName
      }
      // exportFilename += ' (' + frequency + ')'
      exportFilename += ' - ' + dateString
    }

    exportFilename += ' - Bar Chart'
    
    var yAxis = [{
      opposite: false,
      min: fixedAxis ? min: undefined,
      max: fixedAxis ? max: undefined,
      title: {
        text: ''
      },
      labels: {
        x: -8,
        y: 3
      },
      // remove if multiple axis enabled
      plotLines: [{
        color: '#bbbbbb',
        width: 2,
        value:  0
      }]
    }, {
      opposite: true,
      title: {
        text: ''
      },
      labels: {
        y: mapSeries.length > 1 ? 3 : -2,
      },
    }] as any[]
    if (mapSeries.length < 6) {
      yAxis = yAxis.concat(mapSeries.map((s: any, i: number) => {
        var text = ''
        var unit = ''
        if (unit !== undefined && unit !== null) {
          text += unit;
        }
        if (mapSeries.length > 1) {
          text = '<span style="font-weight: 600">'
          var name = s.name
          if (s.chart_attributes.axisName !== undefined) {
            name = s.chart_attributes.axisName
          }
          if (name.length > 30) {
            text += name.substring(0,18) + ' . . . ' + name.substring(name.length - 9, name.length)
          } else {
            text += name
          }
          if (unit !== undefined && unit !== null) {
            text += '</span> - ' + unit
          }
        }
        return ({
          opposite: false,//s.chart_attributes.axisRight,
          visible: s.chart_attributes.showYAxis === undefined || s.chart_attributes.showYAxis,
          title: {
            text: text,
            style: {
              letterSpacing: '0.3px'
            }
          },
        })
      }))
    }
    setChartOptionsState({
      chart: {
        // height: parsedSeries.length > 1 ? Math.min(430 + 20 * parsedSeries.length, 710) : 400,
        animation: false,
        zoomType: 'xy',
        type: 'column'
      },
      credits: {
        enabled: false
      },
      lang: {
        noData: 'No data to display'
      },
      noData: {
        style: {
          fontSize: '15px',
        },
      },
      exporting: {
        // sourceWidth: 800,
        enabled: true,
        filename: exportFilename,
        buttons: {
          contextButton: {
            // verticalAlign: 'bottom',
            symbol: 'download',
            menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] // removed "viewFullscreen", "printChart", "separator", 
          }
        },
      },
      title: {
        verticalAlign: 'bottom',
        text: dateString
      },
      rangeSelector: {
        buttons: [{
          type: 'year',
          count: 5,
          text: '5Y'
        }, {
          type: 'year',
          count: 10,
          text: '10Y'
        }, {
          type: 'all',
          text: 'All'
        }],
        buttonTheme: {
          width: 37
        },
        selected: 2
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
      },
      yAxis: yAxis,
      tooltip: {
        split: false,
        useHTML: true,
        formatter() {
          var self: any = this;
  
          var periodDisplay = '<span style="font-size: 10px">' + self.series.chart.title.textStr;
          
          if (frequency === 'All') {
            periodDisplay += ' (' + self.series.userOptions.frequency + ')'
          }

          periodDisplay += '</span>'

          periodDisplay += '<br>' + self.point.name

          var unit = ''
          if (self.series.userOptions.data_type !== undefined && self.series.userOptions.data_type !== null && self.series.userOptions.data_type !== '') {
            unit = self.series.userOptions.data_type
          }

          if (unit !== '') {
            unit = ' (' + unit + ')'
          }

          var value = self.y
          var origValue = self.y
          var displayValue = ''
          var numDecimals = self.series.userOptions.numDecimals

          displayValue = '<b>' + Highcharts.numberFormat(value, numDecimals, '.', ',') + '</b>'

          var str = '<div style="overflow-wrap: break-word">'
          str += '<span style="font-size:11px">' + periodDisplay
          str += '</span>'
          str += '<br><span style="color:' + self.series.color + '">\u25CF</span> ' + self.series.name
          str += unit
          str += ': ' + displayValue
          str += '</div>'
  
          return str;
        }
      },
      plotOptions: {
        series: {
          showInNavigator: true,
          // animation: {
          //   duration: 1000
          // },
          animation: false,
          // compare: compare,
          dataGrouping: {
            enabled: false
          },
          label: {
            enabled: false
          },
        }
      },
      series: lodash.cloneDeep(parsedSeries) //JSON.parse(JSON.stringify(parsedSeries))
    })
  }

  return (
    <HighchartsReact
      {...props}
      ref={forwardedRef}
      highcharts={Highcharts}
      options={chartoptions}
      constructorType={'chart'}
      containerProps={{ style: { userSelect: "none", msUserSelect: "none", height: '100%' } }}
    />
  )
}

const mapStateToProps = (state: any) => ({
  sessionRecipeInfo: state.sessionRecipeInfo,
  // saveRecipeAlert: state.saveRecipeAlert,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect (mapStateToProps, mapDispatchToProps) (StatesBarChart);